import React from "react";
import css from "./ArtifactParameterNode.module.scss";
import { useDrag } from "react-dnd";
import { useDispatch } from "react-redux";
import { tokenCreationSetDrag } from "../../../../../../redux/actions/tokenCreationOptionsAction";

function ArtifactParameterNode({ data = "", id = "", type = "" }) {
    const dispatch = useDispatch();
    const [{ isDragging, opacity }, drag] = useDrag(
        () => ({
            type: "any",
            item: { data: data, type: type, id: id, module: "tedaas" },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
                opacity: monitor.isDragging() ? 0.5 : 1,
            }),
        }),
        [],
    );
    return (
        <div
            style={{ opacity: opacity }}
            ref={drag}
            className={css.container}
            onMouseOver={() => {
                dispatch(tokenCreationSetDrag(false));
            }}
            onMouseLeave={() => {
                dispatch(tokenCreationSetDrag(true));
            }}
        >
            <span className={css.text}>{data.title}</span>
        </div>
    );
}

export default ArtifactParameterNode;
