import React from "react";
import css from "./TokenomicsSimulationSearchParameter.module.scss";
import searchImg from "../../../TeDaaS-Assets/TokenomicsSimulation/Search.svg";

function TokenomicsSimulationSearchParameter({ search, setSearch, placeholder = "Search" }) {
    return (
        <div className={css.container}>
            <input className={css.input} type="text" value={search} onChange={(e) => setSearch(e.target.value)} placeholder={placeholder} />
            <div className={css.iconContainer}>
                {" "}
                <img className={css.icon} src={searchImg} alt={placeholder} />
            </div>
        </div>
    );
}

export default TokenomicsSimulationSearchParameter;
