import React from "react";
import css from "./TokenNameCard.module.scss";
import { EditOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { tokenCreationOpenModel } from "../../../../../redux/actions/tokenCreationOptionsAction";
import { shortenAddress } from "../../../Constants/reusableFunctions";

function TokenNameCard({ tokenName = "Token Name", objective = "objective", owner = "Assign owner", initialSupply = "$0.0", decimals = "$0.0" }) {
    const navigateTo = useNavigate();
    const dispatch = useDispatch();
    return (
        <div className={css.container}>
            <div className={css.header}>
                <span className={css.tokenName}>{tokenName}</span>
                <EditOutlined className={css.edit} onClick={() => {dispatch(tokenCreationOpenModel("newToken", "update"))}} />
            </div>
            <div className={css.content}>
                <span className={css.name}>Objective:</span>
                <span className={css.value}>{objective}</span>
            </div>
            <div className={css.content}>
                <span className={css.name}>Owner:</span>
                <span className={css.value}>
                    {shortenAddress(owner)}
                </span>
            </div>
            <div className={css.content}>
                <span className={css.name}>Initial Supply:</span>
                <span className={css.value}>{initialSupply}</span>
            </div>
            <div className={css.content}>
                <span className={css.name}>Decimals:</span>
                <span className={css.value}>{decimals}</span>
            </div>
        </div>
    );
}

export default TokenNameCard;
