export const data = {
    daoInsights: [
        {
            value: "$10,548.14",
            name: "Your Portfolio Value",
            percent: 5,
            isProfit: true,
        },
        {
            value: "4,568",
            name: "Total DAO Token",
            percent: 10.5,
            isProfit: false,
        },
        {
            value: 230,
            name: "Total Proposals",
            percent: 10,
            isProfit: true,
        },
        {
            value: "2,340 VP",
            name: "Voting Power",
            percent: 10,
            isProfit: true,
        },
        {
            value: "75.3%",
            name: "Average Participation rate",
            percent: 1,
            isProfit: false,
        },
    ],

    votingPowerDistribution: [
        { value: 10, name: "Mobius", itemStyle: { color: "#94D0FF" } },
        { value: 20.7, name: "MIA", itemStyle: { color: "#FBC96C" } },
        { value: 69.3, name: "Aegis", itemStyle: { color: "#9A9AFF" } },
    ],
    totalBalance: {
        series: [
            {
                name: "Balance",
                type: "line",
                stack: "Total",
                data: [0, 0.2, 0.5, 0.3, 0.7, 1, 0.9, 0.8],
                itemStyle: {
                    color: "#ABDE3A",
                },
            }
        ],
        // yLegends: ["0", "125K", "250K", "375K", "500K"],
        xLegends: ["Aug", "Sep", "Oct", "Nov", "Dec", "Jan", "Feb", "Mar", "Apr", "May"],
    },
    daos: {
        columns: [
            {
                title: "DAO Name",
                index: "daoName",
                type: "string",
            },
            {
                title: "DAO Token",
                index: "tokenAddress",
                type: "address",
            },
            {
                title: "Created on",
                index: "createdAt",
                type: "date",
            },
            // {
            //     title: "Marketplace",
            //     index: "marketplace",
            //     type: "stringWithIcon",
            //     subIndex: "name",
            // },
            // {
            //     title: "Status",
            //     index: "status",
            //     type: "status",
            // },
            {
                title: "Total Proposals",
                index: "totalProposals",
                type: "string",
            },
            {
                title: "Active Proposals",
                index: "activeProposals",
                type: "string",
            },
            {
                title: "Treasury Blance",
                index: "balance",
                type: "string",
            },
            {
                title: "Total Members",
                index: "totalMembers",
                type: "string",
            },
            {
                title: "Manage",
                index: "action",
                type: "action",
            },
        ],
    },
    treasuryBalance: {
        series: [
            {
                name: "Trreasury",
                type: "line",
                stack: "Total",
                smooth: true,
                data: [-10, 5, 10, 3, 0, -3, 4, 4, 5, 3, 5, 9, 10, 5, 3, 0, -3, -6, 5],
                itemStyle: {
                    color: "#EB856B",
                },
            },
        ],
        // yLegends: ["0", "125K", "250K", "375K", "500K"],
        xLegends: ["24Mar", "31Mar", "07Apr", "14Apr"],
    },
};
