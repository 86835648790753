import React, { useState } from "react";
import css from "./ProposalVotingDuration.module.scss";
import DAOStudioProgress from "../../../Components/UI/DAOStudioProgress/DAOStudioProgress";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "antd";

import Minus from "../.../../../../TeDaaS-Assets/Porposal/Minus.svg";
import Plus from "../.../../../../TeDaaS-Assets/Porposal/Plus.svg";
import { useDispatch, useSelector } from "react-redux";
import { proposalCreationConfigureVal } from "../../../../../redux/actions/proposalCreationAction";

const ProposalVotingDuration = () => {
    const proposalData = useSelector((state) => state.pc);
    const fetchedData = proposalData.voting;
    const dispatch = useDispatch();
    const navigateTo = useNavigate();
    const daoSettings = useSelector((state) => state.pa.settings);
    const [votingOptions, setVotingOptions] = useState([
        {
            optionHeading: "Yes, no or abstain",
            optionDescription: "Members can vote for, or against.",
        },
        {
            optionHeading: "Ranked choice voting",
            optionDescription:
                "Members rank the options in order of preference. If no option wins the majority, the option with the fewest votes is eliminated, and those votes are redistributed until a majority is reached.",
        },
        {
            optionHeading: "Veto Voting",
            optionDescription:
                "Certain members or a specific percentage of members have the power to veto a proposal, preventing it from being approved even if it has majority support.",
        },
        {
            optionHeading: "Single Transferable Vote (STV)",
            optionDescription:
                "Members rank options in order of preference. Votes are initially allocated to each member’s top choice. If a candidate achieves the necessary quota, excess votes are transferred according to voters’ preferences.",
        },
    ]);

    const [selectedVotingOption, setSelectedVotingOption] = useState(fetchedData.votingOption);
    const [startTimeOption, setStartTimeOption] = useState(fetchedData.startTimeOption);
    const [endTimeOption, setEndTimeOption] = useState(fetchedData.endTimeOption);
    const [startTime, setStartTime] = useState(fetchedData.startTimeOption === "now" ? new Date().getTime() : fetchedData.startTime);
    const [endTime, setEndTime] = useState(fetchedData.endTime);
    const [duration, setDuration] = useState(fetchedData.duration);

    const handleSelectVotingOption = (index) => {
        setSelectedVotingOption(index);
    };

    const handleChange = (unit, value) => {
        if (value < 0) return; // Prevent negative values
        setDuration((prev) => ({ ...prev, [unit]: value }));
    };

    const handlePlusClickChange = (unit) => {
        setDuration((prev) => ({ ...prev, [unit]: prev[unit] + 1 }));
    };

    const handleMinusClickChange = (unit) => {
        setDuration((prev) => ({
            ...prev,
            [unit]: prev[unit] > 0 ? prev[unit] - 1 : 0,
        }));
    };

    const handleNext = () => {
        dispatch(
            proposalCreationConfigureVal("voting", {
                votingOption: selectedVotingOption,
                startTimeOption,
                endTimeOption,
                duration,
                startTime,
                endTime,
            }),
        );
        if (daoSettings?.daoAddress === proposalData.daoAddress) {
            navigateTo("/tedaasV3/createProposal/reviewProposal");
        } else {
            navigateTo("/tedaasV3/createProposal/setActions");
        }
    };

    return (
        <div className={css.mainContainer}>
            <DAOStudioProgress title="Initiate the Vote" description="Set the choices, start date and end date for your vote." progress={50} />

            <div className={css.mainWrapper}>
                <div className={`${css.divContainer} ${css.mt_32}`}>
                    <div className={css.headingContainer}>
                        <h4 className={css.fieldHeader}>Options</h4>
                    </div>
                    <div className={css.inputWrapper}>
                        {votingOptions.map((value, index) => (
                            <div
                                key={value.optionHeading}
                                className={`${css.optionWrapper} ${selectedVotingOption === value.optionHeading ? css.selected : ""}`}
                                onClick={() => handleSelectVotingOption(value.optionHeading)}
                            >
                                <h4>{value.optionHeading}</h4>
                                <p>{value.optionDescription}</p>
                            </div>
                        ))}
                    </div>
                </div>

                <div className={`${css.divContainer} ${css.mt_32}`}>
                    <div className={css.headingContainer}>
                        <h4 className={css.fieldHeader}>Start Time</h4>
                    </div>
                    <div className={css.inputWrapper}>
                        <div className={css.durationContainer}>
                            <div
                                className={`${css.option} ${startTimeOption === "now" ? css.selected : ""}`}
                                onClick={() => {
                                    setStartTimeOption("now");
                                    setStartTime(new Date().getTime());
                                }}
                            >
                                <span>Now</span>
                                <input type="checkbox" checked={startTimeOption === "now" ? true : false} readOnly className={css.optionCheckbox} />
                            </div>

                            <div
                                className={`${css.option} ${startTimeOption === "specific" ? css.selected : ""}`}
                                onClick={() => setStartTimeOption("specific")}
                            >
                                <span>Specific Date and Time</span>
                                <input type="checkbox" checked={startTimeOption === "now" ? false : true} readOnly className={css.optionCheckbox} />
                            </div>
                        </div>
                        <span>
                            Define when a proposal should be active to receive approvals. If now is selected, the proposal is immediately active after
                            publishing.
                        </span>
                        {startTimeOption === "specific" && (
                            <div className={css.specificDateTimeContainer}>
                                <div className={css.specificDateTimeRow}>
                                    <div className={css.dateInput}>
                                        <label>Date</label>
                                        <input
                                            type="date"
                                            value={`${new Date(startTime).getFullYear()}-${new Date(startTime).getMonth() > 8 ? "" : "0"}${new Date(startTime).getMonth() + 1}-${new Date(startTime).getDate() > 9 ? "" : "0"}${new Date(startTime).getDate()}`}
                                            onChange={(e) => {
                                                setStartTime(new Date(e.target.value).getTime());
                                            }}
                                        />
                                    </div>
                                    <div className={css.timeInput}>
                                        <label>Time</label>
                                        <input
                                            type="time"
                                            value={`${new Date(startTime).getHours() > 9 ? "" : 0}${new Date(startTime).getHours()}:${new Date(startTime).getMinutes() > 9 ? "" : 0}${new Date(startTime).getMinutes()}`}
                                            onChange={(e) => {
                                                setStartTime(
                                                    new Date(
                                                        `${new Date(startTime).getFullYear()}-${new Date(startTime).getMonth() + 1}-${new Date(startTime).getDate()} ${e.target.value}`,
                                                    ).getTime(),
                                                );
                                            }}
                                        />
                                    </div>
                                    <div className={css.timezoneInput}>
                                        <label>Timezone</label>
                                        <select>
                                            <option value="UTC-6">UTC - 6</option>
                                            <option value="UTC-5">UTC - 5</option>
                                            <option value="UTC-4">UTC - 4</option>
                                            {/* Add more timezones as needed */}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                <div className={`${css.divContainer} ${css.mt_32}`}>
                    <div className={css.headingContainer}>
                        <h4 className={css.fieldHeader}>End Date</h4>
                    </div>
                    <div className={css.inputWrapper}>
                        <div className={css.durationContainer}>
                            <div
                                className={`${css.option} ${endTimeOption === "duration" ? css.selected : ""}`}
                                onClick={() => setEndTimeOption("duration")}
                            >
                                <span>Duration</span>
                                <input
                                    type="checkbox"
                                    checked={endTimeOption === "duration" ? true : false}
                                    readOnly
                                    className={css.optionCheckbox}
                                />
                            </div>

                            <div
                                className={`${css.option} ${endTimeOption === "specific" ? css.selected : ""}`}
                                onClick={() => setEndTimeOption("specific")}
                            >
                                <span>Specific Date and Time</span>
                                <input
                                    type="checkbox"
                                    checked={endTimeOption === "duration" ? false : true}
                                    readOnly
                                    className={css.optionCheckbox}
                                />
                            </div>
                        </div>
                        <span>Define how long the voting should last in days, or add an exact date and time for it to conclude</span>

                        {endTimeOption === "duration" && (
                            <div className={css.timeDurationContainer}>
                                <div className={css.timeInput}>
                                    <label htmlFor="minutes">Minutes</label>
                                    <div className={css.timeInputContainer}>
                                        <img src={Minus} alt="minus" onClick={() => handleMinusClickChange("minutes")} />
                                        <input
                                            type="number"
                                            id="minutes"
                                            min="0"
                                            value={duration.minutes}
                                            onChange={(e) => handleChange("minutes", parseInt(e.target.value, 10))}
                                        />
                                        <img src={Plus} alt="plus" onClick={() => handlePlusClickChange("minutes")} />
                                    </div>
                                </div>

                                <div className={css.timeInput}>
                                    <label htmlFor="hours">Hours</label>
                                    <div className={css.timeInputContainer}>
                                        <img src={Minus} alt="minus" onClick={() => handleMinusClickChange("hours")} />
                                        <input
                                            type="number"
                                            id="hours"
                                            min="0"
                                            value={duration.hours}
                                            onChange={(e) => handleChange("hours", parseInt(e.target.value, 10))}
                                        />
                                        <img src={Plus} alt="plus" onClick={() => handlePlusClickChange("hours")} />
                                    </div>
                                </div>

                                <div className={css.timeInput}>
                                    <label htmlFor="days">Days</label>
                                    <div className={css.timeInputContainer}>
                                        <img src={Minus} alt="Minus" onClick={() => handleMinusClickChange("days")} />
                                        <input
                                            type="number"
                                            id="days"
                                            min="1"
                                            value={duration.days}
                                            onChange={(e) => handleChange("days", parseInt(e.target.value, 10))}
                                        />
                                        <img src={Plus} alt="Plus" onClick={() => handlePlusClickChange("days")} />
                                    </div>
                                </div>
                            </div>
                        )}

                        {endTimeOption === "specific" && (
                            <div className={css.specificDateTimeContainer}>
                                <div className={css.specificDateTimeRow}>
                                    <div className={css.dateInput}>
                                        <label>Date</label>
                                        <input
                                            type="date"
                                            value={`${new Date(endTime).getFullYear()}-${new Date(endTime).getMonth() > 8 ? "" : "0"}${new Date(endTime).getMonth() + 1}-${new Date(endTime).getDate() > 9 ? "" : "0"}${new Date(endTime).getDate()}`}
                                            onChange={(e) => {
                                                setEndTime(new Date(e.target.value).getTime());
                                            }}
                                        />
                                    </div>
                                    <div className={css.timeInput}>
                                        <label>Time</label>
                                        <input
                                            type="time"
                                            value={`${new Date(endTime).getHours() > 9 ? "" : 0}${new Date(endTime).getHours()}:${new Date(endTime).getMinutes() > 9 ? "" : 0}${new Date(endTime).getMinutes()}`}
                                            onChange={(e) => {
                                                setEndTime(
                                                    new Date(
                                                        `${new Date(endTime).getFullYear()}-${new Date(endTime).getMonth() + 1}-${new Date(endTime).getDate()} ${e.target.value}`,
                                                    ).getTime(),
                                                );
                                            }}
                                        />
                                    </div>
                                    <div className={css.timezoneInput}>
                                        <label>Timezone</label>
                                        <select>
                                            <option value="UTC-6">UTC - 6</option>
                                            <option value="UTC-5">UTC - 5</option>
                                            <option value="UTC-4">UTC - 4</option>
                                            {/* Add more timezones as needed */}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        )}

                        <p className={css.durationDescription}>
                            The minimum duration of 1 day, 0 hours, and 0 minutes is defined by DAO governance settings.
                        </p>
                    </div>
                </div>
            </div>

            <div className={css.separatorLine}></div>

            <div className={css.navButtons}>
                <Link to={`/tedaasV3/createProposal/proposalMeta`}>
                    <Button type="default" className={css.button}>
                        {"<"} Back
                    </Button>
                </Link>
                {/* <Link to={`/tedaasV3/createProposal/proposalVote`}> */}
                <Button type="primary" className={css.button} onClick={handleNext}>
                    {"Next >"}
                </Button>
                {/* </Link> */}
            </div>
        </div>
    );
};

export default ProposalVotingDuration;
