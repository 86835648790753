import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import css from "./BlacklistAddress.module.scss";
import burnImg from "../../../../TeDaaS-Assets/Modals/editBlacklist.svg";
import closeImg from "../../../../TeDaaS-Assets/Nodes/close.svg";
import radioActive from "../../../../TeDaaS-Assets/Modals/radioActive.svg";
import radioInactive from "../../../../TeDaaS-Assets/Modals/radioInactive.svg";
import addressClose from "../../../../TeDaaS-Assets/Modals/close.svg";
import { tokenActionCloseModal } from "../../../../../../redux/actions/tokenCreationOptionsAction";
import { updateLoader } from "../../../../../../redux/actions/loaderAction";
import { useERC20TokenAction } from "../../../../Hooks/useERC20TokenAction";
import { showErrorToast, showSuccessToast, showWarningToast } from "../../../../../../services/notificationService";
import { useParams } from "react-router-dom";

const BlacklistAddress = ({ totalSupply }) => {
    const dispatch = useDispatch();
    const modelData = useSelector((state) => state.tco);
    const [data, setData] = useState({
        address: [],
        blacklist: true,
    });
    const { address } = useParams();
    const { isValidAddress, blacklistAddress, whitelistAddress, getOwner } = useERC20TokenAction();
    const handleOk = async () => {
        dispatch(updateLoader(true));
        const owner = await getOwner(address);
        for (let i of data.address) {
            if (isValidAddress(i)) {
                if (data.blacklist) {
                    if (i === owner) {
                        showErrorToast("Owner address can't be blacklisted");
                        continue;
                    }
                    const blacklist = await blacklistAddress(address, i);
                    if (blacklist !== true) {
                        showErrorToast(blacklist + ": " + i);
                    } else {
                        showSuccessToast(i + " is blacklisted");
                    }
                } else {
                    const whitelist = await whitelistAddress(address, i);
                    if (whitelist !== true) {
                        showErrorToast(i + " is not whitelisted");
                    } else {
                        showSuccessToast(i + " is whitelisted");
                    }
                }
            } else {
                showErrorToast(i + " is not a valid address");
            }
        }
        dispatch(updateLoader(false));
        dispatch(tokenActionCloseModal());
    };
    const handleCancel = () => {
        dispatch(tokenActionCloseModal());
    };
    const [addressText, setAddressText] = useState("");
    return (
        <Modal
            centered
            title={
                <div className={css.header}>
                    <div className={css.headerLeft}>
                        <div className={css.headerTop}>
                            <img className={css.icon} src={burnImg} alt="Blacklist Addresses" />
                            <span className={css.title}>Blacklist Addresses</span>
                        </div>
                        <div className={css.iconContainer} onClick={handleCancel}>
                            <img className={css.icon} src={closeImg} alt="Close" />
                        </div>
                    </div>
                    <div className={css.subTitleContainer}>
                        <span className={css.subTitle}>Manage Action</span>
                        <span className={css.subTitle}>Current token supply: {totalSupply}</span>
                    </div>
                </div>
            }
            closeIcon={false}
            open={modelData.actionModals["Blacklist Addresses"]}
        >
            <div className={css.container}>
                <span className={css.title}>Block or unblock the addresses from using token</span>
                <div className={css.radioButtons}>
                    <div
                        className={css.radioButton}
                        onClick={() => {
                            if (!data.blacklist) {
                                setData({ ...data, blacklist: true });
                            }
                        }}
                    >
                        <span>Add Blacklist</span>
                        <img className={css.icon} src={data.blacklist ? radioActive : radioInactive} alt="Add Blacklist" />
                    </div>
                    <div
                        className={css.radioButton}
                        onClick={() => {
                            if (data.blacklist) {
                                setData({ ...data, blacklist: false });
                            }
                        }}
                    >
                        <span>Remove Blacklist</span>
                        <img className={css.icon} src={!data.blacklist ? radioActive : radioInactive} alt="Add Blacklist" />
                    </div>
                </div>
                <div className={css.col}>
                    <span className={css.label}>Blacklist Address</span>
                    <div className={css.addresses}>
                        {data.address.map((a, i) => {
                            return (
                                <div className={css.address} key={i}>
                                    <span className={css.addressText}>{a}</span>
                                    <img
                                        className={css.addressIcon}
                                        src={addressClose}
                                        alt={a}
                                        onClick={() => {
                                            try{
                                                setData({ ...data, address: data.address.filter((ele) => ele !== a) });
                                            }catch(e){
                                                console.log(e);
                                            }
                                        }}
                                    />
                                </div>
                            );
                        })}
                        <input
                            type="text"
                            className={css.input}
                            value={addressText}
                            onChange={(e) => {
                                setAddressText(e.target.value);
                            }}
                            placeholder="Enter address..."
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    setData({ ...data, address: [...data.address, addressText] });
                                    setAddressText("");
                                }
                            }}
                        />
                    </div>
                </div>
                <div className={css.buttons}>
                    <span className={css.cancel} onClick={handleOk}>
                        Cancel
                    </span>
                    <span className={css.submit} onClick={handleOk}>
                        Submit
                    </span>
                </div>
            </div>
        </Modal>
    );
};
export default BlacklistAddress;
