import React from 'react'
import ReactECharts from 'echarts-for-react'
import { useSelector } from 'react-redux'

import styles from './TokenBurnLineChart.module.scss'

const TokenBurnLineChart = () => {
	const tokenSimulationData = useSelector((state) => state.tsr);
	const burnScheduleData = tokenSimulationData.burn.burnScheduleData
	const burningIntervals = tokenSimulationData.burn.burningScheduleTenure

	const TokenBurnLineChartData = {
		title: {
			text: 'Token Burn',
			left: '1%',
			textStyle: {
				fontFamily: 'Manrope',
			},
		},
		legend: {
			show: false,
		},
		tooltip: {
			trigger: 'axis',
			axisPointer: {
				type: 'cross',
			},
			formatter: function (params) {
				return (
					'Interval: ' +
					params[0]?.name +
					'<br/>' +
					`Supply after ${params[0].seriesName}:` +
					params[0]?.value
				)
			},
		},
		color: ['#7EC2FF'],
		textStyle: {
			fontFamily: 'Manrope',
		},
		xAxis: {
			type: 'category',
			position: 'bottom',
      inverse: true,
			data: Array.from({ length: burningIntervals }, (_, i) => i + 1),
			name: 'Burning Tenure ->',
			nameLocation: 'center',
			nameTextStyle: {
				verticalAlign: 'top',
				padding: [30, 0, 0, 0],
				fontSize: '14px',
			},
			axisLine: {
				lineStyle: {
					color: '#98A2B3',
				},
			},
			axisTick: {
				show: false,
				lineStyle: {
					color: '#D9D9D9',
				},
			},
			axisLabel: {
				fontFamily: 'Manrope',
				fontSize: 14,
			},
		},
		yAxis: {
			type: 'value',
			name: 'Burn',
			nameLocation: 'middle',
			position: 'left',
			splitLine: {
				show: false,
			},
			axisLine: {
				lineStyle: {
					color: '#98A2B3',
				},
			},
			axisLabel: {
				fontFamily: 'Manrope',
				fontSize: 14,
				formatter: function (params) {
					const suffixes = [
						{ threshold: 1_000_000_000_000, suffix: 'T' },
						{ threshold: 1_000_000_000, suffix: 'B' },
						{ threshold: 1_000_000, suffix: 'M' },
						{ threshold: 1_000, suffix: 'K' },
					]
					for (const { threshold, suffix } of suffixes) {
						if (params >= threshold) {
							const formattedValue = (params / threshold).toFixed(1) + suffix
							return formattedValue
						}
					}
					return params
				},
			},
			nameTextStyle: {
				align: 'center',
				verticalAlign: 'bottom',
				padding: [0, 0, 50, 0],
			},
		},
		series: [
			{
				name: 'Burn',
				data: burnScheduleData, //Data here
				type: 'line',
				showSymbol: false,
				smooth: true,
				cursor: 'crosshair',
				emphasis: {
					focus: 'series',
				},
				lineStyle: {
					color: '#7EC2FF',
					shadowColor: 'rgba(8, 161, 242, 0.8)',
					shadowBlur: 20,
					shadowOffsetY: 12,
				},
			},
		],
	}

	return (
		<div className={styles.container}>
			<ReactECharts option={TokenBurnLineChartData} style={{height: "var(--TClamp330px)"}} />
		</div>
	)
}

export default TokenBurnLineChart
