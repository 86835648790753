import { useState, lazy, useContext } from 'react';
import { useDrop } from "react-dnd";
import css from './NewAuthentication.module.scss';
const DropDown = lazy(() => import('../../../../../commonComponents/DropDown/DropDown'));
import crossIcon from "/svgs/crosscloseicon.svg";
import { handleGroupDelete, authOptions } from "./helperFunctionsData.js";
import TreeItem from '../../../../../commonComponents/TreeView/TreeItem.jsx';
import { ContextApi } from '../../../../context/ContextSMS.jsx';
import { useNavigate } from 'react-router-dom';
import { useMutation } from "react-query";
import { schemas } from '../../../../btf/artifacts.js';
import { IngestPolicyEntities } from '../../../../../../helpers/services.js';

export default function NewAuthentication() {
    const { selectedOptions, setSelectedOptions } = useContext(ContextApi);
    const [droppedItems, setDroppedItems] = useState([]);
    const [policyDesc, setPolicyDesc] = useState("The default policy applies in all situations if no other policy applies");
    const [selectedPolicy, setSelectedPolicy] = useState("Default Policy");
    const [authenticatorSelection, setAuthenticatorSelection] = useState(authOptions.map(auth => auth.authOptions[0]));

    const policySchema = schemas.SUBSCRIPTION_POLICY;

    // Set up the mutation hook for posting data
    const { mutate, isLoading, isError, error } = useMutation(IngestPolicyEntities, {
        onSuccess: (data) => {
            console.log("Data successfully posted:", data);
            // Optionally, navigate to another page or show a success message
        },
        onError: (error) => {
            console.error("Error posting data:", error);
            // Handle the error, such as showing a notification
        }
    });

    const [{ isOver }, dropRef] = useDrop({
        accept: "DRAGGABLE_CARD",
        drop: (item) => {
            setDroppedItems((prevItems) => [...prevItems, item]);
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
        }),
    });

    const navigate = useNavigate();

    const handleCancelClick = () => {
        navigate("/smsV2/security/privacy-policy");
    };

    const handleCreateClick = () => {
        // Map selected authenticator options to their respective attributes in the payload
        const payload = {
            ...selectedOptions,
            policyDescription: policyDesc,
            policyName: selectedPolicy,
            email: authenticatorSelection[0] === "Enabled" ? "Enabled" : "Disabled",
            password: authenticatorSelection[1] === "Required" ? "Required" : "Not Required",
            googleAuthenticator: authenticatorSelection[2] === "Enabled" ? "Enabled" : "Disabled",
            phoneNumber: authenticatorSelection[3] === "Required" ? "Required" : "Not Required",
            securityQuestion: authenticatorSelection[4] === "Enabled" ? "Enabled" : "Disabled",
            assignedGroups: droppedItems.reduce((groups, item, index) => {
                groups[`group${index + 1}`] = item.title;
                return groups;
            }, {}),
            rules: {},
            createdDate: Date.now().toString() 
        };
        setSelectedOptions(payload);
        const pbodyPayload = [
            {
            "maxSimultaneousLogins": payload.maxSimultaneousLogins,
            "enableSessionTimeout": payload.enableSessionTimeout,
            "unlockUserAccount": payload.unlockUserAccount,
            "timeout": payload.timeout,
            "lockUserAccount": payload.lockUserAccount,
            "policyId": payload.policyId,
            "secondaryLoginAction": payload.secondaryLoginAction,
            "adminId": payload.adminId,
            "requireEmailConfirmation": payload.requireEmailConfirmation,
            "enableMultipleLogins": payload.enableMultipleLogins,
            "policyDescription": payload.policyDescription,
            "policyName": payload.policyName,
            "email": payload.email,
            "password": payload.password,
            "googleAuthenticator": payload.googleAuthenticator,
            "phoneNumber": payload.phoneNumber,
            "securityQuestion": payload.securityQuestion,
            "assignedGroups": payload.assignedGroups,
            "rules": payload.rules,
            "createdDate" : payload.createdDate
            }
        ];
        console.log(selectedOptions);

        mutate({ queryKey: [policySchema, pbodyPayload] });

        alert("Created new security policy!")
    };

    const handleAuthenticatorChange = (index, selectedOption) => {
        setAuthenticatorSelection(prev => {
            const updatedSelection = [...prev];
            updatedSelection[index] = selectedOption;
            return updatedSelection;
        });
    };

    return (
        <div className={css.newAuth} id='demoNewAuth'>
            {isLoading && <p>Saving policy...</p>}
            {isError && <p>Error saving policy: {error.message}</p>}
            
            {/* ============ Policy details ==================== */}
            <div className={css.policyDetails}>
                <div className={css.policyName}>
                    <h2 className={css.h2}>Policy Name</h2>
                    <DropDown
                        optionList={["Default Policy", "Custom Policy"]}
                        selectedOption={selectedPolicy}
                        setSelectedOption={setSelectedPolicy}
                        image={false}
                        dropDownStyle={{ padding: "1vh 1.1vw" }}
                        handleOptionClick={(option) => console.log("Selected Policy:", option)}
                    />
                </div>
                <div className={css.policyDesc}>
                    <h2 className={css.h2}>Policy Description</h2>
                    <textarea
                        className={css.textarea}
                        onChange={(e) => setPolicyDesc(e.target.value)}
                        value={policyDesc}>
                    </textarea>
                </div>
            </div>

            {/* ============ Authenticator ==================== */}
            <div className={css.authenticator}>
                <div className={css.header}>
                    <h2 className={css.h2}>Add Authenticator</h2>
                </div>
                <div className={css.body}>
                    {authOptions.map((authOption, index) => (
                        <div className={css.authOption} key={`auth-option-${index}`}>
                            <h2 className={css.h2}>{authOption.authName}</h2>
                            <DropDown
                                optionList={authOption.authOptions}
                                selectedOption={authenticatorSelection[index]}
                                setSelectedOption={(option) => handleAuthenticatorChange(index, option)}
                                image={false}
                                dropDownStyle={{ padding: "1.5vh 1.1vw" }}
                                handleOptionClick={(option) => console.log(`Selected ${authOption.authName}:`, option)}
                            />
                        </div>
                    ))}
                </div>
            </div>

            {/* ============ Assign to groups ==================== */}
            <div className={css.assign}>
                <div className={css.header}>
                    <h2 className={css.h2}>Assign to groups</h2>
                </div>
                <div
                    ref={dropRef}
                    className={css.body}
                    style={{
                        backgroundColor: isOver ? "lightgreen" : "white",
                    }}
                >
                    {droppedItems.map((droppedItem, index) => (
                        <div key={`droppedItem-${index}`} className={css.droppedItem}>
                            <span>{droppedItem?.title}</span>
                            <img src={crossIcon} alt="" className={css.closeIcon}
                                onClick={() => handleGroupDelete(index, droppedItems, setDroppedItems)}
                            />
                        </div>
                    ))}
                </div>
            </div>

            {/* ============ Add rules ==================== */}
            <div className={css.addRules}>
                <div className={css.TreeContainer}>
                    <TreeItem topMostElement={true} customConnectorLeftShift={"var(--Clamp50px)"}>
                        <h2 className={css.h2}>Add rules</h2>
                        <TreeItem>
                            <span className={css.newRule}>Create new rule to add</span>
                        </TreeItem>
                    </TreeItem>
                </div>
            </div>

            {/* ============ Save Button ==================== */}
            <div className={css.bottomBtnCont}>
                <div className={css.rightBtn}>
                    <div className={css.cancleBtn} onClick={handleCancelClick}>Cancel</div>
                    <div className={css.createBtn} onClick={handleCreateClick}>Save</div>
                </div>
            </div>
        </div>
    );
}
