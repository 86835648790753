import React, { useEffect, useState } from "react";

import TokenMintVsBurnLineChart from "./TokenMintVsBurnLineChart/TokenMintVsBurnLineChart";
import TokenMintLineChart from "./TokenMintLineChart/TokenMintLineChart";
import TokenBurnLineChart from "./TokenBurnLineChart/TokenBurnLineChart";

import styles from "./TokenomicsSimulationCharts.module.scss";
import { useDispatch, useSelector } from "react-redux";
import EmissionScheduleStackedAreaChart from "./EmissionScheduleStackedAreaChart/EmissionScheduleStackedAreaChart";
import TokenPieChart from "./TokenPieChart/TokenPieChart";
import RewardDistributionStackedBarChart from "./RewardDistributionStackedBarChart/RewardDistributionStackedBarChart";

import saveTknImg from "../../../TeDaaS-Assets/TokenomicsForm/saveTokenomics.svg";
import runSimImg from "../../../TeDaaS-Assets/TokenomicsForm/runSimulation.svg";
import { useNavigate } from "react-router-dom";
import usePostTokenData from "../../../Hooks/usePostTokenData";
import DoughnutChart from "../../Charts/DoughnutChart/DoughnutChart";
import DoughnutChartWithLegends from "../../UI/DoughnutChartWithLegends/DoughnutChartWithLegends";
import GradientStackedAreaChart from "../../Charts/GradientStackedAreaChart/GradientStackedAreaChart";
import { updateLoader } from "../../../../../redux/actions/loaderAction";

const TokenomicsSimulationCharts = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const tokenSimulationData = useSelector((state) => state.tsr);
    const fetchedData = useSelector((state) => state.tc);
    useEffect(() => {
        if (fetchedData.newToken === false) {
            navigate("/tedaasV3/tokenDashboard");
        }
    }, [tokenSimulationData, fetchedData]);
    const [show, setShow] = useState(false);
    const handleShowFalse = () => {
        setTimeout(() => {
            setShow(true);
        }, 100);
    };
    useEffect(() => {
        setShow(false);
        handleShowFalse();
    }, [tokenSimulationData.reward, tokenSimulationData.emission]);
    return (
        <div className={styles.mainContainer}>
            <div className={styles.container}>
                <div className={styles.chartContainer}>
                    {tokenSimulationData.tokenomics.length > 0 && (
                        <div className={styles.rowHalf}>
                            <div className={styles.chart}>
                                <DoughnutChartWithLegends data={tokenSimulationData.tokenomics} isTitle={true} showTitle={"Tokenomics"} />
                            </div>
                        </div>
                    )}

                    {tokenSimulationData.reward?.isReward == true && show == true && (
                        <div className={styles.rowHalf}>
                            <div className={styles.chart}>
                                <RewardDistributionStackedBarChart />
                            </div>
                        </div>
                    )}
                    {tokenSimulationData.burn?.canBurn == true && (
                        <div className={styles.rowHalf}>
                            <div className={styles.chart}>
                                <TokenBurnLineChart />
                            </div>
                        </div>
                    )}
                    {tokenSimulationData.mint?.canMint == true && (
                        <div className={styles.rowHalf}>
                            <div className={styles.chart}>
                                <TokenMintLineChart />
                            </div>
                        </div>
                    )}
                </div>
                <div className={styles.chartContain}>
                    {(tokenSimulationData.mint?.canMint == true || tokenSimulationData.burn?.canBurn == true) && (
                        <div className={styles.row}>
                            <div className={styles.chart}>
                                <TokenMintVsBurnLineChart />
                            </div>
                        </div>
                    )}
                    {tokenSimulationData.emission?.isEmission == true && show == true && (
                        <div className={styles.row}>
                            <div className={styles.chart}>
                                <EmissionScheduleStackedAreaChart />
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className={styles.btnContainer}>
                <button className={`${styles.saveButton}`}>
                    <img src={saveTknImg} alt="Save Tokenomics" />
                    <span>Save Tokenomics</span>
                </button>
                <button
                    className={`${styles.createSCBtn}`}
                    onClick={async () => {
                        dispatch(updateLoader(true));
                        navigate("/tedaasV3/smartContract");
                    }}
                >
                    <img src={runSimImg} alt="Run Simulation" />
                    <span>Create Smart Contract</span>
                </button>
            </div>
        </div>
    );
};

export default TokenomicsSimulationCharts;
