import React from "react";
import css from "./EventNode.module.scss";
import contextNode from "../../../../TeDaaS-Assets/Nodes/eventNode.svg";
import cohortNode from "../../../../TeDaaS-Assets/Nodes/cohortNode.svg";
import startNode from "../../../../TeDaaS-Assets/Nodes/startNode.svg";
import endNode from "../../../../TeDaaS-Assets/Nodes/endNode.svg";
import { useDrag } from "react-dnd";
function EventNode({ name, type }) {
    const [{ isDragging, opacity }, dragRef] = useDrag(
        () => ({
            type: type,
            item: { node: { name: name, type: type } },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
                opacity: monitor.isDragging() ? 0.5 : 1,
            }),
        }),
        [],
    );
    return (
        <img
            style={{ opacity: opacity }}
            ref={dragRef}
            className={css.icon}
            src={name === "context" ? contextNode : name === "cohort" ? cohortNode : name === "start" ? startNode : endNode}
            alt={name}
        />
    );
}

export default EventNode;
