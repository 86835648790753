import React from "react";
import css from "./TooltipContent.module.scss";

function TooltipContent({
    placement = "bottom",
    date = "12/09/24",
    name = "Advisor",
    focus = { name: "23 Tokens", value: "0.5%" },
    data = [
        {
            name: "Vesting",
            value: "2 months",
        },
        {
            name: "Emission Type",
            value: "2 months",
        },
        {
            name: "Monetary Value",
            value: "1.05 (100)",
        },
    ],
    handleClick = () => {},
}) {
    return (
        <div className={css.container}>
            <div className={css.header}>
                <span>last edited on {date}</span>
                <span>{name}</span>
            </div>
            <div className={css.body}>
                <div className={css.focus}>
                    <span>{focus.name}</span>
                    <span>{focus.value}</span>
                </div>
                {Array.isArray(data) &&
                    data.map((d, i) => {
                        return (
                            <div key={i} className={css.content}>
                                <span>{d.name}</span>
                                <span>{d.value}</span>
                            </div>
                        );
                    })}
            </div>
            <div className={css.footer}>
                <span className={css.footerText} onClick={handleClick}>
                    Click here to edit
                </span>
            </div>
        </div>
    );
}

export default TooltipContent;
