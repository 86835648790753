import React, { useEffect, useState } from "react";
import css from "./DAOCreation.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { updateShowRightSideBar } from "../../../../../redux/actions/rightSideBarAction";
import { updateTopBarStyle } from "../../../../../redux/actions/topbarActions";
import DAOStudioProgress from "../../../Components/UI/DAOStudioProgress/DAOStudioProgress";
import DAOStudioStepDescription from "../../../Components/UI/DAOStudioStepDescription/DAOStudioStepDescription";
import DAOStudioSelectBlockchain from "../../../Components/UI/DAOStudioSelectBlockchain/DAOStudioSelectBlockchain";
import { Outlet } from "react-router-dom";
import DeployYourDAOModal from "../../../Components/Modals/DeployYourDAOModals/DeployYourDAOModal/DeployYourDAOModal";

function DAOCreation() {
    const dispatch = useDispatch();
    const fetchedData = useSelector((state) => state.dc);
    const progress = fetchedData.progress;
    const modelData = fetchedData.deployYourDAO;
    useEffect(() => {
        dispatch(updateShowRightSideBar(true));
        dispatch(
            updateTopBarStyle(
                <div className={css.headerTitle}>
                    <span className={css.greyText}>MarketPlace</span>
                    <span className={css.greyText}>/</span>
                    <span className={css.greyText}>DAO Studio</span>
                    <span className={css.greyText}>/</span>
                    <span className={css.blackText}>Create DAO</span>
                </div>,
                "#ffffff",
            ),
        );
    }, []);
    return (
        <div className={css.mainContainer}>
            <div className={css.container}>
                {progress < 150 && fetchedData.approved==false &&  (
                    <DAOStudioProgress
                        title={progress <= 100 ? "Build your DAO" : "Deploy your DAO"}
                        description={
                            progress <= 100
                                ? "Start simple and learn as you go. You can always evolve your DAO in the future."
                                : "Double-check that everything is correct before deploying your DAO."
                        }
                        progress={progress}
                    />
                )}
                <Outlet />
            </div>
            {
               modelData && <DeployYourDAOModal /> 
            }
        </div>
    );
}

export default DAOCreation;
