import React from 'react';
import css from "./InputWithPlusMinus.module.scss";
import minus from "../../../TeDaaS-Assets/Nodes/minus.svg";
import plus from "../../../TeDaaS-Assets/Nodes/plus.svg";

function InputWithPlusMinus({value, handleInput, handleDecrease, handleIncrease, min=0}) {
  return (
    <div className={css.container}>
        {/* <input type="number" min={min} onChange={handleInput} value={value} /> */}
        <div className={css.input} onChange={handleInput} >{value}</div>
        <div className={css.iconContainer} onClick={handleDecrease} >
            <img className={css.icon} src={minus} alt='Decrease' />
        </div>
        <div className={css.iconContainer} onClick={handleIncrease} >
            <img className={css.icon} src={plus} alt='Increase' />
        </div>
    </div>
  )
}

export default InputWithPlusMinus