import React, { useEffect, useState } from "react";
import { Slider, InputNumber, Row, Col, Button } from "antd";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import css from "./TokenomicsSliderInput.module.scss";
import plus from "../../../TeDaaS-Assets/TokenomicsSimulation/plus.svg";
import minus from "../../../TeDaaS-Assets/TokenomicsSimulation/minus.svg";
import { useSelector } from "react-redux";

const TokenomicsSliderInput = ({ name = "", value = 0, handleChange = () => {}, max = 10000000, min = 0, step = 1 }) => {
    const onChange = (e) => {
        handleChange(e.target.value);
    };

    const increase = () => {
        handleChange(Math.min(value + step, max));
    };
    const decrease = () => {
        handleChange(Math.max(value - step, min));
    };

    return (
        <div className={css.mainContainer}>
            {name.length > 0 && <span className={css.title}>{name}</span>}
            <div className={css.container}>
                <Slider
                    className={css.slider}
                    // marks={{ 0: "", 100: "" }}
                    min={min}
                    max={max}
                    step={step}
                    onChange={handleChange}
                    value={value}
                />
                <div className={css.input}>
                    <input
                        className={css.inputNumber}
                        min={min}
                        // max={max}
                        step={step}
                        value={value}
                        onChange={onChange}
                        style={{ margin: "0 8px" }}
                    />
                    <button className={css.button} onClick={decrease}>
                        <img className={css.icon} src={minus} alt="Decrease" />
                    </button>
                    <button className={css.button} onClick={increase}>
                        <img className={css.icon} src={plus} alt="Increase" />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default TokenomicsSliderInput;
