import React from "react";
import css from "./DAOStudioSelectBlockchain.module.scss";
import imageUrl from "../../../TeDaaS-Assets/Modals/blockchain.png";
import { Checkbox } from "antd";

function DAOStudioSelectBlockchain({ name = "", value = "", blockchain = "", image = imageUrl, selectedValue = "", handleChange = () => {} }) {
    return (
        <div
            className={css.container}
            onClick={() => {
                handleChange(value);
            }}
        >
            <div className={css.left}>
                <img className={css.icon} src={image} alt="name" />
                <div className={css.content}>
                    <span className={css.name}>{name}</span>
                    <span className={css.blockchain}>{blockchain}</span>
                </div>
            </div>
            <div className={css.right}>
                <Checkbox
                    className={css.checkbox}
                    onChange={(e) => {
                        if (e === true) {
                            handleChange(value);
                        }
                    }}
                    checked={value === selectedValue}
                ></Checkbox>
                {/* <input className={css.checkbox} type="checkbox" checked={value === selectedValue} readOnly /> */}
            </div>
        </div>
    );
}

export default DAOStudioSelectBlockchain;
