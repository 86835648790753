import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { configureTokenVal } from "../../../../redux/actions/tokenCreationAction";
import css from "./BurnModel.module.scss";
import burnImg from "../../TeDaaS-Assets/Nodes/changeOwner.svg";
import closeImg from "../../TeDaaS-Assets/Nodes/close.svg";
import "./emptyAntModelDefaultCss.css";
import { tokenCreationCloseModel } from "../../../../redux/actions/tokenCreationOptionsAction";
import { generateEdge, generateParentNode } from "../../Constants/reusableFunctions";
import { tokenCreationUpdateEdges, tokenCreationUpdateNodes } from "../../../../redux/actions/tokenCreationNodesAndEdgesAction";

const TokenPriceModel = () => {
    const dispatch = useDispatch();
    const fetchedData = useSelector((state) => state.tc);
    const modelData = useSelector((state) => state.tco);
    const { nodes, edges } = useSelector((state) => state.tcne);
    const [price, setPrice] = useState("");
    const handleOk = () => {
        dispatch(
            configureTokenVal("tokenPrice", {
                can: true,
                price: typeof price == "string" ? parseFloat(price) : price,
                createdOn: fetchedData.tokenPrice?.createdOn ? fetchedData.tokenPrice.createdOn : new Date().getTime(),
                updatedOn: new Date().getTime(),
            }),
        );
        if (modelData?.models?.position?.x !== 0) {
            const tokenPriceNode = generateParentNode("Token Price", "tokenPrice", "", "tokenPrice");
            tokenPriceNode.position = modelData.models.position;
            dispatch(tokenCreationUpdateNodes([...nodes, tokenPriceNode]));
            const tokenPriceEdge = generateEdge("tokenType", "tokenPrice");
            dispatch(tokenCreationUpdateEdges([...edges, tokenPriceEdge]));
        }
        dispatch(tokenCreationCloseModel());
    };
    const handleCancel = () => {
        dispatch(tokenCreationCloseModel());
    };
    useEffect(() => {
        if (fetchedData?.tokenPrice?.price) {
            setPrice(fetchedData?.tokenPrice?.price);
        }
    }, []);
    return (
        <Modal
        centered
            title={
                <div className={css.header}>
                    <div className={css.headerLeft}>
                        <div className={css.headerTop}>
                            <img className={css.icon} src={burnImg} alt="Token Price" />
                            <span className={css.title}>Token Price</span>
                        </div>
                        <span className={css.subTitle}>Current token supply: {fetchedData.initialSupply}</span>
                    </div>
                    <div className={css.headerRight} onClick={handleCancel}>
                        <img className={css.icon} src={closeImg} alt="Close" />
                    </div>
                </div>
            }
            closeIcon={false}
            open={modelData.models.tokenPrice}
            className={css.main}
        >
            <div className={css.changeOwner}>
                <span className={css.title}>Price</span>
                <input
                    type="number"
                    placeholder="e.g. 1000"
                    className={css.input}
                    value={price}
                    onChange={(e) => {
                        setPrice(e.target.value);
                    }}
                />
                <span className={css.save} onClick={handleOk}>
                    Save
                </span>
            </div>
        </Modal>
    );
};
export default TokenPriceModel;
