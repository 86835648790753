import React, { useEffect, useState } from "react";
import css from "./TokenomicsSimulationChangeParameter.module.scss";
import TokenomicsSliderInput from "../../UI/TokenomicsSliderInput/TokenomicsSliderInput";
import { useDispatch, useSelector } from "react-redux";
import { configureTokenVal } from "../../../../../redux/actions/tokenCreationAction";

function TokenomicsSimulationChangeParameter({ type = "default", id = "default", apply = 0, isHover = false }) {
    const [parameters, setParameters] = useState([]);
    const fetchedData = useSelector((state) => state.tc);
    const dispatch = useDispatch();
    useEffect(() => {
        const percent = {
            name: "Percentage",
            value: 0.05,
            keyName: "percent",
            min: 0.05,
            max: 5,
            step: 0.05,
        };
        const vesting = {
            name: "Vesting (Months)",
            value: 2,
            min: 1,
            keyName: "lock",
            max: 100,
            step: 1,
        };
        const emission = {
            ...vesting,
            name: "Emission (Percentage)",
            keyName: "unlock",
        };
        switch (type) {
            case "initialSupply":
                setParameters([
                    {
                        name: "Initial Supply",
                        value: fetchedData.initialSupply,
                        min: 1,
                        max: 1000000,
                        step: 1,
                        keyName: "initialSupply",
                    },
                ]);
                return;
            case "burn":
                setParameters([
                    { ...percent, value: fetchedData.burn.percent, name: "Burn" },
                    { ...emission, value: fetchedData.burn.unlock, name: "Months" },
                ]);
                return;
            case "mint":
                setParameters([
                    { ...percent, value: fetchedData.mint.percent, name: "Mint" },
                    { ...emission, value: fetchedData.mint.unlock, name: "Months" },
                ]);
                return;
            case "assignee":
                setParameters([
                    { ...percent, value: fetchedData.assignee[id].percent, name: `${fetchedData.assignee[id].name} (Percentage)` },
                    { ...vesting, value: fetchedData.assignee[id].lock },
                    { ...emission, value: fetchedData.assignee[id].unlock },
                ]);
                return;
            case "rewards":
                setParameters([
                    { ...percent, value: fetchedData.rewards[id].percent, name: `${fetchedData.rewards[id].name} (Percentage)` },
                    { ...vesting, value: fetchedData.rewards[id].lock },
                    { ...emission, value: fetchedData.rewards[id].unlock },
                ]);
                return;
            case "default":
                setParameters([]);
                return;
        }
    }, [fetchedData, type, id, isHover]);
    const handleChange = (val, param, i) => {
        const newParams = [...parameters];
        newParams[i].value = val;
        setParameters(newParams);
        if (isHover) {
            applyChange();
        }
    };
    useEffect(() => {
        applyChange();
    }, [apply]);
    const applyChange = () => {
        if (parameters.length === 0) {
            return;
        }
        switch (type) {
            case "initialSupply":
                dispatch(configureTokenVal("initialSupply", parseInt(parameters[0].value)));
                return;
            case "burn":
                dispatch(
                    configureTokenVal("burn", {
                        ...fetchedData.burn,
                        percent: parseFloat(parameters[0].value),
                        unlock: parseFloat(parameters[1].value),
                    }),
                );
                return;
            case "mint":
                dispatch(
                    configureTokenVal("mint", {
                        ...fetchedData.mint,
                        percent: parseFloat(parameters[0].value),
                        unlock: parseFloat(parameters[1].value),
                    }),
                );
                return;
            case "assignee":
                dispatch(
                    configureTokenVal("assignee", {
                        ...fetchedData.assignee,
                        [id]: {
                            ...fetchedData.assignee[id],
                            percent: parseFloat(parameters[0].value),
                            lock: parseFloat(parameters[1].value),
                            unlock: parseFloat(parameters[2].value),
                        },
                    }),
                );
                return;
            case "rewards":
                dispatch(
                    configureTokenVal("rewards", {
                        ...fetchedData.rewards,
                        [id]: {
                            ...fetchedData.rewards[id],
                            percent: parseFloat(parameters[0].value),
                            lock: parseFloat(parameters[1].value),
                            unlock: parseFloat(parameters[2].value),
                        },
                    }),
                );
                return;
        }
    };
    return (
        <div className={css.container}>
            {Array.isArray(parameters) &&
                parameters.map((parameter, i) => {
                    return (
                        <TokenomicsSliderInput
                            name={parameter.name}
                            value={parameter.value}
                            handleChange={(val) => {
                                if (parameter.keyName === "percent") {
                                    handleChange(parseFloat(val), parameter.keyName, i);
                                } else {
                                    handleChange(parseInt(val), parameter.keyName, i);
                                }
                            }}
                            min={parameter.min}
                            step={parameter.step}
                            max={parameter.max}
                            key={i}
                        />
                    );
                })}
        </div>
    );
}

export default TokenomicsSimulationChangeParameter;
