import React from "react";
import css from "./ProposalEmptyCard.module.scss";
import { Button } from "antd";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import emptyLogo from "../../../TeDaaS-Assets/DaoCreate/empty.svg";
import { proposalCreationConfigureVal } from "../../../../../redux/actions/proposalCreationAction";

function ProposalEmptyCard() {
    const dispatch = useDispatch();
    const navigateTo = useNavigate();
    const { daoAddress } = useParams();
    return (
        <div className={css.header}>
            <div className={css.iconFullWidth}>
                <img className={css.icon240} src={emptyLogo} alt="Create your First Proposal" />
            </div>
            <div className={css.textContainer}>
                <span className={css.label}>Create your First Proposal</span>
                <div className={css.displayInline}>
                    <span className={css.description}>
                        Get your community involved in the decision making process. Learn more about proposals in our guide.
                    </span>
                    <span
                        className={css.textLink}
                        onClick={() => {
                            window.open("https://xpx.aidtaas.com", "_blank");
                        }}
                    >
                        Learn More
                    </span>
                </div>
            </div>
            <Button
                className={css.button}
                type="primary"
                onClick={() => {
                    dispatch(proposalCreationConfigureVal("daoAddress", daoAddress));
                    navigateTo("/tedaasV3/createProposal/proposalMeta");
                }}
            >
                Create Proposal
            </Button>
        </div>
    );
}

export default ProposalEmptyCard;
