import React, { useEffect, useState } from "react";

import css from "./TokenManageActions.module.scss";

import TokenTypeIconActive from "../../../TeDaaS-Assets/TokenManage/TokenTypeActive.svg";
import TokenTypeIconInActive from "../../../TeDaaS-Assets/TokenManage/TokenTypeInActive.svg";

import MintIconInActive from "../../../TeDaaS-Assets/TokenManage/MintInActive.svg";
import MintIconActive from "../../../TeDaaS-Assets/TokenManage/MintActive.svg";

import BurnIconInActive from "../../../TeDaaS-Assets/TokenManage/BurnInActive.svg";
import BurnIconActive from "../../../TeDaaS-Assets/TokenManage/BurnActive.svg";

import EditBurnRateActive from "../../../TeDaaS-Assets/TokenManage/EditBurnRateActive.svg";
import EditBurnRateInActive from "../../../TeDaaS-Assets/TokenManage/EditBurnRateInActive.svg";

import TransferIconActive from "../../../TeDaaS-Assets/TokenManage/TransferActive.svg";
import TransferIconInActive from "../../../TeDaaS-Assets/TokenManage/TransferInActive.svg";

import StakeIconInActive from "../../../TeDaaS-Assets/TokenManage/StakeRewardsInActive.svg";
import StakeIconActive from "../../../TeDaaS-Assets/TokenManage/StakeRewardsActive.svg";

import EditStakeAPRIconInActive from "../../../TeDaaS-Assets/TokenManage/EditStakingAPRInActive.svg";
import EditStakeAPRIconActive from "../../../TeDaaS-Assets/TokenManage/EditStakingAPRActive.svg";

import WithdrawInActive from "../../../TeDaaS-Assets/TokenManage/WithdrawInActive.svg";
import WithdrawActive from "../../../TeDaaS-Assets/TokenManage/WithdrawActive.svg";

import WithdrawAllInActive from "../../../TeDaaS-Assets/TokenManage/WithdrawFullInActive.svg";
import WithdrawAllActive from "../../../TeDaaS-Assets/TokenManage/WithdrawFullActive.svg";

import TxnTaxIconInActive from "../../../TeDaaS-Assets/TokenManage/TxnTaxInActive.svg";
import TxnTaxIconActive from "../../../TeDaaS-Assets/TokenManage/TxnTaxActive.svg";

import EditTxTaxInActive from "../../../TeDaaS-Assets/TokenManage/EditTxnTaxInActive.svg";
import EditTxTaxActive from "../../../TeDaaS-Assets/TokenManage/EditTxnTaxActive.svg";

import AprroveIconInActive from "../../../TeDaaS-Assets/TokenManage/ApproveInActive.svg";
import AprroveIconActive from "../../../TeDaaS-Assets/TokenManage/ApproveActive.svg";

import BlacklistAddressIconActive from "../../../TeDaaS-Assets/TokenManage/BlacklistAddressesActive.svg";
import BlacklistAddressIconInActive from "../../../TeDaaS-Assets/TokenManage/BlacklistAddressesInActive.svg";

import EditTokenDocIconActive from "../../../TeDaaS-Assets/TokenManage/EditTokenDocActive.svg";
import EditTokenDocIconInActive from "../../../TeDaaS-Assets/TokenManage/EditTokenDocInActive.svg";

import RevenueshareIconActive from "../../../TeDaaS-Assets/TokenManage/RevenueShareActive.svg";
import RevenueshareIconInActive from "../../../TeDaaS-Assets/TokenManage/RevenueShareInActive.svg";

import PauseIconInActive from "../../../TeDaaS-Assets/TokenManage/PauseInActive.svg";
import PauseIconActive from "../../../TeDaaS-Assets/TokenManage/PauseActive.svg";

import BuybackInActive from "../../../TeDaaS-Assets/TokenManage/BuybackInActive.svg";
import BuybackActive from "../../../TeDaaS-Assets/TokenManage/BuybackActive.svg";

import ChangeOwnerInActive from "../../../TeDaaS-Assets/TokenManage/ChangeOwnerInActive.svg";
import ChangeOwnerActive from "../../../TeDaaS-Assets/TokenManage/ChangeOwnerActive.svg";

import BlacklistActive from "../../../TeDaaS-Assets/TokenManage/BlacklistActive.svg";
import BlacklistInActive from "../../../TeDaaS-Assets/TokenManage/BlacklistInActive.svg";

import { useDispatch, useSelector } from "react-redux";
import { tokenActionOpenModal } from "../../../../../redux/actions/tokenCreationOptionsAction";
import { useERC20TokenAction } from "../../../Hooks/useERC20TokenAction";

const TokenManageActions = ({ selectedToken, owner }) => {
    const dispatch = useDispatch();
    const [tokenAction, setTokenActions] = useState([]);
    const userAddress = useSelector((state) => state.auth.userAddress);

    useEffect(() => {
        const activeCheck = userAddress == owner;
        setTokenActions([
            {
                icon: TokenTypeIconInActive,
                name: selectedToken.tokenType == "GT" ? "Governance Token" : "Utility Token", // Can't be edited
                isEnabled: true,
                isActive: false,
            },
            {
                icon: activeCheck && selectedToken?.canMint && !selectedToken?.isPaused ? MintIconActive : MintIconInActive,
                name: "Mint", // Only Owner
                isEnabled: selectedToken.tokenType !== "GT" && activeCheck && selectedToken?.canMint,
                isActive: activeCheck && selectedToken?.canMint && !selectedToken?.isPaused,
            },
            {
                icon: activeCheck && selectedToken?.canBurn && !selectedToken?.isPaused ? BurnIconActive : BurnIconInActive,
                name: "Burn", // Only Owner
                isEnabled: selectedToken.tokenType !== "GT" && activeCheck && selectedToken?.canBurn,
                isActive: activeCheck && selectedToken?.canBurn && !selectedToken?.isPaused,
            },
            {
                icon: selectedToken?.isBlacklisted ? BlacklistActive : BlacklistInActive,
                name: "Blacklist", // By All
                isEnabled: selectedToken.tokenType !== "GT" && selectedToken?.canBlacklist,
                isActive: selectedToken?.isBlacklisted,
            },
            {
                icon:
                    selectedToken?.balance > 0 && !selectedToken?.isBlacklisted && !selectedToken?.isPaused
                        ? TransferIconActive
                        : TransferIconInActive,
                name: "Transfer", // By All
                isEnabled: selectedToken?.balance > 0,
                isActive: selectedToken?.balance > 0 && !selectedToken?.isBlacklisted && !selectedToken?.isPaused,
            },
            {
                icon: activeCheck && !selectedToken?.isPaused ? TransferIconActive : TransferIconInActive,
                name: "Transfer To User", // By All
                isEnabled: selectedToken.tokenType !== "GT" && activeCheck,
                isActive: activeCheck && !selectedToken?.isPaused,
            },
            {
                icon: activeCheck && selectedToken?.canBurn && !selectedToken?.isPaused ? EditBurnRateActive : EditBurnRateInActive,
                name: "Edit Burn Rate", // By Owner
                isEnabled: selectedToken.tokenType !== "GT" && activeCheck && selectedToken?.canBurn,
                isActive: activeCheck && selectedToken?.canBurn && !selectedToken?.isPaused,
            },
            {
                icon: activeCheck && selectedToken?.canMint && !selectedToken?.isPaused ? MintIconActive : MintIconInActive,
                name: "Edit Mint Rate", // Only Owner
                isEnabled: selectedToken.tokenType !== "GT" && activeCheck && selectedToken?.canMint,
                isActive: activeCheck && selectedToken?.canMint && !selectedToken?.isPaused,
            },
            {
                icon: selectedToken?.balance > 0 && !selectedToken?.isBlacklisted && !selectedToken?.isPaused ? StakeIconActive : StakeIconInActive,
                name: "Stake", // By All
                isEnabled: selectedToken.tokenType !== "GT" && selectedToken?.canStake,
                isActive: selectedToken?.canStake && selectedToken?.balance > 0 && !selectedToken?.isBlacklisted && !selectedToken?.isPaused,
            },
            {
                icon: activeCheck && selectedToken?.canStake && !selectedToken?.isPaused ? EditStakeAPRIconActive : EditStakeAPRIconInActive,
                name: "Change Staking APR", // By Owner
                isEnabled: selectedToken.tokenType !== "GT" && activeCheck && selectedToken?.canStake,
                isActive: activeCheck && selectedToken?.canStake && !selectedToken?.isPaused,
            },
            {
                icon: activeCheck && selectedToken?.canStake && !selectedToken?.isPaused ? EditStakeAPRIconActive : EditStakeAPRIconInActive,
                name: "Change Token Limit", // By Owner
                isEnabled: selectedToken.tokenType !== "GT" && activeCheck && selectedToken?.canStake,
                isActive: activeCheck && selectedToken?.canStake && !selectedToken?.isPaused,
            },
            {
                icon: selectedToken?.stakeBalance && !selectedToken?.isBlacklisted && !selectedToken?.isPaused ? WithdrawActive : WithdrawInActive,
                name: "Withdraw", // By All
                isEnabled: selectedToken.tokenType !== "GT" && selectedToken?.canStake,
                isActive: selectedToken?.stakeBalance && !selectedToken?.isBlacklisted && !selectedToken?.isPaused,
            },
            {
                icon:
                    selectedToken?.stakeBalance && !selectedToken?.isBlacklisted && !selectedToken?.isPaused
                        ? WithdrawAllActive
                        : WithdrawAllInActive,
                name: "Withdraw Full", // By All
                isEnabled: selectedToken.tokenType !== "GT" && selectedToken?.canStake,
                isActive: selectedToken?.stakeBalance && !selectedToken?.isBlacklisted && !selectedToken?.isPaused,
            },
            {
                icon: activeCheck && !selectedToken?.isPaused ? TxnTaxIconActive : TxnTaxIconInActive,
                name: "Taxable Transaction", // By Owner
                isEnabled: selectedToken.tokenType !== "GT" && activeCheck && selectedToken?.canTxnTax,
                isActive: activeCheck && selectedToken?.canTxnTax && !selectedToken?.isPaused,
            },
            {
                icon:
                    selectedToken?.approve && selectedToken?.balance > 0 && !selectedToken?.isBlacklisted && !selectedToken?.isPaused
                        ? AprroveIconActive
                        : AprroveIconInActive,
                name: "Approve", // By All
                isEnabled: selectedToken.tokenType !== "GT" && selectedToken?.approve,
                isActive: selectedToken?.approve && selectedToken?.balance > 0 && !selectedToken?.isBlacklisted && !selectedToken?.isPaused,
            },
            {
                icon:
                    activeCheck && selectedToken?.canBlacklist && !selectedToken?.isPaused
                        ? BlacklistAddressIconActive
                        : BlacklistAddressIconInActive,
                name: "Blacklist Addresses", // By Owner
                isEnabled: selectedToken.tokenType !== "GT" && activeCheck && selectedToken?.canBlacklist,
                isActive: activeCheck && selectedToken?.canBlacklist && !selectedToken?.isPaused,
            },
            {
                icon: activeCheck && selectedToken?.canEditDocs && !selectedToken?.isPaused ? EditTokenDocIconActive : EditTokenDocIconInActive,
                name: "Edit Token Docs", // By Owner
                isEnabled: activeCheck && selectedToken?.canEditDocs,
                isActive: activeCheck && selectedToken?.canEditDocs && !selectedToken?.isPaused,
            },
            {
                icon: RevenueshareIconInActive,
                name: "Revenue Share",
                isEnabled: selectedToken.tokenType !== "GT" && selectedToken?.canRevenueShare && !selectedToken?.isBlacklisted,
            },
            {
                icon: activeCheck && selectedToken?.canPause ? PauseIconActive : PauseIconInActive,
                name: "Pause / Unpause", // By Owner
                isEnabled: selectedToken.tokenType !== "GT" && activeCheck && selectedToken?.canPause,
                isActive: activeCheck && selectedToken?.canPause,
            },
            {
                icon: activeCheck && selectedToken?.canBuyBack && !selectedToken?.isPaused ? BuybackActive : BuybackInActive,
                name: "Buyback", // By Owner
                isEnabled: selectedToken.tokenType !== "GT" && activeCheck && selectedToken?.canBuyBack,
                isActive: activeCheck && selectedToken?.canBuyBack && !selectedToken?.isPaused,
            },
            {
                icon: activeCheck && selectedToken?.canChangeOwner && !selectedToken?.isPaused ? ChangeOwnerActive : ChangeOwnerInActive,
                name: "Change Owner", // By Owner
                isEnabled: selectedToken.tokenType !== "GT" && activeCheck && selectedToken?.canChangeOwner,
                isActive: activeCheck && selectedToken?.canChangeOwner && !selectedToken?.isPaused,
            },
            {
                icon: activeCheck && selectedToken?.canTxnTax && !selectedToken?.isPaused ? EditTxTaxActive : EditTxTaxInActive,
                name: "Edit Tx Tax", // By Owner
                isEnabled: selectedToken.tokenType !== "GT" && activeCheck && selectedToken?.canTxnTax,
                isActive: activeCheck && selectedToken?.canTxnTax && !selectedToken?.isPaused,
            },
        ]);
    }, [userAddress, owner, selectedToken]);

    return (
        <div className={css.actionButtonContainer}>
            {Array.isArray(tokenAction) &&
                tokenAction.map((item, index) => {
                    return item.isEnabled ? (
                        <div
                            key={index}
                            className={`${css.actionButtonWrapper} ${item.isActive ? css.activeAction : ""}`}
                            onClick={() => {
                                item.isActive ? dispatch(tokenActionOpenModal(item.name)) : "";
                                // dispatch(tokenActionOpenModal(item.name));
                            }}
                        >
                            <img src={item.icon} alt={`${item.name} Icon`} />
                            <span style={{ color: item.isActive ? "#fff" : "#323E4F" }}>{item.name}</span>
                        </div>
                    ) : (
                        <> </>
                    );
                })}
        </div>
    );
};

export default TokenManageActions;
