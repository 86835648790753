import { Button, Checkbox } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import css from "./DAOStudioDeployCard.module.scss";
import { showWarningToast } from "../../../../../services/notificationService";

function DAOStudioDeployCard({ editable = false, path = "", name = "", checked = false, handleChange = () => {}, children }) {
    const navigateTo = useNavigate();
    const next = {
        selectBlockchain: "/tedaasV3/buildYourDAO/selectBlockchain",
        describeYourDAO: "/tedaasV3/buildYourDAO/describeYourDAO",
        participationInGovernance: "/tedaasV3/buildYourDAO/defineMembership",
        selectGovernanceSettings: "/tedaasV3/buildYourDAO/governanceSettings",
    };
    return (
        <div className={css.card}>
            <div className={css.cardHeader}>
                <div className={css.cardHeaderLeft}>
                    <span className={css.label}>{name}</span>
                    <span className={css.value}>{editable ? "(Changeable with a vote)" : "(Not Changeable)"}</span>
                </div>
                <Button
                    className={css.button}
                    onClick={() => {
                        navigateTo(next[path]);
                    }}
                >
                    Edit
                </Button>
            </div>
            <div className={css.cardBody}>
                {children}
                <div className={css.checkboxContainer} onClick={handleChange}>
                    <Checkbox className={css.checkbox} checked={checked}></Checkbox>
                    <span>These Values are Correct</span>
                </div>
            </div>
        </div>
    );
}

export default DAOStudioDeployCard;
