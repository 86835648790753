export const schemas = {
    SUBSCRIBER: "66b4af0b41a0013b20854245",
    COMMUNICATION: "66b49bf61b2d1c788b21f2ee",
    SUBSCRIPTION: "66b363f01b2d1c788b21f2dd",
    SUBSCRIPTION_BILL: "66b367a71b2d1c788b21f2df",
    DEVICE: "66b37a431b2d1c788b21f2e1",
    DEVICE_ACTIVITY: "66b471b141a0013b2085423e",
    SUBSCRIPTION_LIFECYCLE: "66b3688f41a0013b2085422a",
    SUBSCRIPTION_POLICY: "66bb48f841a0013b20856a2a",
    ADMIN: "66bb08971b2d1c788b22188b",
    PRIVACY_AND_COMPLIANCE: "66bb2ee141a0013b20856a29"
};