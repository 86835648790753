import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import TokenCreationOverview from "../../Components/Layout/TokenCreationOverview/TokenCreationOverview";

import styles from "./SmartContractLowCode.module.scss";
import TokenSmartContract from "../../Components/Layout/TokenSmartContract/TokenSmartContract";
import LoadingComponent from "../../../shared/molecules/Loading/LoadingComponent";
import { updateShowRightSideBar } from "../../../../redux/actions/rightSideBarAction";
import { updateTopBarStyle } from "../../../../redux/actions/topbarActions";
import { useNavigate } from "react-router-dom";
const SmartContractLowCode = () => {
    const dispatch = useDispatch();
    const loader = useSelector((state) => state.loading.isLoading);
    const fetchedData = useSelector((state) => state.tc);
    const simulationData = useSelector((state) => state.tsr);
    const navigateTo = useNavigate();
    useEffect(() => {
        console.log(fetchedData);
        if(fetchedData.isNewToken==false){
           navigateTo("/tedaasV3/tokenDashboard"); 
        }
        dispatch(updateShowRightSideBar(false));
        dispatch(updateTopBarStyle(<span className={styles.title}>Smart Contract</span>, "#f5f6f7"));
    }, []);


    return (
        <div className={styles.container}>
            {loader && <LoadingComponent />}
            <div className={styles.detailsContainer}>
                <div className={styles.wrapper}>
                    <TokenCreationOverview />
                </div>
                <div className={styles.wrapper}>
                    <TokenSmartContract />
                </div>
            </div>
        </div>
    );
};

export default SmartContractLowCode;
