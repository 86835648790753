import { useParams } from "react-router-dom";
import { getFilteredEntities } from "../../../services/stf";
import { TEDAAS_SCHEMAS } from "../Constants/Constants";
import { useERC20TokenAction } from "./useERC20TokenAction";
import usePostTokenData from "./usePostTokenData";
import { useDispatch, useSelector } from "react-redux";
import { tokenDataConfigureVal } from "../../../redux/actions/tokenDataAction";

export const useTokenManage = () => {
    const signer = useSelector((state) => state.auth.signer);
    const userAddress = useSelector((state) => state.auth.userAddress);
    const { address } = useParams();
    const { getOwner, isPaused, getAddressBalance, getAddressStakedAmount, checkBlacklist } = useERC20TokenAction();
    const dispatch = useDispatch();
    const selectedToken = useSelector((state) => state.td);

    const getTokenDetails = async () => {
        try {
            const resOwnerAddress = await getOwner(address);
            const paused = await isPaused(address);
            const ownerAddress = resOwnerAddress;
            const addressBalance = await getAddressBalance(address);
            const isBlacklisted = selectedToken.tokenType == "UT" ? await checkBlacklist(address) : false;
            const stakedAmount = selectedToken.tokenType == "UT" ? await getAddressStakedAmount(address, selectedToken.tokenType) : 0;
            const tokenDetails = {
                ...selectedToken,
                isPaused: paused,
                stakeBalance: stakedAmount,
                votingPower: selectedToken.tokenType == "GT" ? addressBalance : 0,
                balance: addressBalance,
                isBlacklisted: isBlacklisted,
            };
            const resFrom = await getFilteredEntities(
                { tokenAddress: address, from: userAddress },
                TEDAAS_SCHEMAS.transactionId,
                TEDAAS_SCHEMAS.accessToken,
            );
            const resTo = await getFilteredEntities(
                { tokenAddress: address, to: userAddress },
                TEDAAS_SCHEMAS.transactionId,
                TEDAAS_SCHEMAS.accessToken,
            );
            resTo.entities = resTo.entities.filter((a) => {
                a.to !== a.from;
            });
            const txnData = [...resFrom.entities, ...resTo.entities];
            txnData.sort((a, b) => {
                return b.date - a.date;
            });
            return [ownerAddress, tokenDetails, txnData];
        } catch (e) {
            console.log(e);
            throw Error(e);
        }
    };

    return { getTokenDetails };
};
