import { showWarningToast } from "../../../services/notificationService";

const selectBlockchain = "/tedaasV3/buildYourDAO/selectBlockchain";
const describeYourDAO = "/tedaasV3/buildYourDAO/describeYourDAO";
const defineMembership = "/tedaasV3/buildYourDAO/defineMembership";
const selectGovernanceSettings = "/tedaasV3/buildYourDAO/governanceSettings";
const deployYourDAO = "/tedaasV3/buildYourDAO/deployYourDAO";
export const validateDaoCreationForm = (path, data) => {
    let isValid = false;
    let updated = false;
    let next = selectBlockchain;
    if (path === "selectBlockchain") {
        if (data.blockchain && data.network) {
            next = describeYourDAO;
            updated = true;
            isValid = true;
        } else {
            showWarningToast("Please fill the details");
        }
    } else if (path === "describeYourDAO") {
        if (data.daoName.length > 0 && data.ensSubdomain.length > 0 && data.description.length > 0) {
            isValid = true;
        }
        for (let i of data.links) {
            if (i.name.length === 0 || i.url.length === 0) {
                isValid = false;
                break;
            }
        }
        if (isValid) {
            next = defineMembership;
            updated = true;
        } else {
            showWarningToast("Please fill valid details");
        }
    } else if (path === "defineMembership") {
        let flag = true;
        if (flag && data.participant === "tokenHolders" && data.hasERC20Token === false) {
            if ((flag && data.tokenName.length === 0) || data.tokenSymbol.length === 0) {
                flag = false;
            }
            for (let i of data.distribution) {
                if (i.tokens === 0 || i.address.length === 0) {
                    flag = false;
                    break;
                }
            }
        }
        if (flag && data.participant === "tokenHolders" && data.hasERC20Token === true && data.contractAddress.length === 0) {
            flag = false;
        }
        if (flag && data.participant === "multisigMembers") {
            if (data.daoType !== "wallet") {
                flag = false;
            }
            for (let i of data.multisigMembersAddress) {
                if (flag === false || i.address.length === 0) {
                    flag = true;
                    break;
                }
            }
        }
        if (flag) {
            isValid = true;
            next = selectGovernanceSettings;
            updated = true;
        } else {
            showWarningToast("Please fill valid details");
            isValid = false;
            updated = false;
        }
    } else if (path === "governanceSettings") {
        if (
            data.supportThreeshold < 1 ||
            data.minimumParticipation < 1 ||
            data.minimumDuration.days < 1 ||
            data.minimumRequirement < 1 ||
            data.minimumApproval < 1
        ) {
            showWarningToast("Please fill valid details");
            isValid = false;
            updated = false;
        } else {
            isValid = true;
            next = deployYourDAO;
            updated = true;
        }
    }
    return {
        isValid: isValid,
        validatedData: {
            ...data,
            updated: updated,
        },
        next: next,
    };
};

export const validateDaoCreationFormPreviosSteps = (path, data) => {
    let isValid = false;
    let next = selectBlockchain;
    if (path === "describeYourDAO") {
        isValid = data.blockchain.updated === true ? true : false;
    } else if (path === "defineMembership") {
        isValid = data.blockchain.updated === true && data.describe.updated === true ? true : false;
        next = !isValid && data.describe.updated === false ? describeYourDAO : selectBlockchain;
    } else if (path === "governanceSettings") {
        isValid = data.blockchain.updated === true && data.describe.updated === true && data.participationInGovernance.updated ? true : false;
        next = isValid ? selectBlockchain : data.describe.updated === false ? describeYourDAO : selectGovernanceSettings;
    } else if (path === "deployYourDAO") {
        isValid =
            data.blockchain.updated === true &&
            data.describe.updated === true &&
            data.participationInGovernance.updated &&
            data.governanceSettings.updated
                ? true
                : false;
        next = isValid
            ? selectBlockchain
            : data.describe.updated === false
              ? describeYourDAO
              : data.participationInGovernance.updated === false
                ? defineMembership
                : selectGovernanceSettings;
    }
    return {
        isValid: isValid,
        next: next,
    };
};
