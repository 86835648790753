import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import css from "./WithdrawModal.module.scss";
import burnImg from "../../../../TeDaaS-Assets/Modals/withdraw.svg";
import closeImg from "../../../../TeDaaS-Assets/Nodes/close.svg";
import { tokenActionCloseModal } from "../../../../../../redux/actions/tokenCreationOptionsAction";
import { useParams } from "react-router-dom";
import { useERC20TokenAction } from "../../../../Hooks/useERC20TokenAction";
import { updateLoader } from "../../../../../../redux/actions/loaderAction";
import { showErrorToast, showSuccessToast, showWarningToast } from "../../../../../../services/notificationService";

const WithdrawModal = ({totalSupply}) => {
    const dispatch = useDispatch();
    const modelData = useSelector((state) => state.tco);
    const { address } = useParams();
    const { withdrawStakeAmount } = useERC20TokenAction();
    const [addresss, setAddresss] = useState("");
    const handleOk = async () => {
        dispatch(updateLoader(true));
        const res = await withdrawStakeAmount(address, false, addresss);
        if (res == true) {
            showSuccessToast(`Successfully withdrawed ${addresss} tokens`);
            dispatch(tokenActionCloseModal());
        } else {
            showErrorToast("Withdrawing all tokens has failed");
        }
        dispatch(updateLoader(false));
    };
    const handleCancel = () => {
        dispatch(tokenActionCloseModal());
    };
    return (
        <Modal
            centered
            title={
                <div className={css.header}>
                    <div className={css.headerLeft}>
                        <div className={css.headerTop}>
                            <img className={css.icon} src={burnImg} alt="Withdraw" />
                            <span className={css.title}>Withdraw</span>
                        </div>
                        <div className={css.iconContainer} onClick={handleCancel}>
                            <img className={css.icon} src={closeImg} alt="Close" />
                        </div>
                    </div>
                    <div className={css.subTitleContainer}>
                        <span className={css.subTitle}>Manage Action</span>
                        <span className={css.subTitle}>Current token supply: {totalSupply}</span>
                    </div>
                </div>
            }
            closeIcon={false}
            open={modelData.actionModals["Withdraw"]}
        >
            <div className={css.container}>
                <span className={css.title}>If you withdraw the stake tokens, you will lose the token staking rewards..</span>
                <div className={css.slider}>
                    <span className={css.label}>Amount</span>
                    <input
                        type="text"
                        placeholder="e.g 50,000"
                        className={css.input}
                        value={addresss}
                        onChange={(e) => {
                            setAddresss(e.target.value);
                        }}
                    />
                </div>
                <div className={css.buttons}>
                    <span className={css.cancel} onClick={handleCancel}>
                        Cancel
                    </span>
                    <span
                        className={css.submit}
                        onClick={() => {
                            handleOk();
                        }}
                    >
                        Submit
                    </span>
                </div>
            </div>
        </Modal>
    );
};
export default WithdrawModal;
