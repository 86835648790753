import { useDispatch } from "react-redux";
import { NODES } from "./Constants";
import { configureToken, tokenCreationAssignee, tokenCreationReward } from "../../../redux/actions/tokenCreationAction";
import { showWarningToast } from "../../../services/notificationService";
import { uploadFileInCMS } from "../../../services/stf";

export const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
        return;
    });
};

export const generateTooltipFocus = (data = {}, name = "", type = "", id = "") => {
    var focus = {
        name: "",
        value: "",
    };
    if (type === "Assignee") {
        if (!data.assignee[id]?.can) {
            return [];
        }
        focus.name = `${Math.round(data.assignee[id]?.percent * data.initialSupply)} Tokens`;
        focus.value = `${data.assignee[id]?.percent}%`;
    } else if (type === "Reward") {
        if (!data.rewards[name]?.can) {
            return [];
        }
        // focus.name = `${Math.round(data.rewards[name]?.percent * data.initialSupply)} Tokens`;
        focus.value = `${data.rewards[name]?.percent}%`;
    } else if (data[name]?.can && name === "changeOwner") {
        var n = data[name]?.address;
        focus.name = `${shortenAddress(n)}`;
    } else if (data[name]?.can && name === "buyback") {
        var n = data[name].address;
        focus.name = `${shortenAddress(n)}`;
        focus.value = `${data[name].percent}%`;
    } else if (data[name]?.can && (name === "burn" || name === "mint")) {
        var n = data[name].period;
        focus.name = `${n === "month" ? "Monthly" : n === "quarter" ? "Quarterly" : "Yearly"}`;
        focus.value = `${data[name].percent}%`;
    } else if (data[name]?.can && name === "transactionTax") {
        focus.name = `Buy-${data[name].buy}%`;
        focus.value = `Sell-${data[name].sell}%`;
    } else if (type === "tokenPrice") {
        focus.name = "Token Price";
        focus.value = data?.tokenPrice?.price;
    } else if (type === "tokenType") {
        focus.name = "Token Type";
        focus.value = name;
    } else if (type === "tokenDocs") {
        focus.name = "Token Docs";
        focus.value = data.tokenDocs.docs.length;
    }
    return focus;
};
export const generateTooltipDate = (data = {}, name = "", type = "", id = "") => {
    let date = "";
    if (type === "Assignee") {
        if (!data.assignee[id]?.can) {
            return "";
        }
        date = new Date(data.assignee[id]?.updatedOn);
    } else if (type === "Reward") {
        if (!data.rewards[name]?.can) {
            return "";
        }
        date = new Date(data.rewards[name]?.updatedOn);
    } else if (data[name]?.can) {
        date = new Date(data[name].updatedOn);
    } else if (type === "tokenPrice") {
        date = new Date(data[type].updatedOn);
    } else if (type === "tokenDocs") {
        date = new Date(data[type].updatedOn);
    } else {
        return "";
    }
    return `${date.getDate()}/${date.getMonth() < 10 ? `0${date.getMonth()}` : date.getMonth()}/${String(date.getFullYear()).substring(2)}`;
};
export const generateTooltipData = (data = {}, name = "", type = "", id = "") => {
    var vesting = 0;
    var vestingPeriod = "";
    var emission = 0;
    var emissionPeriod = "";
    if (type === "Assignee") {
        if (!data.assignee[id]?.can) {
            return [];
        }
        var assignee = data.assignee[id];
        vesting = assignee?.lock;
        vestingPeriod = assignee?.vestingPeriod;
        emission = assignee?.unlock;
        emissionPeriod = assignee?.emissionPeriod;
    } else if (type === "Reward") {
        if (!data.rewards[name]?.can) {
            return [];
        }
        var rewards = data.rewards[name];
        vesting = rewards?.lock;
        vestingPeriod = rewards?.vestingPeriod;
        emission = rewards?.unlock;
        emissionPeriod = rewards?.emissionPeriod;
    } else {
        return [];
    }

    return [
        {
            name: `Vesting`,
            value: `${vesting} ${vestingPeriod}`,
        },
        {
            name: `Emission Type`,
            value: `${emission} ${emissionPeriod}`,
        },
    ];
};

export const generateParentNode = (name, type, id = "", nodeType = "parentNode") => {
    return {
        name: name,
        index: type,
        type: nodeType,
        id: type + id,
        data: {
            name: name,
            type: type,
            index: type,
            id: id,
        },
        children: [],
    };
};

export const generateArtifactNode = (name, index, type, id = "") => {
    return {
        name: name,
        index: index,
        type: "artifactNode",
        id: index + id,
        data: {
            name: name,
            type: type,
            index: index,
            id: id,
        },
        children: [],
    };
};

export const generateNode = (item) => {
    var ele = {
        id: item.node.type,
        type: item.node.type,
        data: item.node,
    };
    return ele;
};

export const generateEdge = (source, target) => {
    var ele = {
        id: `${source}-${target}`,
        source: source,
        target: target,
        type: "step",
        style: {
            color: "#75B1D2",
        },
    };
    return ele;
};

export const generateParameter = (item) => {
    if (item === "burn" || item === "mint") {
        return {
            can: true,
            percent: 0.001,
            lock: 0,
            unlock: 0,
            period: "month",
        };
    } else if (item === "pause" || item === "blacklist") {
        return true;
    } else if (item === "changeOwner") {
        return {
            can: true,
            address: "",
        };
    } else if (item === "buyback") {
        return {
            can: true,
            percent: 0.001,
            address: "",
        };
    } else if (item === "transactionTax") {
        return {
            can: true,
            buy: 0.001,
            sell: 0.001,
            address: "",
        };
    } else {
        return {
            can: true,
            percent: 0.001,
            lock: 0,
            unlock: 0,
            period: "month",
        };
    }
};

export const generateContent = (name, item) => {
    var c = [];
    if (name === "transactionTax") {
        c.push(`Buy Tax%-${item.buy}%`);
        c.push(`Sell Tax%-${item.sell}%`);
    } else if (name === "burn" || name === "mint") {
        c.push(`${item.percent}%-${item.period === "month" ? "Monthly" : item.period === "year" ? "Yearly" : ""}`);
    } else if (name === "changeOwner") {
        // if (item.address) {
        c.push(`${shortenAddress(item?.address)}`);
        // }
    } else if (name === "buyback") {
        if (item.address) {
            c.push(`${item.percent}%-${shortenAddress(item?.address)}`);
        }
    } else {
        c.push(`${item.percent}%  ${Math.round(item.percent * 500000)} Tokens`);
        c.push(`Vesting-${item.lock} ${item.vestingPeriod === "month" ? "months" : item.vestingPeriod === "year" ? "years" : "quarter"}`);
        c.push(`E.Type-${item.unlock} ${item.emissionPeriod === "month" ? "months" : item.emissionPeriod === "year" ? "years" : "quarter"}`);
    }
    return c;
};

export const getName = (name, type, e = "") => {
    var arr = [];
    if (type === "Assignee") {
        arr = NODES.assignee.nodes;
        if (name === "custom") {
            arr = [];
        }
    } else if (type === "Reward") {
        arr = NODES.reward.nodes;
        if (name === "custom") {
            arr = [];
        }
    } else {
        arr = NODES.tokenConfiguration.nodes;
    }
    for (let i of arr) {
        if (i.index === name) {
            return i.name;
        }
    }
    if (e.length > 0) {
        return e;
    } else {
        return name;
    }
};

export const handleImageDrop = async (handleChange, key, event, type) => {
    event.preventDefault();
    var file = "";
    if (type === "drop") {
        file = event.dataTransfer.files[0];
    } else if (type === "input") {
        file = event.target.files[0];
    }
    if (file && (file.type === "image/png" || file.type === "image/jpeg" || file.type === "image/svg+xml")) {
        const reader = new FileReader();
        reader.onload = () => {
            handleChange(key, { target: { value: reader.result } });
            return reader.result;
        };
        reader.onerror = () => {
            // ("Failed to convert file to data URL.");
        };
        reader.readAsDataURL(file);
    } else {
        showWarningToast("Please drop a PNG, JPG, or SVG file...");
    }
};

export const handleFileDrop = async (handleChange, key, event, type) => {
    event.preventDefault();
    var file = "";
    if (type === "drop") {
        file = event.dataTransfer.files[0];
    } else if (type === "input") {
        file = event.target.files[0];
    }
    var res = await uploadFileInCMS(file);
    if (res === false) {
        return false;
    }
    return res;
};

export const capitalizeFirstLetterOfString = (str) => {
    try {
        return str.charAt(0).toUpperCase() + str.slice(1);
    } catch (e) {
        return str;
    }
};

export const shortenAddress = (text) => {
    if (text && text.length > 6) {
        return `${text.substring(0, 5)}...${text.substring(text.length - 5)}`;
    } else {
        return text;
    }
};

export const formatInputToIncludeGTandPercnt = (e) => {
    // Remove any existing prefix/suffix to allow proper number input
    let inputValue = e.target.value.replace(/[<\s%]/g, "");
    return inputValue;
};

export const formatDateToString = (dt, type) => {
    let time = 0;
    if (typeof dt === "string" && (dt.length === 13 || dt.length === 10)) {
        if (dt.length === 13) {
            time = new Date(parseInt(dt));
        } else {
            time = new Date(parseInt(dt) * 1000);
        }
    } else {
        time = new Date(dt);
    }
    const year = time.getFullYear();
    const month = time.getMonth();
    const date = time.getDate();
    const day = time.getDay();
    const hour = time.getHours();
    const minute = time.getMinutes();
    const second = time.getSeconds();
    const diff = new Date().getTime() - time.getTime();
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const fullMonths = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    if (type === "month year") {
        return `${fullMonths[month]} ${year}`;
    }
    if (type === "day month date year") {
        return `${days[day]} ${months[month]} ${date} ${year}`;
    }
    return dt;
};

export const getVotingEndtTimeInString = (votingEnds) => {
    if (votingEnds === "") {
        return "";
    }
    const currentTime = new Date().getTime();
    const endTime = new Date(parseInt(votingEnds)).getTime();
    const diff = (endTime - currentTime) / 1000;
    if (diff <= 0) {
        return "";
    }
    if (parseInt(diff / (60 * 60 * 24)) > 0) {
        return `Voting Ends in ${Math.floor(diff / (60 * 60 * 24))} days`;
    } else if (parseInt(diff / (60 * 60)) > 0) {
        return `Voting Ends in ${Math.floor(diff / (60 * 60))} hours`;
    } else if (parseInt(diff / 60) > 0) {
        return `Voting Ends in ${Math.floor(diff / 60)} minutes`;
    } else if (parseInt(diff) > 0) {
        return `Voting Ends in ${Math.floor(diff)} seconds`;
    } else {
        return "";
    }
};

export const getMinimumDurationFromDate = (time) => {
    const currentTime = Number(time);
    const days = parseInt(currentTime / (1000 * 60 * 60 * 24));
    const hours = parseInt((currentTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = parseInt((currentTime % (1000 * 60 * 60)) / (1000 * 60));
    return `${days} days ${hours} hours ${minutes} minutes`;
};
export const extractMinimumDurationFromTimeStamp = (time) => {
    const currentTime = Number(time);
    const days = parseInt(currentTime / (1000 * 60 * 60 * 24));
    const hours = parseInt((currentTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = parseInt((currentTime % (1000 * 60 * 60)) / (1000 * 60));
    return { days: days, hours: hours, minutes: minutes };
};

export const convertToBasisPoints = (data) => {
    if (data > 0.005 && data <= 5) {
        return data * 1000;
    }
};

export const convertJsonToTimeStamp = (data) => {
    const { days, hours, minutes } = data;
    return (parseInt(days) * 24 * 60 * 60 + parseInt(hours) * 60 * 60 + parseInt(minutes) * 60) * 1000;
};


export const getTransferErrorMessage = (errorMessage) => {
    const match = errorMessage.match(/reason="([^"]+)"/);
    return match ? match[1] : "Transfer Failed";
  }