import React, { useEffect, useState } from "react";
import css from "./TokenomicsSimulationApplyParameters.module.scss";
import { Tag, Tooltip } from "antd";
import closeImg from "../../../TeDaaS-Assets/TokenomicsSimulation/close.svg";
import "./TokenomicsSimulationApplyParameters.css";
import { useDispatch, useSelector } from "react-redux";
import { configureTokenVal } from "../../../../../redux/actions/tokenCreationAction";
import TokenomicsSimulationChangeParameter from "../../Layout/TokenomicsSimulationChangeParameter/TokenomicsSimulationChangeParameter";

function TokenomicsSimulationApplyParameters({ parameters = [], removeParameter = (e) => {}, max = 6, type = "default", id = "default" }) {
    const [length, setLength] = useState(max);
    const fetchedData = useSelector((state) => state.tc);
    const dispatch = useDispatch();
    const disableParameter = (type, key = "") => {
        switch (type) {
            case "burn":
                dispatch(configureTokenVal("burn", { ...fetchedData.burn, can: false }));
            case "mint":
                dispatch(configureTokenVal("mint", { ...fetchedData.mint, can: false }));
            case "assignee":
                dispatch(
                    configureTokenVal("assignee", {
                        ...fetchedData.assignee,
                        [key]: {
                            ...fetchedData.assignee[key],
                            can: false,
                        },
                    }),
                );
            case "rewards":
                dispatch(
                    configureTokenVal("rewards", {
                        ...fetchedData.rewards,
                        [key]: {
                            ...fetchedData.rewards[key],
                            can: false,
                        },
                    }),
                );
        }
    };
    useEffect(() => {}, [parameters]);
    return (
        <div className={css.headerLeft}>
            <span className={css.font1422Grey}>Applied Parameters:</span>
            <div className={`${css.headerButtons} tooltip`}>
                {Array.isArray(parameters) &&
                    parameters.map((parameter, i) => {
                        if (i < length) {
                            return (
                                <Tooltip
                                    key={i}
                                    placement="bottomLeft"
                                    overlayStyle={{
                                        width: "max-content",
                                        maxWidth: "none",
                                        "--antd-arrow-background-color": "#ffffff",
                                        padding: "0",
                                        backgroundColor: "#ffffff",
                                    }}
                                    overlayInnerStyle={{
                                        backgroundColor: "#ffffff",
                                        padding: "0",
                                    }}
                                    // visible={parameter.type==="initialSupply"}
                                    trigger={"hover"}
                                    // onVisibleChange={(e) => {
                                    // setVisible(e);
                                    // }}
                                    title={
                                        <div style={{ width: "var(--TClamp580px)" }}>
                                            <TokenomicsSimulationChangeParameter isHover={true} type={parameter.type} id={parameter.id} />
                                        </div>
                                    }
                                >
                                    <Tag
                                        className={`${css.headerButton}`}
                                        closable={parameter.index !== "initialSupply"}
                                        closeIcon={
                                            <img
                                                onClick={() => {
                                                    disableParameter(parameter.type, parameter.id);
                                                }}
                                                className={css.closeIcon}
                                                src={closeImg}
                                                alt="Close"
                                            />
                                        }
                                    >
                                        {`${parameter.name}`}
                                    </Tag>
                                </Tooltip>
                            );
                        }
                    })}
                {parameters.length > max && (
                    <Tag
                        className={`${css.headerButton}`}
                        onClick={() => {
                            if (length !== parameters.length) {
                                setLength(parameters.length);
                            } else {
                                setLength(max);
                            }
                        }}
                    >
                        {length < parameters.length ? `+${parameters.length - length} More` : "Show Less"}
                    </Tag>
                )}
            </div>
        </div>
    );
}

export default TokenomicsSimulationApplyParameters;
