import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import css from "./WithdrawFullModal.module.scss";
import burnImg from "../../../../TeDaaS-Assets/Modals/editBurn.svg";
import closeImg from "../../../../TeDaaS-Assets/Nodes/close.svg";
import addressClose from "../../../../TeDaaS-Assets/Modals/close.svg";
import { tokenActionCloseModal } from "../../../../../../redux/actions/tokenCreationOptionsAction";
import { handleFileDrop } from "../../../../Constants/reusableFunctions";
import warningImg from "../../../../TeDaaS-Assets/Modals/warning.svg";
import { useParams } from "react-router-dom";
import { useERC20TokenAction } from "../../../../Hooks/useERC20TokenAction";
import { updateLoader } from "../../../../../../redux/actions/loaderAction";
import { showErrorToast, showSuccessToast } from "../../../../../../services/notificationService";

const WithdrawFullModal = ({totalSupply}) => {
    const dispatch = useDispatch();
    const modelData = useSelector((state) => state.tco);
    const { address } = useParams();
    const { isValidAddress, withdrawStakeAmount } = useERC20TokenAction();
    const handleOk = async () => {
        dispatch(updateLoader(true));
        const res = await withdrawStakeAmount(address, true);
        if (res==true) {
            showSuccessToast("Successfully withdrawed all tokens")
            dispatch(tokenActionCloseModal());
        }else {
            showErrorToast("Withdrawing all tokens has failed")
        }
        dispatch(updateLoader(false));
    };
    const handleCancel = () => {
        dispatch(tokenActionCloseModal());
    };
    const [data, setData] = useState([]);
    const [image, setImage] = useState(false);
    const handleChange = async (e, y = "") => {
        const res = await handleFileDrop(handleChange, "icon", e, y);
        if (res !== false) {
            setData([
                ...data,
                {
                    id: res.id,
                    name: res.name,
                    url: `https://cdn.gov-cloud.ai${res.info.cdnUrl}`,
                },
            ]);
        } else {
            // setData([
            //     ...data,
            //     {
            //         id: `Dummy${data.length}`,
            //         name: `Dummy${data.length}`,
            //         url: `https://upload.wikimedia.org/wikipedia/commons/3/32/Googleplex_HQ_%28cropped%29.jpg`,
            //     },
            // ]);
        }
    };
    useEffect(() => {
        if (image) {
            document.getElementById("createTokenImageUploadInput").click();
            setImage(false);
        }
    }, [image]);
    return (
        <Modal
            centered
            title={
                <div className={css.header}>
                    <div className={css.headerLeft}>
                        <div className={css.headerTop}></div>
                        <div className={css.iconContainer} onClick={handleCancel}>
                            <img className={css.icon} src={closeImg} alt="Close" />
                        </div>
                    </div>
                    <div className={css.headerRow1}>
                        <img className={css.icon} src={warningImg} alt="Are you sure?" />
                        <span className={css.text}>Are you sure?</span>
                    </div>
                    <span className={css.headerRow2}>If you withdraw all stake token, you will lose the token staking rewards.</span>
                    <div className={css.buttons}>
                        <span className={css.cancel} onClick={handleCancel}>
                            Cancel
                        </span>
                        <span className={css.submit} onClick={handleOk}>
                            Understand, confirm
                        </span>
                    </div>
                </div>
            }
            closeIcon={false}
            open={modelData.actionModals["Withdraw Full"]}
            // className={css.main}
        >
            <div className={css.container}></div>
        </Modal>
    );
};
export default WithdrawFullModal;
