import React, { useState } from "react";
import css from "./ProposalsVote.module.scss";
import { Button, Checkbox } from "antd";
import { useProposalAction } from "../../../Hooks/useProposalAction";
import { showErrorToast } from "../../../../../services/notificationService";
function ProposalsVote({ votingEndsIn, proposal }) {
    const [isVote, setIsVote] = useState(false);
    const [selectedOption, setSelectedOption] = useState("");
    const { voteOnProposal } = useProposalAction();
    const options = [
        {
            name: "Yes",
            desc: `Your choice will be counted for "Yes"`,
        },
        {
            name: "No",
            desc: `Your choice will be counted for "No"`,
        },
        {
            name: "Abstain",
            desc: `Your choice will be counted for "Abstain"`,
        },
    ];
    const handleVoting = async () => {
        try {
            const res = await voteOnProposal(selectedOption);
            if (res == false) {
                showErrorToast("Voting Failed");
            }
        } catch (e) {
            console.log(e);
            showErrorToast("Voting Failed");
        }
    };
    return (
        <div className={css.container}>
            {isVote && (
                <div className={css.container}>
                    <div className={css.chooseYourOption}>
                        <span className={css.label}>Choose your option</span>
                        <span className={css.value}>
                            To vote, you must select one of the following options and confirm in your wallet. Once the transaction is completed, your
                            vote will be counted and displayed.
                        </span>
                    </div>
                    <div className={css.optionsContainer}>
                        {options.map((option, i) => {
                            return (
                                <div
                                    className={css.options}
                                    key={i}
                                    onClick={() => {
                                        setSelectedOption(option.name);
                                    }}
                                >
                                    <div className={css.left}>
                                        <span className={css.label}>{option.name}</span>
                                        <span className={css.value}>{option.desc}</span>
                                    </div>
                                    <div className={css.right}>
                                        <Checkbox
                                            checked={selectedOption == option.name}
                                            style={{
                                                width: "var(--TClamp16px)",
                                                height: "var(--TClamp16px)",
                                            }}
                                            className={css.checkbox}
                                        />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
            <div className={css.flexRowStart15px}>
                <Button
                    type="primary"
                    className={css.button}
                    onClick={() => {
                        if (isVote == false && new Date(parseInt(proposal.votingStartTime)).getTime() <= new Date().getTime()) {
                            setIsVote(true);
                        } else if (selectedOption !== "") {
                            handleVoting();
                        }
                    }}
                >
                    Submit your Vote
                </Button>
                <Button
                    type="default"
                    className={css.button}
                    onClick={() => {
                        setIsVote(false);
                    }}
                >
                    Cancel
                </Button>
            </div>
            <ul className={`${css.font1422} ${css.colorGrey}`} style={{ paddingLeft: "var(--TClamp16px)" }}>
                <li>{votingEndsIn}</li>
            </ul>
        </div>
    );
}

export default ProposalsVote;
