import React, { useEffect } from "react";
import styles from "./TokenomicsSimulationToggle.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { Switch } from "antd";
import TokenomicsSliderInput from "../TokenomicsSliderInput/TokenomicsSliderInput";
import checkedImg from "../../../TeDaaS-Assets/TokenomicsSimulation/checked.svg";
import uncheckedImg from "../../../TeDaaS-Assets/TokenomicsSimulation/unchecked.svg";
import { configureTokenVal, tokenCreationAssignee, tokenCreationReward } from "../../../../../redux/actions/tokenCreationAction";

function TokenomicsSimulationToggle({
    index = "",
    type = "",
    can = false,
    name = "",
    showParams = { type: "default", id: "default" },
    setShowParams = (e) => {},
    // percent = 0.01,
    // lock = 1,
    // unlock = 1,
    // max = 100,
    // maxPercent = 100,
}) {
    const dispatch = useDispatch();
    const fetchedData = useSelector((state) => state.tc);
    const handleChange = (val, key) => {
        if (type == "assignee") {
            dispatch(tokenCreationAssignee({ ...fetchedData[type][index], [key]: val }));
        } else if (type == "rewards") {
            dispatch(tokenCreationReward({ ...fetchedData[type][index], [key]: val }));
        } else if (type == "burn") {
            dispatch(configureTokenVal("burn", { ...fetchedData.burn, [key]: val }));
        } else if (type == "mint") {
            dispatch(configureTokenVal("mint", { ...fetchedData.mint, [key]: val }));
        }
    };
    useEffect(() => {}, [fetchedData]);
    return (
        // <div className={styles.container}>
        <div
            className={`${styles.card} ${showParams.type == type && showParams.id == index ? styles.activeCard : styles.inactiveCard}`}
            onClick={() => {
                setShowParams({ type: type, id: index });
            }}
        >
            <div className={styles.left}>
                <div className={styles.iconContainer}>
                    <img className={can ? styles.activeIcon : styles.inactiveIcon} src={can ? checkedImg : uncheckedImg} alt="Checkbox" />
                </div>
                <span className={styles.label}>{name}</span>
            </div>
            <div
                className={`${styles.switchContainer} ${can ? styles.active : ""}`}
                onClick={(e) => {
                    e.stopPropagation();
                    handleChange(!can, "can");
                }}
            >
                <div className={styles.switch}></div>
            </div>
        </div>
        /* {can && (
                <div className={styles.body}>
                    <TokenomicsSliderInput
                        name="Percentage"
                        value={percent}
                        handleChange={(val) => {
                            handleChange(val, "percent");
                        }}
                        min={0.001}
                        step={0.001}
                        max={maxPercent}
                    />

                    {(type == "assignee" || type == "rewards") && (
                        <TokenomicsSliderInput
                            name={`Vesting (${fetchedData[type][index]?.vestingPeriod})`}
                            value={lock}
                            handleChange={(val) => {
                                handleChange(val, "lock");
                            }}
                            min={1}
                            max={max}
                            step={1}
                        />
                    )}

                    <TokenomicsSliderInput
                        name={
                            type == "burn"
                                ? `Burn Schedule (${fetchedData.burn.period})`
                                : type == "mint"
                                  ? `Mint Schedule (${fetchedData.mint.period})`
                                  : `Emission (${fetchedData[type][index]?.emissionPeriod})`
                        }
                        value={unlock}
                        handleChange={(val) => {
                            handleChange(val, "unlock");
                        }}
                        min={1}
                        max={max}
                        step={1}
                    />
                </div>
            )} */
        // </div>
    );
}

export default TokenomicsSimulationToggle;
