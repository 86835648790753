import React, { useEffect, useState } from "react";
import css from "./DAODetailsMembers.module.scss";
import { Button, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { capitalizeFirstLetterOfString, copyToClipboard, shortenAddress } from "../../../../Constants/reusableFunctions";
import dummyLogo from "../../../../TeDaaS-Assets/proposalCreate/logo.svg";
import linkLogo from "../../../../TeDaaS-Assets/proposalCreate/link.svg";
import { useNavigate, useParams } from "react-router-dom";
import TokenomicsSimulationSearchParameter from "../../../UI/TokenomicsSimulationSearchParameter/TokenomicsSimulationSearchParameter";

function DAODetailsMembers({ dao, proposals, members, token }) {
    const [search, setSearch] = useState("");
    const navigateTo = useNavigate();
    const { daoAddress } = useParams();
    useEffect(() => {}, [dao, proposals, members, token, daoAddress]);
    return (
        <div className={css.container}>
            <div className={css.body}>
                <div className={css.header}>
                    <div className={css.headerLeft}>
                        <span className={css.title}>{members.length > 1 ? `${members.length} Members` : `${members.length} Member`}</span>
                        <span className={css.daoType}>{dao.daoType === "token" ? "Token-based" : "Wallet-based"}</span>
                    </div>
                    <div className={css.headerRight}>
                        <Button type="default" className={`${css.button} ${css.whiteButton}`}>
                            See all Holders
                        </Button>
                        <Button type="primary" className={`${css.button} ${css.blueButton}`}>
                            {"+ Mint Tokens"}
                        </Button>
                    </div>
                </div>
                <div className={css.navbar}>
                    <TokenomicsSimulationSearchParameter search={search} setSearch={setSearch} placeholder="Type to Search" />
                    <span className={css.info}>The DAO members listed here have interacted with this DAO atleast once</span>
                </div>
                <div className={css.content}>
                    {Array.isArray(members) &&
                        members.map((member, i) => {
                            // console.log(member.tokens, token.totalTokens)
                            return (
                                <div key={i} className={css.member}>
                                    <img className={css.logo} src={dummyLogo} alt="Logo" />
                                    <span
                                        className={css.address}
                                        onClick={() => {
                                            copyToClipboard(member.userAddress);
                                        }}
                                    >
                                        {shortenAddress(member.userAddress)}
                                    </span>
                                    <span className={css.tokens}>
                                        {`${member.tokens} ${token.tokenSymbol} (${((parseFloat(member.tokens) * 100) / parseFloat(token.totalTokens)).toFixed(2)}%)`}
                                    </span>
                                    <div></div>
                                    <img className={css.link} src={linkLogo} alt="Go To" />
                                </div>
                            );
                        })}
                </div>
            </div>
        </div>
    );
}

export default DAODetailsMembers;
