import React, { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import { useSelector } from "react-redux";

import styles from "./TokenMintLineChart.module.scss";

const TokenMintLineChart = () => {
    const tokenSimulationData = useSelector((state) => state.tsr);
    const [options, setOptions] = useState(false);
    useEffect(() => {
        const mintScheduleData = tokenSimulationData.mint.mintScheduleData;
        const mintingIntervals = tokenSimulationData.mint.mintingScheduleTenure;
        const TokenMintLineChartData = {
            title: {
                text: "Token Mint",
                left: "1%",
                textStyle: {
                    fontFamily: "Manrope",
                },
            },
            legend: {
                show: false,
            },
            tooltip: {
                trigger: "axis",
                axisPointer: {
                    type: "cross",
                },
                formatter: function (params) {
                    return "Interval: " + params[0]?.name + "<br/>" + `Supply after ${params[0].seriesName}:` + params[0]?.value;
                },
            },
            color: ["#9F84FD"],
            textStyle: {
                fontFamily: "Manrope",
            },
            xAxis: {
                type: "category",
                position: "bottom",
                data: Array.from({ length: mintingIntervals }, (_, i) => i + 1),
                name: "Minting Tenure ->",
                nameLocation: "center",
                nameTextStyle: {
                    verticalAlign: "top",
                    padding: [30, 0, 0, 0],
                    fontSize: "14px",
                },
                axisLine: {
                    lineStyle: {
                        color: "#98A2B3",
                    },
                },
                axisTick: {
                    show: false,
                    lineStyle: {
                        color: "#D9D9D9",
                    },
                },
                axisLabel: {
                    fontFamily: "Manrope",
                    fontSize: 14,
                },
            },
            yAxis: {
                type: "value",
                name: "Total Supply ->",
                nameLocation: "middle",
                position: "left",
                splitLine: {
                    show: false,
                },
                axisLine: {
                    lineStyle: {
                        color: "#98A2B3",
                    },
                },
                axisLabel: {
                    fontFamily: "Manrope",
                    fontSize: 14,
                    formatter: function (params) {
                        const suffixes = [
                            { threshold: 1_000_000_000_000, suffix: "T" },
                            { threshold: 1_000_000_000, suffix: "B" },
                            { threshold: 1_000_000, suffix: "M" },
                            { threshold: 1_000, suffix: "K" },
                        ];
                        for (const { threshold, suffix } of suffixes) {
                            if (params >= threshold) {
                                const formattedValue = (params / threshold).toFixed(1) + suffix;
                                return formattedValue;
                            }
                        }
                        return params;
                    },
                },
                nameTextStyle: {
                    align: "middle",
                    verticalAlign: "bottom",
                    padding: [0, 0, 50, 0],
                },
            },
            series: [
                {
                    name: "Mint",
                    data: mintScheduleData,
                    type: "line",
                    showSymbol: false,
                    smooth: true,
                    cursor: "crosshair",
                    emphasis: {
                        focus: "series",
                    },
                    lineStyle: {
                        color: "#9F84FD",
                        shadowColor: "rgba(0, 0, 255, 0.5)",
                        shadowBlur: 20,
                        shadowOffsetY: 12,
                    },
                },
            ],
        };
        setOptions(TokenMintLineChartData);
    }, [tokenSimulationData.mint]);

    return (
        <div className={styles.container}>
            <div className={styles.chart_container}>
                {options !== false && <ReactECharts option={options} style={{ height: "var(--TClamp330px)" }} />}
            </div>
        </div>
    );
};

export default TokenMintLineChart;
