import React from "react";
import css from "./ProposalsInfo.module.scss";
import { useSelector } from "react-redux";

function ProposalsInfo({ members, tokenSymbol, minimumParticipation, proposal, voters, supportThreeshold, strategy }) {
    const daoDetails = useSelector((state)=>state.dd);
    const tokenDetails = useSelector((state)=>state.td);
    return (
        <div className={css.flexColumnStart15px} style={{ width: "100%" }}>
            <span className={css.font1624}>Rules of Decision</span>
            <div className={`${css.flexColumnStart4px} ${css.font1422}`}>
                <span className={css.colorGrey}>Options</span>
                <span>{proposal.votingType}</span>
            </div>
            <div className={`${css.flexColumnStart4px} ${css.font1422}`}>
                <span className={css.colorGrey}>Strategy</span>
                <span>{strategy}</span>
            </div>
            <div className={`${css.flexColumnStart4px} ${css.font1422}`}>
                <span className={css.colorGrey}>Support Threshold</span>
                <span>{`> ${daoDetails.supportThreeshold}%`}</span>
            </div>
            <div className={`${css.flexColumnStart4px} ${css.font1422}`}>
                <span className={css.colorGrey}>Minimum Participation (Quorum)</span>
                <span>{`> ${((members * daoDetails.minimumParticipation) / 100).toFixed(2)} of ${members} ${tokenDetails.tokenSymbol} (${daoDetails.minimumParticipation}%)`}</span>
            </div>
            <div className={css.line}></div>
            <span className={css.font1624}>Voting Activity</span>
            <div className={`${css.flexColumnStart4px} ${css.font1422}`}>
                <span className={css.colorGrey}>Current Participation</span>
                <span>{`${voters.length} of ${members} ${tokenDetails.tokenSymbol} (${((voters.length * 100) / members).toFixed(0)}%)`}</span>
                <span className={css.colorGrey}>{`${members - voters.length} missing votes`}</span>
            </div>
            <div className={`${css.flexColumnStart4px} ${css.font1422}`}>
                <span className={css.colorGrey}>Unique Voters</span>
                <span>{voters.length}</span>
            </div>
            <div className={css.line}></div>
            <span className={css.font1624}>Duration</span>
            <div className={`${css.flexColumnStart4px} ${css.font1422}`}>
                <span className={css.colorGrey}>Start</span>
                <span>{String(new Date(parseInt(proposal.votingStartTime)))}</span>
            </div>
            <div className={`${css.flexColumnStart4px} ${css.font1422}`}>
                <span className={css.colorGrey}>End</span>
                <span>{String(new Date(parseInt(proposal.votingEndTime)))}</span>
            </div>
            <div className={css.line}></div>
        </div>
    );
}

export default ProposalsInfo;
