import React, { useEffect, useState } from "react";
import css from "./ProposalsVoters.module.scss";
import TokenomicsSimulationSearchParameter from "../TokenomicsSimulationSearchParameter/TokenomicsSimulationSearchParameter";
import deployDAOLogo from "../../../TeDaaS-Assets/DaoCreate/deployDao.svg";
import dummyLogo from "../../../TeDaaS-Assets/DaoCreate/dummyLogo.svg";
import { copyToClipboard, shortenAddress } from "../../../Constants/reusableFunctions";

function ProposalsVoters({ voters }) {
    const [search, setSearch] = useState("");
    const [voter, setVoter] = useState(voters);
    useEffect(() => {
        if (search !== "") {
            const temp = voters.filter((e, i) => e.userAddress.indexOf(search) > -1);
            setVoter(temp);
        } else {
            setVoter(voters);
        }
    }, [voters, search]);
    return (
        <div className={css.flexColumnStart15px} style={{ width: "100%" }}>
            <TokenomicsSimulationSearchParameter search={search} setSearch={setSearch} placeholder="Search Voters..." />
            {voters.length === 0 && (
                <div className={css.flexColumnCenter}>
                    <img className={css.icon240} src={deployDAOLogo} alt="No Voters yet" />
                    <span className={css.font1422Grey}>No Voters Yet</span>
                </div>
            )}
            {voters.map((voter, i) => {
                return (
                    <div className={css.voters} key={voter.userAddress}>
                        <img className={css.icon48} src={dummyLogo} alt={"Member"} />
                        <span
                            className={css.font1420}
                            onClick={() => {
                                copyToClipboard(voter.userAddress);
                            }}
                        >
                            {shortenAddress(voter.userAddress)}
                        </span>
                        <span className={`${css.vote} ${css.Yes}`}>{`${voter.vote}`}</span>
                    </div>
                );
            })}
        </div>
    );
}

export default ProposalsVoters;
