import React, { useEffect, useState } from "react";
import styles from "./TokenSmartContract.module.scss";

import { Editor } from "@monaco-editor/react";
import { erc20 } from "@openzeppelin/wizard";
import { editorOptions } from "../../../Constants/Constants";
import { useDispatch, useSelector } from "react-redux";

import { ERC20Code } from "../../../SmartContractCode/ERC20";

import THEME from "./Theme.json";
import copyToClipboardImg from "../../../TeDaaS-Assets/SmartContract/CopyToClipboard.svg";
import { copyToClipboard } from "../../../Constants/reusableFunctions";
import { updateLoader } from "../../../../../redux/actions/loaderAction";
import { useNavigate } from "react-router-dom";
import { GovernanceTokenCode } from "../../../SmartContractCode/GovernanceToken";
import { SecurityTokenCode } from "../../../SmartContractCode/SecurityToken";

const TokenSmartContract = () => {
    const fetchedData = useSelector((state) => state.tc);
    const navigateTo = useNavigate();
    const dispatch = useDispatch();
    const [copied, setCopied] = useState(false);
    const [value, setValue] = useState("");

    const handleCopyCode = () => {
        // navigator.clipboard.writeText(ERC20Code);
        copyToClipboard(value);
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 3000);
    };

    const handleEditorOnMount = (editor, monaco) => {
        monaco.editor.defineTheme("myCustomTheme", THEME);
        monaco.editor.setTheme("myCustomTheme");
    };
    useEffect(() => {
        dispatch(updateLoader(false));
        if (fetchedData.newToken === false) {
            navigateTo("/tedaasV3/tokenDashboard");
        }
        const temp =
            fetchedData.tokenType == "GT"
                ? GovernanceTokenCode(fetchedData.tokenName)
                : fetchedData.tokenType === "ST"
                  ? SecurityTokenCode(fetchedData.tokenName)
                  : ERC20Code(fetchedData.tokenName);
        setValue(temp);
    }, []);

    return (
        <div className={styles.container}>
            <Editor
                height="100%"
                defaultLanguage="sol"
                readOnly="true"
                value={"\n" + value}
                options={editorOptions}
                theme="vs-dark"
                // onMount={handleEditorOnMount}
            />
            <button onClick={handleCopyCode}>
                <img src={copyToClipboardImg} alt="" />
            </button>
        </div>
    );
};

export default TokenSmartContract;
