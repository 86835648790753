import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
    configureToken,
} from "../../../../redux/actions/tokenCreationAction";
import css from "./BurnModel.module.scss";
import burnImg from "../../TeDaaS-Assets/Nodes/changeOwner.svg";
import closeImg from "../../TeDaaS-Assets/Nodes/close.svg";
import "./emptyAntModelDefaultCss.css";
import { tokenCreationCloseModel } from "../../../../redux/actions/tokenCreationOptionsAction";

const ChangeOwnerModel = () => {
    const dispatch = useDispatch();
    const fetchedData = useSelector((state) => state.tc);
    const modelData = useSelector((state) => state.tco);
    const [address, setAddress] = useState("");
    const handleOk = () => {
        dispatch(
            configureToken("changeOwner",{
                can: true,
                address: address,
                createdOn: fetchedData.changeOwner.can ? fetchedData.changeOwner.createdOn : new Date().getTime(),
                updatedOn: new Date().getTime(),
            }),
        );
        dispatch(tokenCreationCloseModel());
    };
    const handleCancel = () => {
        dispatch(tokenCreationCloseModel());
    };
    useEffect(() => {
        if (fetchedData.changeOwner.can) {
            setAddress(fetchedData.changeOwner.address);
        }
    }, []);
    return (
        <Modal
            title={
                <div className={css.header}>
                    <div className={css.headerLeft}>
                        <div className={css.headerTop}>
                            <img className={css.icon} src={burnImg} alt="Burn" />
                            <span className={css.title}>Configure Change Owner</span>
                        </div>
                        <span className={css.subTitle}>Current token supply: {fetchedData.initialSupply}</span>
                    </div>
                    <div className={css.headerRight} onClick={handleCancel}>
                        <img className={css.icon} src={closeImg} alt="Close" />
                    </div>
                </div>
            }
            closeIcon={false}
            open={modelData.models.changeOwner}
            className={css.main}
        >
            <div className={css.changeOwner}>
                <span className={css.title}>Hash address</span>
                <input
                    type="text"
                    placeholder="Enter address..."
                    className={css.input}
                    value={address}
                    onChange={(e) => {
                        setAddress(e.target.value);
                    }}
                />
                <span className={css.save} onClick={handleOk}>Save</span>
            </div>
        </Modal>
    );
};
export default ChangeOwnerModel;
