import { DAO_CREATION_REDUCER } from "../../components/TeDaaSV3/Constants/Constants";

export const daoCreationConfigureVal = (name, data) => {
    return {
        type: DAO_CREATION_REDUCER.CONFIGURE_VAL,
        payload: data,
        key: name,
    };
};
export const daoCreationReset = () => {
    return {
        type: DAO_CREATION_REDUCER.CONFIGURE_VAL,
    };
};
