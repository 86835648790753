import { useEffect, useState } from "react";
import {
    JSON_SERVER_ENDPOINT,
    JSON_SERVER_TEDAAS_TOKENS,
    JSON_SERVER_TEDAAS_TRANSACTIONS,
    JSON_SERVER_TEDAAS_USERS,
    switchCaseConstants,
    TEDAAS_SCHEMAS,
    TOKEN_SIMULATION,
} from "../Constants/Constants";
import { useDispatch, useSelector } from "react-redux";
import { getRequest, postRequest, putRequest } from "../TeDaaSBTF/TokenomicsBTF/apiRequests";
import axios from "axios";
import { testModeAPI } from "react-ga";
import {
    getFilteredEntities,
    insertEntity,
    postTokenHoldersFromJSONSERVER,
    postTokensToJSONSERVER,
    postTransactionsToJSONSERVER,
} from "../../../services/stf";

const formatDate = (date) => {
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    const dayOfWeek = days[date.getDay()];
    const dayOfMonth = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    return `${dayOfWeek} ${dayOfMonth} ${month} ${year}`;
};

const usePostTokenData = () => {
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);

    const fetchedData = useSelector((state) => state.tc);
    const userTokenDetails = useSelector((state) => state.tokenDataReducer.userTokenDetails);
    const auth = useSelector((state) => state.auth);

    const userAllTokenDetailsData = useSelector((state) => state.txData.userAllTokenDetails);
    const tokenAddress = useSelector((state) => state.txData.currentTokenAddress);
    const newTokenDetailsData = useSelector((state) => state.tokenDetailsReducer);
    const generalDetailsData = useSelector((state) => state.otherGeneralDetailsReducer);
    const tokenSimulationData = useSelector((state) => state.tsr);
    const burnData = tokenSimulationData[TOKEN_SIMULATION.burn];
    const mintData = tokenSimulationData[TOKEN_SIMULATION.mint];
    const txTaxData = useSelector((state) => state.transactionTaxReducer);
    const buybackData = useSelector((state) => state.buybackReducer);
    const emissionDistributionDataValue = useSelector((state) => state.emissionScheduleReducer.emissionDistributionDataValue);
    const rewardsDistributionDataValue = useSelector((state) => state.rewardsScheduleReducer.rewardsDistributionDataValue);

    const postTokenDetails = async (smartContractAddress, txnHash, gasPrice, tokenName, abi) => {
        setIsLoading(true);
        setError(null);
        const resTxn = await postNewTokenTxn(
            smartContractAddress,
            abi,
            txnHash,
            gasPrice,
            fetchedData.initialSupply,
            auth.userAddress,
            smartContractAddress,
            "Contract Deployment",
        );

        const newData = {
            ownerAddress: fetchedData.owner,
            createdAt: String(new Date().getTime()),
            updatedAt: String(new Date().getTime()),
            tokenName: fetchedData.tokenName,
            tokenSymbol: fetchedData.symbol,
            description: fetchedData.objective,
            logo: fetchedData.icon,
            tokenAddress: smartContractAddress,
            decimals: parseInt(fetchedData.decimals),
            tokenType: fetchedData.tokenType,
            tokenDocs: fetchedData.tokenDocs,
            abi: abi,
            burnProperties: fetchedData.burn,
            mintProperties: fetchedData.mint,
            totalSupply: fetchedData.initialSupply,
            balance: fetchedData.initialSupply,
            price: parseInt(fetchedData.tokenPrice.price),
            transactionTax: fetchedData.transactionTax,
            canMint: fetchedData.mint.can,
            canBurn: fetchedData.burn.can,
            canPause: fetchedData.pause.can,
            canBlacklist: fetchedData.blacklist.can,
            canChangeOwner: fetchedData.changeOwner.can,
            canTxTax: fetchedData.transactionTax.can,
            canBuyback: fetchedData.buyback.can,
            buybackProperties: fetchedData.buyback,
            categories: { assignee: fetchedData.assignee, rewards: fetchedData.rewards },
            currencyPecked: fetchedData?.currencyPecked || "USD",
            transfer: true,
            approve: true,
            canEditDocs: true,
            canStake: rewardsDistributionDataValue[switchCaseConstants.STAKING]?.active,
        };
        const newHolders = {
            tokenAddress: smartContractAddress,
            userAddress: fetchedData.owner,
            tokens: 0,
        };
        const resToken = await insertEntity([newData], TEDAAS_SCHEMAS.tokenId, TEDAAS_SCHEMAS.accessToken, true);
        const resTokenHolders = await postTokenHolders(smartContractAddress, fetchedData.owner, 0);
        return true;
    };

    const postNewTokenTxn = async (tokenAddress, abi, txHash, gasFees, amount, from, to, txType, txCategory = "Tranfer Token", txAddress = "") => {
        try {
            var transactionsData = {
                from: from,
                to: to,
                tokenAddress: tokenAddress,
                date: String(new Date().getTime()),
                txHash: txHash,
                txType: txType,
                value: parseFloat(amount),
                gasFees: `${gasFees} Gwei`,
                status: "Success",
                txCategory: txCategory,
                txAddress: txAddress,
            };
            const resTxn = await insertEntity([transactionsData], TEDAAS_SCHEMAS.transactionId, TEDAAS_SCHEMAS.accessToken);
            return true;
        } catch (e) {
            return false;
        }
    };

    const postTokenHolders = async (tokenAddress, userAddress, amount) => {
        try {
            var tokenHoldersData = {
                tokenAddress: tokenAddress,
                userAddress: userAddress,
                tokens: String(amount),
            };
            const resTxn = await insertEntity([tokenHoldersData], TEDAAS_SCHEMAS.tokenHoldersId, TEDAAS_SCHEMAS.accessToken);
            return true;
        } catch (e) {
            return false;
        }
    };

    const updateUserDataSchema = async (smartContractAddress, abi, txnHash, gasPrice, amount, fromAddress, toAddress, userAddress, txType) => {
        axios
            .get(`${JSON_SERVER_ENDPOINT}/${JSON_SERVER_TEDAAS_USERS}/${userAddress}`)
            .then(async (res) => {
                const data = res.data;
                let isTokenAvailable = false;
                for (let token of data.utilityTokens) {
                    if (token.address == smartContractAddress) {
                        if (txType == "Token Transfer") {
                            token.balance = userAddress === fromAddress ? token.balance - parseInt(amount) : token.balance + parseInt(amount);
                        }
                        if (txType == "Change Owner") {
                            token.owner = toAddress;
                            // dispatch(updateData(token, `${JSON_SERVER_ENDPOINT}/${JSON_SERVER_TEDAAS_TOKENS}/${token.address}`))
                            const res = await putRequest(`${JSON_SERVER_ENDPOINT}/${JSON_SERVER_TEDAAS_TOKENS}/${token.address}`, token);
                        }
                        isTokenAvailable = true;
                        break;
                    }
                }

                if (!isTokenAvailable) {
                    const res = await getRequest(`${JSON_SERVER_ENDPOINT}/${JSON_SERVER_TEDAAS_TOKENS}/${smartContractAddress}`);
                    const tokenData = res.data;
                    if (txType == "Change Owner") {
                        tokenData.owner = toAddress;
                        const res = await putRequest(`${JSON_SERVER_ENDPOINT}/${JSON_SERVER_TEDAAS_TOKENS}/${smartContractAddress}`, tokenData);
                        data.utilityTokens.push(tokenData);
                    } else if (txType == "Token Transfer") {
                        tokenData.balance = parseInt(amount);
                        const res = await putRequest(`${JSON_SERVER_ENDPOINT}/${JSON_SERVER_TEDAAS_TOKENS}/${smartContractAddress}`, tokenData);
                        data.utilityTokens.push(tokenData);
                    }
                }
                // dispatch(updateData(data, `${JSON_SERVER_ENDPOINT}/${JSON_SERVER_TEDAAS_USERS}/${userAddress}`));
                const res1 = await putRequest(`${JSON_SERVER_ENDPOINT}/${JSON_SERVER_TEDAAS_USERS}/${userAddress}`, data);
            })
            .catch((err) => {});
    };
    const tokenHoldersUpdate = async (holders, tokenAddress) => {
        try {
            const payload = [];
            console.log("holders", holders, tokenAddress);
            for (let i of holders) {
                const tokenHoldersRes = await getFilteredEntities(
                    { tokenAddress: tokenAddress, userAddress: i.memberAddress },
                    TEDAAS_SCHEMAS.tokenHoldersId,
                    TEDAAS_SCHEMAS.accessToken,
                );
                const json = {
                    tokenAddress: tokenAddress,
                    userAddress: i.memberAddress,
                    tokens: String(i.deposit),
                };
                if (tokenHoldersRes.entities.length > 0) {
                    json.tokens = String(parseFloat(json.tokens) + parseFloat(tokenHoldersRes.entities[0].tokens));
                }
                payload.push(json);
                console.log("PAYLOAD", payload);
            }
            const res = await insertEntity(payload, TEDAAS_SCHEMAS.tokenHoldersId, TEDAAS_SCHEMAS.accessToken);
            return true;
        } catch (e) {
            console.log(e);
            return false;
        }
    };
    const postDAOGovernanceToken = async (ownerAddress, tokenName, tokenSymbol, governanceTokenAddress, totalSupply) => {
        try {
            const newData = {
                ownerAddress: ownerAddress,
                createdAt: String(new Date().getTime()),
                updatedAt: String(new Date().getTime()),
                tokenName: tokenName,
                tokenSymbol: tokenSymbol,
                description: "This is a DAO Governance Token",
                logo: "",
                tokenAddress: governanceTokenAddress,
                decimals: 0,
                tokenType: "GT",
                tokenDocs: [],
                abi: "",
                burnProperties: {},
                mintProperties: {},
                totalSupply: totalSupply,
                balance: totalSupply,
                price: 0,
                transactionTax: {},
                canMint: true,
                canBurn: true,
                canPause: true,
                canBlacklist: true,
                canChangeOwner: true,
                canTxTax: true,
                canBuyback: true,
                buybackProperties: {},
                categories: { assignee: {}, rewards: {} },
                currencyPecked: "USD",
                transfer: true,
                approve: true,
                canEditDocs: true,
                canStake: true,
            };

            const resToken = await insertEntity([newData], TEDAAS_SCHEMAS.tokenId, TEDAAS_SCHEMAS.accessToken, true);
            return true;
        } catch (e) {
            console.log(e);
            return false;
        }
    };

    return { postTokenDetails, postNewTokenTxn, postTokenHolders, tokenHoldersUpdate, postDAOGovernanceToken };
};

export default usePostTokenData;
