import React from 'react';
import css from "./MultilineChartWithLegends.module.scss";
import MultilineChart from '../../Charts/MultilineChart/MultilineChart';

function MultilineChartWithLegends({title="", xLegends=[], series, postfix="", yAxisSplitLine=true}) {
  return (
    <div className={css.container} onMouseOver={(e)=>{e.preventDefault()}}>
        {title.length>0 && <span className={css.title}>{title}</span>}
        <div className={css.chart} onMouseOver={(e)=>{e.preventDefault()}}>
            <MultilineChart xLegends={xLegends} series={series} postfix={postfix} yAxisSplitLine={yAxisSplitLine} />
        </div>
        <div className={css.legends}>
            {
                series.map((s,i) => {
                    return <div className={css.legend} key={i}>
                        <div className={css.icon} style={{background:s.itemStyle.color}}></div>
                        <span className={css.name}>{s.name}</span>
                    </div>
                })
            }
        </div>
    </div>
  )
}

export default MultilineChartWithLegends