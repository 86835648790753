import React, { useEffect, useRef } from "react";
import * as echarts from "echarts";
import css from "./DoughnutChart.module.scss";
import { useSelector } from "react-redux";

const DoughnutChart = ({
    data = [
        { value: 26, name: "UT", itemStyle: { color: "#E3704C" } },
        { value: 26, name: "GT", itemStyle: { color: "#94D0FF" } },
        { value: 24, name: "NFT", itemStyle: { color: "#FBC96C" } },
        { value: 24, name: "ST", itemStyle: { color: "#9A9AFF" } },
    ],
    showTitle="",
    isTitle=false
}) => {

    const tokenSimulationData = useSelector((state) => state.tsr);
    const chartRef = useRef(null);
    useEffect(() => {
        const chartDom = chartRef.current;
        const myChart = echarts.init(chartDom);
        const option = {
            legend: {
                show: false
            },
            title: {
                show: isTitle,
                text: showTitle,
                left: '1%',
                textStyle: {
                    fontFamily: 'Manrope',
                },
            },
            series: [
                {
                    name: "Access From",
                    type: "pie",
                    radius: ["50%", "70%"],
                    avoidLabelOverlap: false,
                    itemStyle: {
                        borderRadius: 10,
                        borderColor: "#fff",
                        borderWidth: 2,
                    },
                    label: {
                        show: false,
                        position: "center",
                    },
                    // emphasis: {
                    //     label: {
                    //         show: false,
                    //         fontSize: 40,
                    //         fontWeight: "bold",
                    //     },
                    // },
                    labelLine: {
                        show: false,
                    },
                    data: data,
                },
            ],
        };
        myChart.setOption(option);

        return () => {
            myChart.dispose();
        };
    }, [tokenSimulationData.tokenomics]);

    return <div className={css.container} ref={chartRef} />;
};

export default DoughnutChart;
