import React, { useCallback, useEffect, useMemo } from "react";
import css from "./TokenDesignCanvas.module.scss";
import { applyEdgeChanges, applyNodeChanges, Background, Controls, ReactFlow, useReactFlow } from "@xyflow/react";
import StartNode from "../../Nodes/StartNode/StartNode";
import "@xyflow/react/dist/style.css";
import TokenTypeNode from "../../Nodes/TokenTypeNode/TokenTypeNode";
import "./TokenDesignCanvas.css";
import { useDrop } from "react-dnd";
import { generateEdge, generateNode, generateParentNode } from "../../../Constants/reusableFunctions";
import ParentNode from "../../Nodes/ParentNode/ParentNode";
import { useDispatch, useSelector } from "react-redux";
import { v4 } from "uuid";
import { configureTokenVal } from "../../../../../redux/actions/tokenCreationAction";
import { tokenCreationUpdateEdges, tokenCreationUpdateNodes } from "../../../../../redux/actions/tokenCreationNodesAndEdgesAction";
import ArtifactNode from "../../Nodes/ArtifactNode/ArtifactNode";
import BurnModel from "../../Modals/BurnModel";
import MintModel from "../../Modals/MintModel";
import ChangeOwnerModel from "../../Modals/ChangeOwnerModel";
import BuybackModel from "../../Modals/BuybackModel";
import TransactionTaxModel from "../../Modals/TransactionTaxModel";
import AssigneeModel from "../../Modals/AssigneeModel";
import RewardModel from "../../Modals/RewardModel";
import { tokenCreationOpenModel } from "../../../../../redux/actions/tokenCreationOptionsAction";
import TokenPriceModel from "../../Modals/TokenPriceModel";
import TokenDocsModel from "../../Modals/TokenDocsModel";

function TokenDesignCanvas() {
    const dispatch = useDispatch();
    const modelData = useSelector((state) => state.tco);
    const fetchedData = useSelector((state) => state.tc);
    const { nodes, edges } = useSelector((state) => state.tcne);
    const { screenToFlowPosition } = useReactFlow();
    const nodeTypes = useMemo(
        () => ({
            start: StartNode,
            tokenType: TokenTypeNode,
            parentNode: ParentNode,
            artifactNode: ArtifactNode,
            tokenPrice: TokenTypeNode,
            tokenDocs: TokenTypeNode,
        }),
        [],
    );

    const onNodesChange = useCallback(
        (changes) => {
            const updatedNodes = applyNodeChanges(changes, nodes);
            dispatch(tokenCreationUpdateNodes(updatedNodes)); // Dispatch action to update nodes in Redux store
            // localStorage.setItem("tokenCreationNodesData", JSON.stringify(updatedNodes));
        },
        [nodes, dispatch],
    );

    const onEdgesChange = useCallback(
        (changes) => {
            const updatedEdges = applyEdgeChanges(changes, edges);
            dispatch(tokenCreationUpdateEdges(updatedEdges)); // Dispatch action to update edges in Redux store
            // localStorage.setItem("tokenCreationEdgesData", JSON.stringify(updatedEdges));
        },
        [edges, dispatch],
    );

    const onNodeDragStop = (event, node) => {
        // const x = Math.max(0, Math.min(node.position.x, window.innerWidth));
        // const y = Math.max(0, Math.min(node.position.y, window.innerHeight));
        const x = node.position.x;
        const y = node.position.y;
        const updatedNodes = Array.isArray(nodes) ? nodes.map((el) => (el.id === node.id ? { ...el, position: { x, y } } : el)) : [];
        dispatch(tokenCreationUpdateNodes(updatedNodes)); // Dispatch action to update node positions in Redux store
        // localStorage.setItem("tokenCreationNodesData", JSON.stringify(updatedNodes));
    };

    const [{ isOver }, drop] = useDrop({
        accept: "tokenomics",
        drop: (item, monitor) => {
            try {
                if (item.node.type === "tokenType") {
                    const tokenType = generateNode(item);
                    dispatch(configureTokenVal("tokenType", item.node.index));
                    tokenType.position = { x: 350, y: 200 };
                    const tokenConfiguration = generateParentNode("Configure", "tokenConfiguration");
                    tokenConfiguration.position = { x: 350, y: 500 };
                    dispatch(tokenCreationUpdateNodes([...nodes, tokenType, tokenConfiguration]));
                    dispatch(configureTokenVal("tokenType", item.node.index));
                    // localStorage.setItem("tokenCreationNodesData", JSON.stringify([...nodes, tokenType, tokenConfiguration]));
                    const tokenTypeEdge = generateEdge("start", "tokenType");
                    const tokenConfigurationEdge = generateEdge("tokenType", "tokenConfiguration");
                    dispatch(tokenCreationUpdateEdges([...edges, tokenTypeEdge, tokenConfigurationEdge]));
                    // localStorage.setItem("tokenCreationEdgesData", JSON.stringify([...edges, tokenTypeEdge, tokenConfigurationEdge]));
                } else if (
                    (item.node.type === "assignee" && !fetchedData.assignee[item.node.index]?.can) ||
                    (item.node.type === "reward" && !fetchedData.rewards[item.node.index]?.can && fetchedData.assignee?.reward?.can)
                ) {
                    const position = monitor.getClientOffset();
                    dispatch(tokenCreationOpenModel(item.node.type, item.node.name, item.node.index, position));
                } else if (item.node.type === "tokenPrice") {
                    const tokenPriceNode = Array.isArray(nodes) ? nodes.filter((ele) => ele.data.index === "tokenPrice") : [];
                    if (tokenPriceNode.length === 0) {
                        const position = monitor.getClientOffset();
                        dispatch(tokenCreationOpenModel(item.node.type, item.node.name, item.node.index, position));
                    }
                } else if (item.node.type === "tokenDocs") {
                    const tokenDocsNode = Array.isArray(nodes) ? nodes.filter((ele) => ele.data.index === "tokenDocs") : [];
                    if (tokenDocsNode.length === 0) {
                        const position = monitor.getClientOffset();
                        dispatch(tokenCreationOpenModel(item.node.type, item.node.name, item.node.index, position));
                    }
                }
            } catch (e) {
                console.log(e);
            }
        },
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
        }),
    });
    // useEffect(() => {
    //     if (localStorage.getItem("tokenCreationNodesData")) {
    //         const nodesLocalData = JSON.parse(localStorage.getItem("tokenCreationNodesData"));
    //         dispatch(tokenCreationUpdateNodes(nodesLocalData));
    //     }
    //     if (localStorage.getItem("tokenCreationEdgesData")) {
    //         const edgesLocalData = JSON.parse(localStorage.getItem("tokenCreationEdgesData"));
    //         dispatch(tokenCreationUpdateEdges(edgesLocalData));
    //     }
    // }, []);
    return (
        <div className={css.container} ref={drop}>
            <ReactFlow
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onNodeDragStop={onNodeDragStop}
                nodeTypes={nodeTypes}
                nodesConnectable={true}
                nodesDraggable={modelData.isDrag}
                defaultEdgeOptions={"smoothStep"}
                minZoom={0.1}
                maxZoom={5}
                panOnDrag={modelData.isDrag}
                // fitViewOptions={{
                //     duration: 1,
                // }}
                // autoFocus={true}
            >
                <Background />
                <Controls showFitView={true} />
                {/* <MiniMap /> */}
            </ReactFlow>
            {modelData.models.burn && <BurnModel />}
            {modelData.models.mint && <MintModel />}
            {modelData.models.changeOwner && <ChangeOwnerModel />}
            {modelData.models.buyback && <BuybackModel />}
            {modelData.models.transactionTax && <TransactionTaxModel />}
            {modelData.models.assignee && <AssigneeModel />}
            {modelData.models.reward && <RewardModel />}
            {modelData.models.tokenPrice && <TokenPriceModel />}
            {modelData.models.tokenDocs && <TokenDocsModel />}
        </div>
    );
}

export default TokenDesignCanvas;
