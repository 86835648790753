import React, { useEffect, useState } from "react";
import dummyLogo from "../../../../TeDaaS-Assets/proposalCreate/logo.svg";
import depositLogo from "../../../../TeDaaS-Assets/DaoCreate/deposit.svg";
import withdrawLogo from "../../../../TeDaaS-Assets/DaoCreate/withdraw.svg";
import css from "./DAODetailsFinance.module.scss";
import { Button } from "antd";
import { getFilteredBQData, getFilteredEntities, getGroupAdhocata } from "../../../../../../services/stf";
import { TEDAAS_SCHEMAS } from "../../../../Constants/Constants";
import { RightOutlined } from "@ant-design/icons";

function DAODetailsFinance({ dao, members, proposals, token, setPage }) {
    const [tokens, setTokens] = useState([]);
    const [transfers, setTransfers] = useState([]);
    const [treasury, setTreasury] = useState(0);
    const [treasury24, setTreasury24] = useState(0);
    const fetchFinanceDetails = async () => {
        const res = await getFilteredEntities(
            { txType: "DAO Treasury", txAddress: dao.daoAddress },
            TEDAAS_SCHEMAS.transactionId,
            TEDAAS_SCHEMAS.accessToken,
            4,
        );
        setTransfers(res.entities);
        setTokens([token]);
    };
    useEffect(() => {
        // fetchFinanceDetails();
    }, []);
    return (
        <div className={css.mainContainer}>
            <div className={css.container}>
                <div className={css.flexColumn4px}>
                    <div className={css.flexRowBetween}>
                        <span className={css.title}>{`$${treasury}`}</span>
                        <Button className={css.button} type={"primary"}>
                            {"+ New Transfer"}
                        </Button>
                    </div>
                    <div className={css.flexRowStart8px}>
                        <span className={css.hours}>24h</span>
                        <span
                            className={`${css.headText} ${treasury24 > 0 ? css.green : treasury24 < 0 ? css.red : css.grey}`}
                        >{`${treasury24 < 0 ? "-" : "+"}$${treasury24}`}</span>
                    </div>
                </div>
                <div className={`${css.card} ${css.flexColumn16px}`}>
                    <div className={css.flexColumn4px}>
                        <span className={css.label}>Tokens</span>
                        {tokens.length === 0 && <span className={css.note}>No Token Information Available</span>}
                    </div>
                    {tokens.length > 0 && (
                        <div className={css.flexColumn8px}>
                            {Array.isArray(tokens) &&
                                tokens.map((t, i) => {
                                    return (
                                        <div className={`${css.card} ${css.flexRowBetween}`} key={i}>
                                            <div className={css.flexRowStart12px}>
                                                <img className={css.icon48} src={t?.logo || dummyLogo} alt="Logo" />
                                                <div className={css.flexColumn2px}>
                                                    <div className={css.flexRowStart8px}>
                                                        <span className={css.label}>{token.tokenName}</span>
                                                        <span className={css.percent}>{"100%"}</span>
                                                    </div>
                                                    <span className={css.note}>{`${t.totalSupply} ${t.tokenSymbol} | $${parseFloat(t.price)}`}</span>
                                                </div>
                                            </div>
                                            <div className={css.flexRowCenter12px}>
                                                <div className={css.flexColumnEnd2px}>
                                                    <span className={css.label}>{`$${t.totalSupply * parseFloat(t.price)}`}</span>
                                                </div>
                                                <RightOutlined className={css.icon10} />
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>
                    )}
                    <Button
                        className={css.button}
                        type="default"
                        onClick={() => {
                            setPage("tokens");
                        }}
                    >
                        {"See all Tokens >"}
                    </Button>
                </div>
                <div className={`${css.card} ${css.flexColumn16px}`}>
                    <div className={css.flexColumn4px}>
                        <span className={css.label}>Latest Transfers</span>
                        {transfers.length === 0 && <span className={css.note}>No Transfer Information Available</span>}
                    </div>
                    {transfers.length > 0 && (
                        <div className={css.flexColumn8px}>
                            {Array.isArray(transfers) &&
                                transfers.map((t, i) => {
                                    return (
                                        <div className={`${css.card} ${css.flexRowBetween}`} key={i}>
                                            <div className={css.flexRowStart12px}>
                                                <img className={css.icon32} src={t.to === dao.daoAddress ? depositLogo : withdrawLogo} alt="Logo" />
                                                <div className={css.flexColumn2px}>
                                                    <div className={css.flexRowStart8px}>
                                                        <span className={css.label}>{t.to === dao.daoAddress ? "Deposit" : "Withdraw"}</span>
                                                    </div>
                                                    <span className={css.note}>{`${new Date(Number(t.date))}`}</span>
                                                </div>
                                            </div>
                                            <div className={css.flexRowCenter12px}>
                                                <div className={css.flexColumnEnd2px}>
                                                    <span className={css.label}>{`${t.value} ${token.tokenSymbol}`}</span>
                                                    <span className={css.note}>{`$${parseFloat(t.value) * parseFloat(t.price)}`}</span>
                                                </div>
                                                <RightOutlined className={css.icon10} />
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>
                    )}
                    <Button
                        className={css.button}
                        type="default"
                        onClick={() => {
                            setPage("transfers");
                        }}
                    >
                        {"See all Transfers >"}
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default DAODetailsFinance;
