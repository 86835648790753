import { TOKEN_CREATION_NODE, TOKEN_CREATION_EDGE, TOKEN_CREATION_NODE_EDGE_RESET } from "../../components/TeDaaSV3/Constants/Constants";
export const tokenCreationUpdateNodes = (data) => ({
    type: TOKEN_CREATION_NODE,
    payload: data,
});

export const tokenCreationUpdateEdges = (data) => ({
    type: TOKEN_CREATION_EDGE,
    payload: data,
});

export const tokenCreationNodeEdgeReset = () => ({
    type: TOKEN_CREATION_NODE_EDGE_RESET,
});
