import PropTypes from "prop-types";

function DownArrow({ stroke, size = 12 }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 12 12" fill="none">
            <path
                d="M1.14 3.57l4.478 4.698a.54.54 0 00.765 0L10.86 3.57"
                stroke={stroke}
                strokeWidth={1.6}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
DownArrow.propTypes = {
    stroke: PropTypes.string.isRequired,
    size: PropTypes.string.isRequired,
};

export default DownArrow;
