import React, { useEffect, useState } from "react";
import { Modal } from "antd";

import css from "./TransferModal.module.scss";

import ApproveIcon from "../../../../TeDaaS-Assets/Modals/transfer.svg";
import CloseIcon from "../../../../TeDaaS-Assets/Modal/close.svg";
import { useDispatch, useSelector } from "react-redux";
import { tokenActionCloseModal } from "../../../../../../redux/actions/tokenCreationOptionsAction";
import { updateLoader } from "../../../../../../redux/actions/loaderAction";
import { showErrorToast, showSuccessToast, showWarningToast } from "../../../../../../services/notificationService";
import { useERC20TokenAction } from "../../../../Hooks/useERC20TokenAction";
import { useParams } from "react-router-dom";
import { getTransferErrorMessage } from "../../../../Constants/reusableFunctions";

const TransferModal = ({ totalSupply, isOwner = false }) => {
    // const [openModal, setOpenModal] = useState(true);
    const dispatch = useDispatch();
    const modelData = useSelector((state) => state.tco.actionModals);
    const openModal = modelData["Transfer"] || modelData["Transfer To User"];
    const [confirmLoading, setConfirmLoading] = useState(false);
    const { address } = useParams();
    const [loading, setLoading] = useState(false);
    const { transferToken, isValidAddress, transferTokenFromTokenBalance } = useERC20TokenAction();
    const [data, setData] = useState({
        address: "",
        amount: "",
    });

    const handleCancel = () => {
        // dispatch(tokenActionModals({approve:false}))
        dispatch(tokenActionCloseModal());
    };

    const handleOk = async () => {
        dispatch(updateLoader(true));
        // const balance = await getAddress
        const isAddressValid = isValidAddress(data.address);
        const amount = data.amount;
        if (isAddressValid && /^[0-9]*\.?[0-9]+$/.test(amount) && amount.length > 0) {
            try {
                if (isOwner) {
                    const res = await transferTokenFromTokenBalance(address, data.address, String(data.amount));
                    res === true ? showSuccessToast("Transfered Token to user Successfully") : showErrorToast("Transfer to user failed");
                } else {
                    const res = await transferToken(address, data.address, String(data.amount));
                    res === true ? showSuccessToast("Transfered Token Successfully") : showErrorToast("Transfer failed");
                }
                dispatch(tokenActionCloseModal());
            } catch (e) {
                const msg = getTransferErrorMessage(e.message);
                showErrorToast(msg);
            }
        } else {
            showWarningToast("Please fill valid details");
        }
        dispatch(updateLoader(false));
    };

    return (
        <Modal
            centered
            title={
                <div className={css.header}>
                    <div className={css.headerTop}>
                        <div className={css.headingWrapper}>
                            <img className={css.icon} src={ApproveIcon} alt="Transfer" />
                            <span className={css.title}>{isOwner ? "Transfer Tokens to User" : "Transfer"}</span>
                        </div>
                        <img className={css.closeIcon} src={CloseIcon} alt="Close" onClick={handleCancel} />
                    </div>
                    <div className={css.subheading}>
                        <span>Manage Action</span>
                        <span>Current Total Supply: {totalSupply}</span>
                    </div>
                </div>
            }
            closeIcon={false}
            open={openModal}
            confirmLoading={confirmLoading}
            onCancel={handleCancel}
            onOk={handleOk}
            loading={loading}
            className={css.main}
        >
            <div className={css.modalBody}>
                <p className={css.modalDescription}>Transfer tokens from one address or account to another within a blockchain network.</p>

                <div className={css.addressInputContainer}>
                    <p>Address</p>
                    <input
                        placeholder="0xc5bfjr860nfky6903tgoeHHF9496NFf5842"
                        value={data.address}
                        onChange={(e) => {
                            setData({ ...data, address: e.target.value });
                        }}
                    />
                </div>

                <div className={css.amountInputContainer}>
                    <p>Amount</p>
                    <input
                        placeholder="e.g 50,000"
                        value={data.amount}
                        onChange={(e) => {
                            setData({ ...data, amount: e.target.value });
                        }}
                    />
                </div>

                <div className={css.buttonContainer}>
                    <button className={`${css.btn} ${css.cancelButton}`} onClick={handleCancel}>
                        Cancel{" "}
                    </button>
                    <button className={`${css.btn} ${css.submitButton}`} onClick={handleOk}>
                        Submit
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default TransferModal;
