import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import css from "./TokenDocsModel.module.scss";
import burnImg from "../../TeDaaS-Assets/Modals/editBurn.svg";
import closeImg from "../../TeDaaS-Assets/Nodes/close.svg";
import addressClose from "../../TeDaaS-Assets/Modals/close.svg";
import { tokenCreationCloseModel } from "../../../../redux/actions/tokenCreationOptionsAction";
import { generateEdge, generateParentNode, handleFileDrop } from "../../Constants/reusableFunctions";
import uploadImg from "../../TeDaaS-Assets/Modals/upload.svg";
import { configureTokenVal } from "../../../../redux/actions/tokenCreationAction";
import { tokenCreationUpdateEdges, tokenCreationUpdateNodes } from "../../../../redux/actions/tokenCreationNodesAndEdgesAction";

const TokenDocsModel = () => {
    const dispatch = useDispatch();
    const modelData = useSelector((state) => state.tco);
    const fetchedData = useSelector((state) => state.tc);
    const { nodes, edges } = useSelector((state) => state.tcne);
    const [data, setData] = useState([]);
    const [image, setImage] = useState(false);
    const handleChange = async (e, y = "") => {
        const res = await handleFileDrop(handleChange, "icon", e, y);
        if (res !== false) {
            setData([
                ...data,
                {
                    id: res.id,
                    name: res.info.name,
                    url: `https://cdn.gov-cloud.ai${res.cdnUrl}`,
                },
            ]);
        }
    };
    const handleOk = () => {
        dispatch(
            configureTokenVal("tokenDocs", {
                can: true,
                docs: data,
                createdOn: fetchedData.tokenDocs?.createdOn ? fetchedData.tokenDocs.createdOn : new Date().getTime(),
                updatedOn: new Date().getTime(),
            }),
        );
        if (modelData?.models?.position?.x !== 0) {
            const tokenDocsNode = generateParentNode("Token Docs", "tokenDocs", "", "tokenDocs");
            tokenDocsNode.position = modelData.models.position;
            dispatch(tokenCreationUpdateNodes([...nodes, tokenDocsNode]));
            const tokenDocsEdge = generateEdge("tokenType", "tokenDocs");
            dispatch(tokenCreationUpdateEdges([...edges, tokenDocsEdge]));
        }
        dispatch(tokenCreationCloseModel());
    };
    const handleCancel = () => {
        dispatch(tokenCreationCloseModel());
    };
    useEffect(() => {
        if (fetchedData?.tokenDocs?.createdOn) {
            setData(fetchedData?.tokenDocs?.docs);
        }
    }, []);
    useEffect(() => {
        if (image) {
            document.getElementById("createTokenImageUploadInput").click();
            setImage(false);
        }
    }, [image]);
    return (
        <Modal
            centered
            title={
                <div className={css.header}>
                    <div className={css.headerLeft}>
                        <div className={css.headerTop}>
                            <img className={css.icon} src={burnImg} alt="Token Docs" />
                            <span className={css.title}>Token Documentations</span>
                        </div>
                        <div className={css.iconContainer} onClick={handleCancel}>
                            <img className={css.icon} src={closeImg} alt="Close" />
                        </div>
                    </div>
                    <div className={css.subTitleContainer}>
                        <span className={css.subTitle}>Current token supply: {fetchedData.initialSupply}</span>
                    </div>
                </div>
            }
            closeIcon={false}
            open={modelData.models.tokenDocs}
            // className={css.main}
        >
            <div className={css.container}>
                <div className={css.slider}>
                    <span className={css.label}>Token Documents</span>
                    {data.length > 0 && (
                        <div className={css.addresses}>
                            {Array.isArray(data) &&
                                data.map((a, i) => {
                                    return (
                                        <div className={css.address} key={i}>
                                            <span
                                                className={css.addressText}
                                                onClick={() => {
                                                    window.open(a.url, "_blank");
                                                }}
                                            >
                                                {a.name}
                                            </span>
                                            <img
                                                className={css.addressIcon}
                                                src={addressClose}
                                                alt={a.id}
                                                onClick={() => {
                                                    try {
                                                        setData(data.filter((ele) => ele.id !== a.id));
                                                    } catch (e) {
                                                        console.log(e);
                                                    }
                                                }}
                                            />
                                        </div>
                                    );
                                })}{" "}
                        </div>
                    )}
                    <div className={css.right}>
                        <div
                            className={css.iconContainerEmpty}
                            onDrop={(e) => {
                                handleChange("icons", e, "drop");
                            }}
                            onDragOver={(e) => {
                                e.preventDefault();
                            }}
                            onClick={() => {
                                setImage(true);
                            }}
                        >
                            <img className={css.icon} src={uploadImg} alt="Upload" />
                            <span className={css.text}>Upload file here</span>
                        </div>
                        <input
                            id="createTokenImageUploadInput"
                            type="file"
                            style={{ display: "none" }}
                            onChange={(e) => {
                                handleChange(e, "input");
                            }}
                        />
                    </div>
                </div>
                <div className={css.buttons}>
                    <span className={css.cancel} onClick={handleOk}>
                        Cancel
                    </span>
                    <span className={css.submit} onClick={handleOk}>
                        Submit
                    </span>
                </div>
            </div>
        </Modal>
    );
};
export default TokenDocsModel;
