import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { configureToken } from "../../../../redux/actions/tokenCreationAction";
import css from "./BurnModel.module.scss";
import burnImg from "../../TeDaaS-Assets/Nodes/burn.svg";
import closeImg from "../../TeDaaS-Assets/Nodes/close.svg";
import "./emptyAntModelDefaultCss.css";
import SliderWithButtons from "../UI/SliderWithButtons/SliderWithButtons";
import { tokenCreationCloseModel } from "../../../../redux/actions/tokenCreationOptionsAction";

const BurnModel = () => {
    const dispatch = useDispatch();
    const fetchedData = useSelector((state) => state.tc);
    const modelData = useSelector((state) => state.tco);
    const handleOk = () => {
        dispatch(
            configureToken("burn", {
                can: true,
                percent: typeof data.value == "string" ? parseFloat(data.value) : data.value,
                period: data.period,
                unlock: fetchedData.burn?.unlock,
                createdOn: fetchedData.burn.can===true ? fetchedData.burn.createdOn : new Date().getTime(),
                updatedOn: new Date().getTime(),
            }),
        );
        dispatch(tokenCreationCloseModel());
    };
    const handleCancel = () => {
        dispatch(tokenCreationCloseModel());
    };
    const [data, setData] = useState({
        name: `Burn Supply (Deflationary Token) %`,
        maximum: 5,
        minimum: 0.001,
        incrementSlider: 0.001,
        incrementButton: 0.1,
        value: 0.5,
        period: "month",
    });
    useEffect(() => {
        if (fetchedData.burn.can) {
            setData({ ...data, value: fetchedData.burn.percent, period: fetchedData.burn.period });
        }
    }, []);
    return (
        <Modal
            title={
                <div className={css.header}>
                    <div className={css.headerLeft}>
                        <div className={css.headerTop}>
                            <img className={css.icon} src={burnImg} alt="Burn" />
                            <span className={css.title}>Configure Burn</span>
                        </div>
                        <span className={css.subTitle}>Current token supply: {fetchedData.initialSupply}</span>
                    </div>
                    <div className={css.headerRight} onClick={handleCancel}>
                        <img className={css.icon} src={closeImg} alt="Close" />
                    </div>
                </div>
            }
            closeIcon={false}
            open={modelData.models.burn}
            className={css.main}
        >
            <div className={css.container}>
                <SliderWithButtons data={data} setData={setData} />
                <div className={css.periods}>
                    <span
                        className={data.period === "month" ? css.active : css.period}
                        onClick={() => {
                            setData({ ...data, period: "month" });
                        }}
                    >
                        Monthly
                    </span>
                    <span
                        className={data.period === "quarter" ? css.active : css.period}
                        onClick={() => {
                            setData({ ...data, period: "quarter" });
                        }}
                    >
                        Quarterly
                    </span>
                    <span
                        className={data.period === "year" ? css.active : css.period}
                        onClick={() => {
                            setData({ ...data, period: "year" });
                        }}
                    >
                        Yearly
                    </span>
                </div>
                <span className={css.save} onClick={handleOk}>
                    Save
                </span>
            </div>
        </Modal>
    );
};
export default BurnModel;
