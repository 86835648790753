import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { configureTokenVal, tokenCreationAssignee } from "../../../../redux/actions/tokenCreationAction";
import css from "./BurnModel.module.scss";
import burnImg from "../../TeDaaS-Assets/Nodes/assignee.svg";
import closeImg from "../../TeDaaS-Assets/Nodes/close.svg";
import "./emptyAntModelDefaultCss.css";
import SliderWithButtons from "../UI/SliderWithButtons/SliderWithButtons";
import DropDown from "../UI/DropDown/DropDown";
import InputWithPlusMinus from "../UI/InputWithPlusMinus/InputWithPlusMinus";
import { tokenCreationCloseModel } from "../../../../redux/actions/tokenCreationOptionsAction";
import { generateEdge, generateParentNode, getName } from "../../Constants/reusableFunctions";
import { tokenCreationUpdateEdges, tokenCreationUpdateNodes } from "../../../../redux/actions/tokenCreationNodesAndEdgesAction";

const AssigneeModel = () => {
    const dispatch = useDispatch();
    const fetchedData = useSelector((state) => state.tc);
    const modelData = useSelector((state) => state.tco);
    const { nodes, edges } = useSelector((state) => state.tcne);
    const [data, setData] = useState({
        label: "Percentage",
        name: fetchedData.assignee[modelData.models.id]?.name || modelData.models.name !== "Add Custom" ? modelData.models.name : "",
        maximum: 5,
        minimum: 0.001,
        incrementSlider: 0.001,
        incrementButton: 0.1,
        value: 0.5,
        period: "month",
        unlock: 0,
        lock: 0,
        index: modelData.models.id,
        id: modelData.models.id,
    });
    const handleOk = () => {
        dispatch(
            tokenCreationAssignee({
                can: true,
                index: data.index,
                id: data.id,
                percent: parseFloat(data.value),
                vestingPeriod: vesting,
                name: data.name,
                emissionPeriod: emission,
                lock: data.lock,
                cohorts: fetchedData.assignee[data.id]?.can ? fetchedData.assignee[data.id].cohorts : [],
                unlock: data.unlock,
                createdOn: fetchedData.assignee[data.id]?.can ? fetchedData.assignee[data.id].createdOn : new Date().getTime(),
                updatedOn: new Date().getTime(),
            }),
        );
        dispatch(tokenCreationCloseModel());
        if (!fetchedData.assignee[data.id]?.can) {
            const assigneeNode = generateParentNode("Assignee", "assignee", data.id);
            assigneeNode.position = modelData.models.position;
            dispatch(tokenCreationUpdateNodes([...nodes, assigneeNode]));
            const assigneeEdge = generateEdge("tokenType", "assignee" + data.id);
            dispatch(tokenCreationUpdateEdges([...edges, assigneeEdge]));
        }
    };
    const handleCancel = () => {
        dispatch(tokenCreationCloseModel());
    };
    useEffect(() => {
        if (fetchedData.assignee[modelData.models.id]?.can) {
            setData({
                ...data,
                index: fetchedData.assignee[modelData.models.id].index,
                id: fetchedData.assignee[modelData.models.id].id,
                value: fetchedData.assignee[modelData.models.id].percent,
                lock: fetchedData.assignee[modelData.models.id].lock,
                unlock: fetchedData.assignee[modelData.models.id].unlock,
            });
            setVesting(fetchedData.assignee[modelData.models.id].vestingPeriod);
            setEmission(fetchedData.assignee[modelData.models.id].emissionPeriod);
        }
    }, []);
    const optionsVesting = [
        {
            key: "month",
            label: (
                <div
                    className={css.optionText}
                    onClick={() => {
                        handleVesting("month");
                    }}
                >
                    Month
                </div>
            ),
        },
        {
            key: "quarter",
            label: (
                <div
                    className={css.optionText}
                    onClick={() => {
                        handleVesting("quarter");
                    }}
                >
                    Quarter
                </div>
            ),
        },
        {
            key: "year",
            label: (
                <div
                    className={css.optionText}
                    onClick={() => {
                        handleVesting("year");
                    }}
                >
                    Year
                </div>
            ),
        },
    ];
    const optionsEmission = [
        {
            key: "month",
            label: (
                <div
                    className={css.optionText}
                    onClick={() => {
                        handleEmission("month");
                    }}
                >
                    Month
                </div>
            ),
        },
        {
            key: "quarter",
            label: (
                <div
                    className={css.optionText}
                    onClick={() => {
                        handleEmission("quarter");
                    }}
                >
                    Quarter
                </div>
            ),
        },
        {
            key: "year",
            label: (
                <div
                    className={css.optionText}
                    onClick={() => {
                        handleEmission("year");
                    }}
                >
                    Year
                </div>
            ),
        },
    ];
    const handleVesting = (e) => {
        setVesting(e);
    };
    const handleEmission = (e) => {
        setEmission(e);
    };
    const [vesting, setVesting] = useState("month");
    const [emission, setEmission] = useState("month");
    const handleInput = (name, value) => {
        setData({ ...data, [name]: value });
    };
    return (
        <Modal
            title={
                <div className={css.header}>
                    <div className={css.headerLeft}>
                        <div className={css.headerTop}>
                            <img className={css.icon} src={burnImg} alt="Assignee" />
                            <span className={css.title}>
                                Assign - {fetchedData.assignee[data.id]?.name || getName(modelData.models.name, "Assignee")}
                            </span>
                        </div>
                        <span className={css.subTitle}>Current token supply: {fetchedData.initialSupply}</span>
                    </div>
                    <div className={css.headerRight} onClick={handleCancel}>
                        <img className={css.icon} src={closeImg} alt="Close" />
                    </div>
                </div>
            }
            closeIcon={false}
            open={modelData.models.assignee}
            className={css.main}
        >
            <div className={css.assignee}>
                <SliderWithButtons data={data} setData={setData} />
                {modelData.models.name === "Add Custom" && (
                    <div className={css.tokens}>
                        <span className={css.title}>Assignee Name</span>
                        <input
                            className={css.input}
                            type="text"
                            value={data.name}
                            onChange={(e) => {
                                setData({
                                    ...data,
                                    name: e.target.value,
                                    index: e.target.value,
                                    id: e.target.value,
                                });
                            }}
                        />
                    </div>
                )}
                <div className={css.tokens}>
                    <span className={css.title}>Tokens</span>
                    <div className={css.input}>{Math.round(data.value * fetchedData.initialSupply)}</div>
                </div>
                <div className={css.cards}>
                    <div className={css.card}>
                        <span className={css.title}>Vesting</span>
                        <span className={css.title}>Months</span>
                    </div>
                    <div className={css.card}>
                        <DropDown prefix="Lock" data={optionsVesting} selected={vesting} />
                        <InputWithPlusMinus
                            value={data.lock}
                            handleDecrease={() => {
                                if (data.lock > 0) {
                                    handleInput("lock", data.lock - 1);
                                }
                            }}
                            handleInput={(e) => {
                                handleInput("lock", e.target.value);
                            }}
                            handleIncrease={() => {
                                handleInput("lock", data.lock + 1);
                            }}
                            min={2}
                        />
                    </div>
                </div>
                <div className={css.cards}>
                    <div className={css.card}>
                        <span className={css.title}>Emission</span>
                        <span className={css.title}>Months</span>
                    </div>
                    <div className={css.card}>
                        <DropDown prefix="Unlock" data={optionsEmission} selected={emission} />
                        <InputWithPlusMinus
                            value={data.unlock}
                            handleDecrease={() => {
                                if (data.unlock > 0) {
                                    handleInput("unlock", data.unlock - 1);
                                }
                            }}
                            handleInput={(e) => {
                                handleInput("unlock", e.target.value);
                            }}
                            handleIncrease={() => {
                                handleInput("unlock", data.unlock + 1);
                            }}
                        />
                    </div>
                </div>
                <span className={css.save} onClick={handleOk}>
                    Save
                </span>
            </div>
        </Modal>
    );
};
export default AssigneeModel;
