import React, { useState } from "react";
import { Modal } from "antd";

import css from "./ApproveModal.module.scss";

import ApproveIcon from "../../../../TeDaaS-Assets/TokenManage/ApproveInActive.svg";
import CloseIcon from "../../../../TeDaaS-Assets/Modal/close.svg";
import { useDispatch, useSelector } from "react-redux";
import { tokenActionCloseModal } from "../../../../../../redux/actions/tokenCreationOptionsAction";
import { updateLoader } from "../../../../../../redux/actions/loaderAction";
import { showErrorToast, showSuccessToast, showWarningToast } from "../../../../../../services/notificationService";
import { useERC20TokenAction } from "../../../../Hooks/useERC20TokenAction";
import { useParams } from "react-router-dom";

const ApproveModal = ({ totalSupply }) => {
    // const [openModal, setOpenModal] = useState(true);
    const dispatch = useDispatch();
    const { address } = useParams();
    const openModal = useSelector((state) => state.tco.actionModals.Approve);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const { approveAddressToTransactOnBehalf, isValidAddress } = useERC20TokenAction();
    const [data, setData] = useState({
        address: "",
        amount: "",
    });

    const handleCancel = () => {
        // dispatch(tokenActionModals({approve:false}))
        dispatch(tokenActionCloseModal());
    };

    const handleOk = async () => {
        dispatch(updateLoader(true));
        // const balance = await getAddress
        const isAddressValid = isValidAddress(data.address);
        const amount = data.amount;
        if (isAddressValid && /^[0-9]*\.?[0-9]+$/.test(amount) && amount.length > 0) {
            try {
                const res = await approveAddressToTransactOnBehalf(address, data.address, parseInt(data.amount));
                res == true ? showSuccessToast("Approved" + data.amount + "Tokens to" + data.address) : showErrorToast("Approve Failed");
                dispatch(tokenActionCloseModal());
            } catch (e) {
                setTimeout(() => {
                    dispatch(updateLoader(false));
                }, [1000]);
            }
        } else {
            showWarningToast("Please fill valid details");
        }
        dispatch(updateLoader(false));
    };

    return (
        <Modal
            centered
            title={
                <div className={css.header}>
                    <div className={css.headerTop}>
                        <div className={css.headingWrapper}>
                            <img className={css.icon} src={ApproveIcon} alt="Burn" />
                            <span className={css.title}>Approve</span>
                        </div>
                        <img className={css.closeIcon} src={CloseIcon} alt="Close" onClick={handleCancel} />
                    </div>
                    <div className={css.subheading}>
                        <span>Manage Action</span>
                        <span>Current Total Supply: {totalSupply}</span>
                    </div>
                </div>
            }
            closeIcon={false}
            open={openModal}
            confirmLoading={confirmLoading}
            onCancel={handleCancel}
            onOk={handleOk}
            loading={loading}
            className={css.main}
        >
            <div className={css.modalBody}>
                <p className={css.modalDescription}>
                    Approving the given address, allows you to authorize a specific wallet address to spend funds on your behalf, up to a specified
                    limit!
                </p>

                <div className={css.addressInputContainer}>
                    <p>Address</p>
                    <input
                        placeholder="0xc5bfjr860nfky6903tgoeHHF9496NFf5842"
                        value={data.address}
                        onChange={(e) => {
                            setData({ ...data, address: e.target.value });
                        }}
                    />
                </div>

                <div className={css.amountInputContainer}>
                    <p>Amount</p>
                    <input
                        placeholder="e.g 50,000"
                        value={data.amount}
                        onChange={(e) => {
                            setData({ ...data, amount: e.target.value });
                        }}
                    />
                </div>

                <div className={css.buttonContainer}>
                    <button className={`${css.btn} ${css.cancelButton}`} onClick={handleCancel}>
                        Cancel{" "}
                    </button>
                    <button className={`${css.btn} ${css.submitButton}`} onClick={handleOk}>
                        Submit
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default ApproveModal;
