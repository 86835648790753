import React, { useState } from "react";
import css from "./TreeItem.module.scss";

const TreeItem = ({ topMostElement = false, customConnectorLeftShift = null, children, expanded = true, className = "", clickCallback = null }) => {
    const [isExpanded, setIsExpanded] = useState(expanded);

    const handleToggle = () => {
        if (clickCallback && typeof clickCallback === "function") {
            clickCallback();
        }
        setIsExpanded(!isExpanded);
    };

    const renderChildren = (children) => {
        let foundTreeItem = false;

        return React.Children.map(children, (child, index) => {
            // Check if child is a valid React element and its type is TreeItem
            if (React.isValidElement(child) && child.type === TreeItem) {
                foundTreeItem = true; // Mark that we found a TreeItem
                return (
                    <div className={css.treeItemChild} key={index} style={{ marginLeft: topMostElement && customConnectorLeftShift }}>
                        <div className={css.connector}></div>
                        {child}
                    </div>
                );
            } else {
                // Render nothing until we find the first TreeItem
                if (!foundTreeItem) {
                    return null;
                }
                // Render non-TreeItem elements normally
                return <div key={index}>{child}</div>;
            }
        });
    };

    return (
        <div className={css.treeItem}>
            <div className={`${css.treeItemContainer} ${topMostElement && css.bigContainer}`} onClick={handleToggle}>
                <div className={`${css.treeItemContent} ${className}`}>
                    {React.Children.map(children, (child) => {
                        if (!React.isValidElement(child) || child.type !== TreeItem) {
                            return (
                                <>
                                    {/* {isExpanded ? <>&blacktriangledown;</> : <><>&blacktriangleright;</></>} */}
                                    {child}
                                </>
                            );
                        }
                    })}
                </div>
            </div>
            {isExpanded && <div className={css.treeItemChildren}>{renderChildren(children)}</div>}
        </div>
    );
};

export default TreeItem;
