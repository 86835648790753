import React, { useEffect, useState } from "react";

// import Search from "../../../TeDaaS-Assets/TokenomicsSimulation/Search.svg";
// import Filter from "../../../TeDaaS-Assets/TokenomicsSimulation/Filter.svg";
// import Tags from "../../../TeDaaS-Assets/TokenomicsSimulation/Tags.svg";

import css from "./TokenomicsParameters.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { updatePreMintValue } from "../../../../../redux/actions/Tokenomics/preMintAction";
import TokenomicsSliderInput from "../../UI/TokenomicsSliderInput/TokenomicsSliderInput";
import { configureTokenVal, tokenCreationAssignee } from "../../../../../redux/actions/tokenCreationAction";
import { Button, Switch } from "antd";
import TokenomicsSimulationToggle from "../../UI/TokenomicsSimulationToggle/TokenomicsSimulationToggle";
import { tokenSimulation, tokenSimulationHeaderOptions } from "../../../../../redux/actions/tokenSimulationAction";
import minimizeImg from "../../../TeDaaS-Assets/TokenomicsSimulation/minimize.svg";
import maximizeImg from "../../../TeDaaS-Assets/TokenomicsSimulation/maximize.svg";
import {
    calculateTokenSimulationBurn,
    calculateTokenSimulationEmission,
    calculateTokenSimulationMint,
    calculateTokenSimulationReward,
    calculateTokenSimulationTokenomics,
} from "../../../Constants/tokenSimulation";
import { TOKEN_SIMULATION } from "../../../Constants/Constants";
import TokenomicsSimulationTokenomicsParameter from "../../UI/TokenomicsSimulationTokenomicsParameter/TokenomicsSimulationTokenomicsParameter";
import TokenomicsSimulationSearchParameter from "../../UI/TokenomicsSimulationSearchParameter/TokenomicsSimulationSearchParameter";
import TokenomicsSimulationApplyParameters from "../../UI/TokenomicsSimulationApplyParameters/TokenomicsSimulationApplyParameters";
import TokenomicsSimulationSelectParameter from "../TokenomicsSimulationSelectParameter/TokenomicsSimulationSelectParameter";
import TokenomicsSimulationChangeParameter from "../TokenomicsSimulationChangeParameter/TokenomicsSimulationChangeParameter";

const TokenomicsParameters = () => {
    const dispatch = useDispatch();
    const fetchedData = useSelector((state) => state.tc);
    const handleChange = (name, val) => {
        dispatch(configureTokenVal(name, val));
    };
    const [search, setSearch] = useState("");
    const [parameters, setParameters] = useState([]);
    const [isReward, setIsReward] = useState(false);
    const [view, setView] = useState("Initial Supply");
    const [apply, setApply] = useState(0);
    const [showParams, setShowParams] = useState({
        type: "initialSupply",
        id: "initialSupply",
    });
    const removeAllParameter = () => {
        dispatch(configureTokenVal("burn", { ...fetchedData.burn, can: false }));
        dispatch(configureTokenVal("mint", { ...fetchedData.mint, can: false }));
        const assigneeIds = Object.keys(fetchedData.assignee);
        const assignee = {};
        for (let id of assigneeIds) {
            assignee[id] = { ...fetchedData.assignee[id], can: false };
        }
        dispatch(configureTokenVal("assignee", assignee));
        const rewardsIds = Object.keys(fetchedData.rewards);
        const rewards = {};
        for (let id of rewardsIds) {
            rewards[id] = { ...fetchedData.rewards[id], can: false };
        }
        dispatch(configureTokenVal("rewards", rewards));
    };
    const tokenSimulationData = useSelector((state) => state.tsr);
    const [headerOptions, setHeaderOptions] = useState({
        "Initial Supply": 0,
        "Rewards Projection": 0,
        "Burn Projection": 0,
        "Mint Projection": 0,
    });
    const [maximize, setMaximize] = useState(true);
    const [tags, setTags] = useState([]);
    useEffect(() => {
        let activeInit = 1;
        let activeReward = 0;
        let assignee = Object.keys(fetchedData.assignee);
        let rewards = Object.keys(fetchedData.rewards);
        let flag = false;
        let params = [];
        params.push({
            name: "Initial Supply",
            index: "initialSupply",
            id: "initialSupply",
            type: "initialSupply",
        });
        for (let i of assignee) {
            if (fetchedData.assignee[i]?.can) {
                activeInit++;
                params.push({
                    name: fetchedData.assignee[i].name,
                    index: fetchedData.assignee[i].index,
                    id: i,
                    type: "assignee",
                });
            }
            if (fetchedData.assignee[i]?.index === "reward" && fetchedData.assignee[i]?.can === true) {
                flag = true;
            }
        }
        for (let i of rewards) {
            if (fetchedData.rewards[i]?.percent > 0 && flag && i !== "undefined") {
                activeReward++;
                params.push({
                    name: fetchedData.rewards[i].name,
                    index: fetchedData.rewards[i].index,
                    id: i,
                    type: "rewards",
                });
            }
        }
        setIsReward(flag);
        const tokenomics = calculateTokenSimulationTokenomics(fetchedData);
        if (flag) {
            var reward = calculateTokenSimulationReward(fetchedData);
            dispatch(tokenSimulation(TOKEN_SIMULATION.reward, reward));
        } else {
            dispatch(tokenSimulation(TOKEN_SIMULATION.reward, { isReward: false }));
        }
        const emission = calculateTokenSimulationEmission(fetchedData);

        const mint = calculateTokenSimulationMint(fetchedData);
        const burn = calculateTokenSimulationBurn(fetchedData);
        dispatch(tokenSimulation(TOKEN_SIMULATION.emission, emission));
        dispatch(tokenSimulationHeaderOptions("Initial Supply", activeInit));
        dispatch(tokenSimulationHeaderOptions("Rewards Projection", activeReward));
        dispatch(tokenSimulation(TOKEN_SIMULATION.mint, mint));
        dispatch(tokenSimulation(TOKEN_SIMULATION.burn, burn));
        dispatch(tokenSimulation(TOKEN_SIMULATION.tokenomics, tokenomics));
        if (fetchedData.mint.can) {
            setHeaderOptions({ ...headerOptions, "Mint Projection": 1 });
            params.push({
                name: "Mint",
                index: "mint",
                id: "mint",
                type: "mint",
            });
        } else {
            setHeaderOptions({ ...headerOptions, "Mint Projection": 0 });
        }
        if (fetchedData.burn.can) {
            setHeaderOptions({ ...headerOptions, "Burn Projection": 1 });
            params.push({
                name: "Burn",
                index: "burn",
                id: "burn",
                type: "burn",
            });
        } else {
            setHeaderOptions({ ...headerOptions, "Burn Projection": 0 });
        }
        setHeaderOptions({
            ...headerOptions,
            "Initial Supply": activeInit,
            "Rewards Projection": activeReward,
            "Burn Projection": fetchedData.burn.can ? 1 : 0,
            "Mint Projection": fetchedData.mint.can ? 1 : 0,
        });
        setParameters(params);
    }, [fetchedData]);
    return (
        <div className={css.container}>
            <div className={css.header}>
                {maximize && (
                    <TokenomicsSimulationTokenomicsParameter
                        headerOptions={headerOptions}
                        setView={setView}
                        view={view}
                        setShowParams={setShowParams}
                    />
                )}
                {!maximize && <TokenomicsSimulationApplyParameters parameters={parameters} type={showParams.type} id={showParams.id} />}
                <div className={css.headerRight}>
                    <div className={css.searchParameter}>
                        <TokenomicsSimulationSearchParameter search={search} setSearch={setSearch} placeholder="Search Parameter" />
                    </div>
                    <div
                        className={`${css.minMax} ${!maximize ? css.maximize : css.minimize}`}
                        onClick={() => {
                            setMaximize(!maximize);
                        }}
                    >
                        <img className={css.icon} src={!maximize ? maximizeImg : minimizeImg} />
                    </div>
                </div>
            </div>
            {maximize && (
                <div className={css.body}>
                    <TokenomicsSimulationSelectParameter group={view} setShowParams={setShowParams} showParams={showParams} />
                    <TokenomicsSimulationChangeParameter type={showParams.type} id={showParams.id} apply={apply} />
                </div>
            )}
            {maximize && (
                <div className={css.header}>
                    <TokenomicsSimulationApplyParameters parameters={parameters} type={showParams.type} id={showParams.id} />
                    <div className={css.headerRight}>
                        <Button type="default" className={css.whiteButton} onClick={removeAllParameter}>
                            Clear All
                        </Button>
                        <Button
                            type="primary"
                            className={css.blueButton}
                            onClick={() => {
                                setApply(apply + 1);
                            }}
                        >
                            Apply Parameters
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default TokenomicsParameters;
