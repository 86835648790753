import React, { memo } from "react";
import css from "./StartNode.module.scss";
import { Handle, Position } from "@xyflow/react";

function StartNode() {
    return (
        <div className={css.container}>
            <Handle type="source" position={Position.Right} className={css.handle} />
            <div className={css.startNode}></div>
            <span className={css.name}>Start</span>
        </div>
    );
}

export default memo(StartNode);
