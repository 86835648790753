import React, { useEffect, useState } from "react";
import css from "./TokenomicsSimulationSelectParameter.module.scss";
import TokenomicsSimulationToggle from "../../UI/TokenomicsSimulationToggle/TokenomicsSimulationToggle";
import { useSelector } from "react-redux";

function TokenomicsSimulationSelectParameter({ group, setShowParams, showParams }) {
    const [content, setContent] = useState([]);
    const fetchedData = useSelector((state) => state.tc);
    const filterGroup = () => {
        let arr = [];
        switch (group) {
            case "Initial Supply":
                let parameter = {
                    index: "initialSupply",
                    name: group,
                    can: true,
                    type: "initialSupply",
                };
                arr.push(parameter);
                const assigneeIds = Object.keys(fetchedData.assignee);
                for (let assigneeId of assigneeIds) {
                    let temp = fetchedData.assignee[assigneeId];
                    arr.push({
                        ...temp,
                        type: "assignee",
                    });
                }
                return arr;
            case "Rewards Projection":
                const rewardIds = Object.keys(fetchedData.rewards);
                for (let rewardId of rewardIds) {
                    let temp = fetchedData.rewards[rewardId];
                    if (temp.percent > 0) {
                        arr.push({
                            ...temp,
                            type: "rewards",
                        });
                    }
                }
                return arr;
            case "Burn Projection":
                arr.push({
                    ...fetchedData.burn,
                    type: "burn",
                    name: "Burn",
                });
                return arr;
            case "Mint Projection":
                arr.push({
                    ...fetchedData.mint,
                    type: "mint",
                    name: "Mint",
                });
                return arr;
            default:
                return [];
        }
    };
    useEffect(() => {
        const arr = filterGroup();
        setContent(arr);
    }, [group, fetchedData]);
    return (
        <div className={css.container}>
            {Array.isArray(content) &&
                content.map((key, i) => {
                    return (
                        <TokenomicsSimulationToggle
                            key={i}
                            index={key.index}
                            type={key.type}
                            can={key.can}
                            name={key.name}
                            showParams={showParams}
                            setShowParams={setShowParams}
                        />
                    );
                })}
        </div>
    );
}

export default TokenomicsSimulationSelectParameter;
