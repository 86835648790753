import "./App.css";
import { lazy, useContext } from "react";
import React from "react";
import { Routes, Route } from "react-router-dom";
import NewAuthentication from "./components/SMS-V2/SecurityMangementFlow/SecurityNewPolicy/Authentication/NewAuthentication/NewAuthentication.jsx";
import EditorGetData from "./components/ITIL-ITSM-V2/WordEditor/EditorGetData/EditorGetData.jsx";
import WordEditor from "./components/ITIL-ITSM-V2/WordEditor/WordEditor.jsx";
const SmsV2 = lazy(() => import('./components/SMS-V2/SmsV2.jsx'));
const SMSDashboard = lazy(() => import('./components/SMS-V2/SMSDashboard/SMSDashboard.jsx'));
const SecurityMangementFlow = lazy(() => import('./components/SMS-V2/SecurityMangementFlow/SecurityMangementFlow.jsx'));
const SMSAdminSetting = lazy(() => import('./components/SMS-V2/SMSAdminSetting/SMSAdminSetting.jsx'));
const SMSSubscriberManagement = lazy(() => import('./components/SMS-V2/SMSSubscriberManagement/SMSSubscriberManagement.jsx'));
const SecurityMangementPrivacyPolicy = lazy(() => import('./components/SMS-V2/SecurityMangementFlow/SecurityMangementPrivacyPolicy/SecurityMangementPrivacyPolicy.jsx'));
const SecurityNewPolicy = lazy(() => import('./components/SMS-V2/SecurityMangementFlow/SecurityNewPolicy/SecurityNewPolicy.jsx'));
const LoginProperty = lazy(() => import('./components/SMS-V2/SecurityMangementFlow/SecurityNewPolicy/LoginProperty/LoginProperty.jsx'));
const PrivacyCompliance = lazy(() => import('./components/SMS-V2/SecurityMangementFlow/SecurityNewPolicy/PrivacyCompliance/PrivacyCompliance.jsx'));
const Authentication = lazy(() => import('./components/SMS-V2/SecurityMangementFlow/SecurityNewPolicy/Authentication/Authentication.jsx'));
const ProcessingActivity = lazy(() => import('./components/SMS-V2/SecurityMangementFlow/SecurityNewPolicy/PrivacyCompliance/ProcessingActivity/ProcessingActivity.jsx'));

const AllProviders = lazy(() => import("./AllProviders.jsx"));

// const AllProviders = lazy(() => import("./AllProviders.jsx"));
// const LoadingComponent = lazy(() => import("./components/shared/molecules/Loading/LoadingComponent.jsx"));
// const FixedPixelPage = lazy(() => import("./components/VoxaV2/FixedPixelPage.jsx"));

const Table = lazy(() => import("./components/commonComponents/Table/Table.jsx"));
const StatusUpdateModal = lazy(() => import("./components/commonComponents/Modal/ModalFolder/StatusUpdateModal/StatusUpdateModal.jsx"));
const StatusDeleteModal = lazy(() => import("./components/commonComponents/Modal/ModalFolder/StatusDeleteModal/StatusDeleteModal.jsx"));
const TaskDetailed = lazy(() => import("./components/ITIL-ITSM-V2/Incident/IncidentTicketDetails/Task/TaskDetailed/TaskDetailed.jsx"));
const TaskDetails = lazy(() => import("./components/ITIL-ITSM-V2/Incident/IncidentTicketDetails/Task/TaskDetailed/TaskDetails/TaskDetails.jsx"));
const LinkIssue = lazy(() => import("./components/ITIL-ITSM-V2/Incident/IncidentTicketDetails/Task/TaskDetailed/LinkIssue/LinkIssue.jsx"));
const POC = lazy(() => import("./components/POC/POC.jsx"));
const AllDevices = lazy(() => import("./components/SMS-V2/SMSSubscriberManagement/SubsTabContainer/AllDevices/AllDevices.jsx"));
const Plan = lazy(() => import("./components/SMS-V2/SMSSubscriberManagement/SubsTabContainer/Plan/Plan.jsx"));
const DeviceActivity = lazy(() => import("./components/SMS-V2/SMSSubscriberManagement/SubsTabContainer/DeviceActivity/DeviceActivity.jsx"));
const SubsLifeCycle = lazy(() => import("./components/SMS-V2/SMSSubscriberManagement/SubsTabContainer/SubsLifeCycle/SubsLifeCycle.jsx"));
const CXDelight = lazy(() => import("./components/CXDelight-V2/CXDelight.jsx"));
const CXDashboard = lazy(() => import("./components/CXDelight-V2/CXDashboard/CXDashboard.jsx"));
const CXDesign = lazy(() => import("./components/CXDelight-V2/CXDesign/CXDesign.jsx"));
const CXPreview = lazy(() => import("./components/CXDelight-V2/CXDesign/Preview/Preview.jsx"));
const CXDatabase = lazy(() => import("./components/CXDelight-V2/CXDatabase/CXDatabase.jsx"));
const CXTheme = lazy(() => import("./components/CXDelight-V2/CXTheme/CXTheme.jsx"));
const CXAnalytics = lazy(() => import("./components/CXDelight-V2/CXAnalytics/CXAnalytics.jsx"));
const CXInteraction = lazy(() => import("./components/CXDelight-V2/CXInteraction/CXInteraction.jsx"));
const CXSettings = lazy(() => import("./components/CXDelight-V2/CXSettings/CXSettings.jsx"));
const StatusPage = lazy(() => import("./components/ITIL-ITSM-V2/StatusPage/StatusPage.jsx"));
const StatusPageAllIncidents = lazy(() => import("./components/ITIL-ITSM-V2/StatusPage/StatusPageAllIncidents/StatusPageAllIncidents.jsx"));
const StatusPageIncidentHistory = lazy(() => import("./components/ITIL-ITSM-V2/StatusPage/StatusPageIncidentHistory/StatusPageIncidentHistory.jsx"));
const StatusPageIncidentDetails = lazy(() => import("./components/ITIL-ITSM-V2/StatusPage/StatusPageIncidentDetails/StatusPageIncidentDetails.jsx"));

// const LoadingComponent = lazy(() => import("./components/shared/molecules/Loading/LoadingComponent.jsx"));

const ITILmainV2 = lazy(() => import("./components/ITIL-ITSM-V2/ITILmainV2.jsx"));
const DashBoardITIL = lazy(() => import("./components/ITIL-ITSM-V2/DashBoard/DashBoardITIL.jsx"));
const BoardITIL = lazy(() => import("./components/ITIL-ITSM-V2/Board/BoardITIL.jsx"));
const Tickets = lazy(() => import("./components/ITIL-ITSM-V2/Tickets/Tickets.jsx"));
const ChangesITIL = lazy(() => import("./components/ITIL-ITSM-V2/Changes/ChangesITIL.jsx"));
const KnowledgeBase = lazy(() => import("./components/ITIL-ITSM-V2/KnowledgeBase/KnowledgeBase.jsx"));
const AssetManagement = lazy(() => import("./components/ITIL-ITSM-V2/AssetManagement/AssetManagement.jsx"));
const Project = lazy(() => import("./components/ITIL-ITSM-V2/Project/Project.jsx"));
const Development = lazy(() => import("./components/ITIL-ITSM-V2/Development/Development.jsx"));
const TableV3 = lazy(() => import("./components/VoxaV2/TableV3/TableV3.jsx"));
const PocCanvas = lazy(() => import("./components/MarcoV2/PocCanvas/PocCanvas.jsx"));
const ProgressbarComponent = lazy(() => import("./components/ITIL-ITSM-V2/Board/ProgressBar/ProgressbarComponent/ProgressbarComponent.jsx"));
const TabularView = lazy(() => import("./components/ITIL-ITSM-V2/KnowledgeBase/TabularView/TabularView.jsx"));
const DeleteArticleName = lazy(() => import("./components/ITIL-ITSM-V2/KnowledgeBase/DeleteArticleName/DeleteArticleName.jsx"));
const CreateArticle = lazy(() => import("./components/ITIL-ITSM-V2/KnowledgeBase/CreateArticle/CreateArticle.jsx"));
const CommerceHero = lazy(() => import("./components/CXDelight/Commerce/CommerceHero.jsx"));
const ReportandDeleteComment = lazy(
    () => import("./components/ITIL-ITSM-V2/KnowledgeBase/ArticleView/Comment/ReportandDeleteComment/Reportand DeleteComment.jsx"),
);
const AddComment = lazy(() => import("./components/ITIL-ITSM-V2/KnowledgeBase/ApproveArticles/AddComment/AddComment.jsx"));
const ArticleProperties = lazy(
    () => import("./components/ITIL-ITSM-V2/KnowledgeBase/CreateArticle/CreateArticleBody/ArticleProperties/ArticleProperties.jsx"),
);
const VersionHistroyEditCard = lazy(
    () => import("./components/ITIL-ITSM-V2/KnowledgeBase/CreateArticle/VersionHistroyEditCard/VersionHistroyEditCard.jsx"),
);
const Incident = lazy(() => import("./components/ITIL-ITSM-V2/Incident/Incident.jsx"));
const MergeSheet = lazy(() => import("./components/ITIL-ITSM-V2/Incident/IncidentTicketDetails/Merge/Merge.jsx"));
const IncidentLanding = lazy(() => import("./components/ITIL-ITSM-V2/Incident/IncidentLanding/IncidentLanding.jsx"));
const IncidentTicketDetails = lazy(() => import("./components/ITIL-ITSM-V2/Incident/IncidentTicketDetails/IncidentTicketDetails.jsx"));
const DetailsIncident = lazy(() => import("./components/ITIL-ITSM-V2/Incident/IncidentTicketDetails/Details/Details.jsx"));
const AffectedServices = lazy(() => import("./components/ITIL-ITSM-V2/Incident/IncidentTicketDetails/AffectedServices/AffectedServices.jsx"));
const StatusPageIncident = lazy(() => import("./components/ITIL-ITSM-V2/Incident/IncidentTicketDetails/StatusPage/StatusPage.jsx"));
const ChangeIncident = lazy(() => import("./components/ITIL-ITSM-V2/Incident/IncidentTicketDetails/Change/Change.jsx"));
const TaskIncident = lazy(() => import("./components/ITIL-ITSM-V2/Incident/IncidentTicketDetails/Task/TaskIncident.jsx"));
const LinkIssueIncident = lazy(() => import("./components/ITIL-ITSM-V2/Incident/IncidentTicketDetails/LinkIssue/LinkIssue.jsx"));
const FormIncident = lazy(() => import("./components/ITIL-ITSM-V2/Incident/IncidentTicketDetails/Form/Form.jsx"));
const ResolutionIncident = lazy(() => import("./components/ITIL-ITSM-V2/Incident/IncidentTicketDetails/Resolution/Resolution.jsx"));
const ActivityIncident = lazy(() => import("./components/ITIL-ITSM-V2/Incident/IncidentTicketDetails/Activity/Activity.jsx"));
const DemoMode = lazy(() =>
    import("./components/DemoMode/DemoMode.jsx").then((module) => ({
        default: module.default,
    })),
);
const DemoMode2 = lazy(() =>
    import("./components/DemoMode/DemoMode2.jsx").then((module) => ({
        default: module.default,
    })),
);

const MarcoV2 = lazy(() =>
    import("./components/MarcoV2/MarcoV2.jsx").then((module) => ({
        default: module.default,
    })),
);
const LandingPage = lazy(() => import("./components/LandingPage/LandingPage.jsx"));
const CXDelightHero = lazy(() => import("./components/CXDelight/CXDelightHero/CXDelightHero.jsx"));
const Moscribe = lazy(() => import("./components/Moscribe/Moscribe.jsx"));
const Marco = lazy(() => import("./components/Marco/Marco.jsx"));

const SMS = lazy(() => import("./components/SMS/SMS.jsx"));
const SMSLandingPage = lazy(() => import("./components/SMS/SMSLandingPage/SMSLandingPage.jsx"));
const SubscriptionManagement = lazy(() => import("./components/SMS/SubscriptionManagement/SubscriptionManagement.jsx"));
const Account = lazy(() => import("./components/SMS/Account/Account.jsx"));
const Feedback = lazy(() => import("./components/SMS/Feedback/Feedback.jsx"));
const AccessControl = lazy(() => import("./components/SMS/AccessControl/AccessControl.jsx"));
const SubscriptionManagementSubscribers = lazy(
    () => import("./components/SMS/SubscriptionManagement/SubscriptionManagementSubscribers/SubscriptionManagementSubscribers.jsx"),
);
const SubscriptionManagementPlans = lazy(
    () => import("./components/SMS/SubscriptionManagement/SubscriptionManagementPlans/SubscriptionManagementPlans.jsx"),
);
const FeedbackManagement = lazy(() => import("./components/SMS/Feedback/FeedbackManagement/FeedbackManagement.jsx"));

const ITILmain = lazy(() => import("./components/ITIL-ITSM/ITILmain.jsx"));
const ITILDashboard = lazy(() => import("./components/ITIL-ITSM/MiddleSection/Dashboard/ITILDashboard.jsx"));
const Overview = lazy(() => import("./components/ITIL-ITSM/MiddleSection/Dashboard/Overview/Overview.jsx"));
const Issues = lazy(() => import("./components/ITIL-ITSM/MiddleSection/Dashboard/Issues/Issues.jsx"));
const UserActivity = lazy(() => import("./components/ITIL-ITSM/MiddleSection/Dashboard/UserActivity/UserActivity.jsx"));
const ITILSupportIssues = lazy(() => import("./components/ITIL-ITSM/MiddleSection/ITILSupportIssues/ITILSupportIssues.jsx"));
const ITILSLAManagement = lazy(() => import("./components/ITIL-ITSM/MiddleSection/ITILSLAManagement/ITILSLAManagement.jsx"));
const ITILVersionControlDashboard = lazy(
    () => import("./components/ITIL-ITSM/MiddleSection/ITILVersionControlDashboard/ITILVersionControlDashboard.jsx"),
);
const ITILSLAMDashboard = lazy(() => import("./components/ITIL-ITSM/MiddleSection/ITILSLAManagement/SLAMDashboard/ITILSLAMDashboard.jsx"));
const ITILSLAMRules = lazy(() => import("./components/ITIL-ITSM/MiddleSection/ITILSLAManagement/SLAMRules/ITILSLAMRules.jsx"));
const ITILSLAMAddNewRule = lazy(() => import("./components/ITIL-ITSM/MiddleSection/ITILSLAManagement/AddNewRule/ITILSLAMAddNewRule.jsx"));
const ITILSupportIssuesTicketDetails = lazy(
    () => import("./components/ITIL-ITSM/MiddleSection/ITILSupportIssues/ITILSupportIssuesTicketDetails/ITILSupportIssuesTicketDetails.jsx"),
);
const VersionReleasedInfo = lazy(
    () => import("./components/ITIL-ITSM/MiddleSection/ITILVersionControlDashboard/VersionReleasedInfo/VersionReleasedInfo.jsx"),
);
const RollOut = lazy(() => import("./components/ITIL-ITSM/MiddleSection/ITILVersionControlDashboard/RollOuts/RollOut.jsx"));
const CreateForm = lazy(() => import("./components/ITIL-ITSM/MiddleSection/ITILVersionControlDashboard/RollOuts/CreateNewForm/CreateForm.jsx"));
const AllArticles = lazy(() => import("./components/ITIL-ITSM-V2/KnowledgeBase/AllArticles/AllArticles.jsx"));
const ArticlesUnderReview = lazy(() => import("./components/ITIL-ITSM-V2/KnowledgeBase/ArticlesUnderReview/ArticlesUnderReview.jsx"));
const ApproveArticles = lazy(() => import("./components/ITIL-ITSM-V2/KnowledgeBase/ApproveArticles/ApproveArticles.jsx"));
const ArticleView = lazy(() => import("./components/ITIL-ITSM-V2/KnowledgeBase/ArticleView/ArticleView.jsx"));
const Content = lazy(() => import("./components/ITIL-ITSM-V2/KnowledgeBase/ArticleView/Content/Content.jsx"));
const Activity = lazy(() => import("./components/ITIL-ITSM-V2/KnowledgeBase/ArticleView/Activity/Activity.jsx"));
const Insight = lazy(() => import("./components/ITIL-ITSM-V2/KnowledgeBase/ArticleView/Insight/Insight.jsx"));
const Comment = lazy(() => import("./components/ITIL-ITSM-V2/KnowledgeBase/ArticleView/Comment/Comment.jsx"));

const VoxaV2 = lazy(() => import("./components/VoxaV2/VoxaV2.jsx"));

const Adwize = lazy(() => import("./components/Adwize/Adwize.jsx"));
const Orders = lazy(() => import("./components/Adwize/Delivery/Orders/Orders.jsx"));
const Inventory = lazy(() => import("./components/Adwize/Inventory/Inventory.jsx"));
const DeliveryFlow = lazy(() => import("./components/Adwize/Delivery/DeliveryFlow/DeliveryFlow.jsx"));
const AdwiseDashboard = lazy(() => import("./components/Adwize/Dashboard/AdwiseDashboard.jsx"));
const Admin = lazy(() => import("./components/Adwize/Admin/Admin.jsx"));
const CreateNewAd = lazy(() => import("./components/Adwize/Inventory/CreateNewAd/CreateNewAd.jsx"));
const GlobalSettings = lazy(() => import("./components/Adwize/Admin/GlobalSettings/GlobalSettings.jsx"));
const Authorisation = lazy(() => import("./components/Adwize/Admin/AccessAuthorization/Authorisation.jsx"));
const LinkedAccount = lazy(() => import("./components/Adwize/Admin/AccountDetails/Account.jsx"));
const AddUser = lazy(() => import("./components/Adwize/Admin/Modals/AddUser/AddUser.jsx"));
const AddAccount = lazy(() => import("./components/Adwize/Admin/Modals/AddAccount/AddAccount.jsx"));

//TeDaasV3Routes
const TeDaasV3 = lazy(() => import("./components/TeDaaSV3/TeDaaSV3.jsx"));
const TeDaaSDashboard = lazy(() => import("./components/TeDaaSV3/Pages/TeDaaSDashboard/TeDaaSDashboard.jsx"));
import TokenStudioDashboard from "./components/TeDaaSV3/Pages/TokenStudioDashboard/TokenStudioDashboard.jsx";
import TokenCreation from "./components/TeDaaSV3/Pages/TokenCreation/TokenCreation.jsx";
import TokenManage from "./components/TeDaaSV3/Pages/TokenManage/TokenManage.jsx";
import MarcoConfigurationModal from "./components/MarcoV2/MarcoForm/MarcoConfigurationModal/MarcoConfigurationModal.jsx";
import AddToCmsModal from "./components/commonComponents/Modal/ModalFolder/AddToCmsModal/AddToCmsModal.jsx";
import TokenomicsSimulation from "./components/TeDaaSV3/Pages/TokenomicsSimulation/TokenomicsSimulation.jsx";
import SmartContractLowCode from "./components/TeDaaSV3/Pages/SmartContractLowCode/SmartContractLowCode.jsx";
import DAOStudioDashboard from "./components/TeDaaSV3/Pages/DAOStudioDashboard/DAOStudioDashboard.jsx";
import DAOCreation from "./components/TeDaaSV3/Pages/DAOCreateForm/DAOCreation/DAOCreation.jsx";
import SelectBlockchain from "./components/TeDaaSV3/Pages/DAOCreateForm/SelectBlockchain/SelectBlockchain.jsx";
import DescribeYourDAO from "./components/TeDaaSV3/Pages/DAOCreateForm/DescribeYourDAO/DescribeYourDAO.jsx";
import ProposalCreation from "./components/TeDaaSV3/Pages/ProposalCreationForm/ProposalCreation/ProposalCreation.jsx";
import ProposalMetaData from "./components/TeDaaSV3/Pages/ProposalCreationForm/ProposalMetaData/ProposalMetaData.jsx";
import ProposalVotingDuration from "./components/TeDaaSV3/Pages/ProposalCreationForm/ProposalVotingDuration/ProposalVotingDuration.jsx";
import DefineMembership from "./components/TeDaaSV3/Pages/DAOCreateForm/DefineMembership/DefineMembership.jsx";
import SelectGovernanceSettings from "./components/TeDaaSV3/Pages/DAOCreateForm/SelectGovernanceSettings/SelectGovernanceSettings.jsx";
import DeployYourDAO from "./components/TeDaaSV3/Pages/DAOCreateForm/DeployYourDAO/DeployYourDAO.jsx";
import DAODetails from "./components/TeDaaSV3/Pages/DAODetails/DAODetails.jsx";
import ProposalSetActions from "./components/TeDaaSV3/Pages/ProposalCreationForm/ProposalSetActions/ProposalSetActions.jsx";
import ProposalReviewProposal from "./components/TeDaaSV3/Pages/ProposalCreationForm/ProposalReviewProposal/ProposalReviewProposal.jsx";
import IndividualProposalDetails from "./components/TeDaaSV3/Pages/IndividualProposalDetails/IndividualProposalDetails.jsx";
import DAODeploying from "./components/TeDaaSV3/Pages/DAOCreateForm/DAODeploying/DAODeploying.jsx";

const AppContext = lazy(() => import("./context/AppContext.js"));
const Modal = lazy(() => import("./components/commonComponents/Modal/Modal.jsx"));

function App() {
    const context = useContext(AppContext);

    return (
        <AllProviders>
        <div id="DashBoardContainer">
            {/* ================= main content ================= */}
            <div id="DashBoardPage">
                <Routes>
                    <Route path="/" element={<LandingPage />} />
                    <Route path="/demo" element={<DemoMode />} />
                    <Route path="/poc" element={<POC />} />
                    <Route path="/table" element={<TableV3 />} />
                    <Route path="/poccanvas1" element={<PocCanvas />} />
                    <Route path="/demo2" element={<DemoMode2 />} />
                    <Route
                        path="/global-table"
                        element={
                            <Table
                                headers={["Name", "Age", "Location", "Gender"]}
                                tableData={[
                                    ["John Doe", "30", "New York", "Male"],
                                    ["Jane Smith", "25", "San Francisco", "Female"],
                                    ["Sam Johnson", "40", "Chicago", "Male"],
                                ]}
                                width="80%"
                                height="400px"
                                checkBoxEnabled={true}
                                headerTextAlign="left"
                                bodyTextAlign="left"
                                headerFontSize="18px"
                                bodyFontSize="16px"
                                headerFontColor="blue"
                                bodyFontColor="darkgray"
                                tableHorizontalScroll={false}
                                tableVerticalScroll={true}
                                verticalScrollBarWidth="10px"
                                scrollBarColor="lightgray"
                                cellBorderColor="black"
                                cellBorderWidth="1px"
                                cellBorderRadius="5px"
                                borderCollapse="separate"
                                borderSpacing="5px"
                                tablePadding="10px"
                                cellPadding="8px"
                                headerBackgroundColor="lightblue"
                                bodyBackgroundColor="white"
                                showPaginationFooter={true}
                                rowsPerPage={20}
                            />
                        }
                    />
                    {/* <Route path="/test" element={<EditorGetData />} /> */}
                    {/* <Route path="/test" element={<Test />} /> */}

                        {/* ============ marco routes ============ */}
                        <Route path="/marco/*" element={<Marco />} />

                        {/* ============= new marco routes ============= */}
                        <Route path="/marcoV2/*" element={<MarcoV2 />}></Route>

                        {/* ============ Voxa routes ============== */}
                        <Route path="/voxaV2/*" element={<VoxaV2 />}></Route>

                        {/* ============ cxdelight routes ============ */}
                        <Route path="/cxdelight" element={<CXDelightHero />} />
                        <Route path="/Commerce" element={<CommerceHero />} />
                        {/* <Route path="/Subscription" element={<SubscriptionPage/>}/> */}

                        {/* =========== cx delight version 2 ========= */}
                        <Route path="/cxdelight-v2" element={<CXDelight />}>
                            <Route path="dashboard" element={<CXDashboard />} />
                            <Route path="design" element={<CXDesign />} />
                            <Route path="preview" element={<CXPreview />} />
                            <Route path="interaction" element={<CXInteraction />} />
                            <Route path="database" element={<CXDatabase />} />
                            <Route path="theme" element={<CXTheme />} />
                            <Route path="analytics" element={<CXAnalytics />} />
                            <Route path="settings" element={<CXSettings />} />
                            <Route path="cmsModal1" element={<Modal variant="AddToCmsModal" />} />
                            <Route path="cmsmodal2" element={<Modal variant="CmsModal" />} />
                            <Route path="themedcalendar" element={<Modal variant="CreateThemeCalendar" />} />
                        </Route>

                        {/* ============ sms routes ============ */}
                        <Route path="/sms" element={<SMS />}>
                            {/* ============ sms child components routes ============ */}
                            <Route path="landing" element={<SMSLandingPage />} />
                            <Route path="management" element={<SubscriptionManagement />}>
                                {/* ============ sms child components routes ============ */}
                                <Route path="subscribers" element={<SubscriptionManagementSubscribers />} />
                                <Route path="plans" element={<SubscriptionManagementPlans />} />
                            </Route>
                            <Route path="feedback" element={<Feedback />} />
                            <Route path="account" element={<Account />} />
                            <Route path="accessControl" element={<AccessControl />} />

                            <Route path="feedbackmanagement" element={<FeedbackManagement />} />
                        </Route>

                        {/* ============ sms-v2 routes ============ */}
                        <Route path="/smsV2" element={<SmsV2 />}>
                            {/* ============ sms child components routes ============ */}
                            <Route path="dashboard" element={<SMSDashboard />} />
                            <Route path="security" element={<SecurityMangementFlow />}>
                                <Route path="privacy-policy" element={<SecurityMangementPrivacyPolicy />} />
                                <Route path="" element={<SecurityNewPolicy />}>
                                    <Route path="loginproperty" element={<LoginProperty />} />
                                    <Route path="privacyandcompliances" element={<PrivacyCompliance />} />
                                    <Route path="authentication/*" element={<NewAuthentication />} />
                                </Route>
                                <Route path="processing-activity" element={<ProcessingActivity />} />
                            </Route>
                            <Route path="admin-setting" element={<SMSAdminSetting />} />
                            <Route path="subsmanagement" element={<SMSSubscriberManagement />}>
                                <Route path="alldevices" element={<AllDevices />} />
                                <Route path="plan" element={<Plan />} />
                                <Route path="deviceactivity" element={<DeviceActivity />} />
                                <Route path="subscriptionlifecycle" element={<SubsLifeCycle />} />
                            </Route>
                            <Route path="login" element={<LoginProperty />} />
                        </Route>

                        {/* ==================== TeDaaS V3 Routes ===================== */}
                        <Route path="/tedaasV3" element={<TeDaasV3 />}>
                            <Route path="dashboard" element={<TeDaaSDashboard />} />
                            <Route path="tokenDashboard" element={<TokenStudioDashboard />}></Route>
                            <Route path="daoDashboard" element={<DAOStudioDashboard />}></Route>
                            <Route path="createToken" element={<TokenCreation />}></Route>
                            <Route path="buildYourDAO" element={<DAOCreation />}>
                                <Route path="selectBlockchain" element={<SelectBlockchain />}></Route>
                                <Route path="describeYourDAO" element={<DescribeYourDAO />}></Route>
                                <Route path="defineMembership" element={<DefineMembership />}></Route>
                                <Route path="governanceSettings" element={<SelectGovernanceSettings />}></Route>
                                <Route path="deployYourDAO" element={<DeployYourDAO />}></Route>
                                <Route path="deploying" element={<DAODeploying />}></Route>
                            </Route>
                            <Route path="createProposal" element={<ProposalCreation />}>
                                <Route path="proposalMeta" element={<ProposalMetaData />}></Route>
                                <Route path="proposalVote" element={<ProposalVotingDuration />}></Route>
                                <Route path="setActions" element={<ProposalSetActions />}></Route>
                                <Route path="reviewProposal" element={<ProposalReviewProposal />}></Route>
                            </Route>
                            <Route path="manageToken/:address" element={<TokenManage />}></Route>
                            <Route path="runSimulation" element={<TokenomicsSimulation />}></Route>
                            <Route path="smartContract" element={<SmartContractLowCode />}></Route>
                            <Route path="dao/:daoAddress" element={<DAODetails />}></Route>
                            <Route path="proposal/:proposalAddress" element={<IndividualProposalDetails />}></Route>
                        </Route>

                        {/* ============ ITIL/ITSM routes ============ */}
                        <Route path="/itil" element={<ITILmain />}>
                            <Route path="dashboard" element={<ITILDashboard />}>
                                {/* ======================== ITIL dashboard child routes ===================== */}
                                <Route path="overview" element={<Overview />} />
                                <Route path="issues" element={<Issues />} />
                                <Route path="user-activity" element={<UserActivity />} />
                            </Route>
                            <Route path="SLA-management" element={<ITILSLAManagement />}>
                                {/* ======================== ITIL SLA Management child routes ======================= */}
                                <Route path="dashboard" element={<ITILSLAMDashboard />} />
                                <Route path="rules" element={<ITILSLAMRules />} />
                            </Route>
                            {/* <Route path="SLA-management/selectRule" element={<SLANewRule />} /> */}
                            <Route path="SLA-management/addRule" element={<ITILSLAMAddNewRule />} />
                            <Route path="support-issues" element={<ITILSupportIssues />} />
                            <Route path="support-issues/ticket" element={<ITILSupportIssuesTicketDetails />} />
                            <Route path="Version-control-dashboard" element={<ITILVersionControlDashboard />} />

                            <Route path="Version-released-info" element={<VersionReleasedInfo />} />
                            <Route path="rollouts" element={<RollOut />} />
                            <Route path="create" element={<CreateForm />} />
                        </Route>

                        {/* ============ ITIL/ITSM  version-2 routes ============ */}
                        <Route path="/itilV2" element={<ITILmainV2 />}>
                            <Route path="dashboard" element={<DashBoardITIL />} />
                            <Route path="board" element={<BoardITIL />} />
                            <Route path="tickets" element={<Tickets />} />
                            <Route path="status" element={<StatusPage />}>
                                <Route path="incidents" element={<StatusPageAllIncidents />}></Route>
                                <Route path="uptime" element={<StatusPageIncidentHistory />}></Route>
                                <Route path="detail" element={<StatusPageIncidentDetails />}></Route>
                            </Route>
                            <Route path="incident" element={<Incident />}>
                                <Route path="landing" element={<IncidentLanding />} />
                                <Route path="ticket" element={<IncidentTicketDetails />}>
                                    <Route path="details" element={<DetailsIncident />} />
                                    <Route path="affectedservices" element={<AffectedServices />} />
                                    <Route path="statuspage" element={<StatusPageIncident />} />
                                    <Route path="change" element={<ChangeIncident />} />
                                    <Route path="task" element={<TaskIncident />} />
                                    <Route path="merge" element={<MergeSheet />} />
                                    <Route path="linkissue" element={<LinkIssueIncident />} />
                                    <Route path="form" element={<FormIncident />} />
                                    <Route path="resolution" element={<ResolutionIncident />} />
                                    <Route path="activity" element={<ActivityIncident />} />
                                    <Route path="statusupdatemodal" element={<StatusUpdateModal />} />
                                    <Route path="statusdeletemodal" element={<StatusDeleteModal />} />
                                </Route>

                                <Route path="taskdetailed" element={<TaskDetailed />}>
                                    <Route path="taskdetails" element={<TaskDetails />} />
                                </Route>
                                <Route path="taskdetails" element={<TaskDetails />} />
                            </Route>
                            <Route path="changes" element={<ChangesITIL />} />

                            <Route path="knowledgeBase" element={<KnowledgeBase />}>
                                {/* ============ knowledgeBase child components routes ============ */}
                                <Route path="tabularView" element={<TabularView />}>
                                    <Route path="AllArticles" element={<AllArticles />} />
                                    <Route path="ApproveArticles" element={<ApproveArticles />} />
                                    <Route path="ArticlesUnderReview" element={<ArticlesUnderReview />} />
                                </Route>
                                <Route path="createArticle" element={<CreateArticle />} />
                                {/* <Route path="articleApprovePage" element={<ApproveArticlePage/>}/> */}
                                <Route path="ArticleView" element={<ArticleView />}>
                                    <Route path="content" element={<Content />} />
                                    <Route path="insight" element={<Insight />} />
                                    <Route path="Comment" element={<Comment />} />
                                    <Route path="activity" element={<Activity />} />
                                </Route>
                                <Route path="ArticleProp" element={<ArticleProperties />} />
                            </Route>

                            <Route path="assetManagement" element={<AssetManagement />} />
                            <Route path="project" element={<Project />} />
                            <Route path="development" element={<Development />} />
                            <Route path="admin" element={<KnowledgeBase />} />
                            <Route
                                path="progressbarcomponent"
                                element={<ProgressbarComponent percentage={13} color="#5E43D8" background="#EAECF0" />}
                            />
                            <Route path="deletearticlename" element={<DeleteArticleName />} />
                            <Route path="reportanddeletecomment" element={<ReportandDeleteComment />} />
                            <Route path="addcomment" element={<AddComment />} />
                            <Route path="versionhistroyeditcard" element={<VersionHistroyEditCard />} />
                        </Route>

                        {/* ============ Adwize routes ============ */}
                        <Route path="/adwize" element={<Adwize />}>
                            <Route path="inventory" element={<Inventory />}></Route>
                            <Route path="adUnit" element={<CreateNewAd />}></Route>
                            <Route path="dashboard" element={<AdwiseDashboard />}></Route>
                            <Route path="inventory"></Route>
                            <Route path="delivery">
                                <Route path="flow" element={<DeliveryFlow />} />
                                <Route path="orders" element={<Orders />} />
                            </Route>
                            <Route path="payment" />
                            <Route path="admin" element={<Admin />}>
                                <Route path="setting" element={<GlobalSettings />} />
                                <Route path="auth" element={<Authorisation />} />
                                <Route path="orders" element={<LinkedAccount />} />
                            </Route>
                            <Route path="adduser" element={<AddUser />} />
                            <Route path="addaccount" element={<AddAccount />} />
                        </Route>

                        {/* ============ Moscribe routes ============ */}
                        <Route path="/moscribe/*" element={<Moscribe />} />
                    </Routes>
                </div>
            </div>
        </AllProviders>
    );
}

export default App;
