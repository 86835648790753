import React, { useEffect, useState } from "react";
import css from "./DescribeYourDAO.module.scss";
import DAOStudioStepDescription from "../../../Components/UI/DAOStudioStepDescription/DAOStudioStepDescription";
import { useDispatch, useSelector } from "react-redux";
import { DAO_CREATION_REDUCER, SELECT_BLOCKCHAIN_OPTIONS } from "../../../Constants/Constants";
import DAOStudioSelectBlockchain from "../../../Components/UI/DAOStudioSelectBlockchain/DAOStudioSelectBlockchain";
import { Button, Input } from "antd";
import { daoCreationConfigureVal } from "../../../../../redux/actions/daoCreationAction";
import { Link, useNavigate } from "react-router-dom";
import uploadImg from "../../../TeDaaS-Assets/DaoCreate/upload.svg";
import generateAIImg from "../../../TeDaaS-Assets/DaoCreate/generateAI.svg";
import menuImg from "../../../TeDaaS-Assets/DaoCreate/menu.svg";
import { handleImageDrop } from "../../../Constants/reusableFunctions";
import { DeleteFilled } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import "./textAreaCss.css";
import { showSuccessToast, showWarningToast } from "../../../../../services/notificationService";
import { validateDaoCreationForm, validateDaoCreationFormPreviosSteps } from "../../../Constants/validateDaoCreationForm";

function DescribeYourDAO() {
    const navigateTo = useNavigate();
    const dispatch = useDispatch();
    const fetchedData = useSelector((state) => state.dc);
    const describe = fetchedData[DAO_CREATION_REDUCER.DESCRIBE];
    const [data, setData] = useState(describe);
    const [image, setImage] = useState(false);
    useEffect(() => {
        if (image) {
            document.getElementById("createTokenImageUploadInput").click();
            setImage(false);
        }
    }, [image]);
    const handleNext = () => {
        const { isValid, validatedData, next } = validateDaoCreationForm("describeYourDAO", data);
        if (isValid === true) {
            dispatch(daoCreationConfigureVal(DAO_CREATION_REDUCER.DESCRIBE, validatedData));
            navigateTo(next);
        }
    };
    const handleChange = (k, e, y = "") => {
        if (k === "icons") {
            handleImageDrop(handleChange, "logo", e, y);
            return;
        } else {
            setData({
                ...data,
                [k]: e.target.value,
            });
        }
    };
    const handleLinkChange = (key, value, index) => {
        let arr = [...data.links];
        arr[index][key] = value;
        handleChange("links", { target: { value: arr } });
    };
    const handleLinkRemove = (index) => {
        let arr = [];
        for (let i in data.links) {
            if (i != index) {
                arr.push(data.links[i]);
            }
        }
        handleChange("links", { target: { value: arr } });
    };
    useEffect(() => {
        const { isValid, next } = validateDaoCreationFormPreviosSteps("describeYourDAO", fetchedData);
        if (isValid) {
            dispatch(daoCreationConfigureVal("progress", 50));
        } else {
            navigateTo(next);
        }
    }, []);
    return (
        <div className={css.container}>
            <DAOStudioStepDescription
                title="Describe your DAO"
                description="Name and define your DAO so new contributors know they've come to the right place. This information is displayed on the DAO Explore page and can be changed with a vote."
                link="https://xpx.aidtaas.com/tedaasV3/dashboard"
            />
            <div className={css.body}>
                <div className={css.card}>
                    <div className={css.cardTop}>
                        <span className={css.label}>Name</span>
                    </div>
                    <div className={css.nameContainer}>
                        <input
                            value={data.daoName}
                            onChange={(e) => {
                                handleChange("daoName", e);
                            }}
                            type="text"
                            maxLength={128}
                            className={css.input}
                        />
                        <span className={css.length}>{data.daoName.length}/128</span>
                    </div>
                    <span className={css.note}>Maximum of 128 characters</span>
                </div>
                <div className={css.card}>
                    <div className={css.cardTop}>
                        <span className={css.label}>ENS Subdomain</span>
                    </div>
                    <div className={css.ensContainer}>
                        <input
                            value={data.ensSubdomain}
                            onChange={(e) => {
                                if (e.target.value === "") {
                                    handleChange("ensSubdomain", e);
                                }
                                if (/^[a-z0-9\-]+$/.test(e.target.value)) {
                                    handleChange("ensSubdomain", e);
                                }
                            }}
                            type="text"
                            maxLength={128}
                            className={css.input}
                        />
                        <span className={css.length}>.dao.eth</span>
                    </div>
                    <span className={css.note}>
                        This will be your DAO’s unique ENS subdomain, created automatically for you. Lowercase letters, numbers, and the dash '-' are
                        all acceptable characters; ideally, the character count should be under 128.
                    </span>
                </div>
                <div className={css.card}>
                    <div className={css.cardTop}>
                        <span className={css.label}>Logo</span>
                        <span className={css.optional}>{"(optional)"}</span>
                    </div>
                    <div className={`${css.logoContainer} ${data.logo === "" ? css.emptyLogoContainer : css.filledLogoContainer}`}>
                        {data.logo.length === 0 && (
                            <div
                                className={css.empty}
                                onDrop={(e) => {
                                    handleChange("icons", e, "drop");
                                }}
                                onDragOver={(e) => {
                                    e.preventDefault();
                                }}
                            >
                                <div className={css.iconContainer}>
                                    <img className={css.icon} src={uploadImg} alt="Upload" />
                                </div>
                                <div className={css.textContainer}>
                                    <span
                                        className={css.clickable}
                                        onClick={() => {
                                            setImage(true);
                                        }}
                                    >
                                        Click to upload
                                    </span>
                                    <span className={css.test}>or drag and drop</span>
                                </div>
                                <input
                                    id="createTokenImageUploadInput"
                                    type="file"
                                    style={{ display: "none" }}
                                    onChange={(e) => {
                                        handleChange("icons", e, "input");
                                    }}
                                />
                            </div>
                        )}
                        {data.logo.length > 0 && (
                            <div className={css.filled}>
                                <img className={css.logo} src={data.logo} alt={data.logo} />
                                <div className={css.deleteLogo}>
                                    <DeleteFilled
                                        onClick={() => {
                                            handleChange("logo", { target: { value: "" } });
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    <span className={css.note}>JPG, PNG or GIF of no more than 3MB. We recommend 1024x1024px.</span>
                </div>
                <div className={css.card}>
                    <div className={css.cardTop}>
                        <span className={css.label}>Description</span>
                        <div className={css.right}>
                            <div className={css.generateUsingAI}>
                                <img className={css.icon} src={generateAIImg} alt="Generate Using AI" />
                                <span>Generate using AI</span>
                            </div>
                        </div>
                    </div>
                    <div className={css.description}>
                        <TextArea
                            className={css.textArea}
                            value={data.description}
                            onChange={(e) => {
                                handleChange("description", e);
                            }}
                            autoSize
                        />
                    </div>
                    <span className={css.note}>
                        Describe your DAO's purpose in a few sentences. This is listed on the Explore page so new contributors can find you.
                    </span>
                </div>
                <div className={css.card}>
                    <div className={css.cardTop}>
                        <span className={css.label}>Links</span>
                        <span className={css.optional}>{"(optional)"}</span>
                    </div>
                    <div className={css.linksContainer}>
                        {data.links.map((link, i) => {
                            return (
                                <div key={i} className={css.links}>
                                    <Input
                                        className={css.input}
                                        placeholder="Lens, Discord etc."
                                        value={link.name}
                                        onChange={(e) => {
                                            handleLinkChange("name", e.target.value, i);
                                        }}
                                        type="text"
                                    />
                                    <Input
                                        className={css.input}
                                        value={link.url}
                                        placeholder="https://"
                                        onChange={(e) => {
                                            handleLinkChange("url", e.target.value, i);
                                        }}
                                        type="url"
                                    />
                                    <div
                                        className={css.iconContainer}
                                        onClick={() => {
                                            handleLinkRemove(i);
                                        }}
                                    >
                                        <img className={css.icon} src={menuImg} alt="Menu" />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <span className={css.note}>
                        Links to your DAO's website, social media profiles, Discord, or other places your community gathers.
                    </span>
                </div>
                <Button
                    type="default"
                    className={css.addLinkButton}
                    onClick={() => {
                        handleChange("links", { target: { value: [...data.links, { name: "", url: "" }] } });
                    }}
                >
                    Add Link
                </Button>
            </div>
            <div className={css.buttons}>
                <Link to={`/tedaasV3/buildYourDAO/selectBlockchain`}>
                    <Button type="default" className={css.button}>
                        {"<"} Back
                    </Button>
                </Link>
                <Button type="primary" className={css.button} onClick={handleNext}>
                    {"Next >"}
                </Button>
            </div>
        </div>
    );
}

export default DescribeYourDAO;
