import React, { useEffect, useState } from "react";
import { Modal, Switch } from "antd";
import { useDispatch, useSelector } from "react-redux";
import css from "./PauseModal.module.scss";
import "./swithCustomCss.css";
import burnImg from "../../../../TeDaaS-Assets/Modals/editPause.svg";
import closeImg from "../../../../TeDaaS-Assets/Nodes/close.svg";
import SliderWithButtons from "../../../UI/SliderWithButtons/SliderWithButtons";
import { tokenActionCloseModal } from "../../../../../../redux/actions/tokenCreationOptionsAction";
import { useParams } from "react-router-dom";
import { updateLoader } from "../../../../../../redux/actions/loaderAction";
import { useERC20TokenAction } from "../../../../Hooks/useERC20TokenAction";
import { showErrorToast, showSuccessToast } from "../../../../../../services/notificationService";

const PauseModal = ({ totalSupply }) => {
    const dispatch = useDispatch();
    const modelData = useSelector((state) => state.tco);
    const [pause, setPause] = useState(false);
    const { address } = useParams();
    const { isPaused, pauseUnpauseToken } = useERC20TokenAction();
    const handleOk = async () => {
        dispatch(updateLoader(true));
        const isPaused1 = await isPaused(address);
        if (isPaused1 == pause) {
            showSuccessToast(`Token is already ${pause ? "Paused" : "Unpaused"}`);
            dispatch(tokenActionCloseModal());
        } else {
            try {
                const result = await pauseUnpauseToken(address);
                showSuccessToast(`Token is ${pause ? "Paused" : "Unpaused"}`);
            } catch (e) {
                showErrorToast(`Token ${pause ? "pausing" : "unpausing"} failed`);
            }
            dispatch(tokenActionCloseModal());
        }
        dispatch(updateLoader(false));
    };
    const handleCancel = () => {
        dispatch(tokenActionCloseModal());
    };
    const fetchPausedStatus = async () => {
        const temp = await isPaused(address);
        setPause(temp);
    };
    useEffect(() => {
        fetchPausedStatus();
    }, []);
    return (
        <Modal
            centered
            title={
                <div className={css.header}>
                    <div className={css.headerLeft}>
                        <div className={css.headerTop}>
                            <img className={css.icon} src={burnImg} alt="Pause / Unpause" />
                            <span className={css.title}>Pause / Unpause Token</span>
                        </div>
                        <div className={css.iconContainer} onClick={handleCancel}>
                            <img className={css.icon} src={closeImg} alt="Close" />
                        </div>
                    </div>
                    <div className={css.subTitleContainer}>
                        <span className={css.subTitle}>Manage Action</span>
                        <span className={css.subTitle}>Current token supply: {totalSupply}</span>
                    </div>
                </div>
            }
            closeIcon={false}
            open={modelData.actionModals["Pause / Unpause"]}
            // className={css.main}
        >
            <div className={css.container}>
                <span className={css.title}>To pause the token, switch the toggle to active</span>
                <div className={css.slider}>
                    <div
                        className={css.input}
                        onClick={() => {
                            setPause(!pause);
                        }}
                    >
                        <span>Pause</span>
                        <Switch handleBg={"red"} className={css.swith} checked={pause} />
                    </div>
                    <span className={css.note}>The pausing operation can be used in case of a software vulnerability or a malicious attack</span>
                </div>
                <div className={css.buttons}>
                    <span className={css.cancel} onClick={handleOk}>
                        Cancel
                    </span>
                    <span className={css.submit} onClick={handleOk}>
                        Submit
                    </span>
                </div>
            </div>
        </Modal>
    );
};
export default PauseModal;
