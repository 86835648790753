// export const tenantId = "65ce0b89cc8a00000108c3d7";
// export const universeId = "65cf0796d21f82081b9bd5fa";
// export const accessToken="eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6ImZmOGYxNjhmLTNmZjYtNDZlMi1iMTJlLWE2YTdlN2Y2YTY5MCJ9.eyJwcm9maWxlVXJsIjoid3d3Lmdvb2dsZS5jb20vaW1hZ2VzL2F2aW5hc2gtcGF0ZWwtcm9ja3oiLCJyZWNlbnRfc2Vzc2lvbiI6Ik5BIiwic3ViIjoiZ2FpYW4uY29tIiwicGFyZW50VGVuYW50SWQiOiJOQSIsImNvbG9yIjpudWxsLCJ1c2VyX25hbWUiOiJtb2JpbGUxMEBnYXRlc3RhdXRvbWF0aW9uLmNvbSIsImlzcyI6ImdhaWFuLmNvbSIsImlzQWRtaW4iOnRydWUsInBsYXRmb3JtSWQiOiI2NWNlMGY5ODczOWQxMTBjNDRhYmM4Y2EiLCJ1c2VyTmFtZSI6Im1vYmlsZTEwQGdhdGVzdGF1dG9tYXRpb24uY29tIiwiYXV0aG9yaXRpZXMiOlsiUk9MRV9NQVJLRVRQTEFDRV9VU0VSIl0sImNsaWVudF9pZCI6ImdhaWFuIiwic2NvcGUiOlsidHJ1c3QiLCJyZWFkIiwid3JpdGUiXSwidGVuYW50SWQiOiI2NWNlMGI4OWNjOGEwMDAwMDEwOGMzZDciLCJsb2dvIjoid3d3Lmdvb2dsZS5jb20vaW1hZ2VzLiIsImV4cCI6MTcwODA0NTUwNiwianRpIjoiNzU5ZmRjZmUtZjE4MS00NTM0LTg3ZjMtZGNlY2JjZDZmNDliIiwiZW1haWwiOiJ4cHhuZXdhcGlzZXJ2aWNlM3Nwcm9kQGdhdGVzdGF1dG9tYXRpb24uY29tIn0.T7YRkMckChfip8kVWBsoYq3Ou5R79jeOzS-iy4Nd9UjciGDVDvqOv5deanxQfS-tCuwQ-5Dh_EnCP20Pz9AsWdVa6ALHmxewgQqBhKGsFieUlY9cT6o14OtHlN3oa3yhlIkS9J9s9uaLu1lkM52DhL8oqJ_MJ3GJyMU-jkPnCbKPuKdS3afX_1Qv2mR5GeP_Q9XtrBQzEt-6Y5OQu-vNpBcsWi9AYgaIgW9_ngxaWvhiX9b5VZw2-y01-n6VbYmzlh4cxE_yQj1YK8a6Wo7Z5oGRFGkv8asj5SDVyJ0AY4Zx5w5Ir2sQtpNEQzLvQdrIbLcm0-5_xQK1PRrSA9i-xg";
// export const voxaTemplateId = "65d5f1c822c26956e9267da4";
// export const voxaCampaignsId = "65d82b2b22c26956e9267e15";
// export const voxaCampaignsHistoryId = "65d5f30722c26956e9267da6";
// export const xpxVoxaEngagementExperienceSchemaId = "65fd14b77bd0d166a1817050";
// export const xpxVoxaTemplateSchemaId = "66028564178a5860c81f7a78";
// export const xpxVoxaCampaignSchemaId = "65fd555f7bd0d166a1817099";
// export const engagementsInstanceSchemaId = "65f7d56d5d1ff73e68ddad40";
// export const xpxMarcoWorkspaceSchemaId = "660e7b5b006fc5431d28aea3";

import { useState } from "react";

var accessTok =
    "eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICI3Ny1NUVdFRTNHZE5adGlsWU5IYmpsa2dVSkpaWUJWVmN1UmFZdHl5ejFjIn0.eyJleHAiOjE3MjI5NzU4MDMsImlhdCI6MTcyMjkzOTgwMywianRpIjoiNmRkYTlmNDItMjU3Ni00Y2ZjLTk4ODQtNjExZWU0NjE3NjgyIiwiaXNzIjoiaHR0cDovL2tleWNsb2FrLmtleWNsb2FrLnN2Yy5jbHVzdGVyLmxvY2FsOjgwODAvcmVhbG1zL21hc3RlciIsImF1ZCI6WyJCT0xUWk1BTk5fQk9UIiwiUEFTQ0FMX0lOVEVMTElHRU5DRSIsIkhPTEFDUkFDWSIsImFjY291bnQiXSwic3ViIjoiYmE3Nzg5NmYtN2MxZi00NTAxLWI0ZjYtNTQ3YTdkMjZkZGU2IiwidHlwIjoiQmVhcmVyIiwiYXpwIjoiTU9ORVQiLCJzZXNzaW9uX3N0YXRlIjoiNzk3NTBiODYtODQ2MS00YzlhLTgzYjAtOTM2ZWNmNjE5ZGJhIiwibmFtZSI6Ik1vYml1cyB4cHhAZ2FpYW5zb2x1dGlvbnMuY29tIiwiZ2l2ZW5fbmFtZSI6Ik1vYml1cyIsImZhbWlseV9uYW1lIjoieHB4QGdhaWFuc29sdXRpb25zLmNvbSIsInByZWZlcnJlZF91c2VybmFtZSI6InBhc3N3b3JkX3RlbmFudF94cHhAZ2FpYW5zb2x1dGlvbnMuY29tIiwiZW1haWwiOiJwYXNzd29yZF90ZW5hbnRfeHB4QGdhaWFuc29sdXRpb25zLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJhY3IiOiIxIiwicmVhbG1fYWNjZXNzIjp7InJvbGVzIjpbImRlZmF1bHQtcm9sZXMtbWFzdGVyIiwib2ZmbGluZV9hY2Nlc3MiLCJ1bWFfYXV0aG9yaXphdGlvbiJdfSwicmVzb3VyY2VfYWNjZXNzIjp7IkJPTFRaTUFOTl9CT1QiOnsicm9sZXMiOlsiQk9MVFpNQU5OX0JPVF9VU0VSIl19LCJQQVNDQUxfSU5URUxMSUdFTkNFIjp7InJvbGVzIjpbIlBBU0NBTF9JTlRFTExJR0VOQ0VfVVNFUiJdfSwiSE9MQUNSQUNZIjp7InJvbGVzIjpbIkhPTEFDUkFDWV9VU0VSIl19LCJhY2NvdW50Ijp7InJvbGVzIjpbIm1hbmFnZS1hY2NvdW50IiwibWFuYWdlLWFjY291bnQtbGlua3MiLCJ2aWV3LXByb2ZpbGUiXX19LCJzY29wZSI6InByb2ZpbGUgZW1haWwiLCJzaWQiOiI3OTc1MGI4Ni04NDYxLTRjOWEtODNiMC05MzZlY2Y2MTlkYmEiLCJ0ZW5hbnRJZCI6ImJhNzc4OTZmLTdjMWYtNDUwMS1iNGY2LTU0N2E3ZDI2ZGRlNiJ9.aLICxII7TJJTMzFnE9Shhjn_wxGHek_8cVCaFnX8U5igTqcWvMHnfHcJytSLUcrq7bVBO4y3gQsQCjJlWpe8yYTy5rQ4hp7O9DljJyM3vqEa_j2IwELUW3cpgQoJw4X68jX4zMbqNomIDYHQleA144k0jf0ZvsqDWaTTpFZIM505GP9771BfrQzm3EyNWz8pEPig_MtvnnTH4qMtBQrYW1CWkE4eueSwZcv18i8Gf9vSLJKV6v0woxfZtEv8XgLn6CwtbEasX_J3BofqBB0lV7_v-siQuReSCT1CWIyRgzthUp2zVFg96vudQzMYCJtQosKmtBzYiM5mW3ZPdyWnlQ";
export const tenantId = "ba77896f-7c1f-4501-b4f6-547a7d26dde6";
export const universeId = "66b210543924ca50372c3fb1";
export const accessToken = accessTok;
export const changeAccessToken = (tok) => {
    accessTok = tok;
};
export const voxaTemplateId = "65d5f1c822c26956e9267da4";
export const voxaCampaignsId = "65d82b2b22c26956e9267e15";
export const voxaCampaignsHistoryId = "65d5f30722c26956e9267da6";
export const xpxVoxaEngagementExperienceSchemaId = "65fd14b77bd0d166a1817050";
export const xpxVoxaTemplateSchemaId = "66028564178a5860c81f7a78";
export const xpxVoxaCampaignSchemaId = "65fd555f7bd0d166a1817099";
export const engagementsInstanceSchemaId = "65f7d56d5d1ff73e68ddad40";
export const xpxMarcoWorkspaceSchemaId = "660e7b5b006fc5431d28aea3";
export const generatePortalAccessTokenPayload = {
    userName: "xpx@gaiansolutions.com",
    password: "Xpx@123456789",
    productId: "4e234190-9c1a-4b08-a90c-b75f2004f7dd",
    requestType: "USER",
    grantType: "PASSWORD",
};
