import React, { useEffect, useState } from "react";
import { Button, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import css from "./DeployYourDAOModal.module.scss";
import burnImg from "../../../../TeDaaS-Assets/Modals/editBurn.svg";
import closeImg from "../../../../TeDaaS-Assets/Nodes/close.svg";
import SliderWithButtons from "../../../UI/SliderWithButtons/SliderWithButtons";
import { tokenActionCloseModal } from "../../../../../../redux/actions/tokenCreationOptionsAction";
import { daoCreationConfigureVal } from "../../../../../../redux/actions/daoCreationAction";
import { useNavigate } from "react-router-dom";
import deployDaoImg from "../../../../TeDaaS-Assets/DaoCreate/deployDao.svg";
import useDeployContract from "../../../../Hooks/useDeployContract";
import { useDAOAction } from "../../../../Hooks/useDAOAction";
import { updateLoader } from "../../../../../../redux/actions/loaderAction";

const DeployYourDAOModal = () => {
    const dispatch = useDispatch();
    const [gasFees, setGasFees] = useState({
        estimatedFees: "0.0 MATIC",
        maxFees: "0.0 MATIC",
        totalFees: "0.0 MATIC",
        totalCost: "$0.00",
    });
    const modelData = useSelector((state) => state.dc.deployYourDAO);
    const navigateTo = useNavigate();
    const handleCancel = () => {
        dispatch(daoCreationConfigureVal("deployYourDAO", false));
    };
    const handleOk = async () => {
        dispatch(daoCreationConfigureVal("deployYourDAO", false));
        dispatch(daoCreationConfigureVal("approved", true));
        navigateTo("/tedaasV3/buildYourDAO/deploying");
    };
    const [deploy, setDeploy] = useState(true);
    return (
        <Modal
            centered
            title={
                <div className={css.header}>
                    <span className={css.title}>Deploy your DAO</span>
                    <div className={css.iconContainer} onClick={handleCancel}>
                        <img className={css.icon} src={closeImg} alt="Close" />
                    </div>
                </div>
            }
            closeIcon={false}
            open={modelData}
        >
            {deploy ? (
                <div className={css.container}>
                    <img className={css.icon} src={deployDaoImg} alt="Deploy Your DAO" />
                    <div className={css.flexColumnCenter}>
                        <span className={css.title}>Transaction Required</span>
                        <span className={css.subTitle}>You will need to sign a transaction in your connected wallet</span>
                    </div>
                    <Button
                        className={css.button}
                        type="primary"
                        onClick={() => {
                            setDeploy(false);
                        }}
                    >
                        Deploy DAO now
                    </Button>
                </div>
            ) : (
                <div className={css.container}>
                    <span className={css.subTitle} style={{ width: "100%", textAlign: "left" }}>
                        The fees below are the cost of using the selected blockchain
                    </span>
                    <div className={css.gasFeesContainer}>
                        <div className={css.flexColumn4px}>
                            <div className={css.flexRowBetween}>
                                <span className={css.estimatedGasFees}>Estimated Gas Fees</span>
                                <span className={css.font16_400}>{gasFees.estimatedFees}</span>
                            </div>
                            <div className={css.flexRowBetween}>
                                <span className={css.maxFee}>Max Fees</span>
                                <span className={css.font14_400}>{gasFees.maxFees}</span>
                            </div>
                        </div>
                        <div className={css.line}></div>
                        <div className={css.flexRowBetween}>
                            <span className={css.totalCost}>Total Cost</span>
                            <span className={css.font16_400}>{gasFees.totalFees}</span>
                        </div>
                        <div className={css.flexRowBetween}>
                            <span></span>
                            <span className={css.font14_400}>{gasFees.totalCost}</span>
                        </div>
                    </div>
                    <Button className={css.button} type="primary" onClick={handleOk}>
                        Approve Transaction
                    </Button>
                </div>
            )}
        </Modal>
    );
};
export default DeployYourDAOModal;
