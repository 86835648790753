import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import css from "./TokenDocsModal.module.scss";
import burnImg from "../../../../TeDaaS-Assets/Modals/editBurn.svg";
import closeImg from "../../../../TeDaaS-Assets/Nodes/close.svg";
import addressClose from "../../../../TeDaaS-Assets/Modals/close.svg";
import { tokenActionCloseModal } from "../../../../../../redux/actions/tokenCreationOptionsAction";
import { handleFileDrop } from "../../../../Constants/reusableFunctions";
import uploadImg from "../../../../TeDaaS-Assets/Modals/upload.svg";

const TokenDocsModal = ({totalSupply}) => {
    const dispatch = useDispatch();
    const modelData = useSelector((state) => state.tco);
    const handleOk = () => {
        dispatch(tokenActionCloseModal());
    };
    const handleCancel = () => {
        dispatch(tokenActionCloseModal());
    };
    const [data, setData] = useState([]);
    const [image, setImage] = useState(false);
    const handleChange = async (e, y = "") => {
        const res = await handleFileDrop(handleChange, "icon", e, y);
        if (res !== false) {
            setData([
                ...data,
                {
                    id: res.id,
                    name: res.name,
                    url: `https://cdn.gov-cloud.ai${res.info.cdnUrl}`,
                },
            ]);
        }
    };
    useEffect(() => {
        if (image) {
            document.getElementById("createTokenImageUploadInput").click();
            setImage(false);
        }
    }, [image]);
    return (
        <Modal
            centered
            title={
                <div className={css.header}>
                    <div className={css.headerLeft}>
                        <div className={css.headerTop}>
                            <img className={css.icon} src={burnImg} alt="Edit Token Docs" />
                            <span className={css.title}>Edit Token Docs</span>
                        </div>
                        <div className={css.iconContainer} onClick={handleCancel}>
                            <img className={css.icon} src={closeImg} alt="Close" />
                        </div>
                    </div>
                    <div className={css.subTitleContainer}>
                        <span className={css.subTitle}>Manage Action</span>
                        <span className={css.subTitle}>Current token supply: {totalSupply}</span>
                    </div>
                </div>
            }
            closeIcon={false}
            open={modelData.actionModals["Edit Token Docs"]}
        >
            <div className={css.container}>
                <span className={css.title}>Upload Token Documentation or Delete the previous documents.</span>
                <div className={css.slider}>
                    <span className={css.label}>Token Documents</span>
                    {data.length > 0 && (
                        <div className={css.addresses}>
                            {data.map((a, i) => {
                                return (
                                    <div className={css.address} key={i}>
                                        <span
                                            className={css.addressText}
                                            onClick={() => {
                                                window.open(a.url, "_blank");
                                            }}
                                        >
                                            {a.name}
                                        </span>
                                        <img
                                            className={css.addressIcon}
                                            src={addressClose}
                                            alt={a.id}
                                            onClick={() => {
                                                try{
                                                    setData(data.filter((ele) => ele.id !== a.id));
                                                }catch(e){
                                                    console.log("Error in Token Docs Modal", e);
                                                }
                                            }}
                                        />
                                    </div>
                                );
                            })}{" "}
                        </div>
                    )}
                    <div className={css.right}>
                        <div
                            className={css.iconContainerEmpty}
                            onDrop={(e) => {
                                handleChange("icons", e, "drop");
                            }}
                            onDragOver={(e) => {
                                e.preventDefault();
                            }}
                            onClick={() => {
                                setImage(true);
                            }}
                        >
                            <img className={css.icon} src={uploadImg} alt="Upload" />
                            <span className={css.text}>Upload file here</span>
                        </div>
                        <input
                            id="createTokenImageUploadInput"
                            type="file"
                            style={{ display: "none" }}
                            onChange={(e) => {
                                handleChange(e, "input");
                            }}
                        />
                    </div>
                </div>
                <div className={css.buttons}>
                    <span className={css.cancel} onClick={handleOk}>
                        Cancel
                    </span>
                    <span className={css.submit} onClick={handleOk}>
                        Submit
                    </span>
                </div>
            </div>
        </Modal>
    );
};
export default TokenDocsModal;
