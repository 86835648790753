import React ,{ Suspense, lazy, useEffect, useState } from "react";

// Importing loading component
const LoadingComponent = lazy(() => import("./components/shared/molecules/Loading/LoadingComponent.jsx"));
const AppState = lazy(() => import("./context/AppState.jsx"));
const DeployProvider = lazy(() =>
    import("./components/TeDaaSV3/Provider.jsx").then((module) => ({
        default: module.DeployProvider,
    })),
);

export default function AllProviders({ children }) {

    const [packages, setPackages] = useState({
        DndProvider: null,
        HTML5Backend: null,
        Provider: null,
        store: null,
        ContextProvider: null
    });
    const [packagesLoaded, setPackagesLoaded] = useState(false);
    // const [autoDemo, setAutoDemo] = useState(false);

    useEffect(() => {
        async function loadPackages() {
            const { DndProvider } = await import("react-dnd");
            const { HTML5Backend } = await import("react-dnd-html5-backend");
            const { Provider } = await import("react-redux");
            const { default: store } = await import("/src/redux/store.js");
            const { ContextProvider } = await import("./components/Marco/Context/MarcoContext.jsx");

            setPackages({
                DndProvider,
                HTML5Backend,
                Provider,
                store,
                ContextProvider
            });

            setPackagesLoaded(true);
        }

        loadPackages();
    }, []);

    const { DndProvider, HTML5Backend, Provider, store, ContextProvider } = packages;

    return (
        packagesLoaded ? <Suspense fallback={<LoadingComponent />}>
            <ContextProvider>
                <Provider store={store}>
                    <AppState>
                        <DeployProvider>
                            <DndProvider backend={HTML5Backend}>{children}</DndProvider>
                        </DeployProvider>
                    </AppState>
                </Provider>
            </ContextProvider>
        </Suspense> : <LoadingComponent />
    );
}
