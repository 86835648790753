import { createContext, useState } from "react";
import { useImmer } from "use-immer";

export const ContextApi = createContext();

export const SMSProvider = ({ children }) => {
    const [testName, setTestName] = useState("testing");
    const [selectedSubscriber, setSelectedSubscriber] = useState(null);
    const [newPoilicyId, setNewPoilicyId] = useState("");
    const [selectedOptions, setSelectedOptions] = useState({});

    const [processingActivity, setProcessingActivity] = useImmer({
        name: "",
        description: "",
        processedData: [
            {
                labal: "Identification data",
                desc: "eg company name, skills, job tittle, diplomas, etc.",
                value: false,
                key: "identificationData"   // From schema
            },
            {
                labal: "Personal data",
                desc: "e.g. lifestyle, family situation, etc.",
                value: false,
                key: "personalData"   // From schema
            },
            {
                labal: "Professional data",
                desc: "eg company name, skills, job tittle, diplomas, etc.",
                value: false,
                key: "professionalData"   // From schema
            },
            {
                labal: "Economic and financial data",
                desc: "eg, income, financial situation, tax situation, banking details, etc",
                value: false,
                key: "economicAndFinancialData"   // Manually assumed key since not provided in the schema
            },
            {
                labal: "Connection data",
                desc: "eg. IP address, logs, terminal and connection identifiers, timestamp, etc.",
                value: false,
                key: "connectionData"   // From schema
            },
            {
                labal: "Location data",
                desc: "e.g. movements, GPS data, GSM, etc.",
                value: false,
                key: "locationData"   // Manually assumed key since not provided in the schema
            },
            {
                labal: "Internet data",
                desc: "eg. IP address, logs, terminal and connection identifiers, timestamp, etc.",
                value: false,
                key: "internetData"   // Manually assumed key since not provided in the schema
            },
            {
                labal: "National identification number",
                desc: "e.g. movements, GPS data, GSM, etc.",
                value: false,
                key: "nationalIdentificationNumber"   // From schema
            },
            {
                labal: "Contact data",
                desc: "eg, email address, phone number, postal address, act.",
                value: false,
                key: "contactData"   // Manually assumed key since not provided in the schema
            },
            {
                labal: "Images & video data",
                desc: "e.g. video inotage or photography",
                value: false,
                key: "imagesAndVideoData"   // From schema
            },
            {
                labal: "Sensible data",
                desc: "eg, racial or ethnic origin, political opinions, religious or philosophical bellefs, trade union membershin, genetic data, biometric data, health cata, sex its or sexual orientation, criminal convictions and offenses",
                value: false,
                key: "sensibleData"   // From schema
            },
        ],
        dataSource: [
            {
                labal: "Online or paper form",
                desc: "fridividual filled in a form",
                value: false,
                key: "onlineOrPaperForm"   // From schema
            },
            {
                labal: "Newsletter subscription",
                desc: "Individual subscribed to our newsletter",
                value: false,
                key: "newsletterSubscription"   // From schema
            },
            {
                labal: "Sign up form",
                desc: "Individual signed up online",
                value: false,
                key: "signUpForm"   // From schema
            },
            {
                labal: "Commercial solicitation",
                desc: "Individual sent us a commercial solicitation",
                value: false,
                key: "commercialSolicitation"   // From schema
            },
            {
                labal: "Job application",
                desc: "Individual sent us a job application",
                value: false,
                key: "jobApplication"   // From schema
            },
            {
                labal: "Website or software visit",
                desc: "Individual browsed our website or our solution",
                value: false,
                key: "websiteOrSoftwareVisit"   // From schema
            },
            {
                labal: "Social Media",
                desc: "We've collected users social media profiles",
                value: false,
                key: "socialMedia"   // From schema
            },
            {
                labal: "Data enrichment tools",
                desc: "We've used tools to enrich personal data",
                value: false,
                key: "dataEnrichmentTools"   // From schema
            },
            {
                labal: "Database purchase",
                desc: "We've purchased a database containing personal data:",
                value: false,
                key: "databasePurchase"   // From schema
            },
            {
                labal: "Partner relationships",
                desc: "Individual filled in a payment checkout",
                value: false,
                key: "partnerRelationships"   // From schema
            },
            {
                labal: "Opensource data",
                desc: "We've collected data publicly accessible on the internet",
                value: false,
                key: "opensourceData"   // From schema
            },
            {
                labal: "Billing form",
                desc: "Individual filled in a payment checkout",
                value: false,
                key: "billingForm"   // From schema
            },
            {
                labal: "Chat & Email conversation",
                desc: "Individual contacted us directly by mail",
                value: false,
                key: "chatAndEmailConversation"   // From schema
            },
            {
                labal: "Video recording",
                desc: "Individual is recorded on camera",
                value: false,
                key: "videoRecording"   // From schema
            },
            {
                labal: "Third Party Service",
                desc: "We've collected data through the use of a third-party solution or service",
                value: false,
                key: "thirdPartyService"   // From schema
            },
            {
                labal: "Import via API",
                desc: "Import of personal data via API",
                value: false,
                key: "importViaAPI"   // From schema
            },
        ],
        legalBasis: [
            {
                labal: "Protection of vital interests",
                desc: "Data is necessary to protect someone's life",
                value: false,
                key: "protectionOfVitalInterests"   // From schema
            },
            {
                labal: "Public interest",
                desc: "Data is necessary for the performance of a mission of public interest",
                value: false,
                key: "publicInterest"   // From schema
            },
            {
                labal: "Legal and regulatory obligations",
                desc: "Data is necessary to comply with the law (not including contractual obligations)",
                value: false,
                key: "legalAndRegulatoryObligations"   // From schema
            },
            {
                labal: "Contractual duties",
                desc: "Data is necessary for the preparation or the execution contract with individuals",
                value: false,
                key: "contractualDuties"   // From schema
            },
            {
                labal: "Legitimate interest",
                desc: "Data is necessary for the pursuit of our legitimate interests or of a third party",
                value: false,
                key: "legitimateInterest"   // From schema
            },
            {
                labal: "Consent",
                desc: "Data is used for a specific purpose upon clear consent collected from individuals",
                value: false,
                key: "consent"   // From schema
            },
        ],
        dataSubjects: [
            {
                labal: "Customers",
                value: false,
                key: "customers"   // From schema
            },
            {
                labal: "Users",
                value: false,
                key: "users"   // From schema
            },
            {
                labal: "Subscribers",
                value: false,
                key: "subscribers"   // From schema
            },
            {
                labal: "Suppliers",
                value: false,
                key: "suppliers"   // From schema
            },
            {
                labal: "Job Applicants",
                value: false,
                key: "jobApplicants"   // From schema
            },
            {
                labal: "Employees",
                value: false,
                key: "employees"   // Manually assumed key since not provided in the schema
            },
            {
                labal: "Contracts",
                value: false,
                key: "contracts"   // Manually assumed key since not provided in the schema
            },
            {
                labal: "Prospects",
                value: false,
                key: "prospects"   // From schema
            },
            {
                labal: "Customers' end-users",
                value: false,
                key: "customersEndUsers"   // From schema
            },
        ],
        retentionDuration: [
            {
                labal: "Traceability measures",
                desc: "User access journaling (identifier, connection date and time, etc.)",
                value: false,
                key: "traceabilityMeasures"   // From schema
            },
        ],
    });
    
    

    const [securityPolicy, setSecurityPolicy] = useState([]);
    const [targetSection, setTargetSection] = useState("processingActivity");

    return (
        <ContextApi.Provider
            value={{
                testName,
                setTestName,
                processingActivity,
                setProcessingActivity,
                selectedSubscriber,
                setSelectedSubscriber,
                securityPolicy,
                setSecurityPolicy,
                targetSection,
                setTargetSection,
                newPoilicyId,
                setNewPoilicyId,
                selectedOptions, setSelectedOptions
            }}
        >
            {children}
        </ContextApi.Provider>
    );
};
