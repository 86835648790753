import React, { useEffect, useState } from "react";
import css from "./ParentNode.module.scss";
import { Handle, Position } from "@xyflow/react";
import dragImg from "../../../TeDaaS-Assets/Nodes/empty.svg";
import { useDrop } from "react-dnd";
import ParameterNode from "./ParameterNode/ParameterNode";
import { useDispatch, useSelector } from "react-redux";

import { CONFIGURE_TOKEN_OPTIONS } from "../../../Constants/Constants";
import { tokenCreationOpenModel, tokenCreationSetDrag } from "../../../../../redux/actions/tokenCreationOptionsAction";
import { configureToken, tokenCreationAssignee, tokenCreationReward } from "../../../../../redux/actions/tokenCreationAction";
import { showWarningToast } from "../../../../../services/notificationService";
import { generateArtifactNode, generateEdge, generateParentNode } from "../../../Constants/reusableFunctions";
import { tokenCreationUpdateEdges, tokenCreationUpdateNodes } from "../../../../../redux/actions/tokenCreationNodesAndEdgesAction";
import { v4 } from "uuid";

function ParentNode({ data }) {
    const [children, setChildren] = useState([]);
    const dispatch = useDispatch();
    const fetchedData = useSelector((state) => state.tc);
    const modelData = useSelector((state) => state.tco);
    const { nodes, edges } = useSelector((state) => state.tcne);
    const [{ isOver }, drop] = useDrop({
        accept: data.type,
        drop: (item, monitor) => {
            if (item.node.index === "pause" || item.node.index === "blacklist") {
                dispatch(
                    configureToken(item.node.index, {
                        can: true,
                    }),
                );
            } else if (item.node.type === "tokenConfiguration") {
                dispatch(tokenCreationOpenModel(item.node.index));
            } else if (item.node.type === "assignee") {
                if (data.id.indexOf("reward") === -1) {
                    const position = monitor.getClientOffset();
                    for (let i of nodes) {
                        if (i.id === `assignee${item.node.id}`) {
                            position.x = i.position.x;
                            position.y = i.position.y;
                        }
                    }
                    position.y = position.y + 200;
                    const artifactNode = generateArtifactNode("Cohort", "cohort", "assignee", data.id);
                    artifactNode.position = position;
                    const artifactEdge = generateEdge("assignee" + data.id, "cohort" + data.id);
                    dispatch(tokenCreationUpdateNodes([...nodes, artifactNode]));
                    dispatch(tokenCreationUpdateEdges([...edges, artifactEdge]));
                }
            } else if (item.node.type === "reward") {
                const position = monitor.getClientOffset();
                for (let i of nodes) {
                    if (i.id === `reward${item.node.id}`) {
                        position.x = i.position.x;
                        position.y = i.position.y;
                    }
                }
                position.y = position.y + 300;
                const artifactNode = generateArtifactNode("Context", "context", "reward", data.id);
                artifactNode.position = position;
                const artifactEdge = generateEdge("reward" + data.id, "context" + data.id);
                dispatch(tokenCreationUpdateNodes([...nodes, artifactNode]));
                dispatch(tokenCreationUpdateEdges([...edges, artifactEdge]));
            }
        },
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
        }),
    });
    useEffect(() => {
        if (data.name === "Assignee") {
            if (fetchedData.assignee[data.id.split("$")[0]]?.can) {
                setChildren([fetchedData.assignee[data.id.split("$")[0]].index]);
            } else {
                setChildren([]);
            }
        } else if (data.name === "Reward") {
            if (fetchedData.rewards[data.id]?.can) {
                setChildren([fetchedData.rewards[data.id].index]);
            } else {
                setChildren({});
            }
        } else {
            var ch = [];
            for (let i of CONFIGURE_TOKEN_OPTIONS) {
                if (fetchedData[i]?.can) {
                    ch.push(i);
                }
            }
            setChildren(ch);
        }
    }, [fetchedData]);
    return (
        <div className={css.container} ref={drop}>
            <Handle type="target" position={Position.Top} className={css.handle} />
            <Handle type="source" position={Position.Bottom} className={css.handle} />
            <Handle type="source" position={Position.Right} className={css.handle} />
            <div className={css.parentNode}>
                <div className={css.nodeName}>
                    <span className={css.name}>{data?.name}</span>
                </div>
                {children.length === 0 && (
                    <div className={css.body}>
                        <img className={css.icon} src={dragImg} alt="Drag and Drop here" />
                        <span className={css.content}>Drag and drop to configure</span>
                        <span className={css.content}>your token</span>
                    </div>
                )}
                {children.length !== 0 && (
                    <div
                        className={css.body2}
                        style={{ gridTemplateColumns: children.length === 1 ? "1fr" : children.length === 2 ? "1fr 1fr" : "1fr 1fr 1fr" }}
                    >
                        {Array.isArray(children) &&
                            children.map((c, i) => {
                                return <ParameterNode name={c} key={i} type={data?.name} id={data?.id} />;
                            })}
                    </div>
                )}
            </div>
        </div>
    );
}

export default ParentNode;
