import React, { useEffect, useState } from "react";
import DashboardHeader from "../../Components/Layout/DashboardHeader/DashboardHeader";
import css from "./DAOStudioDashboard.module.scss";
import NetworkInsightsCard from "../../Components/UI/NetworkInsightsCard/NetworkInsightsCard";
import profitLogo from "../../TeDaaS-Assets/TeDaaSDashboard/profitArrowHead.svg";
import lossLogo from "../../TeDaaS-Assets/TeDaaSDashboard/lossArrowHead.svg";
import DoughnutChartWithLegends from "../../Components/UI/DoughnutChartWithLegends/DoughnutChartWithLegends";
import gasPriceIcon from "../../TeDaaS-Assets/TeDaaSDashboard/gasPrice.svg";
import MultilineChartWithLegends from "../../Components/UI/MultilineChartWithLegends/MultilineChartWithLegends";
import Table from "../../Components/Layout/Table/Table";
import Search from "antd/es/input/Search";
import { FilterOutlined, PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateShowRightSideBar } from "../../../../redux/actions/rightSideBarAction";
import MultilineChart from "../../Components/Charts/MultilineChart/MultilineChart";
import { data } from "../../Constants/daoStudioData.js";
import CreateNewTokenModal from "../../Components/Modals/CreateNewTokenModal.jsx";
import { updateTopBarStyle } from "../../../../redux/actions/topbarActions.js";
import { tokenCreationOpenModel } from "../../../../redux/actions/tokenCreationOptionsAction.js";
import TokenomicsSimulationSearchParameter from "../../Components/UI/TokenomicsSimulationSearchParameter/TokenomicsSimulationSearchParameter.jsx";
import { updateLoader } from "../../../../redux/actions/loaderAction.js";
import { showErrorToast } from "../../../../services/notificationService.jsx";
import { getFilteredEntities } from "../../../../services/stf.js";
import { TEDAAS_SCHEMAS } from "../../Constants/Constants.js";

function DAOStudioDashboard() {
    const navigateTo = useNavigate();
    const dispatch = useDispatch();
    const modelData = useSelector((state) => state.tco);
    const fetchedData = useSelector((state) => state.tokenDataReducer);
    const userAddress = useSelector((state) => state.auth.userAddress);
    const [search, setSearch] = useState("");
    const [userDaoDetails, setUserDaoDetails] = useState([]);
    const fetchAllDAOs = async () => {
        dispatch(updateLoader(true));
        try {
            const userDaoRes = await getFilteredEntities(
                {
                    userAddress: userAddress,
                },
                TEDAAS_SCHEMAS.daoMemFollId,
                TEDAAS_SCHEMAS.accessToken,
            );
            const temp = [];
            for (let i of userDaoRes.entities) {
                const daoRes = await getFilteredEntities({ daoAddress: i.daoAddress }, TEDAAS_SCHEMAS.daoId, TEDAAS_SCHEMAS.accessToken);
                if (daoRes.entities.length > 0) {
                    const proposalRes = await getFilteredEntities(
                        { daoAddress: i.daoAddress },
                        TEDAAS_SCHEMAS.proposalId,
                        TEDAAS_SCHEMAS.accessToken,
                    );
                    let activeProposals = 0;
                    for (let j of proposalRes.entities) {
                        if (j.status === "Running") {
                            activeProposals++;
                        }
                    }
                    const totalMembers = await getFilteredEntities(
                        { daoAddress: i.daoAddress },
                        TEDAAS_SCHEMAS.daoMemFollId,
                        TEDAAS_SCHEMAS.accessToken,
                    );
                    temp.push({
                        ...daoRes.entities[0],
                        activeProposals: activeProposals,
                        totalProposals: proposalRes.entities.length,
                        createdAt: daoRes.entities[0].createdAt,
                        balance: 0,
                        totalMembers: totalMembers.entities.length,
                    });
                }
            }
            setUserDaoDetails(temp);
        } catch (e) {
            showErrorToast("Error in fetcing data");
        }
        dispatch(updateLoader(false));
    };
    useEffect(() => {
        dispatch(updateShowRightSideBar(false));
        dispatch(
            updateTopBarStyle(
                <div className={css.headerTitle}>
                    <span className={css.greyText}>MarketPlace /</span>
                    <span className={css.greyText}>&nbsp;TEDaaS /</span>
                    <span className={css.blackText}>&nbsp;DAO Studio</span>
                </div>,
                "#f5f6f7",
                "100%",
            ),
        );
    }, []);
    useEffect(() => {
        fetchAllDAOs();
    }, [userAddress]);
    return (
        <div className={css.container}>
            <div className={css.daoStudioBody}>
                <span className={css.title}>DAO Studio</span>
                <div className={css.cards}>
                    {data.daoInsights.map((c, i) => {
                        return <NetworkInsightsCard key={i} value={c.value} name={c.name} percent={c.percent} isProfit={c.isProfit} />;
                    })}
                </div>
                <div className={css.charts}>
                    <div className={`${css.chartContainer} ${css.totalBalance}`}>
                        <span className={css.totalBalanceName}>Treasury</span>
                        <div className={css.totalBalanceMid}>
                            <div className={css.totalBalanceMidTop}>
                                <span className={css.totalBalanceMidTopLeft}>$5,987.34</span>
                                <div className={`${css.totalBalanceMidTopRight} ${true ? css.gain : css.loss}`}>
                                    <img
                                        className={css.icon}
                                        src={true ? profitLogo : lossLogo}
                                        style={{ rotate: true ? "0deg" : "180deg" }}
                                        alt="Total Balance"
                                    />
                                    <span className={css.totalBalanceMidTopRightText}>+7.9%</span>
                                </div>
                            </div>
                            <span className={css.totalBalanceMidBottom}>Detailed volume trends & market liquidity over time.</span>
                        </div>
                        <div className={css.chart}>
                            <MultilineChart
                                series={data.treasuryBalance.series}
                                xLegends={data.treasuryBalance.xLegends}
                                postfix="K"
                                xAxisLine={false}
                                xAxisTick={false}
                            />
                        </div>
                    </div>
                    <div className={css.tokenAllocationAndGasPrice}>
                        <div className={`${css.chartContainer} ${css.tokenAllocation}`}>
                            <span className={css.tokenAllocationName}>Voting Power Distribution</span>
                            <div className={css.chart}>
                                <DoughnutChartWithLegends data={data.votingPowerDistribution} />
                            </div>
                        </div>
                        <div className={`${css.chartContainer} ${css.proposals}`}>
                            <span className={css.proposalsTitle}>Proposals</span>
                            <div className={css.proposalsCard}>
                                <div className={css.proposalsCardTop}>
                                    <div className={css.proposalsCardTopLeft}>
                                        <span className={css.proposalsCardTopLeftTitle}>Election</span>
                                        <span className={css.proposalsCardTopLeftText}>NeoOkgy for treasurer</span>
                                    </div>
                                    <div className={css.proposalsCardTopRight}>
                                        <div className={css.proposalsCardTopRightCard}>
                                            <span className={css.no}>N</span>
                                            <span className={css.text}>05</span>
                                        </div>
                                        <div className={css.proposalsCardTopRightCard}>
                                            <span className={css.yes}>Y</span>
                                            <span className={css.text}>3</span>
                                        </div>
                                    </div>
                                </div>
                                <div className={css.proposalsCardBottom}>
                                    <div className={css.progress} style={{ width: "80%" }}></div>
                                </div>
                            </div>
                            <div className={css.proposalsCard}>
                                <div className={css.proposalsCardTop}>
                                    <div className={css.proposalsCardTopLeft}>
                                        <span className={css.proposalsCardTopLeftTitle}>Investment</span>
                                        <span className={css.proposalsCardTopLeftText}>Invest in 100 ETH in Acme</span>
                                    </div>
                                    <div className={css.proposalsCardTopRight}>
                                        <div className={css.proposalsCardTopRightCard}>
                                            <span className={css.no}>N</span>
                                            <span className={css.text}>15</span>
                                        </div>
                                        <div className={css.proposalsCardTopRightCard}>
                                            <span className={css.yes}>Y</span>
                                            <span className={css.text}>73</span>
                                        </div>
                                    </div>
                                </div>
                                <div className={css.proposalsCardBottom}>
                                    <div className={css.progress} style={{ width: "85%" }}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`${css.chartContainer} ${css.tokenPerformance}`}>
                        <span className={css.tokenPerformanceName}>Total Balance</span>
                        <div className={css.chart}>
                            <MultilineChartWithLegends series={data.totalBalance.series} yAxisSplitLine={false} />
                        </div>
                    </div>
                </div>
                <div className={css.myTokens}>
                    <span className={css.title}>My DAOs</span>
                    <div className={css.myTokensRight}>
                        {/* <Search placeholder="Search..." className={css.search} /> */}
                        <TokenomicsSimulationSearchParameter search={search} placeholder="Search..." setSearch={setSearch} />
                        <Button className={css.filter}>
                            <FilterOutlined />
                            Filter
                        </Button>
                        <Button
                            className={css.createToken}
                            color="#5E43D8"
                            type="primary"
                            onClick={() => {
                                navigateTo("/tedaasV3/buildYourDAO/selectBlockchain");
                            }}
                        >
                            <PlusOutlined /> Create DAO
                        </Button>
                    </div>
                </div>
                <div className={css.table}>
                    <Table columnDefination={data.daos.columns} tableData={userDaoDetails} search={search} />
                </div>
            </div>
        </div>
    );
}

export default DAOStudioDashboard;
