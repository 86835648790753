import React, { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import styles from "./EmissionScheduleStackedAreaChart.module.scss";
import { useSelector } from "react-redux";
import { graphic } from "echarts";

const EmissionScheduleStackedAreaChart = () => {
    const tokenSimulationData = useSelector((state) => state.tsr);
    const fetchedData = useSelector((state) => state.tc);
    const assignee = tokenSimulationData.emission.assignee;
    let colors = [];
    Object.keys(tokenSimulationData.emission.colors).forEach((key) => {
        colors.push(tokenSimulationData.emission.colors[key]);
    });
    let maxLength = tokenSimulationData.emission.data.length;
    const [chartData, setChartData] = useState({});
    useEffect(() => {
        try {
            const colors = tokenSimulationData.emission.colors;
            const series = Object.keys(assignee).map((name) => ({
                name: assignee[name].name,
                type: "line",
                stack: "total",
                barWidth: "20px",
                color: tokenSimulationData.emission.colors[name],
                itemStyle: {
                    borderRadius: 0,
                    borderColor: "#fff",
                    borderWidth: 1,
                },
                smooth: true,
                showSymbol: false,
                areaStyle: {
                    opacity: 0.8,
                    color: new graphic.LinearGradient(0, 0, 0, 1, [
                        {
                            offset: 0,
                            color: tokenSimulationData.emission.colors[name],
                        },
                        {
                            offset: 1,
                            color: "#ffffff",
                        },
                    ]),
                },
                emphasis: {
                    focus: "series",
                    label: {
                        show: true,
                        fontSize: 12,
                    },
                },
                label: {
                    show: false,
                    position: "top",
                    verticalAlign: "middle",
                },
                data: Array.isArray(tokenSimulationData.emission.data) ? tokenSimulationData.emission.data.map((item) => item[name]) : [],
            }));

            const chartOptions = {
                title: {
                    text: "Emission Schedule",
                    left: "1%",
                    textStyle: {
                        fontFamily: "Manrope",
                    },
                    // left: '5%'
                },
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "cross",
                    },
                },
                legend: {
                    show: true,
                    orient: "vertical",
                    // align: 'vertical',
                    // itemGap: 15,
                    left: "right",
                    // right: '10%',
                },
                grid: {
                    left: 100,
                    right: 100,
                    top: 50,
                    bottom: 50,
                },
                yAxis: {
                    type: "value",
                    splitLine: {
                        show: false,
                    },
                    axisLine: {
                        lineStyle: {
                            color: "#98A2B3",
                        },
                    },
                    axisLabel: {
                        fontFamily: "Manrope",
                        fontSize: 14,
                        formatter: function (params) {
                            const suffixes = [
                                { threshold: 1_000_000_000_000, suffix: "t" },
                                { threshold: 1_000_000_000, suffix: "b" },
                                { threshold: 1_000_000, suffix: "m" },
                                { threshold: 1_000, suffix: "k" },
                            ];
                            for (const { threshold, suffix } of suffixes) {
                                if (params >= threshold) {
                                    return (params / threshold).toFixed(1) + suffix;
                                }
                            }
                            return params;
                        },
                    },
                },
                xAxis: {
                    type: "category",
                    data: Array.isArray(tokenSimulationData.emission.data) ? tokenSimulationData.emission.data.map((ele) => ele.month) : [],
                    axisLine: {
                        lineStyle: {
                            color: "#98A2B3",
                        },
                    },
                    axisLabel: {
                        fontFamily: "Manrope",
                        fontSize: 14,
                    },
                },
                series: series,
            };

            setChartData(chartOptions);
        } catch (e) {
            console.log(e);
        }
    }, [tokenSimulationData.emission, fetchedData.assignee]);

    return (
        <div className={styles.container}>
            <ReactECharts option={chartData} style={{ height: "var(--TClamp330px)", width: "var(--TClamp840px)" }} />
        </div>
    );
};

export default EmissionScheduleStackedAreaChart;
