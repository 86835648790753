import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import css from "./ChangeOwnerModal.module.scss";
import warningImg from "../../../../TeDaaS-Assets/Modals/warning.svg";
import burnImg from "../../../../TeDaaS-Assets/Modals/editChangeOwner.svg";
import closeImg from "../../../../TeDaaS-Assets/Nodes/close.svg";
import { tokenActionCloseModal } from "../../../../../../redux/actions/tokenCreationOptionsAction";
import { useParams } from "react-router-dom";
import { useERC20TokenAction } from "../../../../Hooks/useERC20TokenAction";
import { updateLoader } from "../../../../../../redux/actions/loaderAction";
import { showErrorToast, showSuccessToast, showWarningToast } from "../../../../../../services/notificationService";

const ChangeOwnerModal = ({totalSupply}) => {
    const dispatch = useDispatch();
    const modelData = useSelector((state) => state.tco);
    const { address } = useParams();
    const [addresss, setAddresss] = useState("");
    const [verification, setVerification] = useState(false);
    const { changeTokenOwner, isValidAddress, checkBlacklist } = useERC20TokenAction();
    const handleOk = async () => {
        dispatch(updateLoader(true));
        const isValidAddresss = isValidAddress(addresss);
        if (isValidAddresss) {
            const isBlacklisted = await checkBlacklist(address, addresss);
            if(isBlacklisted==true){
                showErrorToast("Address given is blacklisted");
                dispatch(updateLoader(false));
                return;
            }
            const isOwnerChanged = await changeTokenOwner(address, addresss);
            if (isOwnerChanged === true) {
                showSuccessToast(address + " is now token owner");
                dispatch(tokenActionCloseModal());
            } else {
                setVerification(false);
                showErrorToast(addresss + " is not changed to owner");
            }
        } else {
            setVerification(false);
            showWarningToast(addresss + " is not a valid address");
        }
        dispatch(updateLoader(false));
    };
    const handleCancel = () => {
        dispatch(tokenActionCloseModal());
    };
    return (
        <Modal
            centered
            title={
                verification === false ? (
                    <div className={css.header}>
                        <div className={css.headerLeft}>
                            <div className={css.headerTop}>
                                <img className={css.icon} src={burnImg} alt="Change Owner" />
                                <span className={css.title}>Change Owner</span>
                            </div>
                            <div className={css.iconContainer} onClick={handleCancel}>
                                <img className={css.icon} src={closeImg} alt="Close" />
                            </div>
                        </div>
                        <div className={css.subTitleContainer}>
                            <span className={css.subTitle}>Manage Action</span>
                            <span className={css.subTitle}>Current token supply: {totalSupply}</span>
                        </div>
                    </div>
                ) : (
                    <div className={css.header}>
                        <div className={css.headerLeft}>
                            <div className={css.headerTop}></div>
                            <div className={css.iconContainer} onClick={handleCancel}>
                                <img className={css.icon} src={closeImg} alt="Close" />
                            </div>
                        </div>
                        <div className={css.headerRow1}>
                            <img className={css.icon} src={warningImg} alt="Are you sure?" />
                            <span className={css.text}>Are you sure?</span>
                        </div>
                        <span className={css.headerRow2}>If you renounce ownership, you will lose the ability to manage the token.</span>
                        <div className={css.buttons}>
                            <span className={css.cancel} onClick={handleCancel}>
                                Cancel
                            </span>
                            <span className={css.submit} onClick={handleOk}>
                                Understand, confirm
                            </span>
                        </div>
                    </div>
                )
            }
            closeIcon={false}
            open={modelData.actionModals["Change Owner"]}
        >
            {verification === false && (
                <div className={css.container}>
                    <span className={css.title}>Update the ownership of this token to a new address or entity.</span>
                    <div className={css.slider}>
                        <span className={css.label}>Owner</span>
                        <input
                            type="text"
                            placeholder="Enter address..."
                            className={css.input}
                            value={addresss}
                            onChange={(e) => {
                                setAddresss(e.target.value);
                            }}
                        />
                    </div>
                    <div className={css.buttons}>
                        <span className={css.cancel} onClick={handleCancel}>
                            Cancel
                        </span>
                        <span
                            className={css.submit}
                            onClick={() => {
                                if (addresss.length > 0) {
                                    setVerification(true);
                                }else {
                                    showWarningToast("Address is empty");
                                }
                            }}
                        >
                            Submit
                        </span>
                    </div>
                </div>
            )}
        </Modal>
    );
};
export default ChangeOwnerModal;
