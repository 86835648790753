import React, { useEffect, useState } from "react";
import css from "./MintTokenAction.module.scss";

import linkLogo from "../../../TeDaaS-Assets/DaoCreate/redirect.svg";
import dummyLogo from "../../../TeDaaS-Assets/DaoCreate/dummyLogo.svg";
import menuImg from "../../../TeDaaS-Assets/DaoCreate/menu.svg";
import { copyToClipboard, shortenAddress } from "../../../Constants/reusableFunctions";
import { UpOutlined } from "@ant-design/icons";
import { getFilteredEntities, getGroupAdhocata } from "../../../../../services/stf";
import { TEDAAS_SCHEMAS } from "../../../Constants/Constants";
import { useSelector } from "react-redux";

function MintTokenAction({
    mintAction = [
        {
            address: "0xFB388d01c5676bAD7AD443AeFBb8da39116368e0",
            tokens: 1,
        },
    ],
    daoAddress = "",
}) {
    const userAddress = useSelector((state) => state.auth.userAddress);
    const [data, setData] = useState({
        newTokens: 0,
        newHolders: 0,
        totalTokens: 0,
        totalHolders: 0,
        tokenSymbol: "BTC",
    });
    const fetchTokenDetails = async () => {
        // const tokenHoldersRes = await getFilteredEntities({ tokenAddress: tokenAddress }, TEDAAS_SCHEMAS.tokenHoldersId, TEDAAS_SCHEMAS.accessToken);
        const groupDefinition =
            "SELECT t_" +
            TEDAAS_SCHEMAS.daoMemFollId +
            "_t.`entity.userAddress`, t_" +
            TEDAAS_SCHEMAS.daoMemFollId +
            "_t.`entity.daoAddress`, t_" +
            TEDAAS_SCHEMAS.daoId +
            "_t.`entity.tokenAddress`, t_" +
            TEDAAS_SCHEMAS.tokenHoldersId +
            "_t.`entity.tokens`, t_" +
            TEDAAS_SCHEMAS.tokenId +
            "_t.`entity.tokenSymbol` FROM t_" +
            TEDAAS_SCHEMAS.daoMemFollId +
            "_t JOIN t_" +
            TEDAAS_SCHEMAS.daoId +
            "_t ON t_" +
            TEDAAS_SCHEMAS.daoMemFollId +
            "_t.`entity.daoAddress` = t_" +
            TEDAAS_SCHEMAS.daoId +
            "_t.`entity.daoAddress` JOIN t_" +
            TEDAAS_SCHEMAS.tokenHoldersId +
            "_t ON t_" +
            TEDAAS_SCHEMAS.daoId +
            "_t.`entity.tokenAddress` = t_" +
            TEDAAS_SCHEMAS.tokenHoldersId +
            "_t.`entity.tokenAddress` JOIN t_" +
            TEDAAS_SCHEMAS.tokenId +
            "_t ON t_" +
            TEDAAS_SCHEMAS.daoId +
            "_t.`entity.tokenAddress` = t_" +
            TEDAAS_SCHEMAS.tokenId +
            "_t.`entity.tokenAddress` WHERE t_" +
            TEDAAS_SCHEMAS.tokenHoldersId +
            "_t.`entity.userAddress` = t_" +
            TEDAAS_SCHEMAS.daoMemFollId +
            "_t.`entity.userAddress` AND t_" +
            TEDAAS_SCHEMAS.daoMemFollId +
            "_t.`entity.daoAddress` = '" +
            daoAddress +
            "';";

        const daoMembersRes = await getGroupAdhocata(groupDefinition, TEDAAS_SCHEMAS.accessToken);
        // const tokenHolders = tokenHoldersRes.entities;
        const daoMembers = daoMembersRes?.model?.data || [];
        let tt = 0;
        const tokenSymbol = daoMembers[0]?.entity?.tokenSymbol || "";
        let oldHolders = {};
        // for (let tokenHolder of tokenHolders) {
        //     tt += parseFloat(tokenHolder.tokens);
        //     oldHolders[tokenHolder.userAddress] = tokenHolder.deposit;
        // }
        for (let i of daoMembers) {
            tt += parseFloat(i.entity.tokens);
            oldHolders[i.entity.userAddress] = parseFloat(i.entity.tokens);
        }
        let newHolders = mintAction.length;
        let newTokens = 0;
        for (let mint of mintAction) {
            if (oldHolders[mint.address || mint.memberAddress] >= 0) {
                newHolders = newHolders - 1;
            }
            // console.log(mint.tokens);
            newTokens = newTokens + parseInt(mint.tokens);
            // tt += parseInt(mint.tokens);
        }
        // console.log(newTokens, newHolders, parseInt(tt + newTokens), tokenHolders.length + newHolders);
        setData({
            newTokens: newTokens,
            newHolders: newHolders,
            totalTokens: parseInt(tt + newTokens),
            totalHolders: daoMembers.length + newHolders,
            tokenSymbol: tokenSymbol,
        });
    };
    useEffect(() => {
        fetchTokenDetails();
    }, []);
    return (
        <div className={css.card}>
            <div className={css.flexColumnStart4px}>
                <div className={css.flexRowBetween}>
                    <span className={css.font1624}>Mint Tokens</span>
                    <div className={css.menuContainer}>
                        <img className={css.icon} src={menuImg} alt="Menu" />
                    </div>
                </div>
                <span className={css.font1422} style={{ paddingRight: "var(--TClamp32px)" }}>
                    Create a Proposal to mint more governance tokens. Select the wallets that should receive tokens and determine how many
                </span>
            </div>
            <div className={css.line}></div>
            <ul className={css.font1220} style={{ color: "#00000073", paddingLeft: "var(--TClamp16px)" }}>
                <li>The total token supply includes all tokens you have already minted</li>
            </ul>
            {Array.isArray(mintAction) &&
                mintAction.map((mint, i) => {
                    return (
                        <div className={css.members} key={i}>
                            <img className={css.icon48} src={dummyLogo} alt={"Member"} />
                            <span
                                className={css.font1420}
                                onClick={() => {
                                    copyToClipboard(mint.address || mint.memberAddress);
                                }}
                            >
                                {shortenAddress(mint.address || mint.memberAddress)}
                            </span>
                            <span
                                className={`${css.font1422} ${css.colorGrey}`}
                            >{`${mint.tokens} ${data.tokenSymbol} (${((mint.tokens / data.totalTokens) * 100).toFixed(2)}%)`}</span>
                            <div></div>
                            <img className={css.icon16} src={linkLogo} alt={"Link"} />
                        </div>
                    );
                })}
            <div className={css.card}>
                <div className={css.flexRowBetween}>
                    <span className={css.font1624} style={{ fontWeight: "400" }}>
                        Summary
                    </span>
                    <UpOutlined className={css.icon10} />
                </div>
                <div className={css.line}></div>
                <div className={css.flexColumnStart4px}>
                    <span className={`${css.font1422} ${css.colorGrey}`}>New Tokens</span>
                    <span className={css.font1422}>
                        +{data.newTokens} {data.depositymbol}
                    </span>
                </div>
                <div className={css.flexColumnStart4px}>
                    <span className={`${css.font1422} ${css.colorGrey}`}>New Holders</span>
                    <span className={css.font1422}>+{data.newHolders}</span>
                </div>
                <div className={css.flexColumnStart4px}>
                    <span className={`${css.font1422} ${css.colorGrey}`}>Total Tokens</span>
                    <span className={css.font1422}>
                        {data.totalTokens} {data.depositymbol}
                    </span>
                </div>
                <div className={css.flexColumnStart4px}>
                    <span className={`${css.font1422} ${css.colorGrey}`}>Total Holders</span>
                    <span className={css.font1422}>{data.totalHolders}</span>
                </div>
            </div>
        </div>
    );
}

export default MintTokenAction;
