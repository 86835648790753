export const TableColumns = [
    {
        title: "Txn Hash",
        index: "txHash",
        type: "transactionAddress",
    },
    {
        title: "Date & Time",
        index: "date",
        type: "date",
    },
    {
        title: "Transaction Type",
        index: "txType",
        type: "string",
    },
    {
        title: "From",
        index: "from",
        type: "address",
    },
    {
        title: "To",
        index: "to",
        type: "address",
    },
    {
        title: "Value",
        index: "value",
        type: "number",
    },
    {
        title: "Gas Fees",
        index: "gasFees",
        type: "number",
    },
    {
        title: "Status",
        index: "status",
        type: "status",
    },
];

export const TableData = [
    {
        from: "0xf606b4B10E0B6863165d0E8077e36Bf06e199123",
        to: "0x2d724339a0bdD740C207AD1d0bF5833590E23968",
        date: "1718013540063",
        txnHash: "0x70e1b7e841ca271174cf6890119413102df3c36c30c7215393542623cd799b39",
        txnType: "Contract Deployment",
        value: 50000000,
        gasFees: "2420.08 Gwei",
        status: "Success",
    },
    {
        id: "0x04249b0d20039f550d430c75b8db692bf9a21573d90f61608a8ddfeb07033319",
        from: "0xf606b4B10E0B6863165d0E8077e36Bf06e199123",
        to: "0x0Ce98c77E8ffB8Da3eea4dC5C985e53Ae0f670F8",
        tokenAddress: "0x2d724339a0bdD740C207AD1d0bF5833590E23968",
        date: "1718013540063",
        txnHash: "0x04249b0d20039f550d430c75b8db692bf9a21573d90f61608a8ddfeb07033319",
        txnType: "Change Owner",
        value: 0,
        gasFees: "2445.59 Gwei",
        status: "Success",
    },
    {
        id: "0xb20dfdbeb1b762b8c4fe0ef3cac2cc619fa1672af959b5c7eefa1890d7ea81da",
        from: "0xf606b4B10E0B6863165d0E8077e36Bf06e199123",
        to: "0x0Ce98c77E8ffB8Da3eea4dC5C985e53Ae0f670F8",
        tokenAddress: "0x2d724339a0bdD740C207AD1d0bF5833590E23968",
        date: "1718015414805",
        txnHash: "0xb20dfdbeb1b762b8c4fe0ef3cac2cc619fa1672af959b5c7eefa1890d7ea81da",
        txnType: "Change Owner",
        value: 0,
        gasFees: "1595.69 Gwei",
        status: "Success",
    },
    {
        id: "0x58a579af1a8722e6086aa00ad11ee2419b9de9abdf79273100d612a6a408aa77",
        from: "0x0Ce98c77E8ffB8Da3eea4dC5C985e53Ae0f670F8",
        to: "0xf606b4B10E0B6863165d0E8077e36Bf06e199123",
        tokenAddress: "0x2d724339a0bdD740C207AD1d0bF5833590E23968",
        date: "1718016577525",
        txnHash: "0x58a579af1a8722e6086aa00ad11ee2419b9de9abdf79273100d612a6a408aa77",
        txnType: "Change Owner",
        value: 0,
        gasFees: "2436.73 Gwei",
        status: "Success",
    },
    {
        id: "0x2ad4ef6ddd64fe522a30174ced12d69e1d7a4286a4c7255d12eda8e2483ea629",
        from: "0xf606b4B10E0B6863165d0E8077e36Bf06e199123",
        to: "0x2d724339a0bdD740C207AD1d0bF5833590E23968",
        tokenAddress: "0x2d724339a0bdD740C207AD1d0bF5833590E23968",
        date: "1718019880916",
        txnHash: "0x2ad4ef6ddd64fe522a30174ced12d69e1d7a4286a4c7255d12eda8e2483ea629",
        txnType: "Paused Token",
        value: 0,
        gasFees: "2858.10 Gwei",
        status: "Success",
    },
    {
        id: "0xeb140bb0e68f9279e3d9a2af5b737f555619cf9b91b347b7ec9a09a06118aa4a",
        from: "0xf606b4B10E0B6863165d0E8077e36Bf06e199123",
        to: "0x2d724339a0bdD740C207AD1d0bF5833590E23968",
        tokenAddress: "0x2d724339a0bdD740C207AD1d0bF5833590E23968",
        date: "1718019932442",
        txnHash: "0xeb140bb0e68f9279e3d9a2af5b737f555619cf9b91b347b7ec9a09a06118aa4a",
        txnType: "Unpaused Token",
        value: 0,
        gasFees: "2618.13 Gwei",
        status: "Success",
    },
    {
        id: "0xe4ff8563f785e7533f42a5c99d144b03df3c1b91773b911610d2b47fada3f84b",
        from: "0xf606b4B10E0B6863165d0E8077e36Bf06e199123",
        to: "0x2d724339a0bdD740C207AD1d0bF5833590E23968",
        tokenAddress: "0x2d724339a0bdD740C207AD1d0bF5833590E23968",
        date: "1718020781897",
        txnHash: "0xe4ff8563f785e7533f42a5c99d144b03df3c1b91773b911610d2b47fada3f84b",
        txnType: "Paused Token",
        value: 0,
        gasFees: "2285.30 Gwei",
        status: "Success",
    },
    {
        id: "0x627449a6a097d38f9b9beed6deef0fcc22961b620563c929a1579c32e4c6f18b",
        from: "0xf606b4B10E0B6863165d0E8077e36Bf06e199123",
        to: "0x2d724339a0bdD740C207AD1d0bF5833590E23968",
        tokenAddress: "0x2d724339a0bdD740C207AD1d0bF5833590E23968",
        date: "1718020887300",
        txnHash: "0x627449a6a097d38f9b9beed6deef0fcc22961b620563c929a1579c32e4c6f18b",
        txnType: "Unpaused Token",
        value: 0,
        gasFees: "2235.38 Gwei",
        status: "Success",
    },
    {
        id: "0xd5e06169ef7b9ffa260902d2469158e7e0437ac300a544e45293b16a7ddc7a13",
        from: "0x0Ce98c77E8ffB8Da3eea4dC5C985e53Ae0f670F8",
        to: "0x2d724339a0bdD740C207AD1d0bF5833590E23968",
        tokenAddress: "0x2d724339a0bdD740C207AD1d0bF5833590E23968",
        date: "1718088580675",
        txnHash: "0xd5e06169ef7b9ffa260902d2469158e7e0437ac300a544e45293b16a7ddc7a13",
        txnType: "Stake Token",
        value: 5000,
        gasFees: "221.33 Gwei",
        status: "Success",
    },
    {
        id: "0x2f516555a4f3d3b570e67687acf02337c71b933c30bfbd0826404712f9a603c5",
        from: "0x0Ce98c77E8ffB8Da3eea4dC5C985e53Ae0f670F8",
        to: "0x2d724339a0bdD740C207AD1d0bF5833590E23968",
        tokenAddress: "0x2d724339a0bdD740C207AD1d0bF5833590E23968",
        date: "1718093623201",
        txnHash: "0x2f516555a4f3d3b570e67687acf02337c71b933c30bfbd0826404712f9a603c5",
        txnType: "Stake Token",
        value: 100000,
        gasFees: "1243.28 Gwei",
        status: "Success",
    },
];
