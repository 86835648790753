import React, { useEffect } from "react";
import css from "./SliderWithButtons.module.scss";
import { Slider } from "antd";
import minusImg from "../../../TeDaaS-Assets/Nodes/minus.svg";
import plusImg from "../../../TeDaaS-Assets/Nodes/plus.svg";

function SliderWithButtons({ data, setData, postfix = "%" }) {
    return (
        <div className={css.container}>
            <span className={css.title}>{data.label}</span>
            <div className={css.body}>
                <div className={css.sliderContainer}>
                    <div className={css.names}>
                        <span className={css.left}>
                            {data.minimum}
                            {postfix}
                        </span>
                        <span className={css.right}>
                            {data.maximum}
                            {postfix}
                        </span>
                    </div>
                    <Slider
                        value={data.value}
                        min={data.minimum}
                        max={data.maximum}
                        step={data.incrementSlider}
                        onChange={(e) => {
                            setData({ ...data, value: e });
                        }}
                    />
                </div>
                <div className={css.buttons}>
                    <div
                        className={css.iconContainer}
                        onClick={() => {
                            if (data.value > data.minimum) {
                            setData({ ...data, value: Number(data.value) - data.incrementSlider });
                            }
                        }}
                    >
                        <img className={css.icon} src={minusImg} alt="Decrease" />
                    </div>
                    <div className={css.value}>
                        {data.value}
                        {postfix}
                    </div>
                    <div
                        className={css.iconContainer}
                        onClick={() => {
                            if (data.value < data.maximum) {
                                console.log(Number(data.value), data.incrementSlider, Number(data.value) + data.incrementSlider)
                                setData({ ...data, value: Number(data.value) + data.incrementSlider });
                            }
                        }}
                    >
                        <img className={css.icon} src={plusImg} alt="Increase" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SliderWithButtons;
