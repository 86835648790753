import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import css from "./ChangeStakingModal.module.scss";
import burnImg from "../../../../TeDaaS-Assets/Modals/editChangeStaking.svg";
import closeImg from "../../../../TeDaaS-Assets/Nodes/close.svg";
import { tokenActionCloseModal } from "../../../../../../redux/actions/tokenCreationOptionsAction";
import SliderWithButtons from "../../../UI/SliderWithButtons/SliderWithButtons";

const ChangeStakingModal = ({totalSupply}) => {
    const dispatch = useDispatch();
    const modelData = useSelector((state) => state.tco);
    const handleOk = () => {
        dispatch(tokenActionCloseModal());
    };
    const handleCancel = () => {
        dispatch(tokenActionCloseModal());
    };
    const [apr, setApr] = useState({
        label: ``,
        maximum: 100,
        minimum: 0.1,
        incrementSlider: 0.1,
        incrementButton: 0.1,
        value: 18,
    });
    const [duration, setDuration] = useState({
        label: ``,
        maximum: 100,
        minimum: 1,
        incrementSlider: 1,
        incrementButton: 1,
        value: 12,
    });
    return (
        <Modal
            centered
            title={
                <div className={css.header}>
                    <div className={css.headerLeft}>
                        <div className={css.headerTop}>
                            <img className={css.icon} src={burnImg} alt="Change Staking APR" />
                            <span className={css.title}>Change Staking APR</span>
                        </div>
                        <div className={css.iconContainer} onClick={handleCancel}>
                            <img className={css.icon} src={closeImg} alt="Close" />
                        </div>
                    </div>
                    <div className={css.subTitleContainer}>
                        <span className={css.subTitle}>Manage Action</span>
                        <span className={css.subTitle}>Current token supply: {totalSupply}</span>
                    </div>
                </div>
            }
            closeIcon={false}
            open={modelData.actionModals["Change Staking APR"]}
        >
            <div className={css.container}>
                <span className={css.title}>Update the staking Annual Percentage Rate (APR) for tokens.</span>
                <div className={css.slider}>
                    <span className={css.label}>APR</span>
                    <SliderWithButtons data={apr} setData={setApr} />
                </div>
                <div className={css.slider}>
                    <span className={css.label}>Staking duration</span>
                    <SliderWithButtons data={duration} setData={setDuration} postfix="" />
                </div>
                <div className={css.buttons}>
                    <span className={css.cancel} onClick={handleOk}>
                        Cancel
                    </span>
                    <span className={css.submit} onClick={handleOk}>
                        Submit
                    </span>
                </div>
            </div>
        </Modal>
    );
};
export default ChangeStakingModal;
