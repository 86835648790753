import { switchCaseConstants } from "../../components/TeDaaSV3/Constants/Constants";
import { UPDATE_PAGE_NAME, UPDATE_PAGE_BACKGROUND, UPDATE_PAGE_WIDTH, UPDATE_TOP_BAR_STYLE } from "../../components/TeDaaSV3/Constants/Constants";

export const updatePageName = (pageName) => {
    return {
        type: UPDATE_PAGE_NAME,
        payload: pageName,
    };
};

export const updatePageBackground = (background) => {
    return {
        type: UPDATE_PAGE_BACKGROUND,
        payload: background,
    };
};

export const updatePageWidth = (width) => {
    return {
        type: UPDATE_PAGE_WIDTH,
        payload: width,
    };
};

export const updateTopBarStyle = (name, background, style="91.3vw") => {
    return {
        type: UPDATE_TOP_BAR_STYLE,
        payload: {
            [switchCaseConstants.PAGENAME]: name,
            [switchCaseConstants.BACKGROUND]: background,
            [switchCaseConstants.PAGEWIDTH]: style,
        }
    }
}