import React, { useEffect, useState } from "react";
import css from "./ArtifactNode.module.scss";
import { Handle, Position } from "@xyflow/react";
import cohortImg from "../../../TeDaaS-Assets/Nodes/cohort.svg";
import contextImg from "../../../TeDaaS-Assets/Nodes/context.svg";
import { useDispatch, useSelector } from "react-redux";
import ArtifactParameterNode from "./ArtifactParameterNode/ArtifactParameterNode";
import { useDrop } from "react-dnd";
import { tokenCreationSetDrag } from "../../../../../redux/actions/tokenCreationOptionsAction";
import { tokenCreationAssignee, tokenCreationReward } from "../../../../../redux/actions/tokenCreationAction";

function ArtifactNode({ data }) {
    const dispatch = useDispatch();
    const fetchedData = useSelector((state) => state.tc);
    const [artifacts, setArtifacts] = useState([]);
    useEffect(() => {
        if (data.index === "cohort") {
            if (fetchedData.assignee[data.id]?.cohorts) {
                setArtifacts(fetchedData.assignee[data.id].cohorts);
            } else {
                setArtifacts([]);
            }
        } else {
            if (fetchedData.rewards[data.id]?.contexts) {
                setArtifacts(fetchedData.rewards[data.id].contexts);
            } else {
                setArtifacts([]);
            }
        }
    }, [fetchedData.assignee, fetchedData.rewards]);
    const [{ isOver }, drop] = useDrop({
        accept: data.index + "s",
        drop: (item, monitor) => {
            if (data.index === "cohort" && fetchedData.assignee[data.id].cohorts.length === 0) {
                dispatch(tokenCreationAssignee({ ...fetchedData.assignee[data.id], cohorts: [...fetchedData.assignee[data.id].cohorts, item] }));
            } else if (data.index === "context") {
                dispatch(tokenCreationReward({ ...fetchedData.rewards[data.id], contexts: [...fetchedData.rewards[data.id].contexts, item] }));
            }
        },
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
        }),
    });
    return (
        <div className={css.mainContainer}>
            <Handle type="target" position={Position.Top} className={css.handle} />
            {/* <Handle type="source" position={Position.Right} className={css.handle} /> */}
            <div className={css.container}>
                <div className={css.customNode}>
                    <div className={css.iconContainer}>
                        <img className={css.icon} src={data.index === "cohort" ? cohortImg : contextImg} alt={data.name} />
                    </div>
                    <div className={css.nodeName}>
                        <span className={css.name}>{data.name}</span>
                    </div>
                </div>
                {artifacts.length === 0 && (
                    <div className={css.body}>
                        <span className={css.content} ref={drop}>
                            {data.index}
                        </span>
                    </div>
                )}
                {artifacts.length !== 0 && (
                    <div className={css.body2} ref={drop}>
                        {Array.isArray(artifacts) &&
                            artifacts.map((c, i) => {
                                return <ArtifactParameterNode data={c} key={i} id={data.id} type={data.index} />;
                            })}
                    </div>
                )}
            </div>
        </div>
    );
}

export default ArtifactNode;
