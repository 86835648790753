import React, { useEffect, useState } from "react";
import css from "./CreateProposalBodyHeader.module.scss";

import BoldIcon from "../../../TeDaaS-Assets/Porposal/BoldIcon.svg";
import ColorIcon from "../../../TeDaaS-Assets/Porposal/ColorIcon.svg";
import AlignLeftIcon from "../../../TeDaaS-Assets/Porposal/AlignLeft.png";
import AlignCenterIcon from "../../../TeDaaS-Assets/Porposal/AlignCenter.png";
import AlignRightIcon from "../../../TeDaaS-Assets/Porposal/AlignRight.png";

const CreateProposalBodyHeader = ({ style, setStyle }) => {
    const handleFontChange = (e) => {
        setStyle({
            ...style,
            fontSize: e.target.value,
        });
    };

    const handleBoldChange = (e) => {
        setStyle({
            ...style,
            fontWeight: style.fontWeight == 400 ? 800 : 400,
        });
    };

    const handleTextAlign = (value) =>{
        setStyle({
            ...style,
            textAlign:value
        })
    }

    const handleColorChange=(e)=>{
        setStyle({
            ...style,
            fontColor:e.target.value
        })
    }

    return (
        <div className={css.container}>
            <select onChange={handleFontChange} value={style.fontSize}>
                <option value="12px">12px</option>
                <option value="14px">14px</option>
                <option value="16px">16px</option>
                <option value="18px">18px</option>
                <option value="20px">20px</option>
                <option value="24px">24px</option>
                <option value="30px">30px</option>
                <option value="36px">36px</option>
                <option value="48px">48px</option>
            </select>
            <span className={css.separationLine}></span>
            <img src={BoldIcon} onClick={handleBoldChange} />
            <span className={css.separationLine}></span>
            <input type="color" name="color"  onChange={handleColorChange}/>
            <span className={css.separationLine}></span>
            <img className={css.textAlignIcon} src={AlignLeftIcon} onClick={()=> handleTextAlign("left")}/>
            <img className={css.textAlignIcon} src={AlignCenterIcon} onClick={()=>handleTextAlign("center")}/>
            <img className={css.textAlignIcon} src={AlignRightIcon} onClick={()=>handleTextAlign("right")}/>
        </div>
    );
};

export default CreateProposalBodyHeader;
