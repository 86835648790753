import React, { useEffect, useState } from "react";
import css from "./TokenCreation.module.scss";
import TokenNameCard from "../../Components/UI/TokenNameCard/TokenNameCard";
import TokenBuilder from "../../Components/UI/TokenBuilder/TokenBuilder";
import { useDispatch, useSelector } from "react-redux";
import { updateShowRightSideBar } from "../../../../redux/actions/rightSideBarAction";
import TokenDesignCanvas from "../../Components/Layout/TokenDesignCanvas/TokenDesignCanvas";
import { ReactFlowProvider } from "@xyflow/react";
import DashboardHeader from "../../Components/Layout/DashboardHeader/DashboardHeader";
import { useNavigate } from "react-router-dom";
import { updatePageBackground, updatePageName, updateTopBarStyle } from "../../../../redux/actions/topbarActions";
import { Button } from "antd";
import { validateTokenCreation } from "../../Constants/validateTokenCreation";
import { tokenCreation } from "../../../../redux/actions/tokenCreationAction";
import { data } from "../../Constants/tokenStudioData";

function TokenCreation() {
    const dispatch = useDispatch();
    const fetchedData = useSelector((state) => state.tc);
    const nodesData = useSelector((state) => state.tcne);
    const navigateTo = useNavigate();
    const [tokenNameInfo, setTokenNameInfo] = useState({
        tokenName: "Bitcoin",
        objective: "Inviting users to webinars on home automation trends",
        owner: "Assign owner",
        initialSupply: "$1.50",
        decimals: "$1.50",
    });
    const handleNext = () => {
        // const validate = validateTokenCreation(fetchedData);
        console.log(fetchedData);
        if (fetchedData.tokenType!=="") {
            navigateTo("/tedaasV3/runSimulation");
        }
    };
    useEffect(() => {
        if (!fetchedData.isNewToken) {
            navigateTo("/tedaasV3/tokenDashboard");
        } else {
            setTokenNameInfo({
                tokenName: fetchedData.tokenName,
                objective: fetchedData.objective,
                owner: fetchedData.owner,
                initialSupply: fetchedData.initialSupply,
                decimals: fetchedData.decimals,
            });
        }
        console.log(fetchedData);
        dispatch(updateShowRightSideBar(true));
        dispatch(
            updateTopBarStyle(
                <div className={css.flexRowBetween}>
                    <div className={css.headerTitle}>
                        <span className={css.greyText}>MarketPlace /</span>
                        <span className={css.greyText}> TEDaaS /</span>
                        <span className={css.greyText}> Token Studio /</span>
                        <span className={css.blackText}> New Token Creation </span>
                        <span className={css.draft}>Draft</span>
                    </div>
                    <Button type="primary" className={css.previewButton} onClick={handleNext}>
                        Preview
                    </Button>
                </div>,
                "f5f6f7",
            ),
        );
    }, [fetchedData]);
    return (
        <div className={css.mainContainer}>
            <ReactFlowProvider>
                <div className={css.body}>
                    <div className={css.left}>
                        <TokenNameCard
                            tokenName={tokenNameInfo.tokenName}
                            objective={tokenNameInfo.objective}
                            owner={tokenNameInfo.owner}
                            initialSupply={tokenNameInfo.initialSupply}
                            decimals={tokenNameInfo.decimals}
                        />
                        <TokenBuilder />
                    </div>
                    <div className={css.right}>
                        <TokenDesignCanvas />
                    </div>
                </div>
            </ReactFlowProvider>
        </div>
    );
}

export default TokenCreation;
