import { PROPOSAL_CREATION_REDUCER } from "../../components/TeDaaSV3/Constants/Constants";

export const proposalCreationConfigureVal = (name, data) => {
    return {
        type: PROPOSAL_CREATION_REDUCER.CONFIGURE_VAL,
        payload: data,
        key: name,
    };
};
export const proposalCreationReset = () => {
    return {
        type: PROPOSAL_CREATION_REDUCER.RESET,
    };
};
