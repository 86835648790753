import React, { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";

import styles from "./TokenMintVsBurnLineChart.module.scss";
import { useSelector } from "react-redux";

const TokenMintVsBurnLineChart = () => {
    const tokenSimulationData = useSelector((state) => state.tsr);
    const [options, setOptions] = useState(false);
    useEffect(() => {
        const mintScheduleData = tokenSimulationData.mint.mintScheduleData;
        const mintingIntervals = tokenSimulationData.mint.mintingScheduleTenure;
        const burnScheduleData = tokenSimulationData.burn.burnScheduleData;
        const burningIntervals = tokenSimulationData.burn.burningScheduleTenure;
        const TokenMintVsBurnLineChartData = {
            title: {
                text: "Mint Vs Burn Chart",
                left: "1%",
                padding: [0, 0, 0, 0],
            },
            legend: {
                show: true,
                right: "10%",
            },
            tooltip: {
                trigger: "axis",
                axisPointer: {
                    type: "cross",
                },
            },
            toolbox: {
                feature: {
                    dataZoom: {
                        show: true,
                        xAxisIndex: [0, 1],
                        yAxisIndex: [0, 1],
                        orient: "vertical",
                    },
                },
            },
            color: ["#9F84FD", "#7EC2FF"],
            textStyle: {
                fontFamily: "Manrope",
            },
            xAxis: [
                {
                    type: "category",
                    data: Array.from({ length: mintingIntervals }, (_, i) => i + 1),
                    name: "Minting Tenure->",
                    nameLocation: "center",
                    nameTextStyle: {
                        verticalAlign: "top",
                        padding: [20, 0, 0, 0],
                        fontSize: "14px",
                    },
                    axisLine: {
                        lineStyle: {
                            color: "#98A2B3",
                        },
                    },
                    axisTick: {
                        show: false,
                        lineStyle: {
                            color: "#D9D9D9",
                        },
                    },
                    axisLabel: {
                        fontFamily: "Manrope",
                        fontSize: 14,
                    },
                },
                {
                    type: "category",
                    position: "top",
                    data: Array.from({ length: mintingIntervals }, (_, i) => i + 1),
                    inverse: true,
                    name: "<- Burning Tenure",
                    nameLocation: "center",
                    nameTextStyle: {
                        verticalAlign: "top",
                        padding: [-40, 0, 0, 0],
                        fontSize: "14px",
                    },
                    axisLine: {
                        lineStyle: {
                            color: "#98A2B3",
                        },
                    },
                    axisTick: {
                        show: false,
                        lineStyle: {
                            color: "#D9D9D9",
                        },
                    },
                    axisLabel: {
                        fontFamily: "Manrope",
                        fontSize: 14,
                    },
                },
            ],
            yAxis: [
                {
                    type: "value",
                    name: "Mint (in thousands)",
                    splitLine: {
                        show: false,
                    },
                    axisLine: {
                        lineStyle: {
                            color: "#98A2B3",
                        },
                    },
                    axisLabel: {
                        fontFamily: "Manrope",
                        fontSize: 14,
                        formatter: function (params) {
                            const suffixes = [
                                { threshold: 1_000_000_000_000, suffix: "T" },
                                { threshold: 1_000_000_000, suffix: "B" },
                                { threshold: 1_000_000, suffix: "M" },
                                { threshold: 1_000, suffix: "K" },
                            ];
                            for (const { threshold, suffix } of suffixes) {
                                if (params >= threshold) {
                                    const formattedValue = (params / threshold).toFixed(1) + suffix;
                                    return formattedValue;
                                }
                            }

                            return params;
                        },
                    },
                    nameTextStyle: {
                        align: "center",
                        verticalAlign: "bottom",
                        padding: [0, 0, 50, 0],
                    },
                },
                {
                    type: "value",
                    position: "right",
                    inverse: true,
                    splitLine: {
                        show: false,
                    },
                    axisLine: {
                        lineStyle: {
                            color: "#98A2B3",
                        },
                    },
                    axisLabel: {
                        fontFamily: "Manrope",
                        fontSize: 14,
                        formatter: function (params) {
                            const suffixes = [
                                { threshold: 1_000_000_000_000, suffix: "T" },
                                { threshold: 1_000_000_000, suffix: "B" },
                                { threshold: 1_000_000, suffix: "M" },
                                { threshold: 1_000, suffix: "K" },
                            ];
                            for (const { threshold, suffix } of suffixes) {
                                if (params >= threshold) {
                                    const formattedValue = (params / threshold).toFixed(1) + suffix;
                                    return formattedValue;
                                }
                            }

                            return params;
                        },
                    },
                    nameTextStyle: {
                        align: "center",
                        verticalAlign: "bottom",
                        padding: [0, 0, 50, 0],
                    },
                },
            ],
            series: [
                {
                    name: "Mint",
                    data: mintScheduleData,
                    type: "line",
                    xAxisIndex: 0,
                    yAxisIndex: 0,
                    smooth: true,
                    cursor: "crosshair",
                    showSymbol: false,
                    tooltip: {
                        formatter: function (params) {
                            let tooltipContent = "";
                            params.forEach(function (param) {
                                const seriesName = param.seriesName;
                                const value = param.value;
                                const axisIndex = param.axisIndex;
                                const axisType = param.axisType;
                                if (axisType === "xAxis") {
                                    tooltipContent += "X: " + value + "<br/>";
                                } else {
                                    tooltipContent += seriesName + ": " + value + "<br/>";
                                }
                            });
                            return tooltipContent;
                        },
                    },
                    emphasis: {
                        focus: "series",
                    },
                    lineStyle: {
                        color: "#9F84FD",
                        shadowColor: "rgba(0, 0, 255, 0.5)",
                        shadowBlur: 20,
                        shadowOffsetY: 12,
                    },
                },
                {
                    name: "Burn",
                    data: burnScheduleData,
                    type: "line",
                    xAxisIndex: 1,
                    yAxisIndex: 1,
                    smooth: true,
                    cursor: "crosshair",
                    showSymbol: false,
                    tooltip: {
                        formatter: function (params) {
                            let tooltipContent = "";
                            params.forEach(function (param) {
                                const seriesName = param.seriesName;
                                const value = param.value;
                                const axisIndex = param.axisIndex;
                                const axisType = param.axisType;
                                if (axisType === "xAxis") {
                                    tooltipContent += "X: " + value + "<br/>";
                                } else {
                                    tooltipContent += seriesName + ": " + value + "<br/>";
                                }
                            });
                            return tooltipContent;
                        },
                    },
                    emphasis: {
                        focus: "series",
                    },
                    lineStyle: {
                        color: "#7EC2FF",
                        shadowColor: "rgba(8, 161, 242, 0.8)",
                        shadowBlur: 20,
                        shadowOffsetY: 12,
                    },
                },
            ],
        };
        setOptions(TokenMintVsBurnLineChartData);
    }, [tokenSimulationData.burn]);

    return <div className={styles.container}>{options !== false && <ReactECharts option={options} style={{ height: "var(--TClamp330px)" }} />}</div>;
};

export default TokenMintVsBurnLineChart;
