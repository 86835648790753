import React, { useEffect, useState } from "react";
// import css from "./DAOEditSettingsAction.module.scss";
import css from "../DAOReviewSettings/DAOReviewSettings.module.scss";
import { useSelector } from "react-redux";
import { Tag } from "antd";
import { getMinimumDurationFromDate } from "../../../../Constants/reusableFunctions";

function DAOEditSettingsAction({ proposalDao }) {
    const dao = useSelector((state) => state.dd);
    const updatedDao = { ...dao, ...proposalDao };
    // const updatedDao = useSelector((state) => state.pa.settings);
    useEffect(() => {}, [dao, updatedDao]);
    return (
        <div className={css.container}>
            {(dao.daoName !== updatedDao.daoName || dao.logo !== updatedDao.logo || dao.description !== updatedDao.description) && (
                <div className={css.card}>
                    <span className={css.title}>DAO</span>
                    <div className={css.line}></div>
                    <div className={css.flexColumn}>
                        <div className={css.flexRowStart}>
                            <span className={css.label}>Name</span> {updatedDao.daoName !== dao.daoName && <Tag className={css.tag}>Updated</Tag>}
                        </div>
                        <span className={css.value}>{updatedDao.daoName}</span>
                    </div>
                    <div className={css.flexColumn}>
                        <div className={css.flexRowStart}>
                            <span className={css.label}>Logo</span> {updatedDao.logo !== dao.logo && <Tag className={css.tag}>Updated</Tag>}
                        </div>
                        {updatedDao.logo ? (
                            <div className={css.logoContainer}>
                                <img className={css.logo} src={updatedDao.logo} alt={"Logo"} />
                            </div>
                        ) : (
                            <span className={css.value}>{"- -"}</span>
                        )}
                    </div>
                    <div className={css.flexColumn}>
                        <div className={css.flexRowStart}>
                            <span className={css.label}>Description</span>{" "}
                            {updatedDao.description !== dao.description && <Tag className={css.tag}>Updated</Tag>}
                        </div>
                        <span className={css.value}>{updatedDao.description}</span>
                    </div>
                </div>
            )}

            {(dao.supportThreeshold !== updatedDao.supportThreeshold ||
                dao.minimumParticipation !== updatedDao.minimumParticipation ||
                dao.minimumDuration !== updatedDao.minimumDuration ||
                dao.earlyExecution !== updatedDao.earlyExecution ||
                dao.voteChange !== updatedDao.voteChange) && (
                <div className={css.card}>
                    <span className={css.title}>Governance</span>
                    <div className={css.line}></div>
                    <div className={css.flexColumn}>
                        <div className={css.flexRowStart}>
                            <span className={css.label}>Support Threeshold</span>{" "}
                            {updatedDao.supportThreeshold !== dao.supportThreeshold && <Tag className={css.tag}>Updated</Tag>}
                        </div>
                        <span className={css.value}>{`>${updatedDao.supportThreeshold}%`}</span>
                    </div>
                    <div className={css.flexColumn}>
                        <div className={css.flexRowStart}>
                            <span className={css.label}>Minimum Participation</span>{" "}
                            {updatedDao.minimumParticipation !== dao.minimumParticipation && <Tag className={css.tag}>Updated</Tag>}
                        </div>
                        <span className={css.value}>{`>${updatedDao.minimumParticipation}%`}</span>
                    </div>
                    <div className={css.flexColumn}>
                        <div className={css.flexRowStart}>
                            <span className={css.label}>Minimum Duration</span>{" "}
                            {updatedDao.minimumDuration !== dao.minimumDuration && <Tag className={css.tag}>Updated</Tag>}
                        </div>
                        <span className={css.value}>{getMinimumDurationFromDate(updatedDao.minimumDuration)}</span>
                    </div>
                    <div className={css.flexColumn}>
                        <div className={css.flexRowStart}>
                            <span className={css.label}>Early Execution</span>{" "}
                            {updatedDao.earlyExecution !== dao.earlyExecution && <Tag className={css.tag}>Updated</Tag>}
                        </div>
                        <span className={css.value}>{updatedDao.earlyExecution ? "Yes" : "No"}</span>
                    </div>
                    <div className={css.flexColumn}>
                        <div className={css.flexRowStart}>
                            <span className={css.label}>Vote Change</span>{" "}
                            {updatedDao.voteChange !== dao.voteChange && <Tag className={css.tag}>Updated</Tag>}
                        </div>
                        <span className={css.value}>{updatedDao.voteChange ? "Yes" : "No"}</span>
                    </div>
                </div>
            )}

            {(dao.proposalCreation !== updatedDao.proposalCreation || dao.minimumRequirement !== updatedDao.minimumRequirement) && (
                <div className={css.card}>
                    <span className={css.title}>Members</span>
                    <div className={css.line}></div>
                    <div className={css.flexColumn}>
                        <div className={css.flexRowStart}>
                            <span className={css.label}>Proposal Creation</span>{" "}
                            {updatedDao.proposalCreation !== dao.proposalCreation && <Tag className={css.tag}>Updated</Tag>}
                        </div>
                        <span className={css.value}>{updatedDao.proposalCreation == "members" ? "Members" : "Any Wallet"}</span>
                    </div>
                    <div className={css.flexColumn}>
                        <div className={css.flexRowStart}>
                            <span className={css.label}>Minimum Requirement</span>{" "}
                            {updatedDao.minimumRequirement !== dao.minimumRequirement && <Tag className={css.tag}>Updated</Tag>}
                        </div>
                        <span className={css.value}>{`≥${updatedDao.minimumRequirement} Tokens`}</span>
                    </div>
                </div>
            )}
        </div>
    );
}

export default DAOEditSettingsAction;
