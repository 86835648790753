import React, { useState } from "react";
import css from "./DAOReviewSettings.module.scss";
import { useSelector } from "react-redux";
import DAOStudioDeployCard from "../../../UI/DAOStudioDeployCard/DAOStudioDeployCard";
import DAOStudioDeployCardColumn from "../../../UI/DAOStudioDeployCard/DAOStudioDeployCardColumn/DAOStudioDeployCardColumn";
import dummyLogo from "../../../../TeDaaS-Assets/DaoCreate/dummyLogo.svg";
import { getMinimumDurationFromDate } from "../../../../Constants/reusableFunctions";
import { Button, Tag } from "antd";

function DAOReviewSettings({ dao }) {
    const updatedDao = useSelector((state) => state.pa.settings);
    return (
        <div className={css.mainContainer}>
            <Button className={css.button} type="default">
                Old Settings
            </Button>
            <Button className={css.button} type="primary">
                New Settings
            </Button>
            <div className={css.container}>
                <div className={css.card}>
                    <span className={css.title}>DAO</span>
                    <div className={css.line}></div>
                    <div className={css.flexColumn}>
                        <span className={css.label}>Name</span>
                        <span className={css.value}>{dao.daoName}</span>
                    </div>
                    <div className={css.flexColumn}>
                        <span className={css.label}>Logo</span>
                        {dao.logo ? (
                            <div className={css.logoContainer}>
                                <img className={css.logo} src={dao.logo} alt={"Logo"} />
                            </div>
                        ) : (
                            <span className={css.value}>{"- -"}</span>
                        )}
                    </div>
                    <div className={css.flexColumn}>
                        <span className={css.label}>Description</span>
                        <span className={css.value}>{dao.description}</span>
                    </div>
                </div>
                <div className={css.card}>
                    <span className={css.title}>Governance</span>
                    <div className={css.line}></div>
                    <div className={css.flexColumn}>
                        <span className={css.label}>Support Threeshold</span>
                        <span className={css.value}>{`>${dao.supportThreeshold}%`}</span>
                    </div>
                    <div className={css.flexColumn}>
                        <span className={css.label}>Minimum Participation</span>
                        <span className={css.value}>{`>${dao.minimumParticipation}%`}</span>
                    </div>
                    <div className={css.flexColumn}>
                        <span className={css.label}>Minimum Duration</span>
                        <span className={css.value}>{getMinimumDurationFromDate(dao.minimumDuration)}</span>
                    </div>
                    <div className={css.flexColumn}>
                        <span className={css.label}>Early Execution</span>
                        <span className={css.value}>{dao.earlyExecution ? "Yes" : "No"}</span>
                    </div>
                    <div className={css.flexColumn}>
                        <span className={css.label}>Vote Change</span>
                        <span className={css.value}>{dao.voteChange ? "Yes" : "No"}</span>
                    </div>
                </div>
                <div className={css.card}>
                    <span className={css.title}>Members</span>
                    <div className={css.line}></div>
                    <div className={css.flexColumn}>
                        <span className={css.label}>Proposal Creation</span>
                        <span className={css.value}>{dao.proposalCreation == "members" ? "Members" : "Any Wallet"}</span>
                    </div>
                    <div className={css.flexColumn}>
                        <span className={css.label}>Minimum Requirement</span>
                        <span className={css.value}>{`≥${dao.minimumRequirement} Tokens`}</span>
                    </div>
                </div>
            </div>
            <div className={css.container}>
                <div className={css.card}>
                    <span className={css.title}>DAO</span>
                    <div className={css.line}></div>
                    <div className={css.flexColumn}>
                        <div className={css.flexRowStart}>
                            <span className={css.label}>Name</span> {updatedDao.daoName !== dao.daoName && <Tag className={css.tag}>Updated</Tag>}
                        </div>
                        <span className={css.value}>{updatedDao.daoName}</span>
                    </div>
                    <div className={css.flexColumn}>
                        <div className={css.flexRowStart}>
                            <span className={css.label}>Logo</span> {updatedDao.logo !== dao.logo && <Tag className={css.tag}>Updated</Tag>}
                        </div>
                        {updatedDao.logo ? (
                            <div className={css.logoContainer}>
                                <img className={css.logo} src={updatedDao.logo} alt={"Logo"} />
                            </div>
                        ) : (
                            <span className={css.value}>{"- -"}</span>
                        )}
                    </div>
                    <div className={css.flexColumn}>
                        <div className={css.flexRowStart}>
                            <span className={css.label}>Description</span>{" "}
                            {updatedDao.description !== dao.description && <Tag className={css.tag}>Updated</Tag>}
                        </div>
                        <span className={css.value}>{updatedDao.description}</span>
                    </div>
                </div>
                <div className={css.card}>
                    <span className={css.title}>Governance</span>
                    <div className={css.line}></div>
                    <div className={css.flexColumn}>
                        <div className={css.flexRowStart}>
                            <span className={css.label}>Support Threeshold</span>{" "}
                            {updatedDao.supportThreeshold !== dao.supportThreeshold && <Tag className={css.tag}>Updated</Tag>}
                        </div>
                        <span className={css.value}>{`>${updatedDao.supportThreeshold}%`}</span>
                    </div>
                    <div className={css.flexColumn}>
                        <div className={css.flexRowStart}>
                            <span className={css.label}>Minimum Participation</span>{" "}
                            {updatedDao.minimumParticipation !== dao.minimumParticipation && <Tag className={css.tag}>Updated</Tag>}
                        </div>
                        <span className={css.value}>{`>${updatedDao.minimumParticipation}%`}</span>
                    </div>
                    <div className={css.flexColumn}>
                        <div className={css.flexRowStart}>
                            <span className={css.label}>Minimum Duration</span>{" "}
                            {updatedDao.minimumDuration !== dao.minimumDuration && <Tag className={css.tag}>Updated</Tag>}
                        </div>
                        <span className={css.value}>{getMinimumDurationFromDate(updatedDao.minimumDuration)}</span>
                    </div>
                    <div className={css.flexColumn}>
                        <div className={css.flexRowStart}>
                            <span className={css.label}>Early Execution</span>{" "}
                            {updatedDao.earlyExecution !== dao.earlyExecution && <Tag className={css.tag}>Updated</Tag>}
                        </div>
                        <span className={css.value}>{updatedDao.earlyExecution ? "Yes" : "No"}</span>
                    </div>
                    <div className={css.flexColumn}>
                        <div className={css.flexRowStart}>
                            <span className={css.label}>Vote Change</span>{" "}
                            {updatedDao.voteChange !== dao.voteChange && <Tag className={css.tag}>Updated</Tag>}
                        </div>
                        <span className={css.value}>{updatedDao.voteChange ? "Yes" : "No"}</span>
                    </div>
                </div>
                <div className={css.card}>
                    <span className={css.title}>Members</span>
                    <div className={css.line}></div>
                    <div className={css.flexColumn}>
                        <div className={css.flexRowStart}>
                            <span className={css.label}>Proposal Creation</span>{" "}
                            {updatedDao.proposalCreation !== dao.proposalCreation && <Tag className={css.tag}>Updated</Tag>}
                        </div>
                        <span className={css.value}>{updatedDao.proposalCreation == "members" ? "Members" : "Any Wallet"}</span>
                    </div>
                    <div className={css.flexColumn}>
                        <div className={css.flexRowStart}>
                            <span className={css.label}>Minimum Requirement</span>{" "}
                            {updatedDao.minimumRequirement !== dao.minimumRequirement && <Tag className={css.tag}>Updated</Tag>}
                        </div>
                        <span className={css.value}>{`≥${updatedDao.minimumRequirement} Tokens`}</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DAOReviewSettings;
