import React, { useEffect, useRef, useState } from "react";
import css from "./IndividualProposalDetails.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "antd";
import backImg from "../../TeDaaS-Assets/DaoCreate/back.svg";
import deployDaoImg from "../../TeDaaS-Assets/DaoCreate/deployDao.svg";
import { useDispatch, useSelector } from "react-redux";
import { UpOutlined } from "@ant-design/icons";
import { proposalCreationConfigureVal } from "../../../../redux/actions/proposalCreationAction";
import { updateTopBarStyle } from "../../../../redux/actions/topbarActions";
import { copyToClipboard, getVotingEndtTimeInString, shortenAddress } from "../../Constants/reusableFunctions";
import { updateLoader } from "../../../../redux/actions/loaderAction.js";
import { getFilteredEntities } from "../../../../services/stf";
import { DAO_DATA_REDUCER, TEDAAS_SCHEMAS } from "../../Constants/Constants";
import MintTokenAction from "../../Components/Layout/MintTokenAction/MintTokenAction.jsx";
import usePostDAOData from "../../Hooks/usePostDAOData.jsx";
import ProposalsInfo from "../../Components/UI/ProposalsInfo/ProposalsInfo.jsx";
import ProposalsVoters from "../../Components/UI/ProposalsVoters/ProposalsVoters.jsx";
import ProposalsBreakdown from "../../Components/UI/ProposalsBreakdown/ProposalsBreakdown.jsx";
import ProposalsVote from "../../Components/UI/ProposalsVote/ProposalsVote.jsx";
import { proposalDataConfigureVal } from "../../../../redux/actions/proposalDataAction.js";
import { useProposalAction } from "../../Hooks/useProposalAction.jsx";
import DAOEditSettingsAction from "../../Components/Layout/DAODetails/DAOEditSettingsAction/DAOEditSettingsAction.jsx";
import WithdrawAssetsAction from "../../Components/Layout/WithdrawAssetsAction/WithdrawAssetsAction.jsx";
import { daoDataConfigureVal } from "../../../../redux/actions/daoDataAction.js";

const IndividualProposalDetails = ({
    members = 5,
    supportThreeshold = 50,
    minimumParticipation = 15,
    strategy = "1 token -> 1 vote",
    tokenSymbol = "EXP",
}) => {
    const { proposalAddress } = useParams();
    const daoDetails = useSelector((state) => state.dd);
    const dispatch = useDispatch();
    const navigateTo = useNavigate();
    const { executeProposal } = useProposalAction();
    const signerAddress = useSelector((state) => state.auth.userAddress);
    const { updateProposal } = usePostDAOData();
    const [votingEndsIn, setVotingEndsIn] = useState("");
    const [votingOption, setVotingOption] = useState("info");
    const [voters, setVoters] = useState([]);
    const [daoMembers, setDaoMembers] = useState([]);
    const proposal = useSelector((state) => state.pd);
    // const [proposal, setProposal] = useState({});
    const [status, setStatus] = useState([]);
    const getProposals = async () => {
        // dispatch(updateLoader(true));
        const res = await getFilteredEntities({ proposalAddress: proposalAddress }, TEDAAS_SCHEMAS.proposalId, TEDAAS_SCHEMAS.accessToken);
        if (res.entities.length === 0) {
            navigateTo("/tedaasV3/daoDashboard");
        }
        if (daoDetails.daoAddress !== res.entities[0].daoAddress) {
            const daoRes = await getFilteredEntities({ daoAddress: res.entities[0].daoAddress }, TEDAAS_SCHEMAS.daoId, TEDAAS_SCHEMAS.accessToken);
            if (daoRes.entities.length === 0) {
                navigateTo("/tedaasV3/daoDashboard");
            }
            dispatch(daoDataConfigureVal(daoRes.entities[0]));
        }
        const proposalDetail = res.entities[0];
        const votersRes = await getFilteredEntities({ proposalAddress: proposalAddress }, TEDAAS_SCHEMAS.voteId, TEDAAS_SCHEMAS.accessToken);
        setVoters(votersRes.entities);
        // var keys = Object.keys(proposalDetail.history);
        var temp = [];
        // for (let i of keys) {
        //     var tempStatus = {
        //         name: i,
        //         status: i,
        //         time: String(new Date(parseInt(proposalDetail?.history[i]))),
        //     };
        //     temp.push(tempStatus);
        // }
        temp.push({
            name: "Published",
            status: "Published",
            time: String(new Date(parseInt(proposalDetail?.history.Published))),
        });
        var currentTime = new Date().getTime();
        var startTime = parseInt(proposalDetail.votingStartTime);
        var endTime = parseInt(proposalDetail.votingEndTime);
        if (currentTime > startTime) {
            temp.push({
                name: "Running",
                status: "Running",
                time: String(new Date(startTime)),
            });
        }
        if (proposalDetail.status === "succeded") {
            temp.push({
                name: "Succeded",
                status: "Succeded",
                time: String(new Date(endTime)),
            });
        } else if (proposalDetail.status === "executed") {
            temp.push({
                name: "Succeded",
                status: "Succeded",
                time: String(new Date(endTime)),
            });
            temp.push({
                name: "Executed",
                status: "Executed",
                time: String(new Date(parseInt(proposalDetail.history?.executed))),
            });
        } else if (currentTime > endTime) {
            temp.push({
                name: "Expired",
                status: "Expired",
                time: String(new Date(endTime)),
            });
        }
        const daoMembersRes = await getFilteredEntities(
            { daoAddress: daoDetails.daoAddress },
            TEDAAS_SCHEMAS.daoMemFollId,
            TEDAAS_SCHEMAS.accessToken,
        );
        console.log(proposalDetail);
        setDaoMembers(daoMembersRes.entities);
        setStatus(temp);
        // setProposal(proposalDetail);
        dispatch(proposalDataConfigureVal(proposalDetail));
        // dispatch(updateLoader(false));
    };
    useEffect(() => {
        getProposals();
        dispatch(
            updateTopBarStyle(
                <div className={css.headerTitle}>
                    <div
                        className={css.title}
                        onClick={() => {
                            navigateTo("/tedaasV3/dao/" + daoDetails.daoAddress);
                        }}
                    >
                        <img className={css.icon} src={backImg} alt="Back" />
                        <span className={css.text}>{"DAO Studio"}</span>
                    </div>
                </div>,
                "#ffffff",
                "100%",
            ),
        );
        const intervalId = setInterval(() => {
            const temp = getVotingEndtTimeInString(proposal?.votingEndTime || "");
            if (temp !== votingEndsIn) {
                setVotingEndsIn(temp);
            }
            if (temp === "") {
                clearInterval(intervalId);
            }
            getProposals();
        }, [1000]);
        return () => clearInterval(intervalId);
    }, []);
    return (
        <div className={css.mainContainer}>
            {proposal?.proposalAddress ? (
                <div className={css.container}>
                    <div className={css.flexColumnStart4px}>
                        <span className={css.font1624}>{proposal?.title}</span>
                        <div className={css.flexRowStart4}>
                            <span className={css.font1422}>Published by</span>
                            <span
                                className={css.link}
                                onClick={() => {
                                    copyToClipboard(proposal?.proposerAddress);
                                }}
                            >
                                {proposal?.proposerAddress == signerAddress ? "You" : shortenAddress(proposal?.proposerAddress)}
                            </span>
                        </div>
                    </div>
                    <div className={css.flexColumnStart4px}>
                        <span className={css.font1422} style={{ color: "#00000073" }}>
                            About
                        </span>
                        <span className={css.font1422}>{proposal?.description}</span>
                    </div>
                    <div className={css.body}>
                        <div className={css.flexColumnStart16px}>
                            <div className={css.card}>
                                <div className={css.flexRowBetween}>
                                    <span className={css.font1422}>Voting</span>
                                    <div className={css.flexRowCenter0px}>
                                        <span
                                            className={`${css.votingButton} ${votingOption == "breakdown" && css.activeVotingButton}`}
                                            onClick={() => {
                                                if (votingOption !== "breakdown") {
                                                    setVotingOption("breakdown");
                                                }
                                            }}
                                        >
                                            Breakdown
                                        </span>
                                        <span
                                            className={`${css.votingButton} ${votingOption == "voters" && css.activeVotingButton}`}
                                            onClick={() => {
                                                if (votingOption !== "voters") {
                                                    setVotingOption("voters");
                                                }
                                            }}
                                        >
                                            Voters
                                        </span>
                                        <span
                                            className={`${css.votingButton} ${votingOption == "info" && css.activeVotingButton}`}
                                            onClick={() => {
                                                if (votingOption !== "info") {
                                                    setVotingOption("info");
                                                }
                                            }}
                                        >
                                            Info
                                        </span>
                                    </div>
                                </div>
                                <div className={css.line}></div>
                                {votingOption === "voters" && <ProposalsVoters voters={voters} />}
                                {votingOption === "info" && (
                                    <ProposalsInfo
                                        members={daoMembers.length}
                                        minimumParticipation={daoDetails.minimumParticipation}
                                        proposal={proposal}
                                        voters={voters}
                                        tokenSymbol={tokenSymbol}
                                        supportThreeshold={daoDetails.supportThreeshold}
                                        strategy={strategy}
                                    />
                                )}
                                {votingOption === "breakdown" && (
                                    <ProposalsBreakdown tokenSymbol={tokenSymbol} voters={voters} daoMembers={daoMembers} />
                                )}
                                {votingEndsIn.length > 0 && <ProposalsVote votingEndsIn={votingEndsIn} proposal={proposal} />}
                                {voters.filter((vote) => vote.vote == "Yes").length > (daoDetails.supportThreeshold / 100) * daoMembers.length && (
                                    <Button
                                        type="primary"
                                        onClick={() => {
                                            executeProposal();
                                        }}
                                    >
                                        Execute
                                    </Button>
                                )}
                            </div>
                            <div className={css.card}>
                                <div className={css.flexRowBetween}>
                                    <span className={css.font1422}>Actions</span>
                                </div>
                                {proposal?.actions?.mint?.length > 0 && (
                                    <MintTokenAction mintAction={proposal.actions.mint} daoAddress={proposal.daoAddress} />
                                )}
                                {Object.keys(proposal?.actions?.settings || {}).length > 0 && (
                                    <DAOEditSettingsAction proposalDao={proposal.actions.settings} />
                                )}
                                {proposal.actions.withdraw.length > 0 && <WithdrawAssetsAction withdraw={proposal.actions.withdraw} />}
                            </div>
                        </div>
                        <div className={css.flexColumnStart16px}>
                            <div className={css.card}>
                                <div className={css.flexRowBetween}>
                                    <span className={css.font1422}>Resources (0)</span>
                                </div>
                                <div className={css.line}></div>
                                <div className={css.icon240Container}>
                                    <img className={css.icon240} src={deployDaoImg} alt="Resources" />
                                </div>
                            </div>
                            <div className={css.card}>
                                <div className={css.flexRowBetween}>
                                    <span className={css.font1422}>Status</span>
                                </div>
                                <div className={css.line}></div>
                                {status.map((s, i) => {
                                    return (
                                        <div className={css.flexColumnStart4px} key={i}>
                                            <span className={`${css.font1422} ${css[s.status]}`}>{s.name}</span>
                                            <span className={`${css.font1422} ${css.colorGrey}`}>{s.time}</span>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div></div>
            )}
        </div>
    );
};

export default IndividualProposalDetails;
