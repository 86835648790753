import React from "react";
import css from "./DAOStudioStepDescription.module.scss";

function DAOStudioStepDescription({ title = "", description = "", link = "" }) {
    return (
        <div className={css.container}>
            <span className={css.title}>{title}</span>
            <div className={css.description}>
                <span className={css.text}>{description}</span>
                <span
                    className={css.link}
                    onClick={() => {
                        window.open(link, "_blank");
                    }}
                >
                    Learn More
                </span>
            </div>
        </div>
    );
}

export default DAOStudioStepDescription;
