import React, { useState } from "react";
import css from "./DAOStudioDeployCardColumn.module.scss";
import { capitalizeFirstLetterOfString, copyToClipboard, shortenAddress } from "../../../../Constants/reusableFunctions.js";

function DAOStudioDeployCardColumn({ name, value, type="text", capitalize=true }) {
    const [isAddress, setIsAddress] = useState(false);
    return (
        <div className={css.container}>
            <span className={css.label}>{name}</span>
            {type === "text" && <span className={css.value}>{ capitalize ? capitalizeFirstLetterOfString(value): value}</span>}
            {type === "logo" && (
                <div className={css.logoContainer}>
                    <img className={css.logo} src={value} alt={name} />
                </div>
            )}
            {type === "links" && (
                <ul className={css.linksContainer}>
                    {value.map((link, index) => {
                        return (
                            <li
                                key={index}
                                className={`${css.value} ${css.pointer}`}
                                onClick={() => {
                                    window.open(link.url, "_blank");
                                }}
                            >
                                {link.url}
                            </li>
                        );
                    })}
                </ul>
            )}
            {type === "address" && (
                <div className={css.container}>
                    {!isAddress && (
                        <span
                            className={css.address}
                            onClick={() => {
                                setIsAddress(true);
                            }}
                        >
                            see {value.length} addresses
                        </span>
                    )}
                    {isAddress && (
                        <div className={css.container}>
                            {value.map((address, index) => {
                                return (
                                    <span
                                        key={index}
                                        className={`${css.value} ${css.pointer}`}
                                        onClick={() => {
                                            copyToClipboard(address);
                                        }}
                                    >
                                        {address?.address ? shortenAddress(address?.address) : shortenAddress(address)}
                                    </span>
                                );
                            })}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export default DAOStudioDeployCardColumn;
