import React from "react";
import css from "./DAOStudioProgress.module.scss";

function DAOStudioProgress({ title = "", description = "", progress = 0 }) {
    return (
        <div className={css.container}>
            <div className={css.header}>
                <span className={css.title}>{title}</span>
                <span className={css.description}>{description}</span>
            </div>
            {progress <= 100 && (
                <div className={css.progressContainer}>
                    <div className={css.progress}>
                        <div className={css.progressFill} style={{ width: `${progress}%` }}></div>
                    </div>
                    <span className={css.progressText}>{progress}%</span>
                </div>
            )}
        </div>
    );
}

export default DAOStudioProgress;
