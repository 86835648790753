export const data = {
    tokenInsights: [
        {
            value: "$958548.14",
            name: "Your Portfolio Value",
            percent: 3.67,
            isProfit: true,
        },
        {
            value: "$1520.80",
            name: "Total Asset",
            percent: 10.5,
            isProfit: false,
        },
        {
            value: 834,
            name: "No.of transactions",
            percent: 70.5,
            isProfit: true,
        },
        {
            value: "$12,768",
            name: "Total DAOs",
            percent: 70.5,
            isProfit: true,
        },
        {
            value: "20.8%",
            name: "Average return",
            percent: 70.5,
            isProfit: true,
        },
    ],

    tokenAllocation: [
        { value: 8, name: "UT", itemStyle: { color: "#E3704C" } },
        { value: 2, name: "GT", itemStyle: { color: "#94D0FF" } },
        { value: 20.7, name: "NFT", itemStyle: { color: "#FBC96C" } },
        { value: 69.3, name: "ST", itemStyle: { color: "#9A9AFF" } },
    ],
    tokenPerformance: {
        series: [
            {
                name: "UT",
                type: "line",
                stack: "Total",
                data: [0, 0.2, 0.5, 0.3, 0.7, 1, 0.9, 0.8],
                itemStyle: {
                    color: "#ABDE3A",
                },
            },
            {
                name: "GT",
                type: "line",
                stack: "Total",
                data: [0.5, 0.4, 0.5, 0.3, 0.7, 1, 0.9, 0.8],
                itemStyle: {
                    color: "#7EC2FF",
                },
            },
            {
                name: "ST",
                type: "line",
                stack: "Total",
                data: [0.2, 0.3, 0.4, 0.5, 0.3, 0.7, 1, 0.9],
                itemStyle: {
                    color: "#EB856B",
                },
            },
            {
                name: "NFT",
                type: "line",
                stack: "Total",
                data: [0.1, 0.15, 0.25, 0.5, 0.3, 0.4, 0.4, 0.7],
                itemStyle: {
                    color: "#EB856B",
                },
            },
        ],
        // yLegends: ["0", "125K", "250K", "375K", "500K"],
        xLegends: ["Aug", "Sep", "Oct", "Nov", "Dec", "Jan", "Feb", "Mar", "Apr", "May"],
    },
    tokens: {
        columns: [
            {
                title: "Token Symbol",
                index: "tokenSymbol",
                type: "stringWithIcon",
                subIndex: "name",
            },
            {
                title: "Token Name",
                index: "tokenName",
                type: "string",
            },
            {
                title: "Created on",
                index: "createdOn",
                type: "date",
            },
            {
                title: "Balance",
                index: "balance",
                type: "string",
            },
            {
                title: "Price ($)",
                index: "price",
                type: "stringDual",
                subIndex: "value",
            },
            {
                title: "Total Supply",
                index: "totalSupply",
                type: "string",
            },
            // {
            //     title: "Allocation",
            //     index: "allocation",
            //     type: "string",
            // },
            // {
            //     title: "Product",
            //     index: "product",
            //     type: "stringWithIcon",
            //     subIndex: "name",
            // },
            {
                title: "Manage",
                index: "action",
                type: "action",
            },
        ]
    },
    tokenBalance: {
        series: [
            {
                name: "Token Balance",
                type: "line",
                stack: "Total",
                smooth: true,
                data: [-10, 5, 10, 3, 0, -3, 4, 4, 5, 3, 5, 9, 10, 5, 3, 0, -3, -6, 5],
                itemStyle: {
                    color: "#EB856B",
                },
            },
        ],
        // yLegends: ["0", "125K", "250K", "375K", "500K"],
        xLegends: ["24Mar", "31Mar", "07Apr", "14Apr"],
    },
};
