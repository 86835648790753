import React, { useEffect, useState } from "react";
import css from "./DAODetailsFinanceTokens.module.scss";
import css2 from "../DAODetailsFinance.module.scss";
import dummyLogo from "../../../../../TeDaaS-Assets/proposalCreate/logo.svg";
import css3 from "../../DAODetailsMembers/DAODetailsMembers.module.scss";
import TokenomicsSimulationSearchParameter from "../../../../UI/TokenomicsSimulationSearchParameter/TokenomicsSimulationSearchParameter";
import { Button } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

function DAODetailsFinanceTokens({ dao, members, proposals, token, setPage }) {
    const [tokens, setTokens] = useState([]);
    const [search, setSearch] = useState("");
    const fetchTokenDetails = async () => {
        setTokens([token]);
    };
    useEffect(() => {
        // fetchTokenDetails();
    }, []);
    return (
        <div className={css3.container}>
            <div className={css3.body}>
                <div
                    className={`${css2.flexRowStart8px} ${css2.cursor}`}
                    style={{ width: "100%" }}
                    onClick={() => {
                        setPage("finance");
                    }}
                >
                    <LeftOutlined className={css2.icon10} />
                    <span className={css2.note}>Finance</span>
                    <span className={css2.note}>/</span>
                    <span className={css2.label}>Tokens</span>
                </div>
                <div className={css3.header}>
                    <div className={css3.headerLeft}>
                        <span className={css3.title}>All Tokens</span>
                        <span className={css3.daoType}>{`${tokens.length} Token${tokens.length > 1 ? "s" : ""}`}</span>
                    </div>
                    <div className={css3.headerRight}>
                        <Button type="primary" className={`${css3.button} ${css3.blueButton}`}>
                            {"+ New Transfer"}
                        </Button>
                    </div>
                </div>
                <div className={css3.navbar}>
                    <TokenomicsSimulationSearchParameter search={search} setSearch={setSearch} placeholder="Type to Search" />
                </div>
                {tokens.length > 0 && (
                    <div className={css2.flexColumn8px}>
                        {Array.isArray(tokens) &&
                            tokens.map((t, i) => {
                                return (
                                    <div className={`${css2.card} ${css2.flexRowBetween}`} key={i}>
                                        <div className={css2.flexRowStart12px}>
                                            <img className={css2.icon48} src={t?.logo || dummyLogo} alt="Logo" />
                                            <div className={css2.flexColumn2px}>
                                                <div className={css2.flexRowStart8px}>
                                                    <span className={css2.label}>{token.tokenName}</span>
                                                    <span className={css2.percent}>{"100%"}</span>
                                                </div>
                                                <span
                                                    className={css2.note}
                                                >{`${token.totalTokens} ${token.tokenSymbol} | $${parseFloat(token.price)}`}</span>
                                            </div>
                                        </div>
                                        <div className={css2.flexRowCenter12px}>
                                            <div className={css2.flexColumnEnd2px}>
                                                <span className={css2.label}>{`$${token.totalTokens * parseFloat(token.price)}`}</span>
                                            </div>
                                            <RightOutlined className={css2.icon10} />
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                )}
            </div>
        </div>
    );
}

export default DAODetailsFinanceTokens;
