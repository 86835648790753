import React, { useEffect, useRef } from "react";
import * as echarts from "echarts";
import css from "./MultilineChart.module.scss";

const MultilineChart = ({
    xLegends = [],
    series = [
        {
            name: "Email",
            type: "line",
            stack: "Total",
            data: [120, 132, 101, 134, 90, 230, 210],
        },
        {
            name: "Union Ads",
            type: "line",
            stack: "Total",
            data: [220, 182, 191, 234, 290, 330, 310],
        },
        {
            name: "Video Ads",
            type: "line",
            stack: "Total",
            data: [150, 232, 201, 154, 190, 330, 410],
        },
        {
            name: "Direct",
            type: "line",
            stack: "Total",
            data: [320, 332, 301, 334, 390, 330, 320],
        },
        {
            name: "Search Engine",
            type: "line",
            stack: "Total",
            data: [820, 932, 901, 934, 1290, 1330, 1320],
        },
    ],
    postfix = "",
    xAxisLine = true,
    xAxisTick = true,
    yAxisSplitLine = true,
}) => {
    const chartRef = useRef(null);

    useEffect(() => {
        const chartDom = chartRef.current;
        const myChart = echarts.init(chartDom);
        const option = {
            tooltip: {
                trigger: "axis",
            },
            legend: {
                show: false,
            },
            grid: {
                left: "3%",
                right: "4%",
                bottom: "3%",
                containLabel: true,
            },
            xAxis: {
                type: "category",
                boundaryGap: false,
                data: xLegends,
                axisLine: {
                    show: xAxisLine,
                },
                axisTick: {
                    show: xAxisTick,
                }
            },
            yAxis: {
                type: "value",
                axisLabel: {
                    formatter: "{value}" + postfix,
                },
                // axisLabel: {
                //     formatter: function (value) {
                //         return yLegends[value / 200];
                //     }
                // },
                splitLine: {
                    show: yAxisSplitLine,
                },
            },
            series: series,
        };

        myChart.setOption(option);

        return () => {
            myChart.dispose();
        };
    }, []);
    return <div ref={chartRef} className={css.container} />;
};

export default MultilineChart;
