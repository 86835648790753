import React, { useEffect, useState } from "react";

import css from "./TokenManage.module.scss";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import { updateShowRightSideBar } from "../../../../redux/actions/rightSideBarAction";
import { useDispatch, useSelector } from "react-redux";

import SearchIcon from "../../TeDaaS-Assets/TokenManage/Search.svg";
import FilterIcon from "../../TeDaaS-Assets/TokenManage/Filter.svg";
import dummyLogo from "../../TeDaaS-Assets/DaoCreate/dummyLogo.svg";
import TokenManageActions from "../../Components/UI/TokenManageActions/TokenManageActions";

import { TableColumns } from "../../Constants/TokenLedgerData.js";

import { updateTopBarStyle } from "../../../../redux/actions/topbarActions";
// import BurnModal from "../../Components/Modals/SmartContractActionModals/BurnModal/BurnModal.jsx"
import ApproveModal from "../../Components/Modals/SmartContractActionModals/ApproveModal/ApproveModal.jsx";
import BurnModal from "../../Components/Modals/SmartContractActionModals/BurnModal/BurnModal.jsx";
import ChangeOwnerModal from "../../Components/Modals/SmartContractActionModals/ChangeOwnerModal/ChangeOwnerModal.jsx";
import ChangeTokenLimitPerAddress from "../../Components/Modals/SmartContractActionModals/ChangeTokenLimitPerAddress/ChangeTokenLimitPerAddress.jsx";
import BlacklistAddress from "../../Components/Modals/SmartContractActionModals/BlacklistAddress/BlacklistAddress.jsx";
import ChangeStakingModal from "../../Components/Modals/SmartContractActionModals/ChangeStakingModal/ChangeStakingModal.jsx";
import PauseModal from "../../Components/Modals/SmartContractActionModals/PauseModal/PauseModal.jsx";
import TokenDocsModal from "../../Components/Modals/SmartContractActionModals/TokenDocsModal/TokenDocsModal.jsx";
import WithdrawFullModal from "../../Components/Modals/SmartContractActionModals/WithdrawFullModal/WithdrawFullModal.jsx";
import MintModal from "../../Components/Modals/SmartContractActionModals/MintModal/MintModal.jsx";
import TransferModal from "../../Components/Modals/SmartContractActionModals/TransferModal/TransferModal.jsx";
import StakingModal from "../../Components/Modals/SmartContractActionModals/StakingModal/StakingModal.jsx";
import WithdrawModal from "../../Components/Modals/SmartContractActionModals/WithdrawModal/WithdrawModal.jsx";
import { copyToClipboard, shortenAddress } from "../../Constants/reusableFunctions.js";
import Table from "../../Components/Layout/Table/Table.jsx";
import { useERC20TokenAction } from "../../Hooks/useERC20TokenAction.jsx";
import { updateLoader } from "../../../../redux/actions/loaderAction.js";
import { useTokenManage } from "../../Hooks/useTokenManage.jsx";
import { getFilteredEntities } from "../../../../services/stf.js";
import { tokenDataConfigureVal } from "../../../../redux/actions/tokenDataAction.js";
import { TEDAAS_SCHEMAS } from "../../Constants/Constants.js";

const TokenManage = (props) => {
    const dispatch = useDispatch();
    const navigateTo = useNavigate();

    const [tokenDetails, setTokenDetails] = useState({});
    const [searchInput, setSearchInput] = useState("");
    const [ownerAddress, setOwnerAddress] = useState("");
    const [ledgerTableData, setLedgerTableData] = useState([]);
    const [fullLedgerTableData, setFullLedgerTableData] = useState([]);

    const { address } = useParams();
    const { getOwner, getAddressBalance, getAddressStakedAmount, checkBlacklist, isPaused } = useERC20TokenAction();
    const { getTokenDetails } = useTokenManage();
    const userAllTokens = useSelector((state) => state.tokenDataReducer.userAllTokens);
    const userAddress = useSelector((state) => state.auth.userAddress);
    const modalData = useSelector((state) => state.tco);
    const users = useSelector((state) => state.tokenDataReducer.users);
    const fetchTokenDetails = async () => {
        try {
            const [ownAdd, tknDetails, txnData] = await getTokenDetails();
            setOwnerAddress(ownAdd);
            setTokenDetails(tknDetails);
            setLedgerTableData(txnData);
            setFullLedgerTableData(txnData);
        } catch (e) {
            console.log(e);
            navigateTo("/tedaasV3/tokenDashboard");
        }
        dispatch(updateLoader(false));
    };
    useEffect(() => {
        dispatch(updateLoader(true));
        fetchTokenDetails();
        dispatch(updateShowRightSideBar(true));
        dispatch(
            updateTopBarStyle(
                <div className={css.headerTitle}>
                    <span className={css.greyText}>MarketPlace /</span>
                    <span className={css.greyText}> TEDaaS /</span>
                    <span className={css.greyText}> Token Studio /</span>
                    <span className={css.blackText}> Token Manage</span>
                </div>,
                "#f5f6f7",
            ),
        );
    }, [address, userAddress, userAllTokens]);
    useEffect(() => {
        if (modalData.actionModals.modal === false) {
            fetchTokenDetails();
        }
    }, [modalData.actionModals.modal]);

    useEffect(() => {
        if (searchInput.trim() == "") {
            setLedgerTableData(fullLedgerTableData);
        }
    }, [searchInput]);

    const handleSearchChange = (e) => {
        setSearchInput(e.target.value);
    };

    const handleKeyDown = (e) => {
        if (e.key == "Enter") {
            performSearch();
        }
    };

    const performSearch = () => {
        try {
            if (searchInput.trim() == "") {
                setLedgerTableData(fullLedgerTableData);
            } else {
                const searchedData = fullLedgerTableData.filter((item) => {
                    return Object.values(item).some((value) => value.toString().toLowerCase().includes(searchInput.toLowerCase()));
                });
                setLedgerTableData(searchedData);
            }
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <div className={css.container}>
            <div className={css.body}>
                <div className={css.tokenOverviewActionContainer}>
                    <div className={css.tokenOverviewContainer}>
                        <div className={css.tokenNameSymbolContainer}>
                            <img src={tokenDetails?.tokenSymbol?.image || dummyLogo} alt="Token Icon" />
                            <div className={css.tokeNameSymbolWrapper}>
                                <h3>
                                    {tokenDetails?.tokenName} ({tokenDetails?.tokenSymbol?.name || tokenDetails?.tokenSymbol})
                                </h3>
                                <p>Token Symbol : {tokenDetails?.tokenSymbol?.name || tokenDetails?.tokenSymbol}</p>
                            </div>
                        </div>
                        <div className={css.tokenDetailsContainer}>
                            <table>
                                <tr>
                                    <td>Address:</td>
                                    <td
                                        onClick={() => {
                                            copyToClipboard(tokenDetails?.tokenAddress);
                                        }}
                                        style={{ cursor: "pointer" }}
                                    >
                                        {shortenAddress(tokenDetails?.tokenAddress)}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Total Supply:</td>
                                    <td>
                                        {tokenDetails?.totalSupply} {tokenDetails?.tokenSymbol?.name}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Balance:</td>
                                    <td>{tokenDetails?.balance}</td>
                                </tr>
                                <tr>
                                    <td>Voting Power:</td>
                                    <td>{tokenDetails?.votingPower ? tokenDetails.votingPower : 0} (If Token is GT)</td>
                                </tr>
                                <tr>
                                    <td>Stake Balance:</td>
                                    <td>{tokenDetails?.stakeBalance ? tokenDetails.stakeBalance : 0}</td>
                                </tr>
                                <tr>
                                    <td>Market Value:</td>
                                    <td>
                                        {tokenDetails?.marketValue || tokenDetails?.price?.value} per{" "}
                                        {tokenDetails?.tokenSymbol?.name || tokenDetails?.tokenSymbol}
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div className={css.tokenActionContainer}>
                        <h3>Manage Token</h3>
                        <div className={css.actionButtonContainer}>
                            {tokenDetails?.tokenAddress && <TokenManageActions selectedToken={tokenDetails} owner={ownerAddress} />}
                        </div>
                    </div>
                </div>
                <div className={css.ledgerContainer}>
                    <div className={css.ledgerHeader}>
                        <h3>Ledger</h3>
                        <div className={css.ledgerHeaderRightContainer}>
                            <h3>View Analytics</h3>
                            <div className={css.searchBarWrapper}>
                                <input placeholder="Search" value={searchInput} onKeyDown={handleKeyDown} onChange={handleSearchChange} />
                                <img src={SearchIcon} alt="Search" onClick={performSearch} />
                            </div>
                            <div className={css.filterWrapper}>
                                <img src={FilterIcon} alt="Filter" />
                                <span>Filter</span>
                            </div>
                        </div>
                    </div>
                    {/* <div className={css.ledgerTableContainer}> */}
                    <Table columnDefination={TableColumns} tableData={ledgerTableData} pageLength={5} path="token" />
                    {/* </div> */}
                </div>
            </div>
            {modalData.actionModals["Approve"] === true && <ApproveModal totalSupply={tokenDetails?.totalSupply} />}
            {modalData.actionModals["Edit Burn Rate"] === true && <BurnModal totalSupply={tokenDetails?.totalSupply} />}
            {modalData.actionModals["Edit Mint Rate"] === true && <MintModal totalSupply={tokenDetails?.totalSupply} />}
            {modalData.actionModals["Change Owner"] === true && <ChangeOwnerModal totalSupply={tokenDetails?.totalSupply} />}
            {modalData.actionModals["Change Token Limit"] === true && <ChangeTokenLimitPerAddress totalSupply={tokenDetails?.totalSupply} />}
            {modalData.actionModals["Blacklist Addresses"] === true && <BlacklistAddress totalSupply={tokenDetails?.totalSupply} />}
            {modalData.actionModals["Change Staking APR"] === true && <ChangeStakingModal totalSupply={tokenDetails?.totalSupply} />}
            {modalData.actionModals["Pause / Unpause"] === true && <PauseModal totalSupply={tokenDetails?.totalSupply} />}
            {modalData.actionModals["Edit Token Docs"] === true && <TokenDocsModal totalSupply={tokenDetails?.totalSupply} />}
            {modalData.actionModals["Withdraw Full"] === true && <WithdrawFullModal totalSupply={tokenDetails?.totalSupply} />}
            {modalData.actionModals["Transfer"] === true && <TransferModal totalSupply={tokenDetails?.totalSupply} balance={tokenDetails?.balance} />}
            {modalData.actionModals["Transfer To User"] === true && (
                <TransferModal totalSupply={tokenDetails?.totalSupply} balance={tokenDetails?.balance} isOwner={ownerAddress === userAddress} />
            )}
            {modalData.actionModals["Stake"] === true && <StakingModal totalSupply={tokenDetails?.totalSupply} balance={tokenDetails?.balance} />}
            {modalData.actionModals["Withdraw"] === true && <WithdrawModal totalSupply={tokenDetails?.totalSupply} />}
        </div>
    );
};

export default TokenManage;
