import React, { useState } from "react";
import css from "./TokenTypeNode.module.scss";
import { Handle, Position } from "@xyflow/react";
import userImg from "../../../TeDaaS-Assets/Nodes/user.svg";
import { Tooltip } from "antd";
import TooltipContent from "../../UI/TooltipContent/TooltipContent";
import { generateTooltipData, generateTooltipDate, generateTooltipFocus } from "../../../Constants/reusableFunctions";
import { useDispatch, useSelector } from "react-redux";
import { tokenCreationOpenModel } from "../../../../../redux/actions/tokenCreationOptionsAction";

function TokenTypeNode({ data }) {
    const dispatch = useDispatch();
    const fetchedData = useSelector((state) => state.tc);
    const [visible, setVisible] = useState(false);
    const handleManualClose = () => {
        setVisible(false);
    };
    const handleClick = () => {
        if (data.type === "tokenPrice") {
            dispatch(tokenCreationOpenModel("tokenPrice", "Token Price", "tokenPrice"));
        } else if (data.type === "tokenDocs") {
            dispatch(tokenCreationOpenModel("tokenDocs", "Token Docs", "tokenDocs"));
        }
        handleManualClose();
    };
    return (
        <div className={css.container}>
            <Handle type="target" position={Position.Left} className={css.handle} />
            <Handle type="source" position={Position.Right} className={css.handle} />
            <Tooltip
                placement="bottom"
                overlayStyle={{ width: "max-content", maxWidth: "none" }}
                open={visible}
                trigger={"hover"}
                onOpenChange={(e) => {
                    setVisible(e);
                }}
                title={
                    <TooltipContent
                        name={data.name}
                        handleClick={handleClick}
                        focus={generateTooltipFocus(fetchedData, data?.name, data?.type, data?.id)}
                        data={generateTooltipData(fetchedData, data?.name, data?.type, data?.id)}
                        date={generateTooltipDate(fetchedData, data?.name, data?.type, data?.id)}
                    />
                }
            >
                <div className={css.customNode}>
                    <div className={css.iconContainer}>
                        <img className={css.icon} src={data.image === "user" ? userImg : userImg} alt={data.name} />
                    </div>
                    <div className={css.nodeName}>
                        <span className={css.name}>{data.name}</span>
                    </div>
                </div>
            </Tooltip>
        </div>
    );
}

export default TokenTypeNode;
