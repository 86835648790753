import React, { useState } from 'react';
import styles from "./DropDown.module.scss";
import downArrowHeadImg from "../../../TeDaaS-Assets/Nodes/downArrowHead.svg";
import { Dropdown } from "antd";

function DropDown({data, selected, handleDropDown, prefix=""}) {
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    }
    return (
        <Dropdown className={styles.dropdown} menu={{ items:data }}>
            <div className={`${styles.select} ${isOpen ? styles["select-clicked"] : ""}`}>
                <span className={styles.selected}>{prefix} {selected}</span>
                <img className={styles.smallImg} src={downArrowHeadImg} alt="Dropdown Icon" />
            </div>
        </Dropdown>
    );
}

export default DropDown;
