import axios from "axios";

const token = import.meta.env.VITE_ACCESS_TOKEN;
const apiClient = axios.create({
    baseURL: import.meta.env.VITE_HOST_URL,
    headers: {
        Authorization: `Bearer ${token}`,
    },
});

export default apiClient;
