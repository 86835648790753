import React, { useEffect, useState } from "react";
import css from "./SelectBlockchain.module.scss";
import DAOStudioStepDescription from "../../../Components/UI/DAOStudioStepDescription/DAOStudioStepDescription";
import { useDispatch, useSelector } from "react-redux";
import { DAO_CREATION_REDUCER, SELECT_BLOCKCHAIN_OPTIONS } from "../../../Constants/Constants";
import DAOStudioSelectBlockchain from "../../../Components/UI/DAOStudioSelectBlockchain/DAOStudioSelectBlockchain";
import { Button } from "antd";
import { daoCreationConfigureVal } from "../../../../../redux/actions/daoCreationAction";
import { Link, useNavigate } from "react-router-dom";
import { showWarningToast } from "../../../../../services/notificationService";
import { validateDaoCreationForm } from "../../../Constants/validateDaoCreationForm";

function SelectBlockchain() {
    const navigateTo = useNavigate();
    const dispatch = useDispatch();
    const blockchain = useSelector((state) => state.dc)[DAO_CREATION_REDUCER.BLOCKCHAIN];
    const [data, setData] = useState(blockchain);
    const handleNext = () => {
        const {isValid, validatedData, next} = validateDaoCreationForm("selectBlockchain", data);
        if (isValid === true) {
            dispatch(daoCreationConfigureVal(DAO_CREATION_REDUCER.BLOCKCHAIN, validatedData));
            navigateTo(next);
        }
    };
    useEffect(() => {
        dispatch(daoCreationConfigureVal("progress", 30));
    }, []);
    return (
        <div className={css.container}>
            <DAOStudioStepDescription
                title="Select blockchain"
                description="The chain you choose is where your tokens and assets are stored. If you already have a token, choose the chain your token is minted on. This cannot be changed later."
                link="https://xpx.aidtaas.com/tedaasV3/dashboard"
            />
            <div className={css.selectNetwork}>
                <span
                    className={data.network === "mainnet" ? css.activeNetwork : css.network}
                    onClick={() => {
                        setData({ ...data, network: "mainnet" });
                    }}
                >
                    MainNet
                </span>
                <span
                    className={data.network === "testnet" ? css.activeNetwork : css.network}
                    onClick={() => {
                        setData({ ...data, network: "testnet" });
                    }}
                >
                    TestNet
                </span>
            </div>
            {SELECT_BLOCKCHAIN_OPTIONS[data.network].map((options, i) => {
                return (
                    <DAOStudioSelectBlockchain
                        key={i}
                        name={options.name}
                        value={options.value}
                        blockchain={options.blockchain}
                        selectedValue={data.blockchain}
                        handleChange={() => {
                            setData({ ...data, blockchain: options.value });
                        }}
                    />
                );
            })}
            <div className={css.buttons}>
                <Link to={`/tedaasV3/daoDashboard`}>
                    <Button type="default" className={css.button}>
                        {"<"} Back
                    </Button>
                </Link>
                <Button type="primary" className={css.button} onClick={handleNext}>
                    {"Next >"}
                </Button>
            </div>
        </div>
    );
}

export default SelectBlockchain;
