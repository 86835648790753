import React, { useEffect, useLayoutEffect } from "react";

import TokenomicsParameters from "../../Components/Layout/TokenomicsParameters/TokenomicsParameters.jsx";
import TokenomicsSimulationCharts from "../../Components/Layout/TokenomicsSimulationCharts/TokenomicsSimulationCharts.jsx";

import styles from "./TokenomicsSimulation.module.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateTopBarStyle } from "../../../../redux/actions/topbarActions.js";
import { updateShowRightSideBar } from "../../../../redux/actions/rightSideBarAction.js";

const TokenomicsSimulation = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const fetchedData = useSelector((state) => state.tc);
    const tokenSimulationData = useSelector((state) => state.tsr);
    // const userTokenData = useSelector((state) => state.tokenDataReducer.userTokenDetails)

    useLayoutEffect(() => {
        console.log(fetchedData);
        if (fetchedData.isNewToken==false) {
        	navigate('/tedaasV3/tokenDashboard')
        }
        dispatch(updateShowRightSideBar(false));
        dispatch(updateTopBarStyle(<span className={styles.headerTitle}>Token Simulation</span>, "#ffffff", "100%"));
    }, [fetchedData]);

    return (
        <div className={styles.container}>
            <TokenomicsParameters />
            <div className={styles.title}>Simulated Parameters</div>
            <TokenomicsSimulationCharts />
        </div>
    );
};

export default TokenomicsSimulation;
