import axios from "axios";
import { openDB } from "idb";
// var devUrl = "https://ig.aidtaas.com";
var devUrl = "https://ig.gov-cloud.ai";
// var prodUrl = "https://ig.aidtaas.com";
// var accessToken = `eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6ImZmOGYxNjhmLTNmZjYtNDZlMi1iMTJlLWE2YTdlN2Y2YTY5MCJ9.eyJwcm9maWxlVXJsIjoid3d3Lmdvb2dsZS5jb20vaW1hZ2VzL2F2aW5hc2gtcGF0ZWwtcm9ja3oiLCJyZWNlbnRfc2Vzc2lvbiI6Ik5BIiwic3ViIjoiZ2FpYW4uY29tIiwicGFyZW50VGVuYW50SWQiOiJOQSIsImNvbG9yIjpudWxsLCJ1c2VyX25hbWUiOiJtb2JpbGUxMEBnYXRlc3RhdXRvbWF0aW9uLmNvbSIsImlzcyI6ImdhaWFuLmNvbSIsImlzQWRtaW4iOnRydWUsInBsYXRmb3JtSWQiOiI2NWNkY2QxMTZjNWMwZTc2ZmZkZWMzMWIiLCJ1c2VyTmFtZSI6Im1vYmlsZTEwQGdhdGVzdGF1dG9tYXRpb24uY29tIiwiYXV0aG9yaXRpZXMiOlsiUk9MRV9NQVJLRVRQTEFDRV9VU0VSIl0sImNsaWVudF9pZCI6ImdhaWFuIiwic2NvcGUiOlsidHJ1c3QiLCJyZWFkIiwid3JpdGUiXSwidGVuYW50SWQiOiI2NWNkYzdkOTIyMjgwOTAwMDE4MzMxYzgiLCJsb2dvIjoid3d3Lmdvb2dsZS5jb20vaW1hZ2VzLiIsImV4cCI6MTcwODAzMTQ1MCwianRpIjoiMDk4M2MyZWQtMzgzNi00ZDMxLWE5NTQtMTJkNmI3MTkwYzkzIiwiZW1haWwiOiJ4cHhuZXdhcGlzZXJ2aWNlM3NAZ2F0ZXN0YXV0b21hdGlvbi5jb20ifQ.RLYcPQZF2sNZh7KzBslReXc6Pu3pHgRTiH952GpjRl7ft6EskuvQeil3lT8UIeIAVs03If3Amqw866jIR_EByB5PJ-hKtgwjSamyXk61ZVjfpXrZKVwifwt6BEyX1_lX1_A2CXW7qhn73JyipC_5sPf-3YcgfUGrAqkfoYRTHlQf6blXR-C6NjngUtIqb_aEwTV_gMaSIhi32l8dJo2LRKhGt87oouGb_5TqduB1AT6BYdTZFWmV8kH7p5EPPnRmENwEOXBFBU_15LdKMcHppzzQ4fQ2kNKkInAWEqD5np4BHajwuV78b-V6bRqgFoiqs3ejYaO4krac2SDs0dy1-A`;
import { accessToken, changeAccessToken, generatePortalAccessTokenPayload } from "./data";

const dbPromise = openDB("my-database01", 1, {
    upgrade(db) {
        db.createObjectStore("Cache01");
    },
});

axios.interceptors.response.use(
    async function (response) {
        // console.log("response",response)
        const { config, data } = response;
        const { method, url } = config;

        // Check if caching is enabled for this request
        if (method === "get" && shouldCache(url)) {
            const db = await dbPromise;
            const tx = db.transaction("Cache01", "readwrite");
            const store = tx.objectStore("Cache01");

            // Store the response data in IndexedDB
            await store.put(data, url);
        }
        return response;
    },
    function (error) {
        return Promise.reject(error);
    },
);

const shouldCache = (url) => {
    // Implement your caching logic here
    return true; // For demonstration purposes, always cache
};

// Schema Creation

export async function createSchema(data, token = accessToken) {
    try {
        const res = await axios.post(`${devUrl}/pi-entity-service/v1.0/schemas`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        console.log(res);
        return res;
    } catch (error) {
        console.error("Error creating schema:", error);
        throw error;
    }
}

//Schema Updation

export async function updateSchema(data, schemaId, accessToken = accessToken) {
    var res = await axios.put(`${devUrl}/pi-entity-service/v1.0/schemas/${schemaId}`, data, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });
    return res;
}
//Schema Get MetaData
export async function getMetaData(schemaId, accessToken = accessToken) {
    var res = await axios.get(`${devUrl}/pi-entity-service/v1.0/schemas/${schemaId}`, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });
    return res;
}

// Entity Insertion

export async function insertEntity(data, schemaId, token = accessToken, upsert = true) {
    // console.log(data);

    var res = await axios.post(`${devUrl}/tf-entity-ingestion/v1.0/schemas/${schemaId}/instances?upsert=${upsert}`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return res;
}

// Getting Entities

export async function getEntities(schemaId, token = accessToken) {
    // try{
    var res = await axios.get(`${devUrl}/tf-entity-ingestion/v1.0/schemas/${schemaId}/instances/list`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    // console.log("api call happenend 6969" , res);
    return res;
    // }catch(error){
    //   console.log("API CALL DIDN'T HAPPENED")
    //   return null;
    // }
}

// ===========        ==========       ========       ========

export const fetchDataWithCache = async (schemaId, accessToken) => {
    // Check if data is cached
    const cachedData = await getCachedData(schemaId);
    if (cachedData) {
        console.log("Fetched the Catched Data!!!!!!");
        return cachedData.entities;
    } else {
        // Fetch data from network and cache it
        const newData = await getEntities(schemaId, accessToken);
        console.log("Created a new DB in Indexed DB!!!!!!");
        return newData.entities;
    }
};

// =======       ========        =========         =========

const getCachedData = async (schemaId) => {
    const db = await dbPromise;
    const tx = db.transaction("Cache01", "readonly");
    const store = tx.objectStore("Cache01");

    return store.get(`${devUrl}/tf-entity-ingestion/v1.0/schemas/${schemaId}/instances/list`);
};

// ============         ============          ===========          ============

// Getting Filtered Entities

export async function getFilteredEntities(data, schemaId, token = accessToken, size = 200) {
    try {
        var res = await axios.post(`${devUrl}/tf-entity-ingestion/v1.0/schemas/${schemaId}/instances/list?size=${size}`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return res.data;
    } catch (e) {
        // if(e.response.status==403){
        //   var res2 = await generatePortalsAccessToken();
        //   console.log(res2);
        //   return await getFilteredEntities(data, schemaId, res2);
        // }
        console.log("Error in Getting Filtered Entities", e);
        return false;
    }
}

// Delete Entitity

export async function deleteEntity(data, schemaId, accessToken = accessToken) {
    var res = await axios.delete(`${devUrl}/tf-entity-ingestion/v1.0/schemas/${schemaId}/instances`, data, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });
    return res;
}

// Group Creation

export async function createGroup(data, accessToken = accessToken) {
    var res = await axios.post(`${devUrl}/pi-cohorts-service/v1.0/cohorts`, data, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });
    return res;
}

// Group Updation

export async function updateGroup(data, groupId, accessToken = accessToken) {
    var res = await axios.put(`${devUrl}/pi-cohorts-service/v1.0/cohorts/${groupId}`, data, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });
    return res;
}

//Group Defination

export async function getGroupDefination(groupId, accessToken = accessToken) {
    var res = await axios.get(`${devUrl}/pi-cohorts-service/v1.0/cohorts/${groupId}`, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });
    return res;
}

// Group Deletion

export async function deleteGroup(groupId, accessToken = accessToken) {
    var res = await axios.delete(`${devUrl}/pi-cohorts-service/v1.0/cohorts/${groupId}`, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });
    return res;
}

// Group Data

export async function getGroupData(groupId, accessToken = accessToken) {
    var res = await axios.post(
        `${devUrl}/pi-cohorts-service/v1.0/cohorts/${groupId}/data`,
        {},
        {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        },
    );
    return res;
}

// Group Data

export async function getGroupAdhocata(definition, accessToken = accessToken) {
    var res = await axios.post(
        `${devUrl}/pi-cohorts-service/v1.0/cohorts/adhoc`,
        {
            type: "TIDB",
            definition: definition,
        },
        {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        },
    );
    return res.data;
}

// Group Data with Filters

export async function getFilteredGroupData(data, groupId, accessToken = accessToken) {
    var res = await axios.post(`${devUrl}/pi-cohorts-service/v1.0/cohorts/${groupId}/data`, data, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });
    return res;
}

// BQ Creation

export async function createBQ(data, accessToken = accessToken) {
    var res = await axios.post(`${devUrl}/pi-bigquery-service/v1.0/big-queries`, data, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });
    return res;
}
//BQ METADATA
export async function getBQMetaData(aqId, accessToken = accessToken) {
    var res = await axios.post(
        `${devUrl}/pi-bigquery-service/v1.0/big-queries/${aqId}/data`,
        {},
        {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        },
    );
    return res;
}

// BQ Data

export async function getBQData(aqId, accessToken = accessToken) {
    var res = await axios.get(`${devUrl}/pi-bigquery-service/v1.0/big-queries/${aqId}/data`, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });
    return res;
}

// BQ Data with Filters

export async function getFilteredBQData(data, aqId, accessToken = accessToken) {
    var res = await axios.post(`${devUrl}/pi-bigquery-service/v1.0/big-queries/${aqId}/data`, data, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });
    return res;
}
// BQ Defination

export async function getBQDefination(aqId, accessToken = accessToken) {
    var res = await axios.get(`${devUrl}/pi-bigquery-service/v1.0/big-queries/${aqId}`, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });
    return res;
}

// BQ Updation

export async function updateBQ(data, aqId, accessToken = accessToken) {
    var res = await axios.put(`${devUrl}/pi-bigquery-service/v1.0/big-queries/${aqId}`, data, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });
    return res;
}

// BQ Deletions

export async function deleteBQ(aqId, accessToken = accessToken) {
    var res = await axios.delete(`${devUrl}/pi-bigquery-service/v1.0/big-queries/${aqId}`, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });
    return res;
}

// BQ Evaluate One Time

export async function evaluateBQ(tenantId, aqId, accessToken = accessToken) {
    var res = await axios.post(`${devUrl}/pi-bigquery-service/v1.0/big-queries/reevaluate?queryID=${aqId}&tenantID=${tenantId}`, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });
    return res;
}

// BQ ADHOC

export async function adhocBQ(data, accessToken = accessToken) {
    var res = await axios.post(`${devUrl}/pi-bigquery-service/v1.0/big-queries/execute?tenantID=618b6fdef5dacc0001a6b1b0`, data, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });
    return res;
}

// Trigger Workflow

export async function triggerWorkflow(workflowId, engagement, accessToken = accessToken, source = "", destination = "", data = "") {
    var bodyFormData = new FormData();
    bodyFormData.append("env", "TEST");
    bodyFormData.append("sync", false);
    bodyFormData.append("engagement", encodeURIComponent(JSON.stringify(engagement)));
    bodyFormData.append("source", source);
    bodyFormData.append("destination", destination);
    bodyFormData.append("data", data);
    var res = await axios.post(`https://bob-workflowdesigner.aidtaas.com/api/wf/execute/${workflowId}`, bodyFormData, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "multipart/form-data",
        },
    });
    return res;
}

// Trigger TEDaaS Workflow

export async function triggerTedaasWorkflow(workflowId, payload) {
    var bodyFormData = new FormData();
    bodyFormData.append("ownerId", "7c2a0cc5-6988-4999-8f6d-482363482eef");
    for (let i of payload) {
        bodyFormData.append(i.key, i.value);
    }
    var res = await axios.post(`https://ig.gov-cloud.ai/bob-camunda-quarkus/v1.0/camunda/execute/${workflowId}?env=TEST`, bodyFormData, {
        headers: {
            Authorization: `Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICI3Ny1NUVdFRTNHZE5adGlsWU5IYmpsa2dVSkpaWUJWVmN1UmFZdHl5ejFjIn0.eyJleHAiOjE3MjI4OTI2MzQsImlhdCI6MTcyMjg1NjYzNCwianRpIjoiNmY1NDk1ZTUtNGFkZC00NWEzLWFlNWMtNjUwOWQ0M2Q5NWM1IiwiaXNzIjoiaHR0cDovL2tleWNsb2FrLmtleWNsb2FrLnN2Yy5jbHVzdGVyLmxvY2FsOjgwODAvcmVhbG1zL21hc3RlciIsImF1ZCI6WyJQQVNDQUxfSU5URUxMSUdFTkNFIiwiTU9ORVQiLCJIT0xBQ1JBQ1kiLCJhY2NvdW50IiwiVklOQ0kiXSwic3ViIjoiMzAzN2RmNmItYTRhNS00MTU2LWExMjgtZDBlN2RhMzljMDc4IiwidHlwIjoiQmVhcmVyIiwiYXpwIjoiQk9MVFpNQU5OX0JPVCIsInNlc3Npb25fc3RhdGUiOiJmMGJhM2Y1Yi1jNzRiLTRhMmQtOGZkYS03N2EwNjgwMDFkZTEiLCJuYW1lIjoibW9iaXVzIG1vYml1cyIsImdpdmVuX25hbWUiOiJtb2JpdXMiLCJmYW1pbHlfbmFtZSI6Im1vYml1cyIsInByZWZlcnJlZF91c2VybmFtZSI6InBhc3N3b3JkX3RlbmFudF9tb2JpdXNAbW9iaXVzZHRhYXMuYWkiLCJlbWFpbCI6InBhc3N3b3JkX3RlbmFudF9tb2JpdXNAbW9iaXVzZHRhYXMuYWkiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiYWNyIjoiMSIsInJlYWxtX2FjY2VzcyI6eyJyb2xlcyI6WyJkZWZhdWx0LXJvbGVzLW1hc3RlciIsIm9mZmxpbmVfYWNjZXNzIiwidW1hX2F1dGhvcml6YXRpb24iXX0sInJlc291cmNlX2FjY2VzcyI6eyJCT0xUWk1BTk5fQk9UIjp7InJvbGVzIjpbIkJPTFRaTUFOTl9CT1RfVVNFUiJdfSwiUEFTQ0FMX0lOVEVMTElHRU5DRSI6eyJyb2xlcyI6WyJQQVNDQUxfSU5URUxMSUdFTkNFX1VTRVIiLCJQQVNDQUxfSU5URUxMSUdFTkNFX0FETUlOIl19LCJNT05FVCI6eyJyb2xlcyI6WyJNT05FVF9VU0VSIl19LCJIT0xBQ1JBQ1kiOnsicm9sZXMiOlsiU1VQRVJBRE1JTiIsIkhPTEFDUkFDWV9VU0VSIl19LCJhY2NvdW50Ijp7InJvbGVzIjpbIm1hbmFnZS1hY2NvdW50IiwibWFuYWdlLWFjY291bnQtbGlua3MiLCJ2aWV3LXByb2ZpbGUiXX0sIlZJTkNJIjp7InJvbGVzIjpbIlZJTkNJX1VTRVIiXX19LCJzY29wZSI6InByb2ZpbGUgZW1haWwiLCJzaWQiOiJmMGJhM2Y1Yi1jNzRiLTRhMmQtOGZkYS03N2EwNjgwMDFkZTEiLCJ0ZW5hbnRJZCI6IjMwMzdkZjZiLWE0YTUtNDE1Ni1hMTI4LWQwZTdkYTM5YzA3OCJ9.LcUJGdDIDcA414io0g1ueoYiTVO6jByWa9-AJQkIMFYiD83bmcW9h9NBL8HL-0dNrJSkE2iK7-ABiTLGWJf8f-3dN7-0vpzD4Mrjhr-AeGTHmceuGbutdHTHeKrpL6HcSKs1E2XMeS6IGIBI9RZp16RZ8z4-SEek0CXLYMcHlw89r_SIo3p0O8GDrHMu6mo5I8PieNTby-HR0VlmSmu4WM4xKy7j-b8QUkBAgWQ97Mvgn_RJazn7sUtQGx1PY-fQBt5a7AfLcPAafFXxnmw9_HPA2wBnFs0Yn8Nb1BwvNGH03DSdxfOwPIuCA7kjzrrPd1vu744m6Z-JeDy7SpeoNA`,
            "Content-Type": "multipart/form-data",
        },
    });
    return res;
}

// Resolve Template

export async function resolveTemplate(templateId, data, token = accessToken, transactionId = "bb9fdaf6-c1ba-4c1d-8b33-bb78676a548") {
    var res = await axios.post(`https://ig.aidtaas.com/mobius-template-service/v2.0/templates/${templateId}?transactionId=${transactionId}`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });
    return res;
}

// Get All Schemas based on Universe

export async function getAllSchemas(universeId, accessToken = accessToken, size = 100) {
    var res = await axios.get(`${devUrl}/pi-entity-service/v1.0/schemas/filter?universe=${universeId}&size=${size}`, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });
    return res;
}

// Get All Cohorts based on Universe

export async function getAllCohorts(universeId, accessToken = accessToken, size = 100) {
    var res = await axios.get(`${devUrl}/pi-cohorts-service/v1.0/cohorts/filter?universe=${universeId}&size=${size}`, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });
    return res;
}

// Get All Schemas based on Universe

export async function getAllBigQueries(universeId, accessToken = accessToken, size = 100) {
    var res = await axios.get(`${devUrl}/pi-bigquery-service/v1.0/big-queries/filter?universe=${universeId}&size=${size}`, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });
    return res;
}

// Generate Access Token

export async function generatePortalsAccessToken() {
    var res = await axios.post(`${devUrl}/mobius-iam-service/v1.0/login`, generatePortalAccessTokenPayload, {
        headers: {
            "Content-Type": "application/json",
        },
    });

    changeAccessToken(res.data.accessToken);
    // console.log(res.data.accessToken);
    return res.data.accessToken;
}

export async function uploadFileInCMS(file, filePath = "/tedaasV3/Docs/") {
    try {
        console.log("uploadFileInCMS", file);
        var formData = new FormData();
        formData.append("file", file);
        var res = await axios.post(
            `${devUrl}/mobius-content-service/v1.0/content/upload?filePath=${filePath}`,
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${accessToken}`,
                },
            },
        );
        console.log("Response", res);
        return res.data;
    } catch (e) {
        console.log("Error in CMS Upload API", e);
        return false;
    }
}

export const XPX_JSON_SERVER = "https://xpx-json-server.onrender.com/";

export const getTransactionsFromJSONSERVER = async (userAddress, tokenAddress) => {
    try {
        var res = await axios.get(`${XPX_JSON_SERVER}tedaasTransactions`);
        let data = res.data.filter((d) => (d.to == userAddress || d.from == userAddress) && d.tokenAddress == tokenAddress);
        return data;
    } catch (e) {
        return false;
    }
};

export const postTransactionsToJSONSERVER = async (data) => {
    try {
        const res = await axios.post(`${XPX_JSON_SERVER}tedaasTransactions`, data);
        return res;
    } catch (e) {
        console.log("Transaction Save Error", e);
        return false;
    }
};

export const getTokensFromJSONSERVER = async (tokenAddress) => {
    try {
        var res = await axios.get(`${XPX_JSON_SERVER}tedaasTokens/${tokenAddress}`);
        // let data = res.data.filter((d)=>d.to==filter || d.from==filter || d.tokenAddress==filter || txHash==filter);
        // console.log(res.data, data);
        if (Object.keys(res.data).length > 0) {
            return res.data;
        }
        return false;
    } catch (e) {
        return false;
    }
};

export const postTokensToJSONSERVER = async (data) => {
    try {
        var res = await axios.post(`${XPX_JSON_SERVER}tedaasTokens`, data);
        return true;
    } catch (e) {
        return false;
    }
};

export const getTokenHoldersFromJSONSERVER = async (userAddress) => {
    try {
        var res = await axios.get(`${XPX_JSON_SERVER}tokenHolders`);
        let data = res.data.filter((d) => d.userAddress == userAddress);
        return data;
    } catch (e) {
        return false;
    }
};

export const getTokenHolderFromJSONSERVER = async (id) => {
    try {
        var res = await axios.get(`${XPX_JSON_SERVER}/${id}`);
        return res.data;
    } catch (e) {
        return false;
    }
};

export const postTokenHoldersFromJSONSERVER = async (data) => {
    try {
        var res = await axios.post(`${XPX_JSON_SERVER}tokenHolders`, data);
        return res.data;
    } catch (e) {
        console.log("Error TOken Holders", e);
        return false;
    }
};
