import React from "react";
import css from "./NetworkInsightsCard.module.scss";
import gainLogo from "../../../TeDaaS-Assets/TeDaaSDashboard/profitArrow.svg";
import lossLogo from "../../../TeDaaS-Assets/TeDaaSDashboard/lossArrow.svg";

function NetworkInsightsCard({ value, name, percent, isProfit }) {
    return (
        <div className={css.container}>
            <span className={css.unit}>{value}</span>
            <span className={css.name}>{name}</span>
            <div className={css.percent}>
                <img className={css.cardImg} src={isProfit ? gainLogo : lossLogo} alt="Percent" style={{rotate:isProfit ? "0deg" : "180deg"}} />
                <span className={isProfit ? css.gain : css.loss}>{percent}%</span>
            </div>
        </div>
    );
}

export default NetworkInsightsCard;
