import React, { useEffect, useState } from "react";
import { Button, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import css from "./ProposalEstimateModal.module.scss";
import closeImg from "../../../../../TeDaaS-Assets/Nodes/close.svg";
import { proposalCreationConfigureVal } from "../../../../../../../redux/actions/proposalCreationAction";

const ProposalEstimateModal = ({ handleNext }) => {
    const dispatch = useDispatch();
    const [gasFees, setGasFees] = useState({
        estimatedFees: "0.0 MATIC",
        maxFees: "0.0 MATIC",
        totalFees: "0.0 MATIC",
        totalCost: "$0.00",
    });
    const modelData = useSelector((state) => state.pc.createProposalModal);
    const handleCancel = () => {
        dispatch(proposalCreationConfigureVal("createProposalModal", false));
    };
    const handleOk = async () => {
        handleNext();
    };
    return (
        <Modal
            centered
            title={
                <div className={css.header}>
                    <span className={css.title}>Create Proposal</span>
                    <div className={css.iconContainer} onClick={handleCancel}>
                        <img className={css.icon} src={closeImg} alt="Close" />
                    </div>
                </div>
            }
            closeIcon={false}
            open={modelData}
        >
            <div className={css.container}>
                <span className={css.subTitle} style={{ width: "100%", textAlign: "left" }}>
                    The fees below are the cost of using the selected blockchain
                </span>
                <div className={css.gasFeesContainer}>
                    <div className={css.flexColumn4px}>
                        <div className={css.flexRowBetween}>
                            <span className={css.estimatedGasFees}>Estimated Gas Fees</span>
                            <span className={css.font16_400}>{gasFees.estimatedFees}</span>
                        </div>
                        <div className={css.flexRowBetween}>
                            <span className={css.maxFee}>Max Fees</span>
                            <span className={css.font14_400}>{gasFees.maxFees}</span>
                        </div>
                    </div>
                    <div className={css.line}></div>
                    <div className={css.flexRowBetween}>
                        <span className={css.totalCost}>Total Cost</span>
                        <span className={css.font16_400}>{gasFees.totalFees}</span>
                    </div>
                    <div className={css.flexRowBetween}>
                        <span></span>
                        <span className={css.font14_400}>{gasFees.totalCost}</span>
                    </div>
                </div>
                <Button className={css.button} type="primary" onClick={handleOk}>
                    Create Proposal
                </Button>
            </div>
        </Modal>
    );
};
export default ProposalEstimateModal;
