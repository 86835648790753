import React, { useEffect, useState } from "react";
import css from "./DAODetailsDashboard.module.scss";
import dummyLogo from "../../../../TeDaaS-Assets/DaoCreate/dummyLogo.svg";
import unionLogo from "../../../../TeDaaS-Assets/DaoCreate/union.svg";
import searchLogo from "../../../../TeDaaS-Assets/DaoCreate/search.svg";
import robotLogo from "../../../../TeDaaS-Assets/DaoCreate/robot.svg";
import emptyLogo from "../../../../TeDaaS-Assets/DaoCreate/empty.svg";
import groupLogo from "../../../../TeDaaS-Assets/DaoCreate/group.svg";
import linkLogo from "../../../../TeDaaS-Assets/DaoCreate/redirect.svg";
import { capitalizeFirstLetterOfString, copyToClipboard, formatDateToString, shortenAddress } from "../../../../Constants/reusableFunctions";
import { Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { proposalCreationConfigureVal } from "../../../../../../redux/actions/proposalCreationAction";
import ProposalEmptyCard from "../../../UI/ProposalEmptyCard/ProposalEmptyCard";

function DAODetailsDashboard({ dao, members, proposals, token }) {
    const { daoAddress } = useParams();
    const dispatch = useDispatch();
    const navigateTo = useNavigate();
    useEffect(() => {}, [dao, daoAddress, members]);

    return (
        <div className={css.container}>
            <div className={css.header}>
                <img className={css.logo} src={dao?.describe?.logo || dummyLogo} alt={"Logo"} />
                <div className={css.textContainer}>
                    <span className={css.label}>About this DAO</span>
                    <span className={css.description}>{dao?.description}</span>
                </div>
                <div className={css.flexRow16}>
                    <div className={css.flexRow8}>
                        <img className={css.icon16} src={searchLogo} alt="Search" />
                        <span className={css.font1422}>{formatDateToString(dao?.createdAt, "month year")}</span>
                    </div>
                    <div className={css.flexRow8}>
                        <img className={css.icon16} src={robotLogo} alt="Blockchain" />
                        <span className={css.font1422}>{capitalizeFirstLetterOfString(dao?.blockchain)}</span>
                    </div>
                    <div className={css.flexRow8}>
                        <img className={css.icon16} src={unionLogo} alt="DAO Type" />
                        <span className={css.font1422}>{dao?.daoType === "token" ? "Token-Based" : "Wallet-Based"}</span>
                    </div>
                </div>
            </div>
            <div className={css.gridRow}>
                <ProposalEmptyCard />
                <div className={css.header}>
                    <div className={css.iconFullWidth}>
                        <img className={css.icon240} src={emptyLogo} alt="Initiate a Token Transfer" />
                    </div>
                    <div className={css.textContainer}>
                        <span className={css.label}>Initiate a Token Transfer</span>
                        <div className={css.displayInline}>
                            <span className={css.description}>
                                Ready to distribute tokens or to pay members? Start a Token Transfer from here. To learn more about tokens check
                            </span>
                            <span
                                className={css.textLink}
                                onClick={() => {
                                    window.open("https://xpx.aidtaas.com", "_blank");
                                }}
                            >
                                our guide
                            </span>
                        </div>
                    </div>
                    <Button className={css.button} type="primary">
                        Initial Transfer
                    </Button>
                </div>
            </div>
            <div className={css.gridRow}>
                <div className={css.flexColumn24}>
                    <div className={css.totalMembersContainer}>
                        <div className={css.flexRowBetween}>
                            <div className={css.iconContainer}>
                                <img className={css.icon16} src={groupLogo} alt="Members" />
                            </div>
                            <Button className={css.button} type="primary">
                                Add Members
                            </Button>
                        </div>
                        <div className={css.flexColumn5}>
                            <span className={css.title}>{`${members.length} Members`}</span>
                            <span className={css.label400}>{dao?.daoType === "token" ? "Token-Based" : "Wallet-Based"}</span>
                        </div>
                    </div>
                    <div className={css.flexColumn12}>
                        {Array.isArray(members) &&
                            members.map((member, i) => {
                                return (
                                    <div className={css.members} key={i}>
                                        <img className={css.icon48} src={member?.logo || dummyLogo} alt={member?.userAddress} />
                                        <span
                                            className={css.link}
                                            onClick={() => {
                                                copyToClipboard(member?.userAddress);
                                            }}
                                        >
                                            {shortenAddress(member?.userAddress)}
                                        </span>
                                        <span
                                            className={css.tokenTicker}
                                        >{`${member?.tokens} ${token?.tokenSymbol} (${Math.round((parseFloat(member?.tokens) * 100) / token.totalTokens)}%)`}</span>
                                        <div></div>
                                        <img className={css.icon16} src={linkLogo} alt={member?.userAddress} />
                                    </div>
                                );
                            })}
                    </div>
                    <Button className={css.whiteButton} type="default">
                        See All{">"}
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default DAODetailsDashboard;
