import React, { useRef, useState } from "react";
import css from "./CustomNode.module.scss";
import userImg from "../../../../TeDaaS-Assets/Nodes/user.svg";
import { useDrag } from "react-dnd";
import "./CustomNode.css";
import { Tooltip } from "antd";

function CustomNode({ node, type = "default" }) {
    const [{ isDragging, opacity, rotate }, dragRef] = useDrag(
        () => ({
            type: type==="tokenConfiguration" ? "tokenConfiguration" : "tokenomics",
            item: { node },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
                opacity: monitor.isDragging() ? 0.5 : 1,
            }),
        }),
        [],
    );
    const [isHover, setIsHover] = useState(false);
    return (
        <div
            className={css.customNode}
            ref={dragRef}
            style={{ opacity, rotate }}
            onMouseEnter={() => {
                if (!isHover) {
                    setIsHover(true);
                }
            }}
            onMouseOut={() => {
                setIsHover(false);
            }}
        >
            <div className={css.iconContainer}>
                <img className={css.icon} src={node.image === "user" ? userImg : userImg} alt={node.name} />
            </div>
            <Tooltip title={node.desc}>
                <div className={css.nodeName}>
                    <span>{node.name}</span>
                </div>
            </Tooltip>
        </div>
    );
}

export default CustomNode;
