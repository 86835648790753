import React from "react";
import css from "./TokenomicsSimulationTokenomicsParameter.module.scss";
import { useSelector } from "react-redux";

function TokenomicsSimulationTP({ headerOptions, setView, view, setShowParams }) {
    const fetchedData = useSelector((state) => state.tc);
    const handleShowParams = (param) => {
        if (view !== param) {
            setView(param);
        }
        const defaultParam = {
            type: "default",
            id: "default",
        };
        switch (param) {
            case "Initial Supply":
                setShowParams({
                    type: "initialSupply",
                    id: "initialSupply",
                });
                return;
            case "Rewards Projection":
                const keys = Object.keys(fetchedData.rewards);
                if (keys.length === 0) {
                    setShowParams(defaultParam);
                    return;
                }
                const type = "rewards";
                setShowParams({
                    type: type,
                    id: keys[0],
                });
                return;
            case "Burn Projection":
                setShowParams({
                    type: "burn",
                    id: "burn",
                });
                return;
            case "Mint Projection":
                setShowParams({
                    type: "mint",
                    id: "mint",
                });
                return;
        }
    };
    return (
        <div className={css.headerLeft}>
            <span className={css.font1422Grey}>Tokenomics Parameter:</span>
            <div className={css.headerButtons}>
                {Object.keys(headerOptions).map((parameterGroup, i) => {
                    return (
                        <span
                            key={i}
                            className={`${css.headerButton} ${view === parameterGroup ? css.activeHeaderButton : css.inactiveHeaderButton}`}
                            onClick={() => {
                                handleShowParams(parameterGroup);
                            }}
                        >
                            {`${parameterGroup} (${headerOptions[parameterGroup]})`}
                        </span>
                    );
                })}
            </div>
        </div>
    );
}

export default TokenomicsSimulationTP;
