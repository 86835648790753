import React, { useEffect, useState } from "react";
import { Button, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import css from "./ProposalCreationAddActionModal.module.scss";
import closeImg from "../../../../TeDaaS-Assets/Nodes/close.svg";
import { proposalCreationConfigureVal } from "../../../../../../redux/actions/proposalCreationAction";

const ProposalCreationAddActionModal = ({ actions, setActions }) => {
    const dispatch = useDispatch();
    const modelData = useSelector((state) => state.pc.addActionModal);
    const handleCancel = () => {
        dispatch(proposalCreationConfigureVal("addActionModal", false));
    };
    const handleOk = (type) => {
        if (type === "withdraw") {
            setActions({
                ...actions,
                withdraw: [
                    ...actions.withdraw,
                    {
                        address: "",
                        tokenAddress: "",
                        tokens: 0,
                    },
                ],
            });
        } else {
            setActions({
                ...actions,
                mint: [
                    ...actions.mint,
                    {
                        address: "",
                        tokens: 0,
                    },
                ],
            });
        }
        dispatch(proposalCreationConfigureVal("addActionModal", false));
    };
    return (
        <Modal
            centered
            title={
                <div className={css.header}>
                    <span className={css.title}>Add Action</span>
                    <div className={css.iconContainer} onClick={handleCancel}>
                        <img className={css.icon} src={closeImg} alt="Close" />
                    </div>
                </div>
            }
            closeIcon={false}
            open={modelData}
        >
            <div className={css.container}>
                <div
                    className={css.card}
                    onClick={() => {
                        handleOk("withdraw");
                    }}
                >
                    <span className={css.label}>Withdraw Assets</span>
                    <span className={css.value}>Create a Proposal to withdraw assets to a wallet</span>
                </div>
                <div
                    className={css.card}
                    onClick={() => {
                        handleOk("mint");
                    }}
                >
                    <span className={css.label}>Mint Tokens</span>
                    <span className={css.value}>Mint more DAO tokens and distribute to wallets</span>
                </div>
            </div>
        </Modal>
    );
};
export default ProposalCreationAddActionModal;
