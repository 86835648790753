import React, { useEffect, useState } from "react";

import MintIconInActive from "../../../TeDaaS-Assets/TokenManage/MintInActive.svg";
import MintIconActive from "../../../TeDaaS-Assets/TokenManage/MintActive.svg";

import BurnIconInActive from "../../../TeDaaS-Assets/TokenManage/BurnInActive.svg";
import BurnIconActive from "../../../TeDaaS-Assets/TokenManage/BurnActive.svg";

import EditTxTaxInActive from "../../../TeDaaS-Assets/TokenManage/EditTxnTaxInActive.svg";
import EditTxTaxActive from "../../../TeDaaS-Assets/TokenManage/EditTxnTaxActive.svg";

import PauseIconInActive from "../../../TeDaaS-Assets/TokenManage/PauseInActive.svg";
import PauseIconActive from "../../../TeDaaS-Assets/TokenManage/PauseActive.svg";

import BuybackInActive from "../../../TeDaaS-Assets/TokenManage/BuybackInActive.svg";
import BuybackActive from "../../../TeDaaS-Assets/TokenManage/BuybackActive.svg";

import ChangeOwnerInActive from "../../../TeDaaS-Assets/TokenManage/ChangeOwnerInActive.svg";
import ChangeOwnerActive from "../../../TeDaaS-Assets/TokenManage/ChangeOwnerActive.svg";

import BlacklistActive from "../../../TeDaaS-Assets/TokenManage/BlacklistActive.svg";
import BlacklistInActive from "../../../TeDaaS-Assets/TokenManage/BlacklistInActive.svg";

import styles from "./TokenCreationOverview.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { ERC20Code } from "../../../SmartContractCode/ERC20";
import useDeployContract from "../../../Hooks/useDeployContract";
import { useNavigate } from "react-router-dom";
import { Button } from "antd";
import { copyToClipboard, shortenAddress } from "../../../Constants/reusableFunctions";
import { tokenCreationReset } from "../../../../../redux/actions/tokenCreationAction";
import { tokenCreationNodeEdgeReset } from "../../../../../redux/actions/tokenCreationNodesAndEdgesAction";

const TokenCreationOverview = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    // const intialSupply = useSelector((state) => state.preMintReducer.preMintValue);
    // const tokenDetails = useSelector((state) => state.tokenDetailsReducer);
    const { compileERC20Contract, compileGovernanceTokenContract, compileSecurityTokenContract } = useDeployContract();
    const userAddress = useSelector((state) => state.auth.userAddress);
    const fetchedData = useSelector((state) => state.tc);

    const iconStates = {
        isMintable: fetchedData.mint.can,
        isBurnable: fetchedData.burn.can,
        isBlackListable: fetchedData.blacklist.can,
        isPausable: fetchedData.pause.can,
        isOwnerChangeable: fetchedData.changeOwner.can,
        isTaxable: fetchedData.transactionTax.can,
        isBuyBack: fetchedData.buyback.can,
    };

    const iconArray = [
        {
            icon: iconStates?.isMintable ? MintIconActive : MintIconInActive,
            name: "Mint",
            isActive: iconStates?.isMintable,
        },
        {
            icon: iconStates?.isBurnable ? BurnIconActive : BurnIconInActive,
            name: "Burn",
            isActive: iconStates?.isBurnable,
        },
        {
            icon: iconStates?.isBlackListable ? BlacklistActive : BlacklistInActive,
            name: "Blacklist",
            isActive: iconStates?.isBlackListable,
        },
        {
            icon: iconStates?.isPausable ? PauseIconActive : PauseIconInActive,
            name: "Pause / Unpause",
            isActive: iconStates?.isPausable,
        },
        {
            icon: iconStates?.isBuyBack ? BuybackActive : BuybackInActive,
            name: "Buyback",
            isActive: iconStates?.isBuyBack,
        },
        {
            icon: iconStates?.isOwnerChangeable ? ChangeOwnerActive : ChangeOwnerInActive,
            name: "Change Owner",
            isActive: iconStates?.isOwnerChangeable,
        },
        {
            icon: iconStates?.isTaxable ? EditTxTaxActive : EditTxTaxInActive,
            name: "Transaction Tax",
            isActive: iconStates?.isTaxable,
        },
    ];

    const handleButtonClick = async () => {
        const res =
            fetchedData.tokenType == "GT"
                ? await compileGovernanceTokenContract()
                : fetchedData.tokenType == "ST"
                  ? await compileSecurityTokenContract()
                  : await compileERC20Contract();
        if (res) {
            dispatch(tokenCreationReset());
            dispatch(tokenCreationNodeEdgeReset());
        }
        navigate("/tedaasV3/tokenDashboard");
    };

    return (
        <div className={styles.container}>
            <div className={styles.overviewContainer}>
                <span className={styles.headingWrapper}>Overview</span>
                <div className={styles.tokenDetailsContainer}>
                    <div className={styles.rowContainer}>
                        <div className={styles.detailsHeadingContainer}>Name</div>
                        <div className={styles.detailsInfoContainer}>
                            <span>{fetchedData.tokenName}</span>
                        </div>
                    </div>
                    <div className={styles.rowContainer}>
                        <div className={styles.detailsHeadingContainer}>Symbol</div>
                        <div className={styles.detailsInfoContainer}>
                            <span>{fetchedData.symbol}</span>
                        </div>
                    </div>
                    <div className={styles.rowContainer}>
                        <div className={styles.detailsHeadingContainer}>Intial Supply</div>
                        <div className={styles.detailsInfoContainer}>
                            <span>{fetchedData.initialSupply}</span>
                        </div>
                    </div>
                    <div className={styles.rowContainer}>
                        <div className={styles.detailsHeadingContainer}>Owner Address</div>
                        <div className={styles.detailsInfoContainer}>
                            <span
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    copyToClipboard(fetchedData.owner);
                                }}
                            >
                                {shortenAddress(fetchedData.owner)}
                            </span>
                        </div>
                    </div>
                    <div className={styles.rowContainer}>
                        <div className={styles.detailsHeadingContainer}>Token Type</div>
                        <div className={styles.detailsInfoContainer}>
                            <span>
                                {fetchedData.tokenType == "UT"
                                    ? "Utility Token"
                                    : fetchedData.tokenType == "GT"
                                      ? "Governance Token"
                                      : fetchedData.tokenType == "ST"
                                        ? "Security Token"
                                        : fetchedData.tokenType == "NFT"
                                          ? "Non-Fungible Token"
                                          : fetchedData.tokenType}
                            </span>
                        </div>
                    </div>
                    <div className={styles.rowContainer}>
                        <div className={styles.detailsHeadingContainer}>Token Key Function</div>
                    </div>
                    <div className={styles.tokenFunctions}>
                        {Array.isArray(iconArray) &&
                            iconArray.map((item, index) => {
                                return (
                                    <div key={index} className={`${styles.actionButtonWrapper} ${item.isActive ? styles.activeAction : ""}`}>
                                        <img src={item.icon} alt={`${item.name} Icon`} />
                                        <span style={{ color: item.isActive ? "#fff" : "#323E4F" }}>{item.name}</span>
                                    </div>
                                );
                            })}
                    </div>
                </div>
            </div>
            <div className={styles.deployBtnContainer}>
                <Button className={styles.deployBtn} type="primary" onClick={() => handleButtonClick()}>
                    Deploy Smart Contract
                </Button>
            </div>
        </div>
    );
};

export default TokenCreationOverview;
