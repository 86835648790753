import React, { useEffect } from "react";
import css from "./ProposalsBreakdown.module.scss";
function ProposalsBreakdown({ options = ["Yes", "No", "Abstain"], voters = [], totalSupply = 5, tokenSymbol, daoMembers = [] }) {
    return (
        <div className={css.container}>
            {options.map((option, i) => {
                var votes = voters.filter((vt) => vt.vote == option);
                return (
                    <div className={css.optionsContainer} key={option}>
                        <div className={css.nameContainer}>
                            <span className={css.name}>{option}</span>
                            <span className={css.value}>
                                {votes.length} {tokenSymbol}
                            </span>
                        </div>
                        <div className={css.progressContainer}>
                            <div className={css.progress}>
                                <div
                                    className={css.value}
                                    style={{ width: voters.length > 0 ? `${parseInt((votes.length * 100) / daoMembers.length)}%` : "0%" }}
                                ></div>
                            </div>
                            <span className={css.percent}>{voters.length > 0 ? parseInt((votes.length * 100) / daoMembers.length) : 0}%</span>
                        </div>
                    </div>
                );
            })}
        </div>
    );
}

export default ProposalsBreakdown;
