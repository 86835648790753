import React, { useEffect, useState } from "react";
import css from "./DAODetailsSettings.module.scss";
import { Button } from "antd";
import { copyToClipboard, getMinimumDurationFromDate, shortenAddress } from "../../../../Constants/reusableFunctions";
import DAOEditSettings from "../DAOEditSettings/DAOEditSettings";
import DAOReviewSettings from "../DAOReviewSettings/DAOReviewSettings";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { showWarningToast } from "../../../../../../services/notificationService";
import { daoDataConfigureVal } from "../../../../../../redux/actions/daoDataAction";
import { proposalCreationConfigureVal } from "../../../../../../redux/actions/proposalCreationAction";

function DAODetailsSettings({ dao, proposals, members, token }) {
    const [isEdit, setIsEdit] = useState(0);
    const navigateTo = useNavigate();
    const dispatch = useDispatch();
    const proposalData = useSelector((state) => state.pc);
    const updatedDao = useSelector((state) => state.pa.settings);
    useEffect(() => {}, [dao, proposals, members, token]);
    const handleSubmit = () => {
        if (
            dao.daoName !== updatedDao.daoName ||
            dao.logo !== updatedDao.logo ||
            dao.description !== updatedDao.description ||
            dao.supportThreeshold !== updatedDao.supportThreeshold ||
            dao.minimumParticipation !== updatedDao.minimumParticipation ||
            dao.minimumDuration !== updatedDao.minimumDuration ||
            dao.earlyExecution !== updatedDao.earlyExecution ||
            dao.voteChange !== updatedDao.voteChange ||
            dao.proposalCreation !== updatedDao.proposalCreation ||
            dao.minimumRequirement !== updatedDao.minimumRequirement
        ) {
            const settings = {};
            if (dao.daoName !== updatedDao.daoName) {
                settings.daoName = updatedDao.daoName;
            }
            if (dao.logo !== updatedDao.logo) {
                settings.logo = updatedDao.logo;
            }
            if (dao.description !== updatedDao.description) {
                settings.description = updatedDao.description;
            }
            if (dao.supportThreeshold !== updatedDao.supportThreeshold) {
                settings.supportThreeshold = updatedDao.supportThreeshold;
            }
            if (dao.minimumParticipation !== updatedDao.minimumParticipation) {
                settings.minimumParticipation = updatedDao.minimumParticipation;
            }
            if (dao.minimumDuration !== updatedDao.minimumDuration) {
                settings.minimumDuration = updatedDao.minimumDuration;
            }
            if (dao.earlyExecution !== updatedDao.earlyExecution) {
                settings.earlyExecution = updatedDao.earlyExecution;
            }
            if (dao.voteChange !== updatedDao.voteChange) {
                settings.voteChange = updatedDao.voteChange;
            }
            if (dao.proposalCreation !== updatedDao.proposalCreation) {
                settings.proposalCreation = updatedDao.proposalCreation;
            }
            if (dao.minimumRequirement !== updatedDao.minimumRequirement) {
                settings.minimumRequirement = updatedDao.minimumRequirement;
            }
            dispatch(proposalCreationConfigureVal("daoAddress", dao.daoAddress));
            dispatch(
                proposalCreationConfigureVal("actions", {
                    ...proposalData.actions,
                    settings: settings,
                }),
            );
            navigateTo("/tedaasV3/createProposal/proposalMeta");
        } else {
            showWarningToast("Nothing to update");
        }
    };
    return (
        <div className={css.container}>
            <div className={css.body}>
                <div className={css.header}>
                    <span className={css.title}>DAO Settings</span>
                    <div className={css.flexRow}>
                        <Button
                            type="primary"
                            className={css.button}
                            onClick={() => {
                                if (isEdit == 0) {
                                    setIsEdit(1);
                                } else if (isEdit === 1) {
                                    setIsEdit(2);
                                } else if (isEdit === 2) {
                                    handleSubmit();
                                }
                            }}
                        >
                            {isEdit === 1 ? "Review Settings" : isEdit === 2 ? "Create Proposal" : "Edit Settings"}
                        </Button>
                        {isEdit !== 0 && (
                            <Button
                                type="default"
                                className={css.button}
                                onClick={() => {
                                    setIsEdit(0);
                                }}
                            >
                                Cancel
                            </Button>
                        )}
                    </div>
                </div>
                {isEdit === 0 && dao?.daoName && (
                    <div className={css.content}>
                        <div className={css.row}>
                            <div className={css.card}>
                                <span className={css.title}>DAO</span>
                                <div className={css.line}></div>
                                <div className={css.info}>
                                    <span className={css.label}>Name</span>
                                    <span className={css.value}>{dao.daoName}</span>
                                </div>
                                <div className={css.info}>
                                    <span className={css.label}>{`Blockchain (Not changeable)`}</span>
                                    <span className={css.value}>{dao.blockchain}</span>
                                </div>
                                <div className={css.info}>
                                    <span className={css.label}>{`ENS Name (Not changeable)`}</span>
                                    <span className={css.link}>{dao.ensSubdomain}</span>
                                    <span
                                        className={css.address}
                                        onClick={() => {
                                            copyToClipboard(dao.daoAddress);
                                        }}
                                    >
                                        {shortenAddress(dao.daoAddress)}
                                    </span>
                                </div>
                                <div className={css.info}>
                                    <span className={css.label}>Summary</span>
                                    <span className={css.value}>{dao.description}</span>
                                </div>
                                <div className={css.info}>
                                    <span className={css.label}>Links</span>
                                    {Array.isArray(dao?.links) &&
                                        dao?.links.map((link, i) => {
                                            return (
                                                <div key={i} className={css.info}>
                                                    <span
                                                        className={css.link}
                                                        onClick={() => {
                                                            window.open(link?.url, "_blank");
                                                        }}
                                                    >
                                                        {link?.name}
                                                    </span>
                                                    <span className={css.label}>{link?.url}</span>
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                            <div className={css.card}>
                                <span className={css.title}>Version Info</span>
                                <div className={css.line}></div>
                                <div className={css.info}>
                                    <span className={css.label}>App</span>
                                    <span className={css.link}>{"Aragon App ve8d203105c5dd.."}</span>
                                </div>
                                <div className={css.info}>
                                    <span className={css.label}>Operating System</span>
                                    <span className={css.link}>{"Aragon OSx v1.3.0"}</span>
                                    <span className={css.address}>{shortenAddress(dao.tokenAddress)}</span>
                                </div>
                                <div className={css.info}>
                                    <span className={css.label}>Governance</span>
                                    <span className={css.link}>{"Token Voting v1.2"}</span>
                                    <span className={css.address}>{shortenAddress(dao.tokenAddress)}</span>
                                </div>
                            </div>
                        </div>
                        <div className={css.row}>
                            <div className={css.card}>
                                <span className={css.title}>Governance</span>
                                <div className={css.line}></div>
                                <div className={css.info}>
                                    <span className={css.label}>Approval Threeshold</span>
                                    <span className={css.value}>{`>${dao.supportThreeshold}%`}</span>
                                </div>
                                <div className={css.info}>
                                    <span className={css.label}>Minimum Participation</span>
                                    <span className={css.value}>{`>${dao.minimumParticipation} ${token.tokenSymbol}`}</span>
                                </div>
                                <div className={css.info}>
                                    <span className={css.label}>Minimum Duration</span>
                                    <span className={css.value}>{`${getMinimumDurationFromDate(dao.minimumDuration)}`}</span>
                                </div>
                                <div className={css.info}>
                                    <span className={css.label}>Early Execution</span>
                                    <span className={css.value}>{dao.earlyExecution ? "Yes" : "No"}</span>
                                </div>
                                <div className={css.info}>
                                    <span className={css.label}>Vote Change</span>
                                    <span className={css.value}>{dao.voteChange ? "Yes" : "No"}</span>
                                </div>
                                <div className={css.info}>
                                    <span className={css.label}>Proposal Threeshold</span>
                                    <span
                                        className={css.value}
                                    >{`Members with ≥ ${dao.minimumRequirement} ${token.tokenSymbol} voting power or balance`}</span>
                                </div>
                            </div>
                            <div className={css.card}>
                                <span className={css.title}>Members</span>
                                <div className={css.line}></div>
                                <div className={css.info}>
                                    <span className={css.label}>Eligible Voters</span>
                                    <span className={css.value}>{dao.daoType === "token" ? "Token Holders" : "Members"}</span>
                                </div>
                                <div className={css.info}>
                                    <span className={css.label}>Token</span>
                                    <span className={css.link}>{`${token.tokenName} (${token.tokenSymbol})`}</span>
                                    <span
                                        className={css.address}
                                        onClick={() => {
                                            copyToClipboard(dao.tokenAddress);
                                        }}
                                    >
                                        {shortenAddress(dao.tokenAddress)}
                                    </span>
                                </div>
                                <div className={css.info}>
                                    <span className={css.label}>Distribution</span>
                                    <span className={css.link}>{`${members.length} token holders`}</span>
                                    <span className={css.address}>{`view all token holders`}</span>
                                </div>
                                <div className={css.info}>
                                    <span className={css.label}>Supply</span>
                                    <span className={css.value}>{`${token.totalTokens} ${token.tokenSymbol}`}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {isEdit === 1 && dao?.daoName && <DAOEditSettings dao={dao} />}
                {isEdit === 2 && dao?.daoName && <DAOReviewSettings dao={dao} />}
            </div>
        </div>
    );
}

export default DAODetailsSettings;
