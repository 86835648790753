import React, { useEffect, useState } from "react";
import css from "./WithdrawAssetsAction.module.scss";

import linkLogo from "../../../TeDaaS-Assets/DaoCreate/redirect.svg";
import dummyLogo from "../../../TeDaaS-Assets/DaoCreate/dummyLogo.svg";
import menuImg from "../../../TeDaaS-Assets/DaoCreate/menu.svg";
import { copyToClipboard, shortenAddress } from "../../../Constants/reusableFunctions";
import { UpOutlined } from "@ant-design/icons";
import { getFilteredEntities, getGroupAdhocata } from "../../../../../services/stf";
import { TEDAAS_SCHEMAS } from "../../../Constants/Constants";
import { useSelector } from "react-redux";

function WithdrawAssetsAction({
    withdraw = [
        {
            address: "0xFB388d01c5676bAD7AD443AeFBb8da39116368e0",
            tokens: 1,
            tokenAddress,
        },
    ],
}) {
    const [tokens, setTokens] = useState({});
    const fetchTokenDetails = async () => {
        let tempTokens = {};
        for (let i of withdraw) {
            const tokenRes = await getFilteredEntities({ tokenAddress: i.tokenAddress }, TEDAAS_SCHEMAS.tokenId, TEDAAS_SCHEMAS.accessToken);
            if (tokenRes.entities.length > 0) {
                tempTokens[i.tokenAddress] = tokenRes.entities[0];
            }
        }
        setTokens(tempTokens);
    };
    useEffect(() => {
        fetchTokenDetails();
    }, []);
    return (
        <div className={css.card}>
            <div className={css.flexColumnStart4px}>
                <div className={css.flexRowBetween}>
                    <span className={css.font1624}>Withdraw Assets</span>
                    <div className={css.menuContainer}>
                        <img className={css.icon} src={menuImg} alt="Menu" />
                    </div>
                </div>
                <span className={css.font1422} style={{ paddingRight: "var(--TClamp32px)" }}>
                    Create a Proposal to withdraw tokens from DAO Treasury. Select the wallets that should receive tokens and determine how many
                </span>
            </div>
            <div className={css.line}></div>
            {/* <ul className={css.font1220} style={{ color: "#00000073", paddingLeft: "var(--TClamp16px)" }}>
                <li>The total token supply includes all tokens you have already minted</li>
            </ul> */}
            {Array.isArray(withdraw) &&
                withdraw.map((w, i) => {
                    return (
                        <div className={css.members} key={i}>
                            <img className={css.icon48} src={dummyLogo} alt={"Member"} />
                            <span
                                className={css.font1420}
                                onClick={() => {
                                    copyToClipboard(w.address || w.memberAddress);
                                }}
                            >
                                {shortenAddress(w.address || w.memberAddress)}
                            </span>
                            <span
                                className={`${css.font1422} ${css.colorGrey}`}
                            >{`${w.tokens} (${tokens[w.tokenAddress]?.tokenSymbol || "token"})`}</span>
                            <div></div>
                            <img className={css.icon16} src={linkLogo} alt={"Link"} />
                        </div>
                    );
                })}
        </div>
    );
}

export default WithdrawAssetsAction;
