import React from 'react'; 
export default function LoadingComponent({ width }) {
    return (
        <div
            className="tedaasSpinner"
            style={{
                position: "absolute",
                top: "0vw",
                left: "0vw",
                zIndex: "1002",
                width: "100vw",
                height: "100vh",
                backdropFilter: "blur(10px)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <svg viewBox="25 25 50 50">
                <circle
                    cx="50"
                    cy="50"
                    r="20"
                    fill="none"
                    strokeWidth="5" // Adjust this value to increase the width
                    className="tedaasSpinnerPath"
                ></circle>
            </svg>
        </div>
    );
}
