import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import css from "./StakingModal.module.scss";
import burnImg from "../../../../TeDaaS-Assets/Modals/stake.svg";
import closeImg from "../../../../TeDaaS-Assets/Nodes/close.svg";
import { tokenActionCloseModal } from "../../../../../../redux/actions/tokenCreationOptionsAction";
import { useParams } from "react-router-dom";
import { useERC20TokenAction } from "../../../../Hooks/useERC20TokenAction";
import { updateLoader } from "../../../../../../redux/actions/loaderAction";
import { showErrorToast, showSuccessToast, showWarningToast } from "../../../../../../services/notificationService";

const StakingModal = ({ totalSupply, balance }) => {
    const dispatch = useDispatch();
    const modelData = useSelector((state) => state.tco);
    const { address } = useParams();
    const { stakeToken } = useERC20TokenAction();
    const [stake, setStake] = useState({
        amount: "",
        period: "",
    });
    const handleOk = async () => {
        try {
            if (parseFloat(stake.amount) <= balance && parseInt(stake.period) < 12 && parseInt(stake.period) > 0) {
                dispatch(updateLoader(true));
                const res = await stakeToken(address, String(stake.amount), String(stake.period));
                res === true ? showSuccessToast("Staked Token Successfully") : showErrorToast("Staking Token failed");
                dispatch(updateLoader(false));
                dispatch(tokenActionCloseModal());
            } else {
                if (parseFloat(stake.amount) > balance) {
                    showWarningToast("Insufficient Balance");
                } else {
                    showWarningToast("Stake Period should be between 1 to 12");
                }
            }
        } catch (e) {
            showErrorToast("Staking Token failed");
        }
    };
    const handleCancel = () => {
        dispatch(tokenActionCloseModal());
    };
    return (
        <Modal
            centered
            title={
                <div className={css.header}>
                    <div className={css.headerLeft}>
                        <div className={css.headerTop}>
                            <img className={css.icon} src={burnImg} alt="Stake" />
                            <span className={css.title}>Stake</span>
                        </div>
                        <div className={css.iconContainer} onClick={handleCancel}>
                            <img className={css.icon} src={closeImg} alt="Close" />
                        </div>
                    </div>
                    <div className={css.subTitleContainer}>
                        <span className={css.subTitle}>Manage Action</span>
                        <span className={css.subTitle}>Current token supply: {totalSupply}</span>
                    </div>
                </div>
            }
            closeIcon={false}
            open={modelData.actionModals["Stake"]}
        >
            <div className={css.container}>
                <span className={css.title}>Stake your token to get staking rewards and other benefits in DAO, if the token is GT.</span>
                <div className={css.slider}>
                    <span className={css.label}>Stake</span>
                    <input
                        type="text"
                        placeholder="e.g 50,000"
                        className={css.input}
                        value={stake.amount}
                        onChange={(e) => {
                            if (/^[0-9]*\.?[0-9]+$/.test(e.target.value) || e.target.value === "") {
                                setStake({ ...stake, amount: e.target.value });
                            }
                        }}
                    />
                </div>
                <div className={css.slider}>
                    <span className={css.label}>Period</span>
                    <input
                        type="text"
                        placeholder="e.g 5"
                        className={css.input}
                        value={stake.period}
                        onChange={(e) => {
                            if (/^[0-9]*\.?[0-9]+$/.test(e.target.value) || e.target.value === "") {
                                setStake({ ...stake, period: e.target.value });
                            }
                        }}
                    />
                </div>
                <div className={css.buttons}>
                    <span className={css.cancel} onClick={handleCancel}>
                        Cancel
                    </span>
                    <span
                        className={css.submit}
                        onClick={() => {
                            handleOk();
                        }}
                    >
                        Submit
                    </span>
                </div>
            </div>
        </Modal>
    );
};
export default StakingModal;
