import { ITEM_COLORS } from "./Constants";

export const tokenSimulationShuffleColors = () => {
    const arr = [...ITEM_COLORS];
    // for (let i = arr.length - 1; i > 0; i--) {
    //     const j = Math.floor(Math.random() * (i + 1));
    //     [arr[i], arr[j]] = [arr[j], arr[i]];
    // }
    return arr;
};

export const calculateTokenSimulationTokenomics = (data) => {
    let persons = [];
    let total = 0;
    let colors = tokenSimulationShuffleColors();
    // if (data.rewards?.reward?.can) {
    //     persons.push({
    //         name: data.rewards?.reward?.name,
    //         value: data.rewards?.reward?.percent,
    //     });
    //     total += data.rewards?.reward?.percent;
    // }
    let keys = Object.keys(data.assignee);
    for (let i of keys) {
        if (data.assignee[i].can) {
            persons.push({
                name: data.assignee[i].name,
                value: parseFloat(data.assignee[i].percent),
            });
            total += parseFloat(data.assignee[i].percent);
        }
    }
    let finalData = [];
    for (let i in persons) {
        finalData.push({
            ...persons[i],
            value: Math.round((persons[i].value / total) * 100),
            itemStyle: {
                color: colors[i],
            },
        });
    }
    return finalData;
};

export const calculateTokenSimulationBurn = (data) => {
    if (data.burn.can == true) {
        const initialSupply = data.initialSupply;
        const percent = data.burn.percent;
        const unlock = data.burn.unlock;
        const period = data.burn.period;
        const schedule = calculateMintBurningSchedule(initialSupply, percent, unlock);
        const tenure = calculateIntervals(period, unlock);
        return {
            canBurn: true,
            burnScheduleData: schedule,
            burnPercentage: percent,
            burningScheduleTenure: tenure,
            burningScheduleTenureType: period === "month" ? "Months" : period === "quarter" ? "Quarters" : period === "year" ? "Years" : "",
        };
    }
    return {
        canBurn: false,
        burnScheduleData: [],
        burnPercentage: 0,
        burningScheduleTenure: 0,
        burningScheduleTenureType: "Months",
    };
};

export const calculateTokenSimulationMint = (data) => {
    if (data.mint.can == true) {
        const initialSupply = data.initialSupply;
        const percent = data.mint.percent;
        const unlock = data.mint.unlock;
        const period = data.mint.period;
        const schedule = calculateMintBurningSchedule(initialSupply, percent, unlock);
        const tenure = calculateIntervals(period, unlock);
        return {
            canMint: true,
            mintScheduleData: schedule,
            mintPercentage: percent,
            mintingScheduleTenure: tenure,
            mintingScheduleTenureType: period === "month" ? "Months" : period === "quarter" ? "Quarters" : period === "year" ? "Years" : "",
        };
    }
    return {
        canMint: false,
        mintScheduleData: [],
        mintPercentage: 0,
        mintingScheduleTenure: 0,
        mintingScheduleTenureType: "Months",
    };
};

export const calculateMintBurningSchedule = (preMintValue, percentage, tenure) => {
    const schedule = [];

    let totalSupply = preMintValue;
    for (let i = 1; i <= tenure; i++) {
        if (percentage > 0) {
            const tokensToMint = totalSupply * (percentage / 100);
            totalSupply += tokensToMint;
            schedule.push(totalSupply);
        }
    }
    return schedule;
};

export const calculateIntervals = (scheduleType, projectionPeriodYears) => {
    let intervals;
    switch (scheduleType) {
        case "month":
            intervals = projectionPeriodYears;
            break;
        case "quarter":
            intervals = projectionPeriodYears * 4;
            break;
        case "year":
            intervals = projectionPeriodYears;
            break;
        default:
            throw new Error("Invalid schedule type");
    }
    return intervals;
};

export const calculateTotalPercentageSum = (emissionDistribution, emissionDistributionPercentage, subkey) => {
    return emissionDistribution.reduce((sum, key) => sum + emissionDistributionPercentage[key][subkey], 0);
};

export const calculateTokenSimulationReward = (data) => {
    let initialValue = data.initialSupply;
    const reward = data.rewards;
    let assignee = Object.keys(data.assignee);
    let isReward = false;
    for (let i of assignee) {
        if (data.assignee[i]?.index === "reward" && data.assignee[i]?.can === true) {
            isReward = true;
            initialValue = data.initialSupply * (data.assignee[i].percent / 100);
        }
    }
    if (isReward == false) {
        return { isReward: false };
    }
    let months = 0;
    let colors = {};
    let allColors = tokenSimulationShuffleColors();
    let colorI = 0;

    let rewards = {};

    Object.keys(reward).forEach((key) => {
        if (reward[key]?.can) {
            let totalMonths = reward[key]?.lock + reward[key]?.unlock;
            if (totalMonths > months) {
                months = totalMonths;
            }
            colors[key] = allColors[colorI];
            colorI++;
            rewards[key] = reward[key];
        }
    });
    if (Object.keys(rewards).length === 0) {
        return {
            isReward: false,
        };
    }
    Object.keys(rewards).forEach((key) => {
        let totalMonths = rewards[key]?.lock + rewards[key]?.unlock;
        if (totalMonths > months) {
            months = totalMonths;
        }
        colors[key] = allColors[colorI];
        colorI++;
    });

    const monthlyValues = Array(months)
        .fill(0)
        .map(() => {
            let obj = Object.keys(rewards);
            let json = {};
            for (let i of obj) {
                json[i] = 0;
            }
            return json;
        });

    Object.keys(rewards).forEach((key) => {
        let previousMonthValue = 0;
        for (let month = 0; month < months; month++) {
            if (month >= rewards[key]?.lock && month < rewards[key]?.lock + rewards[key]?.unlock) {
                const currentValue = rewards[key].percent * initialValue;
                monthlyValues[month][key] = previousMonthValue + currentValue;
                previousMonthValue = monthlyValues[month][key];
            } else {
                monthlyValues[month][key] = previousMonthValue;
            }
        }
    });

    const chartData = monthlyValues.map((value, index) => ({
        month: `${index + 1}`,
        ...value,
    }));

    const selectedData = selectElementsFromArrayAtEqualIntervals(chartData, 10);
    return { isReward: true, colors: colors, data: selectedData, reward: rewards };
};

function selectElementsFromArrayAtEqualIntervals(array, num) {
    const length = array.length;
    const result = [];
    const step = length / num;
    for (let i = 0; i < num; i++) {
        const index = Math.floor(i * step);
        if (!result.includes(array[index])) {
            result.push(array[index]);
        }
    }
    return result;
}

export const calculateTokenSimulationEmission = (data) => {
    const initialValue = data.initialSupply;
    const assignees = data.assignee;
    let months = 0;
    let colors = {};
    let allColors = tokenSimulationShuffleColors();
    let colorI = 0;
    let assignee = {};

    Object.keys(assignees).forEach((key) => {
        if (assignees[key]?.can) {
            let totalMonths = assignees[key]?.lock + assignees[key]?.unlock;
            if (totalMonths > months) {
                months = totalMonths;
            }
            colors[key] = allColors[colorI];
            colorI++;
            assignee[key] = assignees[key];
        }
    });
    if (Object.keys(assignee).length === 0) {
        return {
            isEmission: false,
        };
    }
    const monthlyValues = Array(months)
        .fill(0)
        .map(() => {
            let obj = Object.keys(assignee);
            let json = {};
            for (let i of obj) {
                json[i] = 0;
            }
            return json;
        });

    Object.keys(assignee).forEach((key) => {
        let previousMonthValue = 0;
        for (let month = 0; month < months; month++) {
            if (month >= assignee[key]?.lock && month < assignee[key]?.lock + assignee[key]?.unlock) {
                const currentValue = assignee[key].percent * initialValue;
                monthlyValues[month][key] = previousMonthValue + currentValue;
                previousMonthValue = monthlyValues[month][key];
            } else {
                monthlyValues[month][key] = previousMonthValue;
            }
        }
    });

    const chartData = monthlyValues.map((value, index) => ({
        month: `${index + 1}`,
        ...value,
    }));
    // const selectedData = selectElementsFromArrayAtEqualIntervals(chartData, 10);
    return { isEmission: true, colors: colors, data: chartData, assignee: assignee };
};
