import React, { useEffect } from "react";
import css from "./DoughnutChartWithLegends.module.scss";
import DoughnutChart from "../../Charts/DoughnutChart/DoughnutChart";
import { useSelector } from "react-redux";

function DoughnutChartWithLegends({ title = "", data , isTitle=false, showTitle=""}) {
    return (
        <div className={css.networkChart}>
            {title.length > 0 && <span className={css.networkChartTitle}>{title}</span>}
            <div className={css.chart}>
                <DoughnutChart data={data} showTitle={showTitle} isTitle={isTitle} />
                <div className={css.chartLegends}>
                    {data.map((d, i) => {
                        return (
                            <div className={css.chartLegend} key={i}>
                                <div className={css.icon} style={{ background: d.itemStyle.color }}></div>
                                <span className={css.value}>{d.value}%</span>
                                <span className={css.name}>{d.name}</span>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}

export default DoughnutChartWithLegends;
