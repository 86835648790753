import React, { useEffect, useState } from "react";
import css from "./DAODetailsFinanceTransfers.module.scss";
import css2 from "../DAODetailsFinance.module.scss";
import dummyLogo from "../../../../../TeDaaS-Assets/proposalCreate/logo.svg";
import depositLogo from "../../../../../TeDaaS-Assets/DaoCreate/deposit.svg";
import withdrawLogo from "../../../../../TeDaaS-Assets/DaoCreate/withdraw.svg";
import css3 from "../../DAODetailsMembers/DAODetailsMembers.module.scss";
import TokenomicsSimulationSearchParameter from "../../../../UI/TokenomicsSimulationSearchParameter/TokenomicsSimulationSearchParameter";
import { Button } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { getFilteredEntities, getGroupAdhocata } from "../../../../../../../services/stf";
import { TEDAAS_SCHEMAS } from "../../../../../Constants/Constants";

function DAODetailsFinanceTransfers({ dao, members, proposals, token, setPage }) {
    const [transfers, setTransfers] = useState([]);
    const [allTransfers, setAllTransfers] = useState(0);
    const [search, setSearch] = useState("");
    const [filter, setFilter] = useState("All");
    const fetchTokenDetails = async () => {
        const arr = [];
        const res = await getFilteredEntities(
            { txAddress: dao.daoAddress, txType: "DAO Treasury" },
            TEDAAS_SCHEMAS.transactionId,
            TEDAAS_SCHEMAS.accessToken,
        );
        let transactions = res.entities;
        const weekTimestamp = new Date(Number(new Date().getTime() - new Date().getDay() * 1000 * 60 * 60 * 24)).getTime();
        const weekTransfers = transactions.filter((d) => {
            return Number(d.date) >= weekTimestamp;
        });
        arr.push({
            name: "This week",
            transfers: weekTransfers,
        });
        transactions = transactions.filter((d) => {
            return Number(d.date) < weekTimestamp;
        });
        transactions = transactions.filter((d) => {
            return (
                String(new Date(Number(d.date)).getMonth()) === String(new Date().getMonth()) &&
                String(new Date(Number(d.date)).getFullYear()) === String(new Date().getFullYear())
            );
        });
        const monthName = new Date().getMonth();
        const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        arr.push({
            name: months[monthName],
            transfers: transactions,
        });
        setTransfers(arr);
    };
    useEffect(() => {
        fetchTokenDetails();
    }, []);
    return (
        <div className={css3.container}>
            <div className={css3.body}>
                <div
                    className={`${css2.flexRowStart8px} ${css2.cursor}`}
                    style={{ width: "100%" }}
                    onClick={() => {
                        setPage("finance");
                    }}
                >
                    <LeftOutlined className={css2.icon10} />
                    <span className={css2.note}>Finance</span>
                    <span className={css2.note}>/</span>
                    <span className={css2.label}>Transfers</span>
                </div>
                <div className={css3.header}>
                    <div className={css3.headerLeft}>
                        <span className={css3.title}>All Transfers</span>
                        <span className={css3.daoType}>{`$${allTransfers} Total Volume`}</span>
                    </div>
                    <div className={css3.headerRight}>
                        <Button type="primary" className={`${css3.button} ${css3.blueButton}`}>
                            {"+ New Transfer"}
                        </Button>
                    </div>
                </div>
                <div className={css2.flexRowBetween}>
                    <div className={css2.flexRowStart0px}>
                        <span
                            className={`${css.navbarButton} ${filter === "All" ? css.activeNavbarButton : css.inactiveNavbarButton}`}
                            onClick={() => {
                                if (filter !== "All") {
                                    setFilter("All");
                                }
                            }}
                        >
                            All
                        </span>
                        <span
                            className={`${css.navbarButton} ${filter === "Deposit" ? css.activeNavbarButton : css.inactiveNavbarButton}`}
                            onClick={() => {
                                if (filter !== "Deposit") {
                                    setFilter("Deposit");
                                }
                            }}
                        >
                            Deposit
                        </span>
                        <span
                            className={`${css.navbarButton} ${filter === "Withdraw" ? css.activeNavbarButton : css.inactiveNavbarButton}`}
                            onClick={() => {
                                if (filter !== "Withdraw") {
                                    setFilter("Withdraw");
                                }
                            }}
                        >
                            Withdrawal
                        </span>
                    </div>
                    <div className={css.width240px}>
                        <TokenomicsSimulationSearchParameter search={search} setSearch={setSearch} placeholder="Type to Search" />
                    </div>
                </div>
                {transfers.length > 0 && (
                    <div className={css2.flexColumn8px}>
                        {Array.isArray(transfers) &&
                            transfers.map((group, i) => {
                                return (
                                    <div className={css2.flexColumn24px}>
                                        <span className={css2.font20px}>{group.name}</span>
                                        <div className={css2.flexColumn8px}>
                                            {Array.isArray(group.transfers) &&
                                                group.transfers.map((transfer, i) => {
                                                    return (
                                                        <div className={`${css2.card} ${css2.flexRowBetween}`} key={i}>
                                                            <div className={css2.flexRowStart12px}>
                                                                <img
                                                                    className={css2.icon48}
                                                                    src={transfer?.name === "Deposit" ? depositLogo : withdrawLogo}
                                                                    alt="Logo"
                                                                />
                                                                <div className={css2.flexColumn2px}>
                                                                    <div className={css2.flexRowStart8px}>
                                                                        <span className={css2.label}>{transfer?.name}</span>
                                                                    </div>
                                                                    <span className={css2.note}>{`${new Date(parseInt(transfer.date))}`}</span>
                                                                </div>
                                                            </div>
                                                            <div className={css2.flexRowCenter12px}>
                                                                <div className={css2.flexColumnEnd2px}>
                                                                    <span
                                                                        className={css2.label}
                                                                    >{`${transfer?.name === "Deposit" ? "+" : "-"}${transfer.value} ${token.tokenSymbol}`}</span>
                                                                    <span
                                                                        className={css2.note}
                                                                    >{`$${Number(transfer.value) * Number(token.price)}`}</span>
                                                                </div>
                                                                <RightOutlined className={css2.icon10} />
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                )}
            </div>
        </div>
    );
}

export default DAODetailsFinanceTransfers;
