import React from "react";
import css from "./TokenBuilder.module.scss";
import { NODES } from "../../../Constants/Constants";
import CustomNode from "./CustomNode/CustomNode";
import EventNode from "./EventNode/EventNode";

function TokenBuilder({}) {
    return (
        <div className={css.container}>
            <span className={css.title}>Token Builder</span>
            <div className={css.flowControlContainer}>
                <span className={css.name}>Flow control</span>
                <div className={css.flowControl}>
                    <EventNode name={"start"} type={"canvas"} />
                    <EventNode name={"context"} type={"reward"} />
                    <EventNode name={"cohort"} type={"assignee"} />
                    <EventNode name={"end"} type={"canvas"} />
                </div>
            </div>
            <div className={css.task}>
                <span className={css.taskTitle}>Task</span>
                <div className={css.taskCards}>
                    {Array.isArray(Object.keys(NODES)) &&
                        Object.keys(NODES).map((n, i) => {
                            return (
                                <div key={i} className={css.taskCard}>
                                    <div className={css.taskName}>{NODES[n].name} </div>
                                    <div className={css.content}>
                                        {Array.isArray(NODES[n].nodes) &&
                                            NODES[n].nodes.map((node, j) => {
                                                return <CustomNode key={j} node={node} type={node.type} />;
                                            })}
                                    </div>
                                </div>
                            );
                        })}
                </div>
            </div>
        </div>
    );
}

export default TokenBuilder;
