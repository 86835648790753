// import { createStandaloneToast } from "@chakra-ui/toast";
import css from "./notificationService.module.scss";
import { getIcon } from "./notificationServiceData.jsx";
import { assetMapITIL } from "../assetMap/assetMapITIL.js";
import { notification } from "antd";

// const { toast } = createStandaloneToast();
//we are on way to remove chakra ui library

// export const toast = ({ status, title, description, handleChangeToast }) => {
//     console.log(handleChangeToast);
//     let icon = getIcon(status);
//     return (
//         <div className={css.container}>
//             <div className={css.left}>{icon.icon}</div>
//             <div className={css.center}>
//                 <span className={`${css.top} ${status == "info" ? css.info : css.success}`}>{title}</span>
//                 {description !== "" && <span className={css.bottom}>{description}</span>}
//             </div>
//             <div className={css.right} onClick={() => handleChangeToast(null)}>
//                 <img src={assetMapITIL.ITIL.closeIcon} alt="" />
//             </div>
//         </div>
//     );
// };

export const showToast = ({ status, title, description }) => {
    // let icon = getIcon(status);
    // console.log(icon);
    // toast({
    //     status, // Can be "success", "error", "warning", "info"
    //     duration: 2000,
    //     isClosable: true,
    //     position: "top-right",
    //     // render: () => (
    //     //     <div className={css.container}>
    //     //         <div className={css.left}>{icon.icon}</div>
    //     //         <div className={css.center}>
    //     //             <span className={`${css.top} ${status == "info" ? css.info : css.success}`}>{title}</span>
    //     //             {description !== "" && <span className={css.bottom}>{description}</span>}
    //     //         </div>
    //     //         <div className={css.right}>
    //     //             <svg xmlns="http://www.w3.org/2000/svg" width="70%" height="75%" viewBox="0 0 14 15" fill="none">
    //     //                 <path d="M13 1.12842L1 13.1284" stroke="#C1C7D1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    //     //                 <path d="M1 1.12842L13 13.1284" stroke="#C1C7D1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    //     //             </svg>
    //     //         </div>
    //     //     </div>
    //     // ),
    //     title: title,
    //     description: description,
    // });
};

export const showSuccessToast = (title, description = "") => {
    notification["success"]({
        message: title,
        description: description,
    });
};

export const showErrorToast = (title, description = "") => {
    notification["error"]({
        message: title,
        description: description,
    });
};

export const showWarningToast = (title, description = "") => {
    notification["warning"]({
        message: title,
        description: description,
    });
};

export const showInfoToast = (title, description = "", handleChangeToast) => {
    notification["info"]({
        message: title,
        description: description,
    });
};
