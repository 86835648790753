import {
    TOKEN_SIMULATION
} from "../../components/TeDaaSV3/Constants/Constants";

export const tokenSimulation = (key, data) => ({
    type: TOKEN_SIMULATION.action,
    payload: data,
    key: key
});

export const tokenSimulationHeaderOptions = (key, data) => ({
    type: TOKEN_SIMULATION.header,
    payload: data,
    key: key
});
