import PropTypes from "prop-types";

function RightArrowSVG({ stroke = "#cccc" }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 12 13" fill="none" stroke={stroke}>
            <path d="M3.297 1.052l5.271 5.272a.411.411 0 010 .6l-5.271 5.271" />
        </svg>
    );
}

RightArrowSVG.propTypes = {
    stroke: PropTypes.string.isRequired,
};

export default RightArrowSVG;
