import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import css from "./MintModal.module.scss";
import burnImg from "../../../../TeDaaS-Assets/Modals/editMint.svg";
import closeImg from "../../../../TeDaaS-Assets/Nodes/close.svg";
import SliderWithButtons from "../../../UI/SliderWithButtons/SliderWithButtons";
import { tokenActionCloseModal } from "../../../../../../redux/actions/tokenCreationOptionsAction";
import { useParams } from "react-router-dom";
import { updateLoader } from "../../../../../../redux/actions/loaderAction";
import { useERC20TokenAction } from "../../../../Hooks/useERC20TokenAction";
import { showErrorToast, showSuccessToast } from "../../../../../../services/notificationService";

const MintModal = ({totalSupply}) => {
    const dispatch = useDispatch();
    const modelData = useSelector((state) => state.tco);
    const { address } = useParams();
    const { isValidAddress, mintToken } = useERC20TokenAction();
    const handleCancel = () => {
        dispatch(tokenActionCloseModal());
    };
    const [data, setData] = useState({
        label: ``,
        maximum: 5,
        minimum: 0.001,
        incrementSlider: 0.001,
        incrementButton: 0.1,
        value: 0.5,
        period: "month",
    });
    const handleOk = async () => {
        dispatch(updateLoader(true));
        const burn = await mintToken(address, data.value);
        if(burn===true){
            showSuccessToast("Mint Rate set Successfully");
            dispatch(tokenActionCloseModal());
        }else {
            showErrorToast("Failed to set Mint Rate");
        }
        dispatch(tokenActionCloseModal());
    };
    return (
        <Modal
            centered
            title={
                <div className={css.header}>
                    <div className={css.headerLeft}>
                        <div className={css.headerTop}>
                            <img className={css.icon} src={burnImg} alt="Mint" />
                            <span className={css.title}>Edit Mint Rate</span>
                        </div>
                        <div className={css.iconContainer} onClick={handleCancel}>
                            <img className={css.icon} src={closeImg} alt="Close" />
                        </div>
                    </div>
                    <div className={css.subTitleContainer}>
                        <span className={css.subTitle}>Manage Action</span>
                        <span className={css.subTitle}>Current token supply: {totalSupply}</span>
                    </div>
                </div>
            }
            closeIcon={false}
            open={modelData.actionModals["Edit Mint Rate"]}
            // className={css.main}
        >
            <div className={css.container}>
                <span className={css.title}>Adjust the mint rate settings of the token's mint schedule.</span>
                <div className={css.slider}>
                    <span className={css.label}>Edit mint rate</span>
                    <SliderWithButtons data={data} setData={setData} />
                </div>
                <div className={css.buttons}>
                    <span className={css.cancel} onClick={handleOk}>
                        Cancel
                    </span>
                    <span className={css.submit} onClick={handleOk}>
                        Submit
                    </span>
                </div>
            </div>
        </Modal>
    );
};
export default MintModal;
