import { AxiosHeaders } from "axios";
import axios from "./axios";

export const services = {
    TF_INGESTION: "https://ig.gov-cloud.ai/tf-entity-ingestion/v1.0",
    VITE_XPX_TOKEN : `eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICI3Ny1NUVdFRTNHZE5adGlsWU5IYmpsa2dVSkpaWUJWVmN1UmFZdHl5ejFjIn0.eyJleHAiOjE3MjI5NzU4MDMsImlhdCI6MTcyMjkzOTgwMywianRpIjoiNmRkYTlmNDItMjU3Ni00Y2ZjLTk4ODQtNjExZWU0NjE3NjgyIiwiaXNzIjoiaHR0cDovL2tleWNsb2FrLmtleWNsb2FrLnN2Yy5jbHVzdGVyLmxvY2FsOjgwODAvcmVhbG1zL21hc3RlciIsImF1ZCI6WyJCT0xUWk1BTk5fQk9UIiwiUEFTQ0FMX0lOVEVMTElHRU5DRSIsIkhPTEFDUkFDWSIsImFjY291bnQiXSwic3ViIjoiYmE3Nzg5NmYtN2MxZi00NTAxLWI0ZjYtNTQ3YTdkMjZkZGU2IiwidHlwIjoiQmVhcmVyIiwiYXpwIjoiTU9ORVQiLCJzZXNzaW9uX3N0YXRlIjoiNzk3NTBiODYtODQ2MS00YzlhLTgzYjAtOTM2ZWNmNjE5ZGJhIiwibmFtZSI6Ik1vYml1cyB4cHhAZ2FpYW5zb2x1dGlvbnMuY29tIiwiZ2l2ZW5fbmFtZSI6Ik1vYml1cyIsImZhbWlseV9uYW1lIjoieHB4QGdhaWFuc29sdXRpb25zLmNvbSIsInByZWZlcnJlZF91c2VybmFtZSI6InBhc3N3b3JkX3RlbmFudF94cHhAZ2FpYW5zb2x1dGlvbnMuY29tIiwiZW1haWwiOiJwYXNzd29yZF90ZW5hbnRfeHB4QGdhaWFuc29sdXRpb25zLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJhY3IiOiIxIiwicmVhbG1fYWNjZXNzIjp7InJvbGVzIjpbImRlZmF1bHQtcm9sZXMtbWFzdGVyIiwib2ZmbGluZV9hY2Nlc3MiLCJ1bWFfYXV0aG9yaXphdGlvbiJdfSwicmVzb3VyY2VfYWNjZXNzIjp7IkJPTFRaTUFOTl9CT1QiOnsicm9sZXMiOlsiQk9MVFpNQU5OX0JPVF9VU0VSIl19LCJQQVNDQUxfSU5URUxMSUdFTkNFIjp7InJvbGVzIjpbIlBBU0NBTF9JTlRFTExJR0VOQ0VfVVNFUiJdfSwiSE9MQUNSQUNZIjp7InJvbGVzIjpbIkhPTEFDUkFDWV9VU0VSIl19LCJhY2NvdW50Ijp7InJvbGVzIjpbIm1hbmFnZS1hY2NvdW50IiwibWFuYWdlLWFjY291bnQtbGlua3MiLCJ2aWV3LXByb2ZpbGUiXX19LCJzY29wZSI6InByb2ZpbGUgZW1haWwiLCJzaWQiOiI3OTc1MGI4Ni04NDYxLTRjOWEtODNiMC05MzZlY2Y2MTlkYmEiLCJ0ZW5hbnRJZCI6ImJhNzc4OTZmLTdjMWYtNDUwMS1iNGY2LTU0N2E3ZDI2ZGRlNiJ9.aLICxII7TJJTMzFnE9Shhjn_wxGHek_8cVCaFnX8U5igTqcWvMHnfHcJytSLUcrq7bVBO4y3gQsQCjJlWpe8yYTy5rQ4hp7O9DljJyM3vqEa_j2IwELUW3cpgQoJw4X68jX4zMbqNomIDYHQleA144k0jf0ZvsqDWaTTpFZIM505GP9771BfrQzm3EyNWz8pEPig_MtvnnTH4qMtBQrYW1CWkE4eueSwZcv18i8Gf9vSLJKV6v0woxfZtEv8XgLn6CwtbEasX_J3BofqBB0lV7_v-siQuReSCT1CWIyRgzthUp2zVFg96vudQzMYCJtQosKmtBzYiM5mW3ZPdyWnlQ`
};

export async function getSchemaEntities(schemaId) {
    try {
        if (!schemaId) {
            throw new Error("schemaId is required!!");
        }
        const res = await axios.get(`${services.TF_INGESTION}/schemas/${schemaId}/instances/list`, {
            headers: {
                Authorization: `Bearer ${services.VITE_XPX_TOKEN}`,
            },
        });
        return res;
    } catch (error) {
        console.error("getSchemaEntities", error);
    }
}

export async function getSchemaEntitiesWithFilters(schemaId, filters = {}) {
    try {
        if (!schemaId) {
            throw new Error("schemaId is required!!");
        }
        const res = await axios.post(`${services.TF_INGESTION}/schemas/${schemaId}/instances/list`, filters);
        return res;
    } catch (error) {
        console.error("getSchemaEntities", error);
    }
}



export const fetchPolicyEntities = async ({ queryKey }) => {
    const [FindIn, FindWhatValue, findWhatKey] = queryKey;

    if (!FindWhatValue) {
        throw new Error("FindWhatValue is required!!");
    }

    try {
        const res = await axios.post(
            `${services.TF_INGESTION}/schemas/${FindIn}/instances/list`,
            { [findWhatKey]: FindWhatValue }, // Use dynamic key-value pair
            {
                headers: {
                    Authorization: `Bearer ${services.VITE_XPX_TOKEN}`,
                }
            }
        );
        return res.data; // Return only the data
    } catch (error) {
        console.error("fetchPolicyEntities", error);
        throw error; // Re-throw the error so that react-query can handle it
    }
};


export const IngestPolicyEntities = async ({ queryKey }) => {
    const [policySchema, payload] = queryKey;

    if (!payload) {
        throw new Error("payload is required!!");
    }

    try {
        const res = await axios.post(
            `${services.TF_INGESTION}/schemas/${policySchema}/instances?upsert=true`, // Correct the endpoint
            payload, // Ensure the payload is passed directly
            {
                headers: {
                    Authorization: `Bearer ${services.VITE_XPX_TOKEN}`, // Pass the token correctly
                }
            }
        );
        return res.data; // Return only the data
    } catch (error) {
        console.error("IngestPolicyEntities", error);
        throw error; // Re-throw the error so that react-query can handle it
    }
};


export const updateEntityInSchema = async ({ schemaId, entity, entityId, updatedValues }) => {
    try {
        const response = await axios.post(
            `${services.TF_INGESTION}/schemas/${schemaId}/instance?upsert=true`,
            {
                ...updatedValues, 
                [entity]: entityId
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${services.VITE_XPX_TOKEN}`
                }
            }
        );
        return response.data; // Return the response data
    } catch (error) {
        console.error("Failed to update entity", error);
        throw error;
    }
};


