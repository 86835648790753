import React, { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import styles from "./RewardDistributionStackedBarChart.module.scss";
import { useSelector } from "react-redux";
import { BUSINESS_REWARDS, INCENTIVES, STAKING } from "../../../../Constants/Constants";

const RewardDistributionStackedBarChart = () => {
    const tokenSimulationData = useSelector((state) => state.tsr);
    const fetchedData = useSelector((state) => state.tc);
    const rewards = tokenSimulationData.reward.reward;
    let colors = [];
    Object.keys(tokenSimulationData.reward.colors).forEach((key) => {
        colors.push(tokenSimulationData.reward.colors[key]);
    });
    let maxLength = tokenSimulationData.reward.data.length;
    const [chartData, setChartData] = useState({});
    useEffect(() => {
        try {
            const colors = tokenSimulationData.reward.colors;
            const series = Object.keys(rewards).map((name) => ({
                name: rewards[name].name,
                type: "bar",
                stack: "total",
                barWidth: "20px",
                color: tokenSimulationData.reward.colors[name],
                itemStyle: {
                    borderRadius: 0,
                    borderColor: "#fff",
                    borderWidth: 1,
                },
                emphasis: {
                    focus: "series",
                    label: {
                        show: true,
                        fontSize: 12,
                    },
                },
                label: {
                    show: false,
                    position: "top",
                    verticalAlign: "middle",
                },
                data: tokenSimulationData.reward.data.map((item) => item[name]),
            }));

            const chartOptions = {
                title: {
                    text: "Reward Distribution",
                    left: "1%",
                    textStyle: {
                        fontFamily: "Manrope",
                    },
                    // left: '5%'
                },
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "cross",
                    },
                },
                legend: {
                    show: true,
                    orient: "vertical",
                    // align: 'vertical',
                    // itemGap: 15,
                    left: "right",
                    // right: '10%',
                },
                grid: {
                    left: 100,
                    right: 100,
                    top: 50,
                    bottom: 50,
                },
                yAxis: {
                    type: "value",
                    splitLine: {
                        show: false,
                    },
                    axisLine: {
                        lineStyle: {
                            color: "#98A2B3",
                        },
                    },
                    axisLabel: {
                        fontFamily: "Manrope",
                        fontSize: 14,
                        formatter: function (params) {
                            const suffixes = [
                                { threshold: 1_000_000_000_000, suffix: "t" },
                                { threshold: 1_000_000_000, suffix: "b" },
                                { threshold: 1_000_000, suffix: "m" },
                                { threshold: 1_000, suffix: "k" },
                            ];
                            for (const { threshold, suffix } of suffixes) {
                                if (params >= threshold) {
                                    return (params / threshold).toFixed(1) + suffix;
                                }
                            }
                            return params;
                        },
                    },
                },
                xAxis: {
                    type: "category",
                    data: tokenSimulationData.reward.data.map((ele) => ele.month),
                    axisLine: {
                        lineStyle: {
                            color: "#98A2B3",
                        },
                    },
                    axisTick: {
                        show: false,
                        lineStyle: {
                            color: "#D9D9D9",
                        },
                    },
                    axisLabel: {
                        fontFamily: "Manrope",
                        fontSize: 14,
                    },
                },
                series: series,
            };
            setChartData(chartOptions);
        } catch (e) {
            console.log("Reward Distribution Stacked Bar Chart", e);
        }
    }, [tokenSimulationData, fetchedData]);

    return (
        <div className={styles.container}>
            <ReactECharts option={chartData} style={{ height: "var(--TClamp330px)" }} />
        </div>
    );
};

export default RewardDistributionStackedBarChart;
