import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.jsx";
import "./index.css";

import { BrowserRouter } from "react-router-dom";
import AllProviders from "./AllProviders.jsx";
// import { ChakraProvider } from "@chakra-ui/react";
// import { ContextProvider } from "./components/Marco/Context/MarcoContext.jsx";

// import { Provider } from "react-redux";
// import store from "./redux/store.js";

ReactDOM.createRoot(document.getElementById("root")).render(
    <AllProviders>

    <BrowserRouter>
        <App />
    </BrowserRouter>
    </AllProviders>
);
