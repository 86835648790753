import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
    configureToken,
} from "../../../../redux/actions/tokenCreationAction";
import css from "./BurnModel.module.scss";
import burnImg from "../../TeDaaS-Assets/Nodes/changeOwner.svg";
import closeImg from "../../TeDaaS-Assets/Nodes/close.svg";
import "./emptyAntModelDefaultCss.css";
import SliderWithButtons from "../UI/SliderWithButtons/SliderWithButtons";
import { tokenCreationCloseModel } from "../../../../redux/actions/tokenCreationOptionsAction";

const BuybackModel = () => {
    const dispatch = useDispatch();
    const fetchedData = useSelector((state) => state.tc);
    const modelData = useSelector((state) => state.tco);
    const [address, setAddress] = useState("");
    const [data, setData] = useState({
        name: `Buyback %`,
        maximum: 5,
        minimum: 0.001,
        incrementSlider: 0.001,
        incrementButton: 0.1,
        value: 0.5,
        period: "month",
    });
    const handleOk = () => {
        dispatch(
            configureToken("buyback",{
                can: true,
                address: address,
                percent: typeof data.value == "string" ? parseFloat(data.value) : data.value,
                createdOn: fetchedData.buyback.can ? fetchedData.buyback.createdOn : new Date().getTime(),
                updatedOn: new Date().getTime(),
            }),
        );
        dispatch(tokenCreationCloseModel());
    };
    const handleCancel = () => {
        dispatch(tokenCreationCloseModel());
    };

    useEffect(() => {
        if (fetchedData.buyback.can) {
            setData({ ...data, value: fetchedData.buyback.percent, address: fetchedData.buyback.address });
        }
    }, []);
    return (
        <Modal
            title={
                <div className={css.header}>
                    <div className={css.headerLeft}>
                        <div className={css.headerTop}>
                            <img className={css.icon} src={burnImg} alt="Burn" />
                            <span className={css.title}>Buyback</span>
                        </div>
                        <span className={css.subTitle}>Current token supply: {fetchedData.initialSupply}</span>
                    </div>
                    <div className={css.headerRight} onClick={handleCancel}>
                        <img className={css.icon} src={closeImg} alt="Close" />
                    </div>
                </div>
            }
            closeIcon={false}
            open={modelData.models.buyback}
            className={css.main}
        >
            <div className={css.changeOwner}>
                <SliderWithButtons data={data} setData={setData} />
                <span className={css.title}>Wallet address</span>
                <input
                    type="text"
                    placeholder="Enter address..."
                    className={css.input}
                    value={address}
                    onChange={(e) => {
                        setAddress(e.target.value);
                    }}
                />
                <span className={css.save} onClick={handleOk}>
                    Save
                </span>
            </div>
        </Modal>
    );
};
export default BuybackModel;
