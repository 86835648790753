import {
    FETCH_TOKEN_CREATION,
    TOKEN_CREATION_ASSIGNEE,
    TOKEN_CREATION_REWARD,
    FETCH_TOKEN_CREATION_ASSIGNEE,
    FETCH_TOKEN_CREATION_REWARDS,
    TOKEN_CREATION,
    CONFIGURE_TOKEN,
    CONFIGURE_TOKEN_VAL,
    TOKEN_CREATION_DELETE_REWARD,
    TOKEN_CREATION_DELETE_ASSIGNEE,
    TOKEN_CREATION_RESET,
} from "../../components/TeDaaSV3/Constants/Constants";

export const fetchTokenCreationDetails = () => ({
    type: FETCH_TOKEN_CREATION,
});

export const tokenCreation = (data) => ({
    type: TOKEN_CREATION,
    payload: {
        tokenName: data.tokenName,
        owner: data.owner,
        decimals: data.decimals,
        symbol: data.symbol,
        icon: data.icon,
        objective: data.objective,
        initialSupply: data.initialSupply,
        isNewToken: data.isNewToken,
    },
});

export const fetchTokenCreationAssignee = () => ({
    type: FETCH_TOKEN_CREATION_ASSIGNEE,
});

export const fetchTokenCreationRewards = () => ({
    type: FETCH_TOKEN_CREATION_REWARDS,
});

export const configureToken = (name, data) => ({
    type: CONFIGURE_TOKEN,
    payload: data,
    name: name,
});
export const configureTokenVal = (name, data) => ({
    type: CONFIGURE_TOKEN_VAL,
    payload: data,
    name: name,
});

export const tokenCreationAssignee = (data) => ({
    type: TOKEN_CREATION_ASSIGNEE,
    payload: data,
});

export const tokenCreationReward = (data) => ({
    type: TOKEN_CREATION_REWARD,
    payload: data,
});

export const tokenCreationDeleteReward = (data) => ({
    type: TOKEN_CREATION_DELETE_REWARD,
    payload: data,
});

export const tokenCreationDeleteAssignee = (data) => ({
    type: TOKEN_CREATION_DELETE_ASSIGNEE,
    payload: data,
});
export const tokenCreationReset = () => ({
    type: TOKEN_CREATION_RESET,
});
