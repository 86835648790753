import React, { useEffect } from "react";

import css from "./ProposalCreation.module.scss";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateShowRightSideBar } from "../../../../../redux/actions/rightSideBarAction";
import { updateTopBarStyle } from "../../../../../redux/actions/topbarActions";
import { Button } from "antd";

const ProposalCreation = () => {
    const fetchedData = useSelector((state) => state.pc);
    const dispatch = useDispatch();
    const navigateTo = useNavigate();
    useEffect(() => {
        if (fetchedData.daoAddress.length === 0) {
            navigateTo("/tedaasV3/daoDashboard");
        }
        dispatch(updateShowRightSideBar(true));
        dispatch(
            updateTopBarStyle(
                <div
                    className={css.headerTitle}
                    onClick={() => {
                        navigateTo("/tedaasV3/dao/"+fetchedData.daoAddress);
                    }}
                >
                    <span className={css.greyText}>{"<"}</span>
                    <div className={css.gap}></div>
                    <span className={css.greyText}>DAO Studio</span>
                    <span className={css.greyText}>/</span>
                    <span className={css.greyText}>Governance</span>
                    <span className={css.greyText}>/</span>
                    <span className={css.blackText}>Create a Proposal</span>
                </div>,
                "#ffffff",
            ),
        );
    }, []);

    return (
        <div className={css.mainContainer}>
            <Outlet />
        </div>
    );
};

export default ProposalCreation;
