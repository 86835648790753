import React, { useEffect, useState } from "react";
import css from "./ParameterNode.module.scss";
import userImg from "../../../../TeDaaS-Assets/Nodes/user.svg";
import { useDispatch, useSelector } from "react-redux";
import { generateTooltipData, generateTooltipDate, generateTooltipFocus, getName } from "../../../../Constants/reusableFunctions";
import MintModel from "../../../Modals/MintModel";
import BurnModel from "../../../Modals/BurnModel";
import ChangeOwnerModel from "../../../Modals/ChangeOwnerModel";
import { configureToken } from "../../../../../../redux/actions/tokenCreationAction";
import AssigneeModel from "../../../Modals/AssigneeModel";
import RewardModel from "../../../Modals/RewardModel";
import BuybackModel from "../../../Modals/BuybackModel";
import TransactionTaxModel from "../../../Modals/TransactionTaxModel";
import { Tooltip } from "antd";
import TooltipContent from "../../../UI/TooltipContent/TooltipContent";
import { useDrag } from "react-dnd";
import { tokenCreationOpenModel, tokenCreationSetDrag } from "../../../../../../redux/actions/tokenCreationOptionsAction";

function ParameterNode({ name, type, id = "" }) {
    const dispatch = useDispatch();
    const fetchedData = useSelector((state) => state.tc);
    const [visible, setVisible] = useState(false);
    const [{ isDragging, opacity, rotate }, drag, preview] = useDrag(
        () => ({
            type: "any",
            item: { name, type, id, module: "tedaas" },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
                opacity: monitor.isDragging() ? 0.5 : 1,
            }),
        }),
        [name, type],
    );
    useEffect(() => {
        if (name === "pause") {
            dispatch(configureToken("pause", { can: true }));
        } else if (name === "blacklist") {
            dispatch(configureToken("blacklist", { can: true }));
        }
    }, []);
    const handleManualClose = () => {
        setVisible(false);
    };
    const handleClick = () => {
        if (type === "Assignee") {
            dispatch(tokenCreationOpenModel("assignee", name, id));
        } else if (type === "Reward") {
            dispatch(tokenCreationOpenModel("reward", name, id));
        } else {
            dispatch(tokenCreationOpenModel(name));
        }
        handleManualClose();
    };
    return (
        <div
            className={css.container}
            style={{ opacity: opacity }}
            ref={drag}
            onMouseOver={() => {
                dispatch(tokenCreationSetDrag(false));
            }}
            onMouseLeave={() => {
                dispatch(tokenCreationSetDrag(true));
            }}
        >
            {name === "pause" || name === "blacklist" ? (
                <div className={css.header}>
                    <div className={css.iconContainer}>
                        <img className={css.icon} src={userImg} alt={name} />
                    </div>
                    <div className={css.name}>
                        <span>{getName(name, type)}</span>
                    </div>
                </div>
            ) : (
                <Tooltip
                    placement="bottom"
                    overlayStyle={{ width: "max-content", maxWidth: "none" }}
                    open={visible}
                    trigger={"hover"}
                    onOpenChange={(e) => {
                        setVisible(e);
                    }}
                    title={
                        <TooltipContent
                            name={getName(name, type)}
                            handleClick={handleClick}
                            focus={generateTooltipFocus(fetchedData, name, type, id)}
                            data={generateTooltipData(fetchedData, name, type, id)}
                            date={generateTooltipDate(fetchedData, name, type, id)}
                        />
                    }
                >
                    <div className={css.header}>
                        <div className={css.iconContainer}>
                            <img className={css.icon} src={userImg} alt={name} />
                        </div>
                        <div className={css.name}>
                            <span>{getName(name, type)}</span>
                        </div>
                    </div>
                </Tooltip>
            )}
        </div>
    );
}

export default ParameterNode;
