import React, { useEffect, useState } from "react";
import css from "./DeployYourDAO.module.scss";
import { validateDaoCreationFormPreviosSteps } from "../../../Constants/validateDaoCreationForm";
import { daoCreationConfigureVal } from "../../../../../redux/actions/daoCreationAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DAOStudioDeployCard from "../../../Components/UI/DAOStudioDeployCard/DAOStudioDeployCard";
import DAOStudioDeployCardColumn from "../../../Components/UI/DAOStudioDeployCard/DAOStudioDeployCardColumn/DAOStudioDeployCardColumn";
import { Button } from "antd";
import { shortenAddress } from "../../../Constants/reusableFunctions";

function DeployYourDAO() {
    const fetchedData = useSelector((state) => state.dc);
    const navigateTo = useNavigate();
    const dispatch = useDispatch();
    const governanceParticipation = fetchedData.participationInGovernance;
    const maxAddresses =
        governanceParticipation.participant === "tokenHolders"
            ? governanceParticipation.hasERC20Token === true
                ? 1
                : governanceParticipation.distribution.length
            : governanceParticipation.multisigMembersAddress.length;
    const [verify, setVerify] = useState({
        blockchain: false,
        describe: false,
        participationInGovernance: false,
        governanceSettings: false,
    });
    const handleVerifyChange = (key) => {
        setVerify({ ...verify, [key]: !verify[key] });
    };
    useEffect(() => {
        const { isValid, next } = validateDaoCreationFormPreviosSteps("governanceSettings", fetchedData);
        if (isValid) {
            dispatch(daoCreationConfigureVal("progress", 125));
        } else {
            navigateTo(next);
        }
    }, []);
    return (
        <div className={css.mainContainer}>
            <div className={css.container}>
                <DAOStudioDeployCard
                    path="selectBlockchain"
                    name="Blockchain"
                    checked={verify.blockchain}
                    handleChange={() => handleVerifyChange("blockchain")}
                >
                    <DAOStudioDeployCardColumn name={"Network"} value={fetchedData.blockchain.network} />
                    <DAOStudioDeployCardColumn type={"text"} name={"Blockchain"} value={fetchedData.blockchain.blockchain} />
                </DAOStudioDeployCard>
                <DAOStudioDeployCard
                    path="describeYourDAO"
                    editable={true}
                    name="DAO"
                    checked={verify.describe}
                    handleChange={() => handleVerifyChange("describe")}
                >
                    {fetchedData.describe.logo.length > 0 && (
                        <DAOStudioDeployCardColumn type={"logo"} name={"Logo"} value={fetchedData.describe.logo} />
                    )}
                    <DAOStudioDeployCardColumn name={"Name"} value={fetchedData.describe.daoName} />
                    <DAOStudioDeployCardColumn
                        name={"ENS Subdomain"}
                        value={`${fetchedData.describe.ensSubdomain.substring(fetchedData.describe.ensSubdomain.length - 8) === ".dao.eth" ? fetchedData.describe.ensSubdomain : `${fetchedData.describe.ensSubdomain}.dao.eth`}`}
                        capitalize={false}
                    />
                    <DAOStudioDeployCardColumn name={"Summary"} value={fetchedData.describe.description} />
                    {fetchedData.describe.links.length > 0 && (
                        <DAOStudioDeployCardColumn type={"links"} name={"Links"} value={fetchedData.describe.links} />
                    )}
                </DAOStudioDeployCard>
                <DAOStudioDeployCard
                    path="participationInGovernance"
                    name="Voters"
                    editable={true}
                    checked={verify.participationInGovernance}
                    handleChange={() => handleVerifyChange("participationInGovernance")}
                >
                    <DAOStudioDeployCardColumn
                        name={"Eligible Votes"}
                        value={fetchedData.participationInGovernance.participant === "tokenHolders" ? "Token Holders" : "Multisig Members"}
                    />
                    {fetchedData.participationInGovernance.participant === "tokenHolders" &&
                        fetchedData.participationInGovernance.hasERC20Token !== false && (
                            <DAOStudioDeployCardColumn name={"Token"} value={shortenAddress(fetchedData.participationInGovernance.contractAddress)} />
                        )}
                    {fetchedData.participationInGovernance.participant === "tokenHolders" &&
                        fetchedData.participationInGovernance.hasERC20Token == false && (
                            <DAOStudioDeployCardColumn
                                name={"Supply"}
                                value={`${fetchedData.participationInGovernance.totalTokens} ${fetchedData.participationInGovernance.tokenSymbol} (mintable)`}
                            />
                        )}
                    {fetchedData.participationInGovernance.hasERC20Token == false && (
                        <DAOStudioDeployCardColumn
                            type={"address"}
                            name={"Distribution"}
                            value={
                                fetchedData.participationInGovernance.participant === "tokenHolders"
                                    ? fetchedData.participationInGovernance.hasERC20Token === true
                                        ? [fetchedData.participationInGovernance.contractAddress]
                                        : fetchedData.participationInGovernance.distribution
                                    : fetchedData.participationInGovernance.multisigMembersAddress
                            }
                        />
                    )}

                    <DAOStudioDeployCardColumn
                        name={"Proposal Creation"}
                        value={
                            fetchedData.participationInGovernance.participant === "tokenHolders"
                                ? `Members with > ${fetchedData.governanceSettings.minimumRequirement} ${fetchedData.participationInGovernance.tokenSymbol} voting power or balance`
                                : "Multisig Members"
                        }
                    />
                </DAOStudioDeployCard>

                <DAOStudioDeployCard
                    path="selectGovernanceSettings"
                    name="Voting Parameters"
                    checked={verify.governanceSettings}
                    handleChange={() => handleVerifyChange("governanceSettings")}
                >
                    {fetchedData.participationInGovernance.participant === "tokenHolders" && (
                        <DAOStudioDeployCardColumn name={"Support Threeshold"} value={`>${fetchedData.governanceSettings.supportThreeshold}%`} />
                    )}
                    {fetchedData.participationInGovernance.participant === "tokenHolders" && (
                        <DAOStudioDeployCardColumn
                            name={"Minimum Participation"}
                            value={`>${fetchedData.governanceSettings.minimumParticipation}% ${fetchedData.participationInGovernance.hasERC20Token === false ? `(>${fetchedData.governanceSettings.minimumRequirement} ${fetchedData.participationInGovernance.tokenSymbol})` : ""}`}
                        />
                    )}
                    {fetchedData.participationInGovernance.participant === "tokenHolders" && (
                        <DAOStudioDeployCardColumn
                            name={"Minimum Duration"}
                            value={`${fetchedData.governanceSettings.minimumDuration.days} days ${fetchedData.governanceSettings.minimumDuration.hours} hours ${fetchedData.governanceSettings.minimumDuration.minutes} minutes`}
                        />
                    )}
                    {fetchedData.participationInGovernance.participant === "tokenHolders" && (
                        <DAOStudioDeployCardColumn
                            name={"Early Execution"}
                            value={fetchedData.governanceSettings.earlyExecution === true ? "Yes" : "No"}
                        />
                    )}
                    {fetchedData.participationInGovernance.participant === "tokenHolders" && (
                        <DAOStudioDeployCardColumn name={"Vote Change"} value={fetchedData.governanceSettings.voteChange === true ? "Yes" : "No"} />
                    )}
                    {fetchedData.participationInGovernance.participant !== "tokenHolders" && (
                        <DAOStudioDeployCardColumn
                            name={"Minimum Approval"}
                            value={`${fetchedData.governanceSettings.minimumApproval} of ${maxAddresses} addresses`}
                        />
                    )}
                </DAOStudioDeployCard>
            </div>
            <div className={css.line}></div>
            <Button
                className={css.button}
                disabled={!verify.blockchain || !verify.describe || !verify.participationInGovernance || !verify.governanceSettings}
                type="primary"
                onClick={() => {
                    dispatch(daoCreationConfigureVal("deployYourDAO", true));
                }}
            >
                {"Deploy your DAO >"}
            </Button>
        </div>
    );
}

export default DeployYourDAO;
