import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import css from "./BurnModel.module.scss";
import burnImg from "../../TeDaaS-Assets/Nodes/assignee.svg";
import closeImg from "../../TeDaaS-Assets/Nodes/close.svg";
import "./emptyAntModelDefaultCss.css";
import SliderWithButtons from "../UI/SliderWithButtons/SliderWithButtons";
import DropDown from "../UI/DropDown/DropDown";
import InputWithPlusMinus from "../UI/InputWithPlusMinus/InputWithPlusMinus";
import { tokenCreationCloseModel } from "../../../../redux/actions/tokenCreationOptionsAction";
import { tokenCreationReward } from "../../../../redux/actions/tokenCreationAction";
import { generateEdge, generateParentNode, getName } from "../../Constants/reusableFunctions";
import { tokenCreationUpdateEdges, tokenCreationUpdateNodes } from "../../../../redux/actions/tokenCreationNodesAndEdgesAction";

const RewardModel = () => {
    const dispatch = useDispatch();
    const fetchedData = useSelector((state) => state.tc);
    const modelData = useSelector((state) => state.tco);
    const {nodes, edges} = useSelector((state) => state.tcne);
    const [data, setData] = useState({
        label: "Percentage",
        name: fetchedData.rewards[modelData.models.id]?.name || modelData.models.name!=="Add Custom" ? modelData.models.name : "",
        maximum: 5,
        minimum: 0.001,
        incrementSlider: 0.001,
        incrementButton: 0.1,
        value: 0.5,
        period: "month",
        unlock: 2,
        lock: 2,
        index: modelData.models.id,
        id: modelData.models.id,
    });
    const [page, setPage] = useState(true);
    const handleOk = () => {
        dispatch(
            tokenCreationReward({
                can: true,
                index: data.index,
                id: data.id,
                percent: typeof data.value == "string" ? parseFloat(data.value) : data.value,
                name: data.name,
                vestingPeriod: vesting,
                emissionPeriod: emission,
                lock: data.lock,
                // parameter: data.parameter,
                unlock: data.unlock,
                contexts: fetchedData.rewards[modelData.models.name]?.can ? fetchedData.rewards[modelData.models.name].contexts : [],
                createdOn: fetchedData.rewards[modelData.models.name]?.can ? fetchedData.rewards[modelData.models.name].createdOn : new Date().getTime(),
                updatedOn: new Date().getTime(),
            }),
        );
        dispatch(tokenCreationCloseModel());
        if(!fetchedData.rewards[data.id]?.can && fetchedData?.assignee?.reward?.can){
            const rewardNode = generateParentNode("Reward", "reward", data.id);
            rewardNode.position = modelData.models.position;
            dispatch(tokenCreationUpdateNodes([...nodes, rewardNode]));
            const rewardEdge = generateEdge("assigneereward", "reward"+data.id);
            dispatch(tokenCreationUpdateEdges([...edges, rewardEdge]));
        }
    };
    useEffect(() => {
        if (fetchedData.rewards[modelData.models.name]?.can) {
            setData({
                ...data,
                index: fetchedData.rewards[modelData.models.name].index,
                id: fetchedData.rewards[modelData.models.name].id,
                value: fetchedData.rewards[modelData.models.name].percent,
                lock: fetchedData.rewards[modelData.models.name].lock,
                unlock: fetchedData.rewards[modelData.models.name].unlock,
                // parameter: fetchedData.rewards[modelData.models.name].parameter,
            });
            setVesting(fetchedData.rewards[modelData.models.name].vestingPeriod);
            setEmission(fetchedData.rewards[modelData.models.name].emissionPeriod);
        }
    }, []);
    const handleCancel = () => {
        dispatch(tokenCreationCloseModel());
    };
    const optionsVesting = [
        {
            key: "month",
            label: (
                <div
                    className={css.optionText}
                    onClick={() => {
                        handleVesting("month");
                    }}
                >
                    Month
                </div>
            ),
        },
        {
            key: "quarter",
            label: (
                <div
                    className={css.optionText}
                    onClick={() => {
                        handleVesting("quarter");
                    }}
                >
                    Quarter
                </div>
            ),
        },
        {
            key: "year",
            label: (
                <div
                    className={css.optionText}
                    onClick={() => {
                        handleVesting("year");
                    }}
                >
                    Year
                </div>
            ),
        },
    ];
    const optionsEmission = [
        {
            key: "month",
            label: (
                <div
                    className={css.optionText}
                    onClick={() => {
                        handleEmission("month");
                    }}
                >
                    Month
                </div>
            ),
        },
        {
            key: "quarter",
            label: (
                <div
                    className={css.optionText}
                    onClick={() => {
                        handleEmission("quarter");
                    }}
                >
                    Quarter
                </div>
            ),
        },
        {
            key: "year",
            label: (
                <div
                    className={css.optionText}
                    onClick={() => {
                        handleEmission("year");
                    }}
                >
                    Year
                </div>
            ),
        },
    ];
    const handleVesting = (e) => {
        setVesting(e);
    };
    const handleEmission = (e) => {
        setEmission(e);
    };
    const [vesting, setVesting] = useState("month");
    const [emission, setEmission] = useState("month");
    const handleInput = (name, value) => {
        setData({ ...data, [name]: value });
    };
    const handleVestingInput = (e) => {
        setData({ ...data, lock: parseInt(e.target.innerText) });
    };
    const handleEmissionnput = (e) => {
        setData({ ...data, unlock: parseInt(e.target.innerText) });
    };
    const handleVestingIncrease = (e) => {
        setData({ ...data, lock: data.lock + 1 });
    };
    const handleEmissionIncrease = (e) => {
        setData({ ...data, unlock: data.unlock + 1 });
    };
    const handleVestingDecrease = (e) => {
        if (data.lock > 2) {
            setData({ ...data, lock: data.lock - 1 });
        }
    };
    const handleEmissionDecrease = (e) => {
        if (data.unlock > 2) {
            setData({ ...data, unlock: data.unlock - 1 });
        }
    };
    return (
        <Modal
            title={
                <div className={css.header}>
                    <div className={css.headerLeft}>
                        <div className={css.headerTop}>
                            <img className={css.icon} src={burnImg} alt="Assignee" />
                            <span className={css.title}>{fetchedData.rewards[data.id]?.name || getName(modelData.models.name, "Reward")}</span>
                        </div>
                        <span className={css.subTitle}>Current token supply: {fetchedData.initialSupply}</span>
                    </div>
                    <div className={css.headerRight} onClick={handleCancel}>
                        <img className={css.icon} src={closeImg} alt="Close" />
                    </div>
                </div>
            }
            closeIcon={false}
            open={modelData.models.reward}
            className={css.main}
        >
            <div className={css.assignee}>
                <div className={css.anchors}>
                    <div className={css.anchor} onClick={()=>{setPage(true)}}>
                        <div className={page ? css.activeAnchorNum : css.inactiveAnchorNum}>1</div>
                        <span className={page ? css.activeAnchor : css.inactiveAnchor}>Input Value</span>
                    </div>
                    <div className={css.anchor} onClick={()=>{setPage(false)}}>
                        <div className={!page ? css.activeAnchorNum : css.inactiveAnchorNum}>2</div>
                        <span className={!page ? css.activeAnchor : css.inactiveAnchor}>Set Parameters</span>
                    </div>
                </div>
                {page && <SliderWithButtons data={data} setData={setData} />}
                {page && modelData.models.name === "Add Custom" && (
                    <div className={css.tokens}>
                        <span className={css.title}>Reward Name</span>
                        <input
                            className={css.input}
                            type="text"
                            value={data.name}
                            onChange={(e) => {
                                setData({
                                    ...data,
                                    name: e.target.value,
                                    index: e.target.value,
                                    id: e.target.value,
                                });
                            }}
                        />
                    </div>
                )}
                {page && (
                    <div className={css.tokens}>
                        <span className={css.title}>Tokens</span>
                        <div className={css.input}>{Math.round(data.value * fetchedData.initialSupply)}</div>
                    </div>
                )}
                {/* {!page && (
                    <div className={css.tokens}>
                        <span className={css.title}>Set {modelData.models.name}</span>
                        <input
                            type="text"
                            className={css.input}
                            value={data.parameter}
                            placeholder={`Type here...`}
                            onChange={(e) => {
                                setData({ ...data, parameter: e.target.value });
                            }}
                        />
                    </div>
                )} */}
                {!page && (
                    <div className={css.cards}>
                        <div className={css.card}>
                            <span className={css.title}>Vesting</span>
                            <span className={css.title}>Validity (Months)</span>
                        </div>
                        <div className={css.card}>
                            <DropDown prefix="Lock" data={optionsVesting} selected={vesting} />
                            <InputWithPlusMinus
                                value={data.lock}
                                handleDecrease={() => {
                                    if (data.lock > 0) {
                                        handleInput("lock", data.lock - 1);
                                    }
                                }}
                                handleInput={(e) => {
                                    handleInput("lock", e.target.value);
                                }}
                                handleIncrease={() => {
                                    handleInput("lock", data.lock + 1);
                                }}
                                min={2}
                            />
                        </div>
                    </div>
                )}
                {!page && (
                    <div className={css.cards}>
                        <div className={css.card}>
                            <span className={css.title}>Emission</span>
                            <span className={css.title}>Validity (Months)</span>
                        </div>
                        <div className={css.card}>
                            <DropDown prefix="Unlock" data={optionsEmission} selected={emission} />
                            <InputWithPlusMinus
                                value={data.unlock}
                                handleDecrease={() => {
                                    if (data.unlock > 0) {
                                        handleInput("unlock", data.unlock - 1);
                                    }
                                }}
                                handleInput={(e) => {
                                    handleInput("unlock", e.target.value);
                                }}
                                handleIncrease={() => {
                                    handleInput("unlock", data.unlock + 1);
                                }}
                            />
                        </div>
                    </div>
                )}
                {!page && (
                    <span className={css.save} onClick={handleOk}>
                        Save
                    </span>
                )}
                {page && (
                    <span
                        className={css.save}
                        onClick={() => {
                            setPage(false);
                        }}
                    >
                        Next
                    </span>
                )}
            </div>
        </Modal>
    );
};
export default RewardModel;
