import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Table as Tab } from "antd";
import { StepForwardOutlined } from "@ant-design/icons";
import css from "./Table.module.scss";
import { v4 } from "uuid";
import { capitalizeFirstLetterOfString, copyToClipboard, formatDateToString, shortenAddress } from "../../../Constants/reusableFunctions";
import "./antTable.css";
import { useDispatch } from "react-redux";
import { tokenDataConfigureVal } from "../../../../../redux/actions/tokenDataAction";
import { daoDataConfigureVal } from "../../../../../redux/actions/daoDataAction";
import { showWarningToast } from "../../../../../services/notificationService";

const sortAscendDescendString = (title, index, j) => {
    return {
        key: v4(),
        title: title,
        dataIndex: index,
        render: (text) => <span className={css.text}>{text}</span>,
        sorter: (a, b) => a[index].localeCompare(b[index]),
        sortDirections: ["descend", "ascend"],
    };
};
const sortAscendDescendDate = (title, index, j) => {
    return {
        key: v4(),
        title: title,
        dataIndex: index,
        render: (text) => <span className={css.text}>{formatDateToString(text, "day month date year")}</span>,
        sorter: (a, b) => a[index] - b[index],
        sortDirections: ["descend", "ascend"],
    };
};
const sortAscendDescendStatus = (title, index, j) => {
    return {
        key: v4(),
        title: title,
        dataIndex: index,
        render: (text) => <span className={css[text]}>{capitalizeFirstLetterOfString(text)}</span>,
        sorter: (a, b) => a[index].localeCompare(b[index]),
        sortDirections: ["descend", "ascend"],
    };
};
const sortAscendDescendAddress = (title, index, j) => {
    return {
        key: v4(),
        title: title,
        dataIndex: index,
        render: (text) => (
            <span
                className={css.text}
                style={{ cursor: "pointer" }}
                onClick={() => {
                    copyToClipboard(text);
                }}
            >
                {shortenAddress(text)}
            </span>
        ),
        sorter: (a, b) => a[index].localeCompare(b[index]),
        sortDirections: ["descend", "ascend"],
    };
};
const sortAscendDescendTransactionAddress = (title, index, j) => {
    return {
        key: v4(),
        title: title,
        dataIndex: index,
        render: (text) => (
            <span
                className={css.text}
                style={{ cursor: "pointer" }}
                onClick={() => {
                    copyToClipboard(text);
                    window.open(`https://sepolia.etherscan.io/tx/${text}`, "_blank");
                }}
            >
                {shortenAddress(text)}
            </span>
        ),
        sorter: (a, b) => a[index].localeCompare(b[index]),
        sortDirections: ["descend", "ascend"],
    };
};
const sortAscendDescendNumber = (title, index, j) => {
    return {
        key: v4(),
        title: title,
        dataIndex: index,
        render: (text) => <span className={css.text}>{text}</span>,
        sorter: (a, b) => parseFloat(a[index]) - parseFloat(b[index]),
        sortDirections: ["descend", "ascend"],
    };
};
const sortAscendDescendStringWithIcon = (title, index, subIndex, j) => {
    return {
        key: v4(),
        title: title,
        dataIndex: index,
        render: (text) => (
            <div className={css.stringWithIcon}>
                {text?.image && <img className={css.icon} src={text.image} alt="Image" />}
                <span className={css.text}>{text[subIndex]}</span>
            </div>
        ),
        sorter: (a, b) => a[index][subIndex].localeCompare(b[index][subIndex]),
        sortDirections: ["descend", "ascend"],
    };
};
const sortAscendDescendNumberDual = (title, index, subIndex, j) => {
    return {
        key: v4(),
        title: title,
        dataIndex: index,
        render: (text) => (
            <div className={css.numberDual}>
                <span className={css.text}>{text[subIndex]}</span>
                <span className={text.isGain ? css.gain : css.loss}>{text.percent}</span>
            </div>
        ),
        sorter: (a, b) => a[index][subIndex] - b[index][subIndex],
        sortDirections: ["descend", "ascend"],
    };
};
const sortAscendDescendStringDual = (title, index, subIndex, j) => {
    return {
        key: v4(),
        title: title,
        dataIndex: index,
        render: (text) => (
            <div className={css.numberDual}>
                <span className={css.text}>{text[subIndex]}</span>
                <span className={text.isGain ? css.gain : css.loss}>{text.percent}</span>
            </div>
        ),
        sorter: (a, b) => a[index][subIndex].localeCompare(b[index][subIndex]),
        sortDirections: ["descend", "ascend"],
    };
};
const action = (title, index, handleAction) => {
    return {
        key: v4(),
        title: title,
        dataIndex: index,
        render: (text, record) => (
            <div
                onClick={() => {
                    handleAction(record);
                }}
                className={css.action}
            >
                view / edit
            </div>
        ),
        sorter: (a, b) => {},
        sortDirections: [],
    };
};
const onChange = (pagination, filters, sorter, extra) => {};
function Table({ columnDefination = [], tableData = [], path = "dao", pageLength = 10, search = "" }) {
    const [columns, setColumns] = useState([]);
    const [data, setData] = useState([]);
    const dispatch = useDispatch();
    const navigateTo = useNavigate();
    const handleAction = (record) => {
        if (path === "dao") {
            dispatch(daoDataConfigureVal(record));
            navigateTo(`/tedaasV3/dao/${record.daoAddress}`);
        } else if (path === "manageToken") {
            dispatch(tokenDataConfigureVal(record));
            navigateTo(`/tedaasV3/manageToken/${record.tokenAddress}`);
        }
    };
    useEffect(() => {
        let col = [];
        let j = 1;
        for (let i of columnDefination) {
            if (i.type === "string") {
                var t = sortAscendDescendString(i.title, i.index, j);
                col.push(t);
            } else if (i.type === "number") {
                var t = sortAscendDescendNumber(i.title, i.index, j);
                col.push(t);
            } else if (i.type === "stringWithIcon") {
                var t = sortAscendDescendStringWithIcon(i.title, i.index, i.subIndex, j);
                col.push(t);
            } else if (i.type === "numberDual") {
                var t = sortAscendDescendNumberDual(i.title, i.index, i.subIndex, j);
                col.push(t);
            } else if (i.type === "stringDual") {
                var t = sortAscendDescendStringDual(i.title, i.index, i.subIndex, j);
                col.push(t);
            } else if (i.type === "action") {
                // const path = tableData[0]?.daoAddress?.length>0 ? "createProposal/proposalMeta" : "manageToken"; // Originally instead of createProposal/proposalMeta path should be manage Dao
                var t = action(i.title, i.index, handleAction);
                col.push(t);
            } else if (i.type === "address") {
                var t = sortAscendDescendAddress(i.title, i.index, j);
                col.push(t);
            } else if (i.type === "status") {
                var t = sortAscendDescendStatus(i.title, i.index, j);
                col.push(t);
            } else if (i.type === "date") {
                var t = sortAscendDescendDate(i.title, i.index, j);
                col.push(t);
            } else if (i.type === "transactionAddress") {
                var t = sortAscendDescendTransactionAddress(i.title, i.index, j);
                col.push(t);
            }
            j++;
        }
        const dataWithKeys = Array.isArray(tableData)
            ? tableData.map((row) => ({
                  ...row,
                  key: row.id || row.address || v4(),
              }))
            : [];
        setColumns(col);
        if (search !== "") {
            const temp = dataWithKeys ? dataWithKeys.filter((d) => JSON.stringify(d).indexOf(search) > -1) : undefined;
            temp === undefined ? "" : setData(temp);
        } else {
            dataWithKeys ? setData(dataWithKeys) : "";
        }
    }, [columnDefination, tableData, path, search]);
    return (
        <Tab
            className={css.table}
            columns={columns}
            dataSource={data}
            onChange={onChange}
            showSorterTooltip={{
                target: "sorter-icon",
            }}
            pagination={{ pageSize: pageLength }}
        />
    );
}
export default Table;
